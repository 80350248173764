.payment-menu {
    height: 216px;
    background: #0C0C0C;

    @media (max-width: 1279px) {
        height: 72px;
    }
}

.payment-menu-50 {
    height: 266px;
    background: #0C0C0C;

    @media (max-width: 1279px) {
        height: 122px;
    }

    @media (max-width: 700px) {
        height: 72px;
    }
}

.slider-48{
    margin-top: 0;
}

.payment-services {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 110px;
    }

    .heading {
        margin-bottom: 25px;

        h1 {
            margin: 0 !important;
            padding: 0 !important;

            font-h1(52px, font-color-dark);
        }
    }

    &__content {
        max-width: 927px;

        p {
            font-p(17px, font-color-normal);
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 35px 30px 0;
            margin-bottom: 80px;
        }

        .heading {
            margin-bottom: 20px;

            h1 {
                font-h1(30px, font-color-dark);
            }
        }
    }
}

.payment-how {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 160px;
    }

    .heading {
        margin-bottom: 75px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__accordion {
        border-top: 1px solid #E5E5E5;
    }

    &__item {
        //border-bottom: 1px solid #E5E5E5;
    }

    &__item-button {
        height: 152px;
        width: 100%;

        display: flex;
        align-items: center;
        gap: 36px;

        background: none;
    }

    &__item-button-logo {
        height: 71px;
        width: 71px;

        img {
            display: block;
            height: 100%
            width: auto;
        }
    }

    &__item-button-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;
    }

    &__item-button-content-txt {
        display: flex;
        flex-direction: column;
        gap: 5px;

        span:nth-child(1) {
            font-h3(20px, font-color-normal)
        }

        span:nth-child(2) {
            font-p(17px, #848484 !important)
        }
    }

    &__item-button-plus {
        height: 17px;
        width: 17px;

        margin-left: 12px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;

        span {
            transition: transform 0.3s;

            width: 17px;
            height: 1px;
            background-color: font-color-green;

            position: absolute;
            top: 8px;
            right: 0;
        }

        span:first-child {
            transform: rotate(90deg);
        }
    }

    &__item-button-plus.active {
        span:first-child {
            transform: rotate(0deg);
        }
    }

    &__item-button-plus:hover {
        span {
            background-color: font-color-green-hover;
        }
    }

    &__item-content {
        transition: all 0.3s;

        background: #FBFBFB;

        padding: 0 75px;
        border-bottom: 1px solid #E5E5E5;

        p {
            transition: all 0.3s;

            margin-bottom: 0;
            max-width: 896px;

            font-p(17px, font-color-normal);
            line-height: 0%;
            color: transparent !important;
        }

        ul {
            margin-bottom: 0px;

            li {
                transition: all 0.3s;

                margin-bottom: 0px;

                marker-3();
                font-p(17px, font-color-normal);
                line-height: 0%;
                color: transparent !important;

                &:before {
                    min-height: 0px;
                }
            }

            li:last-child {
                margin-bottom: 0;
            }
        }

        .payment-how__item-content-heading {
            height: 0;
        }
    }

    &__item-content.active {
        padding: 55px 75px 75px;

        p {
            margin-bottom: 20px;

            line-height: 170%;
            color: font-color-normal;
        }

        ul {
            margin-bottom: 20px;

            li {
                margin-bottom: 5px;

                line-height: 170%;
                color: font-color-normal;

                &:before {
                    min-height: 3px;
                }
            }
        }

        .payment-how__item-content-heading {
            height: auto;
        }

        .payment-how__item-content-ul {
            li {
                height: 40px;

                img {
                    height: 40px;
                }
            }
        }
    }

    &__item-content-heading {
        p {
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500 !important;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }

        &_last {
            p {
                margin-bottom: 0 !important;
            }
        }
    }

    &__item-content-ul {
        li {
            transition: all 0.3s;

            height: 0px;
            //height: 40px;
            display: flex;
            align-items: center;
            gap: 30px;

            margin-left: 36px !important;
            margin-bottom: 0 !important;

            &:before {
                display: none;
            }

            img {
                transition: all 0.3s;

                display: block;
                //height: 40px;
                height: 0px;
                width: 48px;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        .heading {
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__item-button {
            height: auto;
            padding-top: 20px;
            margin-bottom: 20px;
            gap: 20px;
        }

        &__item-button-logo {
            height: 64px;
            width: 64px;
        }

        &__item-button-content-txt {
            gap: 0;

            span:nth-child(1) {
                font-h3(15px, font-color-normal)
            }

            span:nth-child(2) {
                font-p(15px, #848484 !important)
            }
        }

        &__item-content {
            padding: 0 20px;

            p {
                //font-p(15px, font-color-normal);
                line-height: 0%;
                color: transparent !important;
            }

            ul {
                li {
                    //font-p(15px, font-color-normal);
                    line-height: 0%;
                    color: transparent !important;
                }
            }
        }

        &__item-content.active {
            padding: 35px 20px 40px;

            p {
                font-p(15px, font-color-normal);
            }

            ul {
                li {
                    font-p(15px, font-color-normal);
                }
            }
        }

        &__item-content-ul {
            li {
                //height: 48px;
                //display: flex;
                //align-items : center;
                //gap: 30px;

                margin-left: 0 !important;

                &:before {
                    //display: none;
                }

                img {
                    //display: block;
                    //height: 40px;
                    //width: 48px;
                }
            }
        }
    }
}

.payment-faq {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 160px;
    }

    .heading {
        margin-bottom: 75px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__accordion {
        border-top: 1px solid #E5E5E5;
    }

    &__item {
        //border-bottom: 1px solid #E5E5E5;
    }

    &__item-button {
        height: 118px;
        width: 100%;

        display: flex;
        align-items: center;
        background: none;
    }

    &__item-button-number {
        min-width: 71px;
        text-align: left;

        span {
            font-p-25-500(font-color-green)
        }
    }

    &__item-button-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;

        span {
            font-p-500(17px, font-color-normal);
        }
    }

    &__item-button-plus {
        height: 17px;
        width: 17px;

        align-self: flex-end;

        margin-left: 12px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;

        span {
            transition: transform 0.3s;

            width: 17px;
            height: 1px;
            background-color: font-color-green;

            position: absolute;
            top: 8px;
            right: 0;
        }

        span:first-child {
            transform: rotate(90deg);
        }
    }

    &__item-button-plus.active {
        span:first-child {
            transform: rotate(0deg);
        }
    }

    &__item-button-plus:hover {
        span {
            background-color: font-color-green-hover;
        }
    }

    &__item-content {
        transition: all 0.3s;

        background: #FBFBFB;

        padding: 0 75px;
        border-bottom: 1px solid #E5E5E5;

        p {
            transition: all 0.3s;

            margin-bottom: 0;
            max-width: 896px;

            font-p(17px, font-color-normal);
            line-height: 0%;
            color: transparent !important;
        }

        ul {
            li {
                transition: all 0.3s;

                margin-bottom: 0px;

                marker-3();
                font-p(17px, font-color-normal);
                line-height: 0%;
                color: transparent !important;

                &:before {
                    min-height: 0px;
                }
            }

            li:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__item-content.active {
        padding: 55px 75px 75px;

        p {
            line-height: 170%;
            color: font-color-normal;
        }

        li {
            margin-bottom: 5px;

            line-height: 170%;
            color: font-color-normal;

            &:before {
                min-height: 3px;
            }
        }
    }


    @media (max-width: 1679px) {
        &__page {
            margin-bottom: 110px;
        }
    }
    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        .heading {
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }


        &__item-button {
            height: auto;
            padding-top: 20px;
            margin-bottom: 20px;

            flex-direction: column;
            align-items: flex-start;
        }

        &__item-button-content {
            span {
                font-p-500(15px, font-color-normal);
            }
        }

        &__item-button-number {
            margin-bottom: 5px;
        }

        &__item-content {
            padding: 0 20px;

            p {
                font-p(15px, font-color-normal);
                line-height: 0%;
                color: transparent !important;
            }
        }

        &__item-content.active {
            padding: 35px 20px 40px;
        }
    }
}