.org-list{
  flex-flow: row wrap;

  .item-org{
    flex: 360px 0 0;
    border: border;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;

    &__head{
      margin-bottom: 15px;

      h4{
        color: #807d7d !important;
        text-align: center;

        a{
          color: #807d7d !important;
          text-align: center;
          text-decoration: none;
          margin-left: 20px;
        }
      }
        
      i{
        float: right;
        top: -2px;
        width: 19px;
        height: 19px;
      }  
    }

    &__logo{
      height: 130px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__info{
      font-size: 14px;
    }

    &_special{
      .item-org__head{
        margin-bottom: 30px;
        h4{
          color: purple !important;
          font-weight: 600;
        }
      }

      .item-org__info{
        max-height: 130px;
      }

      .opinion{
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
      }
    }
  }

  &__clients{

    &_hidden{
      display: none;

      .item-org{
        opacity: 0;
      }
    }
  }

  &__partners{
    .item-org{
      &__info{
        max-height: 40px;
      }
    }
  }

  @media (max-width: 1160px){
    justify-content center

    .item-org{
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media (max-width: 900px){
    .item-org{
      margin-left: 4px;
      margin-right: 4px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 765px){
    .item-org{
      margin: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 749px){
    .item-org{
      height: auto;

      &__info{
        max-height: inherit;
      }

      &_special{
        .item-org__info{
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 425px){
    .item-org{
      flex: 100% 0 0;
    }
  }
}