typeImageWebp = 'type("image/webp")'
typeImageJpeg = 'type("image/jpeg")'

.recommendation-repair-hero {
  position: relative;
  background: #073A1C linear-gradient(180deg, #0e1a13 0%, #0c2014 28%, #093019 52.86%, #073a1c 73.29%, #073a1c 100%);
  min-height: 700px;
  padding-top: 300px;

  @media (max-width: $bp-lg) {
    min-height: 776px;
    padding-top: 492px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1920px;
    background: url(/src/img/pages/recommendations-repair-hero-bg.jpg) bottom center no-repeat;
    background-image: image-set(
            url(/src/img/pages/recommendations-repair-hero-bg-2x.webp) unquote(typeImageWebp) 2x,
            url(/src/img/pages/recommendations-repair-hero-bg.webp) unquote(typeImageWebp) 1x,
            url(/src/img/pages/recommendations-repair-hero-bg-2x.jpg) unquote(typeImageJpeg) 2x,
            url(/src/img/pages/recommendations-repair-hero-bg.jpg) unquote(typeImageJpeg) 1x
    );

    @media (max-width: $bp-lg) {
      background-position: top center;
      background-image: url(/src/img/pages/recommendations-repair-hero-bg-sm.jpg); // fallback
      background-image: image-set(
              url(/src/img/pages/recommendations-repair-hero-bg-sm-2x.webp) unquote(typeImageWebp) 2x,
              url(/src/img/pages/recommendations-repair-hero-bg-sm.webp) unquote(typeImageWebp) 1x,
              url(/src/img/pages/recommendations-repair-hero-bg-sm-2x.jpg) unquote(typeImageJpeg) 2x,
              url(/src/img/pages/recommendations-repair-hero-bg-sm.jpg) unquote(typeImageJpeg) 1x
      );
    }

    @media (min-width: 1921px) {
      mask-image: linear-gradient(90deg, transparent 0px, #000 80px, #000 calc(100% - 80px), transparent 100%);
    }
  }

  &__inner {
    position: relative;
    max-width: 658px;

    @media (max-width: $bp-lg) {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  &__heading {
    font-family: $font-family-heading;
    font-weight: 500;
    font-size: 52px;
    line-height: 1.2;
    letter-spacing: 0;
    color: #F5F1F8;
    margin: 0 0 20px;

    @media (max-width: $bp-lg) {
      font-size: 30px;
      margin-bottom: 26px;
    }

    h1 {
      color: inherit !important;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-weight: inherit;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  &__sub-heading {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.35;
    letter-spacing: .02em;
    color: #F8F8F8;

    @media (max-width: $bp-lg) {
      font-size: 17px;
      line-height: 1.7;
      letter-spacing: .03em;
    }
  }

}

.recommendation-repair-section {

  .h2 {
    font-family: $font-family-heading;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .01em;
    color: #202020;
    margin: 0 0 25px;

    @media (max-width: $bp-lg) {
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    h1 {
      color: inherit !important;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      font-weight: inherit;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

}

.recommendation-repair-info {
  display flex;
  flex-wrap: wrap;
  align-items center;
  justify-content: space-between;
  gap: 45px 64px;
  margin-top: 80px;
  max-width: 1228px;

  @media (max-width: $bp-lg) {
    margin-top: 45px;
  }
}

.recommendation-repair-icons {
  display flex;
  flex-wrap: wrap;
  align-items flex-start;
  gap: 35px 65px;

  @media (max-width: 1366px) {
    column-gap: 45px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: $bp-md) {
      flex-basis: 100%;
    }

    svg {
      flex: 0 0 auto;
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    flex: 1 1 0;
  }
}

.recommendation-repair-warning {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background: #F8F8F8;
  padding: 15px 25px;
  border-radius: 4px;

  svg {
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
  }

  &__text {
    flex: 1 1 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: .01em;
    color: #202020;
  }
}

.recommendation-repair-options {
  margin-top: 80px;

  &__item {
    border-bottom: 1px solid #E5E5E5;
    padding: 70px 0;

    @media (max-width: 1366px) {
      padding: 55px 0;
    }

    @media (max-width: $bp-lg) {
      padding: 45px 0;
    }

    &:first-child {
      border-top: 1px solid #E5E5E5;
    }
  }

  &__item-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    max-width: 1228px;
  }

  &__image {
    align-self: stretch;
    display: flex;
    width: 657px;
    height: 520px;
    background: #F8F8F8;
    border-radius: 6px;

    @media (max-width: 1366px) {
      flex: 1 1 calc(50% - 18px);
      height: auto;
    }

    img {
      width: 100%;
      min-height: 254px;
      object-fit: contain;

      @media (max-width: 1366px) {
        height: auto;
      }

      @media (max-width: 800px) {
        object-fit: cover;
      }
    }
  }

  &__desc {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1366px) {
      flex: 1 1 calc(50% - 18px);
    }

    @media (max-width: 800px) {
      flex-basis: 100%;
      display: block;
    }
  }

  &__desc-inner {
    flex: 1 1 0;
    max-width: 418px;

    @media (max-width: 1366px) {
      max-width: 438px;
    }

    @media (max-width: 800px) {
      max-width: initial;
    }
  }

  &__heading {
    font-family: $font-family-heading;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .01em;
    color: #202020;
    margin-bottom: 25px;

    @media (max-width: $bp-lg) {
      font-size: 17px;
      line-height: 1.5;
    }
  }

}
