.footer-menu{
    background-color: #202020;

    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        //max-width: 1796px;
        //padding: 0 98px;
        max-width 1440px;
        padding: 0 55px;



        display: flex;
        justify-content: space-between;
    }

    &__address{
        padding: 85px 0 60px;

        display: flex;
        flex-direction: column;
    }

    &__address-button{
        margin-bottom: 90px;

        button{
            background-color: transparent;
            padding: 20px 45px 21px;

            border: 1px solid rgba(245, 241, 248, 0.25);
            border-radius: 6px;

            font-p-20-500(#F5F1F8);
            font-weight: 400;
        }

        button:hover{
            border: 1px solid #F5F1F8;
        }

    }

    &__address-city{
        display: flex;
        align-items: center;
        gap: 7px;

        margin-bottom: 20px;

        span{
            font-p-20-500(#FFFFFF);
            font-weight: 400;
        }

        svg{
            path{
                fill: #FFFFFF;
            }
        }
    }

    &__address-street{
        margin-bottom: 50px;

        span{
            display: block;
            max-width: 229px;

            font-p(17px, #C6C6C6);
            letter-spacing: 0.03em;
        }
    }

    &__contacts{
        // margin-bottom: 193px;
        margin-bottom: 50px;

        a{
            cursor: pointer;

            text-decoration: none;

            font-p-20-500(#FFFFFF);
            font-weight: 400;
        }
    }

    &__logo{
        margin-top: auto;

        width: 250px;

        a{
            text-decoration: none;
        }

        img{
            display: block;
            width: 100%;
        }
    }

    &__menu{
        padding: 80px 0 59px;

        display: flex;
        flex-direction: column;
    }

    &__filter-buttons{
        display: flex;
        gap: 30px;

        margin-bottom: 60px;

        button{
            background-color: transparent;
            border-bottom: 1px solid rgba(245, 241, 248, 0.45);
            border-radius: 0;
        }

        span{
            display: block
            height: 100%;
            width: 100%;

            //padding: 18px 135px 20px 0;
            padding: 18px 85px 20px 0;
            border-bottom: 2px solid transparent;

            font-p-20-500(rgba(245, 241, 248, 0.45));
            font-weight: 400;
        }

        button:hover, button.active{
            border-bottom: 1px solid #FFFFFF;

            span{
                border-bottom: 2px solid #FFFFFF;

                color: #F5F1F8;
            }
        }
    }

    &__filter-cards{
        // height: 313px;

        margin-bottom: 100px;
    }

    &__filter-card{
        display: none;
        flex-wrap: wrap;
        gap: 72px;
        color: white;
        max-width: 986px;

        @media (max-width: 1679px) {
            max-width: 866px;
        }

        div{
            display: flex;
            flex-direction: column;
        }

        span{
            margin-bottom: 20px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 145%;
            letter-spacing: 0.02em;
            color: #F5F1F8;
        }

        a{
            margin-bottom: 15px;
            text-decoration: none;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 170%;
            letter-spacing: 0.03em;
            color: #C6C6C6;
        }

        a:hover{
            color: #F5F1F8;
        }

        &_2{
            display: flex;
            flex-direction: row !important;
            gap: 72px;
        }

        &_3 {
            flex: 1 0 100%;
        }
    }

    &__filter-card.active{
        display: flex;
    }

    &__footnote{
        margin-top: auto;

        border-top: 1px solid rgba(245, 241, 248, 0.25);
        padding-top: 25px;

        display: flex;
        justify-content: space-between;
    }

    &__footnote-copyright{
        span{
            font-p-500(12px, #C6C6C6);
            font-weight: 300;
        }
    }

    &__footnote-agreement{
        display: flex;
        gap: 60px;

        a{
            text-decoration: none;
            font-p-500(12px, #C6C6C6);
            font-weight: 300;
        }

        a:hover{
            color: #F5F1F8;
        }
    }

    @media (max-width: 1679px){
        &__page{
            max-width: 1440px;

            padding: 0 55px;

            gap: 46px;
        }

        &__filter-buttons{
            span{
                padding: 18px 55px 20px 0;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0 30px;

            flex-direction: column;
            gap: 0;
        }

        &__filter{
            display: none;
        }

        &__address{
            align-items: center;

            padding: 40px 0 0;
        }

        &__address-revers{
            display: flex;
            flex-direction: column-reverse;
        }

        &__address-city{
            justify-content: center;

            margin-bottom: 15px;

            span{
                font-p-20-500(#FFFFFF);
                font-size: 17px;
            }
        }

        &__address-street{
            margin-bottom: 40px;

            span{
                max-width: 100%;

                font-p(15px, #C6C6C6);
                letter-spacing: 0;
            }
        }

        &__address-button{
            margin-bottom: 40px;

            text-align: center;

            button{
                padding: 10px 30px;

                font-p-20-500(#F5F1F8);
                font-weight: 500;
                font-size: 16px;
            }
        }

        &__contacts{
            a{
                font-p-20-500(#FFFFFF);
                font-weight: 500;
                font-size: 17px;
            }
        }

        &__logo{
            display: none;
        }

        &__menu{
            padding: 0;
        }

        &__footnote{
            padding-top: 50px;

            margin-bottom: 40px;

            flex-direction: column-reverse;
            align-items: center;
        }

        &__footnote-agreement{
            gap: 15px;
            flex-direction: column;
            align-items: center;

            margin-bottom: 40px;
        }
    }
}
