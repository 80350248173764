.vpnoffice-slider {
  width: 390px;
  max-width: 100%;
  float: right;
  z-index: 1;
  margin-left: 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  &__container {}

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }

  &__prev,
  &__next {
    background: none;
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    margin: 0;

    &.slick-disabled {
      opacity: .3;
      cursor: default;
    }

    svg {
      display: block;
    }
  }

  &__controls-text {
    @media (min-width: 769px) {
      font-size: 20px;
    }
  }
}
