.header{
  margin: 0 auto;
  height: 120px;
  display: flex;

  &__phone, &__email{
    color: purple;

    a{
      text-decoration: none;
      color: inherit;
    }
  }

  &_desc{
    display: flex;
    flex-basis: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .header__logo{
      margin-bottom: 0px;
    }
    .header__phone{
      font-family: $font-family-heading;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
    .header__email{
      font-family: $font-family-heading;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .log-in{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 1px solid red;
    border-radius: 4px;
    padding: 12px 20px;
    transition: all .3s;

    &:hover{
      background-color: red;

      span{
        color: #fff;
      }

      i{
        .path-poiner{
          fill: #fff;
        }
      }
    }

    span{
      // color: black;

      font-family: $font-family-content;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #535353;
    }
    i{
      margin-left: 6px;

      svg{
        width: 24px;
        height: 21px;
      }
    }
  }

  &_mobile{
    display: none;
  }

  @media (max-width: 1080px){
    height: 90px;

    &_desc{
      .header__logo{
        svg{
          width: 201px;
        }
      }

      .header__phone, .header__email{
        font-size: 18px;
      }

      .log-in{
        padding: 5px 14px;
        width: 125px;

        span{
          line-height: 1;
          font-size: 14px;
        }
      }
    }

  }

  @media (max-width: 700px){
    height: 100px;

    &_desc{
      display: none;
    }
    &_mobile{
      display: block;
      padding: 12px 9px;
      flex-basis: 100%;

      .bl{
        &_1,&_2{
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }

        &_1{
          .header__logo{
            svg{
              width: 170px;
              height: 34px;
            }
          }

          .log-in{
            padding: 7px;
            width: auto;
            height: auto;
            max-height: 35px;

            i{
              margin: 0;
            }
          }

          .header__burger-nav{
            display: flex;
            flex-flow: row nowrap;

            .burger{
              padding: 6px;
              border: 1px solid #f5f5f5;
              margin-left: 10px;
              max-height: 35px;

              &.active_burger{
                background-color: #f5f5f5;
              }

              div{
                width: 25px;
                height: 4px;
                background-color: #5c5c5c;
                margin-bottom: 4px;
                transition: all .5s;

                &:last-child{
                  margin-bottom: 0;
                }
              }

              &_active{
                background-color: #f5f5f5;
              }
            }
          }

        }

        &_2{
          padding-top: 20px;
        }
      }

    }
  }
}