// u — just namespace prefix (utility)
.u-flex
  display flex !important

.u-inline-flex
  display inline-flex !important

.u-flex-v
  display flex !important
  flex-direction column !important

.u-inline-flex-v
  display flex !important
  flex-direction column !important

.u-flex-wrap
  flex-wrap wrap

.u-flex-item
  flex 1 1 0

.u-flex-basis-100
  flex-basis 100% !important

.u-flex-item-auto
  flex 1 1 auto

.u-flex-item-none
  flex none

.u-flex-stretch-self
  align-self stretch

.u-flex-main-
  &start
    justify-content flex-start !important
  &center
    justify-content center !important
  &end
    justify-content flex-end !important
  &around
    justify-content space-around !important !important
  &between
    justify-content space-between !important
  &evenly
    justify-content space-evenly !important

.u-flex-cross-
  &stretch
    align-items stretch !important
  &start
    align-items flex-start !important
  &center
    align-items center !important
  &end
    align-items flex-end !important

.u-col-gap-60
  column-gap 60px

.u-row-gap-30
  row-gap 30px
