.enum {
  display: grid;
  grid-gap: 28px;

  &--4 {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @media (max-width: 425px){
      margin-bottom: 15px;
    }
  }

  &__number {
    width: 40px;
    height: 40px;
    background: #6331ae;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    
    @media (max-width: 425px){
      width: 35px;
      height: 35px;
    }
  }

  &__string {
    flex: 1;
    margin-left: 10px;
    height: 1px;
    background-color: #EBEBEB;
  }

  p {
    margin-bottom: 0;
    &.enum__header {
      margin-bottom: 5px;
    }
  }

  &__header {
    font-size: 20px;
    line-height: 24px;
    color: #1C1C1C;
    font-weight: 700;
  }
}