.ppo-modal{
    z-index: 1000;
    position fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.45);

    justify-content: center;
    align-items: center;

    &__page{
        max-height: 756px;
        max-width: 1172px;
        background-color: white;
        overflow: hidden;

        position relative;

        padding: 60px 25px 80px 60px;

        border: 1px solid #EFEFEF;

        box-shadow: 0px 8px 20px rgba(195, 201, 210, 0.3);
        border-radius: 4px;
    }

    &__button-close{
        width: 16px;
        height: 16px;
        background-color: white;

        position: absolute;
        right: 20px;
        top: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        span{
            display: block;
            width: 22px;
            height: 1px;
            background: #C6C6C6;

            position absolute;

            &:nth-child(1){
                transform: rotate(45deg);
            }
            &:nth-child(2){
                transform: rotate(135deg);
            }
        }
    }

    .heading{
        margin-bottom: 68px;
        margin-right: 35px;

        h2{
            //font-family: 'PT Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        display: flex;
        flex-wrap: wrap;
        gap: 28px 60px;

        max-height: 516px;
        overflow-y: scroll;
    }

    &__content::-webkit-scrollbar {
        width: 8px;
    }

    &__content::-webkit-scrollbar-track {
        background: #e2e2e2;
    }

    &__content::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
        border-radius: 4px;
    }

    &__channel-card{
        width: 310px;
        height: 40px;

        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__channel-img{
        width: 70px;
        height: 40px;
        overflow: hidden;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &_active{
        display: flex;
    }

    &_passive{
        display: none;
    }

    @media (max-width: 1200px) {
        &__page{
            max-height: calc(100% - 30px);
            margin: 15px;
        }

        &__content{
            max-height: 250px;
        }
    }

    @media (max-width: 800px) {
        &__page{
            padding: 60px 20px 40px;
        }

        .heading{
            margin-right: 0;
        }

        &__channel{
            width: 100%;
        }

        &__content{
            max-height: 400px;
        }
    }
}