.navigation{
  mapColors = {
    '1': red,
    '2': green,
    '3': purple,
    '4': yellow,
  }

  margin-bottom: 48px;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 17px 0;

  *{
    position: static;
  }

  &--no-subpanel {
    margin-bottom: 0;

    & + main.content {
      margin-top: 0;
    }
  }

  for key, val in mapSubPanel{
    &.active-{val}{

      .cont_{val}{
        li{
          color: #505050;
          font-weight: 700;

          &:before{
            display: block;
          }
        }
        .nav-panel__item_phisics{
          a{
            color: #007A33;
          }
        }
        .nav-subpanel{
          display: block;
          overflow: hidden;

          for i in (1..10) {
            &.active-link-{i}{
              & a:nth-child({i}){
                span{
                  position: relative;

                  &:after{
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 20%;
                    height: 2px;
                    background-color: #fff;
                    display: block;
                  }
                  
                  &.active-black{
                    &:after{
                      background-color: black;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }

  .cont{
    &:nth-child(4){
      li{
        margin-right: 0 !important;
      }
    }

    .formobile{
      display: none;
    }

    &_default{
      flex-basis: 100%;
    }
  }

  &.navigator_dark{
    background-color: gray-black !important;

    .nav-panel{
      li a{
        color: #fff

        &:hover{
          color: #fff;
        }
      }
    }
  }


  .nav-panel{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    li{
      list-style: none;
      margin-right: 20px;
      position: relative;

      &:last-child{
        margin-right: 0;
      }

      &:before{
        display: none;
        content: '';
        position: absolute;
        bottom: -17px;
        left: calc(50% - 9px);
        border: 9px solid transparent;
        border-bottom: 9px solid;
        z-index: 2;
      }

      a{
        display: block;
        text-align: center;
        text-decoration: none;
        transition: all .2s;

        font-family: $font-family-content;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #535353;

        &:hover{
          color: #505050;
          transform: scale(1.1);
        }
      }
    }
  }

  .nav-subpanel{
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    //width: 100vw;
    width: 100%;
    padding: 12px 0 10px;
    background-color: purple;
  //transition: all .5s;
    z-index: 5;
  //display: none;

    &__container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      &.phisics{
        a{
          svg path, svg rect{
              fill: #7DD191;
          }

          span{
            color: #7DD191;

            &:after{
              width: 0px !important;
            }
          }

          &:hover{
            span{
              color: rgb(255, 255, 255);
              transition: all 0.3s;
            }
            
            svg path, svg rect{
              fill: #ffffff;
              transition: fill 0.3s;
            }
          }
        }
      }

      &.operators{
        a{
          svg path, svg rect{
              fill: black
          }

          span{
            color: black;

            &:after{
              background-color: black
            }
          }
        }
      }
    }

    .default{
      display: flex;
    }

    a{
      text-decoration: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      i{
        svg{
          height: 26px;
          width: auto;
          max-width: 31px;
        }
      }
      span{
        color: #fff;
        font-weight: 600;
        display: inline-block;
        margin-left: 10px;
        position: relative;

        &:after{
          content: '';
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 0;
          height: 2px;
          background-color: #fff;
          display: block;
          transition: width .6s;
        }
      }

      &:hover{
        span{
          &:after{
            width: 20%;
            transition: width .3s;
          }
        }
      }
    }
  }

  for key, color in mapColors {
    .nav-panel{
      .cont:nth-child({key}){
        li:before{
          border-bottom-color: color !important;
        }

        .nav-subpanel{
          background-color: color;
        }
      }
    }
  }

  @media (min-width: 701px) and (max-width: 900px){
    .nav-panel{
      .cont{
        li{
          max-width: 130px;
        }
      }

      .nav-subpanel{
        a span{
          line-height: 1;
        }
      }
    }
  }

  @media (max-width: 700px){
    //margin-top: -35px;
    padding: 0;
    position: absolute;
    z-index: 9999;
    height: auto;
    display: none;

    .container{
      padding: 0;
    }

    .nav-panel{
      flex-flow: column nowrap;
      padding: 0;

      .cont{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #fff;
        padding: 8px 19px 6px;
        transition: all .5s;

        &_default{
          padding: 0;
          display: none;
        }

        a{
          font-size: 18px;
        }

        &.active{
          .formobile{
            div{
              transform: rotateX(180deg);
            }
          }
        }

        &:nth-child(4){
          li a{
            color: black;
          }

          .formobile{
            border-color: black;

            div{
              &:before, &:after{
                background-color: black !important;
              }
            }
          }
        }

        &:nth-child(-n+3){
          li a{
            color: #fff;
          }

          .formobile{
            border-color: #fff;

            div{
              &:before, &:after{
                background-color: #fff !important;
              }
            }
          }
        }

        &:last-child{
          border-bottom: 0;
        }

        for key, color in mapColors {
          &:nth-child({key}) {
            background-color: color;
          }
        }

        li{
          margin-right: 0;

          &:before{
            display: none !important;
          }
        }

        .formobile{
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #fff;
          border-radius: 50%;
          width: 32px;
          height: 32px;

          div{
            position: relative;

            &:before, &:after{
              content: '';
              position: absolute;
              width: 6px;
              height: 1px;
              background-color: #fff;
              top: 2px;
            }

            &:before{
              left: -6px;
              transform-origin: right center;
              transform: rotate(45deg);
            }
            &:after{
              right: -6px;
              transform-origin: left center;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .nav-subpanel{
        position: relative;
        margin-left: -19px;
        margin-right: -19px;
        margin-top: 8px;
        padding-left: 19px;
        padding-right: 19px;
        padding-bottom: 0;
        border-top: 1px solid #fff;
        flex-basis: calc(100% + 38px);

        & > div{
          padding: 0;
          flex-flow: column nowrap;

          a{
            margin-bottom: 15px;

            span{
              font-size: 18px;
              font-weight: 400;
              line-height: 1;
            }

            &:last-child{
              margin-bottom: 0;
            }

            svg{
              width: 32px;
            }
          }
        }
      }
    }
  }
}

.phisics{
  &_passive{
    &:hover{
      span{
        &:after{
          width: 0% !important;
          transition: width 0s;
        }
      }
    }
  }

  &_active{
    span{
      position: relative;

      color: rgb(255, 255, 255) !important;
      transition: all 0.3s;

      &:after{
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0) !important;
        display: block;

        transition: width 0s;
      }

      &.active-black{
        &:after{
          background-color: rgba(255, 255, 255, 0) !important;
        }
      }
    }

    i{
      svg path, svg rect{
        fill: #ffffff !important;
        transition: fill 0.3s;
      }
    }

    background: #13a53f;
    box-shadow: 0px 0px 10px 10px #13a53f, 0px 0px 30px 10px #13a53f, 0px 0px 60px 50px #13a53f;
  }
  @media (max-width: 700px){
    &_active{
      width: 120%;
      box-shadow: -20px 0px 10px 5px #13a53f, -10px 0px 30px 10px #13a53f, 0px 0px 60px 15px #13a53f;
    }
  }
}
        