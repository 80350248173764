r($width)
  @media (max-width unit($width, 'px'))
    {block}

rmin($width)
  @media (min-width unit($width, 'px'))
    {block}

getSides(abbr)
  if !abbr
    (top right bottom left)
  else if abbr == 'h'
    (right left)
  else if abbr == 'v'
    (top bottom)
  else if abbr == 't'
    (top)
  else if abbr == 'r'
    (right)
  else if abbr == 'b'
    (bottom)
  else if abbr == 'l'
    (left)

getMultiplier(size)
  if !size
    0
  else if size == 1
    .5
  else
    size - 1

sizingHelpers(prop, abbr)
  for side in '' 'h' 'v' 't' 'r' 'b' 'l'
    for size in range(0, 60, 5)
      .{abbr + side + '-' + size}
        for sideName in getSides(side)
          {prop}-{sideName}: unit(size, px) !important

relativeSizingHelpers(prop, abbr, gutter)
  for side in '' 'h' 'v' 't' 'r' 'b' 'l'
    for size in 0..5
      .{abbr + side + '-' + size}
        for sideName in getSides(side)
          {prop}-{sideName}: gutter * getMultiplier(size) !important
          if size != 0
            +r(768)
              {prop}-{sideName}: gutter * (getMultiplier(size) * .75) !important
            +r(420)
              {prop}-{sideName}: gutter * (getMultiplier(size) * .5) !important

sizingHelpers('margin', 'm')
sizingHelpers('padding', 'p')
relativeSizingHelpers('margin', '_m', 20px)
relativeSizingHelpers('padding', '_p', 20px)

.pb-250 { padding-bottom: 250px !important }
.pb-300 { padding-bottom: 300px !important }

.pt-80px {
  padding-top: 80px !important;
}

.pb-80px {
  padding-bottom: 80px !important;
}

.py-80px {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-72px {
  margin-bottom: 72px !important;
}

.mb-80px {
  margin-bottom: 80px !important;
}

.mb-120px {
  margin-bottom: 120px !important;
}

.mb-160px {
  margin-bottom: 160px !important;
}

@media (min-width: $bp-lg + 1) {
  .{'lg\:'}pt-160px {
    padding-top: 160px !important;
  }

  .{'lg\:'}pb-220px {
    padding-bottom: 220px !important;
  }

  .{'lg\:'}mb-25px {
    margin-bottom: 25px !important;
  }

  .{'lg\:'}mb-72px {
    margin-bottom: 72px !important;
  }

  .{'lg\:'}mb-80px {
    margin-bottom: 80px !important;
  }

  .{'lg\:'}mb-120px {
    margin-bottom: 120px !important;
  }

  .{'lg\:'}mb-160px {
    margin-bottom: 160px !important;
  }
}

@media (min-width: $bp-xl + 1) {
  .{'xl\:'}pt-160px {
    padding-top: 160px !important;
  }

  .{'xl\:'}pb-220px {
    padding-bottom: 220px !important;
  }
}

.ovh { overflow: hidden }

.width-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.h-auto {
  height: auto;
}

.max-width-927,
.width-927 { max-width: 927px; }
.just-left {justify-content: flex-end;}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

.color-white {
  color: #fff;
}
