.lermontovskiy-offer{
    .heading{
        h1{
            font-family: 'Rubik',sans-serif;
            font-weight: 500;
            font-size: 52px;
        }

        &--h2 h1 {
            font-size: 36px;
            line-height: 1.6;
        }
    }

    &__content{
        margin-bottom: 52px;

        &_green{
            margin-bottom: 12px;
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 44px;
                letter-spacing: 0.01em;
                color: #007A33;
            }
        }
    }

    &__button{
        margin-bottom: 80px;

        svg{
            transform: rotate(90deg);
        }
    }

    &__img{
        margin-bottom: 108px;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__conditions{
        .heading{
            margin-bottom: 36px;
        }

        ul{
            margin-bottom: 148px;

            li{
                display: flex;
                align-items: center;
                gap: 8px;

                margin-bottom: 8px;

                img{
                    align-self: flex-start;
                    padding-top: 7px;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .heading{

            h1{
                font-size: 28px;
                margin: 20px 0;
                line-height: 35px;
            }

        }

        &__img {
            margin-bottom: 50px !important;
        }

    }
}

.lermontovskiy-rates{
    .heading{
        margin-bottom: 68px;

        h2{
            font-family: 'Rubik',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 0.005em;
            color: #202020;
        }
    }

    @media (max-width: 500px) {
        .heading{
            h2{
                font-size: 28px;
            }
        }
    }
}

.lermontovskiy-ip{
    .heading{
        margin-bottom: 144px;

        h3{
            font-family: 'Rubik',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 44px;
            letter-spacing: 0.01em;
            color: #007A33 !important;
        }
    }
}

.lermontovskiy-block3{
    .heading{
        margin-bottom: 88px;
    }

    @media (max-width: 500px) {
        .heading {
            margin-bottom: 30px !important;
        }
    }
}

.lermontovskiy-block2{
    .heading{
        margin-bottom: 88px;
    }
}

.lermontovskiy-surveillance{
    .heading{
        margin-bottom: 72px;
    }

    &__list{
        margin-bottom: 144px;

        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    @media (max-width: 603px) {
        &__list{
            flex-direction: column;
            align-items: center;
        }
    }
}


.productcard-surveillance{
    padding-top: 72px;
    width: 277px;
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid #F8F8F8;
    box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
    border-radius: 4px;

    &:hover{
        box-shadow: 0px 8px 20px rgba(173, 173, 173, 30%);
    }

    &__content{
        margin-bottom: 68px;

        display: flex;
        flex-direction: column;
        align-items: center;

        span{
            display: block;

            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #202020;
        }

        span:nth-child(2){
            font-weight: 400;
            font-size: 18px;
            color: #7c7c7c;
        }
    }

    &__button{
        margin-bottom: 84px;
    }

}

.lermontovskiy-telephony{
    .heading{
        margin-bottom: 72px;
    }

    &__list{
        margin-bottom: 200px;
    }

    &__header{
        padding-bottom: 8px;
        border-bottom: 1px solid #E5E5E5;

        display: flex;
        flex-wrap: wrap;

        font-family: $font-family-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #848484;

        &_1{
            max-width: 340px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;
            flex-wrap: wrap;

            margin-right: 98px;
        }

        &_2{
            max-width: 244px;
            display: flex;
            flex: 1 0 100%;
        }

        &_3{
            max-width: 452px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            margin-right: 36px;
        }
    }

    &__block{
        display: flex;
        flex-wrap: wrap;

        padding-top: 46px;

        height: 138px;
        border-bottom: 1px solid #E5E5E5;

        line-height: 28px;

        &_1{
            max-width: 340px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            margin-right: 98px;

            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            color: #007A33;

            div{
                span:nth-child(1){
                    font-family: 'Fira Sans',sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 22px;
                    text-align: right;
                    color: #7c7c7c;

                    text-decoration-line:line-through;
                    margin-right: 10px;
                }
            }
        }

        &_2{
            max-width: 244px;
            display: flex;
            flex-direction: column;
            flex: 1 0 100%;
        }

        &_3{
            max-width: 452px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            margin-right: 36px;
        }

        &_3-price{
            display: flex;
            flex-direction: column;
        }

        &_3-bold{
            font-weight: 700;
        }
    }

    &__button{
        position relative;
        top: -12px;
    }

    @media (max-width: 1200px) {
        &__block{
            height: 330px;
        }
    }

    @media (max-width: 500px) {
        &__header, &__block{
            &_1{
                margin-right: 0px;
            }

            &_3{
                margin-right: 0px;
            }
        }
    }
}

.button-green{
    transition: all 0.2s;
    height: 46px;
    padding: 0px 28px 0px 24px;

    background: white;
    border: 1px solid #007A33;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 22px;
    text-align: right;
    color: #007A33;

    &:hover{
        background-color: #007A33;
        color: #FFFFFF;

        svg path{
            fill: white;
            transition: all 0.2s;
        }
    }
}

