.block-news{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 37px;


  .news{
    flex-basis: 50%;
    display: flex;
    flex-flow: row nowrap;
    padding: 28px;
    border: 1px solid #f5f5f5;
    box-shadow: 5px 2px 5px 3px rgba(245,245,245,.75);

    border-radius: 6px;

    &__img{
      flex: 211px 0 0;
      height: 211px;
      background-size: contain;
      background-position: 50% 0;
      background-repeat: no-repeat;

      img{
        width: 100%;
      }
    }

    time{
      display: block;
      color: #d5d5d3;
      margin-bottom: 15px;
    }

    &__information{
      flex-basis: auto;
      margin-left: 29px;
    }

  }

  @media (max-width: 1200px){
    padding: 0 20px;

    .item{
      align-items: start;
      flex-basis: 500px;
    }
  }

  @media (max-width: 1000px){
    flex-wrap: wrap;

    .item{
      flex-basis: 100%;

      &:first-child{
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 600px){
    .item{
      .news__img{
        display: none;
      }
    }
  }

  @media (max-width: 425px){
    padding: 0 15px;

    time{
      font-size: 14px;
    }

    .item{
      padding: 28px 20px;

      .news__information{
        margin-left: 0;
      }
    }

  }
}