.iptv-24tv{
    background: url(/src/img/pages/header_iptv_24tv.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);

    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading{
        padding-top: 282px;
        margin-bottom: 128px;

        width: 523px;

        span{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 700;
            font-size: 46px;
            line-height: 130%;
            letter-spacing: -0.01em;
            text-transform: uppercase;

            color: #FFFFFF !important;
        }
    }

    &__content{
        &_1{
            margin-bottom: 35px;
        }

        &_2{
            display: flex;
            gap: 15px;

            img{
                display: block;
            }
        }
    }

    @media (max-width: 1679px){
        // background: url(/src/img/pages/headerPackageOffers.jpeg) 60% no-repeat, linear-gradient(3.19deg, #073A1C 3.58%, #09321A 29.23%, #0D2115 62.99%, #0E1A13 98.3%);

        // .heading{
        //     padding-top: 250px;
        // }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/headerMobileTelevision.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 579px;

        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 0 30px;

            position relative;
        }

        .heading{
            padding-top: 307px;
            margin-bottom: 40px;

            width: 100%;

            span{
                font-size: 28px;
                letter-spacing: 0.02em;
            }
        }

        &__content{
            margin-bottom: 50px;
            &_1{
                height: 40px;

                margin-bottom: 40px;

                img{
                    display: block;
                    height: 100%;
                    width: auto;
                }
            }

            &_2{
                gap: 9px;
            }
        }
    }
}

.iptv-24tv-introduction{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 170px 55px 0px;
        margin-bottom: 110px;
    }

    .heading{
        margin-bottom: 25px;

        h1{
            margin-bottom: 0px;
            padding-top: 0px;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 52px;
            line-height: 120%;
            color: #202020;
        }
    }

    &__content{
        p{
            margin-bottom: 0px;
        }

        &_1{
            margin-bottom: 45px;

        }

        &_2{
            p{
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #007A33;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            padding: 80px 30px 0px;
            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 20px;

            h1{
                font-size: 30px;
            }
        }

        &__content{
            &_1{
                margin-bottom: 35px;

                p{
                    font-size: 15px;
                }
            }

            &_2{
                p{
                    font-size: 17px;
                }
            }
        }
    }
}

.iptv-24tv-subscriptions{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1710px;

        padding: 0px 55px 0px;
        margin-bottom: 240px;
    }

    .heading{
        margin-bottom: 80px;

        justify-content: center;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            text-align: center;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__cinema{
        margin-bottom: 120px;

        &-heading{
            margin-bottom: 65px;
            display: flex;
            justify-content: center;

            h3{
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
        }
    }

    &__packages{
        &-heading{
            margin-bottom: 65px;
            display: flex;
            justify-content: center;

            h3{
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
        }
    }

    &__card{
        background: linear-gradient(360deg, #12B845 -3.85%, #007A33 17.9%, #006527 32.43%, #00551D 47.29%);
        border-radius: 6px;
        overflow: hidden;

        width: 262px;
        height: 487px;
        padding: 304px 35px 45px;

        position relative;

        transition: all 0.3s;

        &-img, &-img-bg{
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            img{
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        &-heading{
            border-bottom: 1px solid rgba(245, 241, 248, 0.25);

            margin-bottom: 15px;

            h4{
                margin-bottom: 0;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #FFFFFF !important;
            }
        }

        &-content{
            overflow: hidden;

            p{
                transition: all 0.3s;
                padding-top: 15px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: rgba(233, 252, 238, 0);
            }
        }

        &-button{
            position: absolute;
            left: 35px;
            bottom: 45px;

            button{
                width: 128px;
                height: 50px;
                display: flex;
                align-items: center;
                padding-left: 25px;

                gap: 14px;

                background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                box-shadow: 0px 2px 6px rgba(1, 84, 29, 0.8), 0px 20px 48px rgba(1, 84, 29, 0.4), 0px 40px 160px rgba(1, 84, 29, 0.9);
                border-radius: 6px;

                &:hover{
                    background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
                }

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #FFFFFF;

                svg{
                    path{
                        fill: #FFFFFF;
                    }
                }
            }
        }

        &:hover{
            padding: 240px 35px 45px;

            .iptv-24tv-subscriptions__card-img-bg{
                background: linear-gradient(360deg, #12b845 -3.85%, #007a33 17.9%, #006527 32.43%, #0055006b);
            }

            .iptv-24tv-subscriptions__card-content{
                p{
                    color: #E9FCEE;
                }
            }
        }
    }

    &__cards-wrap{
        display: flex;
        justify-content: center;
        gap: 5px;
        flex-wrap: wrap;
    }

    &__products-buttons{
        display: none;
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px 0px 0px;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0 30px;
            margin-bottom: 45px;

            justify-content: flex-start;

            h2{
                font-size: 20px;
                line-height: 150%;

                text-align: left;
            }
        }

        &__cinema{
            margin-bottom: 80px;

            &-heading{
                padding: 0 30px;
                margin-bottom: 30px;

                justify-content: flex-start;

                h3{
                    margin-bottom: 0px;

                    font-size: 17px;
                    line-height: 150%;
                }
            }
        }

        &__packages{
            &-heading{
                padding: 0 30px;
                margin-bottom: 30px;

                justify-content: flex-start;

                h3{
                    margin-bottom: 0px;

                    font-size: 17px;
                    line-height: 150%;
                }
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__products-wrap{
            overflow: hidden;

            margin-bottom: 45px;
        }

        &__cards-wrap{
            justify-content: flex-start;
            flex-wrap: nowrap;

            position: relative;
            left: 10px;

            transition: left 1s;
        }

        &__card{
            min-width: 262px;
        }
    }
}

.iptv-24tv-connect{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px 0px;
        margin-bottom: 200px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 120px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        display: flex;
        justify-content: space-between;
    }

    &__content-Instruction{

        &-heading{
            margin-bottom: 35px;

            h3{
                margin-bottom: 0px;
                text-align: left;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
        }

        ul{
            margin-bottom: 60px;
            max-width: 503px;

            position relative;
            left: 45px;

            li{
                position relative;
                margin-bottom: 26px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;

                &:last-child{
                    margin-bottom: 0px;
                }
            }

            span{
                position: absolute;
                top: 0;
                left: -45px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #007A33;
            }
        }

        &-button{
            button{
                display: flex;
                align-items: center;
                padding: 15px 29px 15px 25px;

                gap: 14px;

                background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                border-radius: 6px;

                &:hover{
                    background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
                }

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                text-align: right;
                color: #FFFFFF;

                svg{
                    path{
                        fill: #FFFFFF;
                    }
                }
            }
        }
    }

    &__content-icons{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        max-width: 657px;
    }

    &__card{
        cursor: pointer;
        border: 1px solid #F8F8F8;
        border-radius: 6px;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);

        overflow: hidden;

        min-width: 212px;
        width: 212px;
        height: 255px;
        padding: 50px 45px 45px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-img{
            display: flex;
            justify-content: center;

            img{
                display: block;
            }
        }

        &-content{
        }

        &-heading{
            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 15px;

            h4{
                margin-bottom: 0px;

                text-align: center;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #848484 !important;
            }
        }

        &-text{
            p{
                margin-bottom: 0px;

                text-align: center;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;
            }
        }
    }

    &__products-buttons{
        display: none;
    }

    &__modal{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.6);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;

        display: none;
        justify-content: center;
        align-items: center;

        &.active{
            display: flex;
        }
    }
    &__modal-window{
        min-width: 523px;
        max-width: 523px;

        background: #FFFFFF;
        border: 1px solid #F8F8F8;
        box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 6px;

        position: relative;

        padding: 75px 75px 85px;
    }
    &__modal-close{
        position: absolute;
        top: 22px;
        right: 22px;

        width: 15px;
        height: 15px;

        display: flex;
        justify-content: center;

        cursor: pointer;

        span{
            position: absolute;
            top: 6px;

            width: 22px;
            height: 1px;
            background-color: #C6C6C6;

            &:nth-child(1){
                transform: rotate(45deg);
            }
            &:nth-child(2){
                transform: rotate(-45deg);
            }
        }

        &:hover{
            span{
                background-color: #535353;
            }
        }
    }

    &__modal-logo{
        margin-bottom: 60px;
    }
    &__modal-heading{
        margin-bottom: 40px;

        h3{
            margin-bottom: 0;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020 !important;
        }
    }
    @media (max-width: 1679px){
        &__page{
            margin-bottom: 160px;
        }

        .heading{
            padding-bottom: 15px;
            margin-bottom: 110px;
        }

        &__content-Instruction{
            ul{
                max-width: 384px;

            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px;
            margin-bottom: 80px;
        }

        .heading{
            margin: 0px 30px 0px;
            padding-bottom: 10px;
            margin-bottom: 35px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            flex-direction: column-reverse;
        }

        &__content-icons-wrap{
            width: 100%;
            height: 280px;
            overflow: hidden;

            padding-top: 10px;
            margin-bottom: 30px;

            // background-color: rgb(255, 164, 164);
        }

        &__content-icons{
            flex-wrap: nowrap;

            max-width: 100%;

            position relative;
            left: 10px;

            transition: left 1s;
        }

        &__products-buttons{
            margin-bottom: 65px;

            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__content-Instruction{
            margin: 0px 30px 0px;

            &-heading{
                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 20px;

                h3{
                    font-size: 17px;
                    line-height: 150%;
                }
            }

            ul{
                margin-bottom: 45px;
                max-width: 100%;

                position static;

                li{
                    position relative;
                    margin-bottom: 20px;

                    font-size: 15px;
                }

                span{
                    position: static;

                    display: block;

                    margin-bottom: 5px;
                }
            }
        }
    }
}

.iptv-24tv-package{
    background: url(/src/img/pages/BG_iptv-24tv-package.jpg) no-repeat center, linear-gradient(180deg, #0B2616 0%, #092C18 28%, #08341A 52.86%, #073A1C 73.29%, #073A1C 100%);
    display: flex;
    justify-content: center;

    &__page{
        height: 460px;

        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px 0px;
    }

    .heading{
        padding-top: 85px;
        margin-bottom: 25px;

        width: 523px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF !important;
        }
    }

    &__content{
        max-width: 620px;
        margin-bottom: 65px;

        p{
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 170%;
            letter-spacing: 0.03em;
            color: #E9FCEE;
        }
    }

    &__button{
        button, a{
            display: flex;
            align-items: center;
            padding: 15px 29px 15px 25px;

            gap: 14px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            border-radius: 6px;

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            text-align: right;
            color: #FFFFFF;

            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }

        a{
            text-decoration: none;
            display: inline;
            cursor: pointer;
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/BG_iptv_24tv_package.jpg) no-repeat, #11301b;
        //background-size: 100% auto;
        //background-position: 50% 25%;
        background-position: center;
        background-size: auto 100%;

        &__page{
            height: 594px;

            padding: 0px 30px 0px;
        }

        .heading{
            padding-top: 292px;
            margin-bottom: 20px;

            width: 100%;

            h2{
                font-size: 17px;
            }
        }

        &__content{
            max-width: 100%;
            margin-bottom: 40px;

            p{
                font-size: 12px;
            }
        }

    }
}

.iptv-24tv-advantages{
    display: flex;
    justify-content: center;

    &__page{
        margin-bottom: 240px;
        padding: 0px 55px 0px;

        flex: 1 0 100%;
        max-width: 1440px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 90px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        display: flex;
        gap: 15px;
    }

    &__content-card{
        flex: 1 0 100%;
        max-width: 433px;

        &-img{
            margin-bottom: 25px;
            height: 91px;

            img{
                display: block;
                height: 100%;
                width: auto;
            }
        }

        &-txt{
            max-width: 390px;

            p{
                margin-bottom: 0;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }
        }
    }

    @media (max-width: 1679px){
        &__content{
            gap: 40px;
        }

        &__content-card{
            max-width: 355px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            padding: 0px 30px 0px;
            margin-bottom: 80px;
        }

        .heading{
            padding-bottom: 10px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            flex-wrap: wrap;
            gap: 30px;
        }

        &__content-card{
            flex: 1 0 100%;
            max-width: 100%;

            &-img{
                margin-bottom: 25px;
            }

            &-txt{
                max-width: 100%;

                p{
                    font-size: 15px;
                }
            }
        }
    }
}

.iptv-24tv-equipment{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 180px 55px 0px;

        margin-bottom: 180px;
    }

    .heading{
        margin-bottom: 25px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__txt{
        margin-bottom: 80px;

        max-width: 927px;

        p{
            margin-bottom: 0;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    &__brands{
        display: flex;
        justify-content: space-between
        gap: 60px;
        padding: 45px 0;

        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;

        margin-bottom: 110px;

        &-logo{
            margin-right: 74px;

            display: flex;
            gap: 60px;
        }
    }

    &__equipment{
        // margin-bottom: 20px;
    }

    &__equipment-content{
        display: flex;
        gap: 151px;
        justify-content: space-between;
        margin-bottom: 106px;

        &-heading{
            margin-bottom: 25px;

            h3{
                text-align: left;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }

            svg{
                display: none;
            }
        }

        &-txt{
            margin-bottom: 64px;

            max-width: 656px;

            p{
                margin-bottom: 0;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }
        }

        &-price{
            display: flex;
            align-items: center;

            &_green{
                padding-right: 35px;
                border-right: 1px solid #E5E5E5;
                margin-right: 35px;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #007A33;
            }

            &_green-small{
                max-width: 74px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 140%;
                color: #007A33;
            }
        }
    }

    &__data{
        display: flex;
        justify-content: space-between;
    }

    &__data-accordion_1, &__data-accordion_2{
        span{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }

        ul{
            li{
                margin-bottom: 5px;
                position relative;
                padding-left: 13px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;

                &:before{
                    position absolute;
                    top: 13px;
                    left: 0px;

                    content: '';
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #535353
                }

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__data-accordion-heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        padding-right: 25px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
    }
    &__data-accordion-content{
        transition: all 0.3s;
        padding-top: 0;
        ul li{
            transition: all 0.3s;
            margin-bottom: 0;
            line-height: 0%;

            color: transparent;

            &:before{
                height: 0;
            }
        }

        &.active{
            padding-top: 25px;

            ul li{
                line-height: 170%;
                margin-bottom: 5px;
                color: #535353;
                &:before{
                    height: 3px;
                }
            }
        }
    }
    &__data-accordion_1{
        flex: 1 0 100%;
        max-width: 791px;
    }

    &__data-accordion_2{
        flex: 1 0 100%;
        max-width: 388px;
    }


    &__plus{
        height: 17px;
        width: 17px;

        //margin-right: 25px;

        position: relative;

        span{
            display: block;
            width: 17px;
            height: 1px;
            background-color: #007A33;

            position: absolute;
            left: 0;
            top: 8px;

            transition: all 0.3s;

            &:nth-child(1){
                transform: rotate(90deg);
            }
        }
        &.active{
            span{
                &:nth-child(1){
                    transform: rotate(0deg);
                }
            }
        }
    }

    @media (max-width: 1679px){
        &__brands{

            &-img{
                max-width: 676px;

                img{
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

        }

        &__data-accordion_1{
            max-width: 578px;
        }

        &__data-accordion_2{
            max-width: 479px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            flex: 1 0 100%;
            max-width: 1440px;

            padding: 80px 30px 0px;

            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 20px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__txt{
            margin-bottom: 50px;

            max-width: 927px;

            p{
                font-size: 15px;
            }
        }

        &__brands{
            flex-wrap: wrap;

            gap: 20px;
            padding: 0px;

            margin-bottom: 61px;

            &-img{
                width: 100%;
                padding: 15px 0;

                border-bottom: 1px solid #E5E5E5;
            }

            &-logo{
                padding: 15px 0;

                display: flex;
                gap: 20px;

                img{
                    height: 33px;
                }
            }
        }

        &__equipment-content{
            gap: 0;
            flex-direction: column-reverse;
            justify-content: space-between;

            margin-bottom: 40px;

            &-heading{
                display: flex;
                justify-content: space-between;

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 0;

                h3{
                    padding-right: 10px;
                    margin-bottom: 0;

                    font-size: 17px;
                    line-height: 150%;
                }

                svg{
                    transition: all 0.3s;

                    display: block;
                    align-self: flex-end;
                    transform: rotate(90deg);

                    margin-right: 6px;
                    margin-bottom: 6px;

                    &.active{
                        transform: rotate(270deg);

                        path{
                            stroke: #E5E5E5;
                        }
                    }
                }
            }

            &-txt{
                transition: all 0.3s;
                padding-top: 0;
                margin-bottom: 0;

                p{
                    transition: all 0.3s;
                    font-size: 15px;
                    line-height: 0%;
                    color: transparent;
                }

                &.active{
                    padding-top: 25px;

                    p{
                        line-height: 150%;
                        color: #535353;
                    }
                }
            }

            &-price{
                display: flex;
                align-items: center;

                padding-top: 25px;

                &_green{
                    font-size: 20px;
                    line-height: 150%;
                }

                &_green-small{
                    max-width: 100%;

                    font-size: 15px;
                    line-height: 170%;
                }
            }

            &-img{
                margin-left: 13px;
                margin-bottom: 28px;

                max-width: 228px;

                img{
                    display: block;
                    width: 100%;
                }
            }
        }

        &__data{
            flex-direction: column;
        }

        &__data-accordion_1, &__data-accordion_2{
            max-width: 100%;

            span{
                font-size: 17px;
                line-height: 150%;
            }

            ul{
                margin-top: 5px;

                li{
                    font-size: 15px;
                }
            }
        }

        &__data-accordion_1{
            margin-bottom: 30px;
        }

        &__data-accordion-heading{
            padding-bottom: 5px;
            padding-right: 6px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;
        }
    }
}

.iptv-24tv-rates{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 180px;

        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 170%;
        color: #535353;
    }

    .heading{
        margin-bottom: 25px;
    }

    &__introduction{
        margin-bottom: 110px;
        max-width: 927px;

        p{
            margin-bottom: 0px;
        }
    }

    .accordion-rates{
        margin-bottom: 60px;

        &__heading{
            display: flex;
            padding-bottom: 10px;
            border-bottom: solid 1px #E5E5E5;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;
            }

            &1, &2, &3{
                display: flex;
                justify-content: space-between;
            }

            &1{
                width: 388px;
                margin-right: 150px;
            }

            // &2{
            //     width: 254px;
            //     margin-right: 104px;
            // }

            &2{
                width: 118px;
                margin-right: 30px;

                span:nth-child(1){
                    width: 60px;
                    border-right: 1px solid #E5E5E5;
                }
            }

            &3{
                width: 133px;
            }
        }

        &__item{
            border-bottom: solid 1px #E5E5E5;
        }

        &__showcase{
            height: 120px;
            display: flex;
            align-items: center;

            &1, &2, &3, &4{
                display: flex;
                justify-content: space-between;
            }

            &1{
                width: 388px;
                margin-right: 150px;

                span{
                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 150%;
                    color: #007A33;
                }
            }

            &2-0{
                display: none;

                &-data{
                    display: none;
                }
            }

            &2{
                width: 118px;
                margin-right: 30px;

                span{
                    display: flex;
                    align-items: center;
                }

                span:nth-child(1){
                    height: 29px;
                    width: 60px;
                    border-right: 1px solid #E5E5E5;
                }
            }

            &3{
                width: 133px;
                margin-right: 78px;
            }

            &4{
                width: 378px;
                margin-right: 56px;
            }
        }

        &__additional-content{
            display: none;
        }

        &__button{
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;

                width: 203px;
                height: 45px;
                border-radius: 6px;

                transition: all 0.2s;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 0px;
                letter-spacing: 0.05em;

                &:hover{
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: #FFFFFF;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }
                }
            }

            &_active{
                button{
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: white;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }

                    &:hover{
                        background: linear-gradient(180deg, #12B845 100%, #12B845 100%);
                    }
                }
            }

            &_passive{
                button{
                    background: white;
                    color: #007A33;
                    border: 1px solid #007A33;
                    border-radius: 6px;

                    svg path{
                        fill: #007A33;
                    }
                }
            }
        }

        &__button-close{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor pointer;

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                border-bottom: 1px dashed #C6C6C6;
                padding: 0px !important;

                background-color: transparent;
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;
            }

            &_passive{
                svg{
                   transform: rotate(90deg);
                   transition: transform 0.2s;
                }

                &:hover{
                    button{
                        border-bottom: 1px dashed #E5E5E5;
                        color: #C6C6C6;
                    }

                    svg path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &_active{
                svg{
                   transform: rotate(-90deg);
                   transition: transform 0.3s;
                }

                button{
                    border-bottom: 1px dashed #E5E5E5;
                    color: #C6C6C6;
                }

                svg path{
                    stroke: #C6C6C6;
                }
            }
        }

        &__content{
            background: #FBFBFB;
            padding: 70px 134px 75px;
            transition: all 0.2s;

            display: flex;

            &_passive{
                height: 0px;
                padding: 0px 0px 0px 134px !important;
                span{
                    color: transparent !important;
                    border-bottom: 0px solid transparent !important;
                }

                .span-arrow-right{
                    display: none;
                }
            }

            &_active{
                height: auto;
                padding: 70px 134px 75px;
                color: #535353;
            }

            &1{
                flex: 1 0 100%;
                max-width: 254px;
                margin-right: 150px;

                span{
                    display: block;
                    margin-bottom: 8px;
                }

                button{
                    background-color: transparent;
                    width: 100%;
                    display: flex;

                    .spanheadingminicolor{
                        display: flex;
                        justify-content: start;
                        align-items: flex-start;
                        gap: 5px;

                        width: 100%;
                        &:hover{
                            border-bottom: 1px solid #6d6d6d;
                            transition: all 0.2s;
                        }
                    }

                    span{
                        display: block;
                        margin-bottom: 0px;
                    }
                }
            }

            &2{
                flex-basis: 100%;

                span{
                    display: block;
                }

                &-subscriptions{
                    display: flex;
                    flex-wrap: wrap;
                }

                &-subscription{
                    display: flex;
                    position relative;

                    span:nth-child(2){
                        position absolute;
                        left: 140px;
                        width: 200px;
                        color: #848484;
                    }

                    span:nth-child(3){
                        position absolute;
                        left: 224px;
                        width: 100px;
                        color: #848484;
                    }
                }

                &-subscription:nth-child(2n+1){
                    width: 350px;
                }
            }

            .spanheadingmini{
                display: block;
                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 20px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;

                &color{
                    color: #007A33;
                }
            }

            .spanheadingminimarkcolor{
                display: inline;
                position relative;
                top: 0px;

                font-size: 16px;
                color: #007A33;
            }

            .spanbold{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }

            .spanbottom{
                margin-bottom: 30px;
            }

            .spaninline{
                display: inline;
            }
        }
    }

    &__attention{
        max-width: 792px;

        p{
            margin-bottom: 0px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #848484
        }

        span{
            font-weight: 600;
            color: #DA1E4C;
        }
    }

    @media (max-width: 1679px){
        .accordion-rates{
            &__heading{
                &1{
                    width: 288px;
                    margin-right: 100px;
                }

                &2{
                    // width: 254px;
                    margin-right: 54px;
                }
            }

            &__showcase{
                &1{
                    width: 288px;
                    margin-right: 100px;
                }

                &2{
                    // width: 254px;
                    margin-right: 54px;
                    // position relative;
                }
            }

        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px 0px;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0px 30px;
            margin-bottom: 20px;
        }

        &__introduction{
            padding: 0px 30px;
            margin-bottom: 45px;
        }

        &__products-wrap{
            overflow: hidden;

            padding: 10px 0px 20px;
            margin-bottom: 25px;
        }

        &__products{
            display: flex;
            gap: 10px;

            position relative;
            left: 10px;

            transition: left 1s;
        }

        .accordion-rates{
            flex-grow: 1;
            &__heading{
                display: none;
            }

            &__item, &__item-cabel {
                min-width: 300px;
                width: 300px;
                // height: 269px;
                height: fit-content;

                border: 1px solid #F8F8F8;
                box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
                border-radius: 6px;

                padding: 30px 20px 45px;
            }

            &__cabel-item {
                min-width: 300px;
                width: 300px;
                // height: 269px;
                height: fit-content;

                border: 1px solid #F8F8F8;
                box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
                border-radius: 6px;

                padding: 30px 20px 45px;
            }

            &__showcase{
                height: auto;

                flex-direction: column;

                &1, &2, &3, &4{
                    width: 100%;
                    margin-right: 0px;
                }

                &1{
                    margin-bottom: 25px;
                }

                &2-0{
                    width: 100%;

                    display: flex;
                    justify-content: space-between;


                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;
                    margin-bottom: 5px;

                    span{
                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                        letter-spacing: 0.01em;
                        color: #848484;
                    }

                    span:nth-child(2){
                            position absolute;
                            right: 125px;
                            top: 0;
                        }
                    span:nth-child(3){
                        position absolute;
                        right: 90px;
                        top: 0;
                    }

                    &_passive{
                        padding: 0 !important;
                        margin-bottom: 0 !important;
                        font-size: 0 !important;

                        color: transparent !important;
                        border-bottom: 0px solid transparent !important;
                        *{
                            padding: 0 !important;
                            margin-bottom: 0 !important;
                            font-size: 0 !important;

                            color: transparent !important;
                            border-bottom: 0px solid transparent !important;
                        }
                    }

                    &-data{
                        display: flex;
                        justify-content: space-between;

                        width: 100%;
                        margin-bottom: 40px;

                        &_passive{
                            display: none;
                        }

                        position relative;

                        span:nth-child(2){
                            position absolute;
                            right: 125px;
                            top: 0;
                        }
                        span:nth-child(3){
                            position absolute;
                            right: 90px;
                            top: 0;
                        }
                    }
                }

                &2{
                    margin-bottom: 40px;

                    &_passive{
                        display: none;
                    }

                    display: none;
                }

                &3{
                    display: none;
                }
            }

            &__additional-content{
                display: block;
                width: 100%;

                span{
                    display: block;
                    transition: all 0.2s linear;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 170%;
                    color: #848484;
                }

                &_passive{
                    *{
                        padding: 0 !important;
                        margin-bottom: 0 !important;
                        font-size: 0 !important;

                        color: transparent !important;
                        border-bottom: 0px solid transparent !important;
                    }
                    .span-arrow-right{
                        display: none;
                    }
                }

                &_active{

                }

                &_1{
                    width: 100%;

                    button{
                        background-color: transparent;
                        width: 100%;
                        display: flex;
                        padding: 0;

                        .spanheadingminicolor{
                            display: flex;
                            justify-content: start;
                            align-items: flex-end;
                            gap: 5px;

                            width: 100%;
                            &:hover{
                                border-bottom: 1px solid #6d6d6d;
                                transition: all 0.2s;
                            }
                        }

                        .spanheadingminimarkcolor{
                            top: 4px;
                        }

                        span{
                            display: block;
                            margin-bottom: 0px;
                        }
                    }
                }

                &_2{
                    margin-bottom: 40px;

                    &-subscription{
                        display: flex;
                        position relative;

                        span:nth-child(2){
                            // position absolute;
                            // left: 120px;
                            // width: 200px;
                            // color: #848484;
                        }

                        span:nth-child(3){
                            // position absolute;
                            // left: 195px;
                            // color: #848484;
                        }
                    }
                }

                .spanheadingmini{
                    display: block;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;
                    margin-bottom: 5px;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 150%;
                    letter-spacing: 0.01em;
                    color: #848484;

                    &color{
                        color: #007A33;
                    }
                }

                .spanheadingminimarkcolor{
                    display: inline;
                    position relative;
                    top: 0px;

                    font-size: 16px;
                    color: #007A33;
                }

                .spanbold{
                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 170%;
                    color: #535353;
                }

                .spanbottom{
                    margin-bottom: 30px;
                }

                .spanbottom-mini{
                    margin-bottom: 20px;
                }

                .spaninline{
                    display: inline;
                }
            }

            &__button{
                button{
                    width: 173px;
                    height: 45px;
                }
            }

            &__button-close{
            }

            &__content{
                display: none;
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__attention{
            display: none;
        }
    }

    .accordion-rates__content2-subscription-2{
        position absolute;
        left: 120px !important;
        width: 200px;
        color: #848484;
    }

    .accordion-rates__content2-subscription-3{
        position absolute;
        left: 195px !important;
        color: #848484;
    }

}
