.corporation-internet-wifi{
    p{
        max-width: 927px;
        margin-bottom: 80px;
    }
}

.corporation-internet-wired{
    padding-top: 110px !important;
    padding-bottom: 110px !important;

    p{
        max-width: 927px;
        margin-bottom: 110px;
    }

    .about-services .item{
        h3{
            font-size: 24px;
            margin-bottom: 80px;
        }

        p{
            margin-bottom: 0;
            text-align: center;
        }
    }
}