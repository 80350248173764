.about-services{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 50px 0;

  &:last-child {
    margin-bottom: 0;
  }

  .item{
    flex-basis: 252px;

    .svg {
      margin-bottom: 30px;
      text-align: center;

      svg{
        max-height: 86px;
        width: auto;
      }
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 1100px){
    margin: 0 auto;
    width: 750px;

    .item{
      flex-basis: 350px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 768px){
    width: 100%;
  }
  @media (max-width: 740px){
    .item{
      flex-basis: 260px;
    }
  }
  @media (max-width: 560px){
    .item{
      flex-basis: 100%;
      margin-bottom: 30px;
    }
  }

//index
  &_default{
    .item{
      .svg{
        &_house{
          path{
            fill: green;

            &.svg_theme-path{
              fill: green;
            }
          }
        }
        &_speedometer{
          path{
            fill: purple !important;
          }
        }
        &_mechanism{
          path{
            fill: yellow !important;
          }
        }
        &_certificate{
          path{
            fill: red !important;
          }
        }
      }
    }
  }

  &_3-items{
    .item{
      margin-bottom: 40px;
      flex-basis: 27%;
    }
      
    @media (max-width: 670px){
      .item{
        flex-basis: 43%;
      }
    }
    @media (max-width: 470px){
      .item{
        flex-basis: 100%;
      }
    }
  }

  &_detail{
    .item{
      flex-basis: 32%;

      .a-button{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 45px;
    }
      
    @media (max-width: 1230px){
      flex-flow: row wrap;
      justify-content: center;

      .item{
        flex-basis: 43%;
        margin-left: 20px;
        margin-right: 20px;

        &__info{
          height: 300px;
        }
      }
    }
    @media (max-width: 1100px){
      .item{
        flex: 100% 0 0;

        &__info{
          height: auto;
        }
      }
    }
  }

}




