
// mapBGColors = {
//     red: linear-gradient(180deg, rgba(28, 28, 28, 0) 21.57%, #50002B 100%),
//     green: linear-gradient(180deg, rgba(28, 28, 28, 0) 28.86%, #1F9E54 126%),
//     purple: linear-gradient(180deg, rgba(28, 28, 28, 0) 22.71%, #2B0156 100%),
//     yellow: linear-gradient(180deg, rgba(28, 28, 28, 0) 23.57%, #978800 100%),
//     default: transparent
//   }

mapBGColors = {
    red: linear-gradient(180deg, rgba(28, 28, 28, 0) 50%, rgba(80, 0, 43, 70%) 100%),
    green: linear-gradient(180deg, rgba(28, 28, 28, 0) 50%, rgba(0, 122, 51, 70%) 100%),
    purple: linear-gradient(180deg, rgba(28, 28, 28, 0) 50%, rgba(43, 1, 86, 70%) 100%),
    yellow: linear-gradient(180deg, rgba(28, 28, 28, 0) 50%, rgba(151, 136, 0, 70%) 100%),
    default: transparent
}

for val, BGcolor in mapBGColors{
    .theme-color-{val}{
        --BG-color: BGcolor;
    }
}

mapBannerDisplay = {
    red: none,
    green: block,
    purple: none,
    yellow: none,
    default: none
  }

for val, BannerDisplay in mapBannerDisplay{
    .theme-color-{val}{
        --banner-display: BannerDisplay;
    }
}

.menu-color{
    z-index: 2;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 216px;

    display: flex;
    justify-content: center;

    &__BG{
        z-index: 3;

        background-color: #0C0C0C;

        position: absolute;
        top: -700px;
        left: 0;
        right: 0;

        height: 700px;

        transition: top 0.3s;
    }

    &__BG.active{
        top: 0;
    }

    &__BG-color{
        background: var(--BG-color);

        height: 100%;
        width: 100%;
    }

    &__page{
        z-index: 4;

        flex: 1 0 100%;
        //max-width: 1796px;
        max-width: 1440px;
        padding: 0 55px;
    }

    &__logo{
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 96px;

        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-top: 10px;
        margin-bottom: 50px;
    }

    &__logo-img{
        display: flex;
        align-items: center;
        gap: 49px;

        &-home{
            width: 205px;

            position: relative;
            top: -5px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &-geo{
            display: flex;
            align-items: center;
            gap: 7px;
            cursor: pointer;

            span{
                font-p-20-600(white !important);
                font-weight:  400;
            }

            svg{
                path{
                    fill: white;
                }
            }
        }
    }

    &__logo-contacts{
        display: flex;
        gap: 81px;
        margin-top: 5px;
    }

    &__logo-contacts-button{
        button{
            background-color: transparent;
            font-p-20-600(white !important);
            font-weight: 400;
        }
    }

    &__logo-contacts-acc {
        a {
            color: #fff;
            text-decoration: none;

            top: 3px;

            span {
                top: -6px;
            }
        }
    }

    &__buttons{
        display: flex;
        align-items: center;
        gap: 41px;

        padding-bottom: 20px;

        button{
            background-color: transparent;
            font-p-20-600(rgba(245, 241, 248, 0.45) !important);
            font-weight: 400;

            display: flex;
            align-items: center;
            gap: 11px

            svg{
                path{
                    stroke: rgba(245, 241, 248, 0.45) !important;
                    stroke-opacity: 1;
                }
            }
        }

        button:hover, button.active, button.white{
            // font-weight: 400;
            color: white !important;

            svg{
                path{
                    stroke: white !important;
                    stroke-opacity: 1;
                }
            }
        }
    }

    &__buttons.active{
        display: none;
    }

    &__links{
        position: relative;

        top: -1000px;

        transition: top 0.3s;
    }

    &__links.active{
        top: 0;
    }

    &__menu{
        display: none;

        padding-top: 40px;
        border-top: 1px solid rgba(245, 241, 248, 0.25);

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;

        // display: flex;
        gap: 40px 80px;
        flex-wrap: wrap;

        div{
            width: fit-content;

            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        span{
            margin-bottom 10px;

            font-p-20-500(#F5F1F8 !important);
            font-weight: 700;
        }

        a{
            text-decoration none;

            font-p-300(17px, #F5F1F8 !important);

            &:hover{
                font-weight: 500;
            }
        }

        &_1{
            width: 205px !important;
        }

        &_2{
            display: flex;
            flex-direction: row !important;
            gap: 40px 80px !important;
        }

        &_3 {
            flex: 1 0 100%;
        }
    }

    &__menu.active{
        display: flex;
    }

    &__BG-banner{
        display: var(--banner-display);
        position: absolute;
        // top: -253px;
        top: -260px;
        right: 58px;

        transition: top 0.3s;
    }

    &__BG-banner.active{
        top: 253px;
    }

    &__BG-banner-content{
        background: url(/src/img/menu/menu_offer.jpg) no-repeat center;
        height: 260px;
        width: 595px;

        padding: 55px;

        span{
            display: block;
            width: 189px;
            margin-bottom: 35px;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: #FFFFFF;
        }

        a{
            display: inline-flex;
            gap: 14px;
            align-items: center;

            padding: 10px 38px 10px 35px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            border-radius: 6px;

            text-decoration: none;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: #FFFFFF;

            svg{
                path{
                    fill: white;
                }
            }

            &:hover{
                background: #12B845;
            }
        }
    }

    &__contacts{
        position relative;
        top: -1000px;

        transition: top 0.3s;
    }

    &__contacts.active{
        top: 0;
    }

    &__contacts-heading{
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 50px;
    }

    .heading{
        font-h2(36px, #F5F1F8 !important);
    }

    &__contacts-close{
        background-color: transparent;
        cursor: pointer;

        height: 15px;
        width: 15px;

        position: relative;

        span{
            width: 21px;
            height: 1px;
            background-color: white;

            position: absolute;
            top: 7px;
            left: -3px;
        }

        span:first-child{
            transform: rotate(45deg);
        }

        span:last-child{
            transform: rotate(-45deg);
        }
    }

    &__contacts-buttons{
        display: flex;
        gap: 20px;

        margin-bottom: 70px;

        button{
            background: transparent;
            border-bottom: 1px solid rgba(245, 241, 248, 0.25);
            border-radius: 0;
            transition: all 0.3s;
        }

        span{
            display: block;
            height: 100%;
            width: 100%;

            font-weight: 400;
            font-size: 20px;
            line-height: 29px;
            letter-spacing : 0.1em;
            color: rgba(245,241,248,0.45); /* #F5F1F8*/

            padding: 0 44px 12px 0;
            transition: all 0.2s;
        }

        button.button:hover,
        button.button.active {
            border-bottom: 3px solid #FFFFFF;

            span{
                color: #F5F1F8;
            }
        }
    }

    &__contacts-cards{
        margin-bottom: 45px;
    }

    &__contacts-card{
        display: none;
        gap: 80px;
    }

    &__contacts-card.active{
        display: flex;
    }

    &__contacts-phone,
    &__contacts-mail,
    &__contacts-support{
        display: flex;
        flex-direction: column;

        span{
            font-p-300(17px, rgba(245, 241, 248, 0.45) !important);

            margin-bottom: 10px;
        }

        a{
            cursor: pointer;
            text-decoration: none;

            font-h3-ls(28px, #F5F1F8 !important)
        }
    }

    &__contacts-mail,
    &__contacts-support{

        span{
            margin-bottom: 20px;
        }

        a{
            font-h3-ls(20px, #F5F1F8 !important)
        }
    }

    &__contacts-phone{
        width: 250px;
    }

    &__contacts-mail{
        width: 194px;
    }

    &__contacts-support{
        width: 231px;
    }

    &__contacts-button-call{
        button{
            padding: 20px 45px 21px;

            border: 1px solid rgba(245, 241, 248, 0.25);
            border-radius: 6px;

            font-p(20px, #F5F1F8 !important);
            line-height: 145%;

            background-color: transparent;
        }

        button:hover{
            border: 1px solid #F5F1F8;
        }
    }

    &__mobile{
        display: none;
    }

    @media (max-width: 1679px){
        &__BG-banner-content{
            background: url(/src/img/menu/menu_offer_medium.jpg) no-repeat center;
            width: 559px;

            padding: 55px;
        }

        &__menu{
            &_2{
                display: flex;
                flex-direction: column !important;
            }
        }

        &__contacts-buttons{
            span{
                padding: 0 40px 10px 0;
            }
        }

    }

    @media (max-width: 1526px){
        &__page, &__BG-page{
            max-width: 1440px;

            padding: 0 55px;
        }
        &__BG-banner{
            right: 55px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0;
        }

        &__BG,
        &__logo,
        &__buttons,
        &__links,
        &__BG-banner,
        &__contacts{
            display: none;
        }

        &__mobile{
            display: flex;
            flex-direction: column;

            width: 100%;
            position relative;
        }

        &__mobile-top{
            padding: 12px 31px 0 27px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            z-index: 1;
        }

        &__mobile-logo{
            width: 116px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &__mobile-sidebar{
            display: flex;
            gap: 31px;
            align-items: center;

            padding-top: 6px;
        }

        &__mobile-phone{
            button{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: linear-gradient(170.97deg, #12B845 6.87%, #007A33 44.45%, #00551D 72.29%);

                display: flex;
                justify-content: center;
                align-items: center;
            }

            button.active{
                background:#444444;
            }

            button.black{
                background:#444444;
            }
        }
        &__mobile-account{
            a {
                svg {
                    top:5px;
                }
            }
        }
        &__mobile-burger{
            width: 28px;
            height: 16px;
            display: flex;

            background-color: transparent;

            span{
                transition: top 0.1s, opacity 0s 0.1s, transform 0.1s 0.1s, background-color 0s 0.1s;

                position: absolute;

                display: block;
                width: 20px;
                height: 2px;
                background-color: white;

                border-radius: 1px;

                right: 0;

                &:nth-child(1){
                    top: 0;
                    transform: rotate(0deg);
                }
                &:nth-child(2){
                    top: 7px;
                    opacity: 1;
                }
                &:nth-child(3){
                    top: 14px;
                    transform: rotate(0deg);
                }
            }
        }

        &__mobile-burger.active{
            span{
                background-color: #848484;

                &:nth-child(1){
                    top: 7px;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    top: 7px;
                    opacity: 0;
                }
                &:nth-child(3){
                    top: 7px;
                    transform: rotate(-45deg);
                }
            }
        }

        &__mobile-content{
            background: #202020;

            position: absolute;
            height: fit-content;
            width: 100%;

            padding: 82px 30px 40px;

            left: 0;
            top: -1500px;

            transition: top 0.5s;
        }

        &__mobile-content.active{
            top: 0;
        }

        &__mobile-content-links{
            margin-bottom: 85px;
        }

        &__mobile-content-links-card-header{
            transition: all 0.3s;

            padding-top: 30px;
            border-bottom: 1px solid rgba(245, 241, 248, 0.25);
            //margin-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 170%;
                color: #FFFFFF;
            }

            &-img{
                margin-right: 10px;

                svg{
                    transition: all 0.3s;
                    transform: rotate(90deg);
                    path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &-img.active{
                svg{
                    transform: rotate(-90deg);
                }
            }
        }

        &__mobile-content-links-card-header.active{
            margin-bottom: 25px;
        }

        &__mobile-content-links-card-ul{
            display: flex;
            flex-direction: column;

            a{
                transition: all 0.3s;
                height: 0;
                display: block;

                margin-bottom: 0;

                text-decoration: none;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                line-height: 170%;
                color: transparent;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }

        &__mobile-content-links-card-ul.active{
            a{
                margin-bottom: 20px;
                height: 26px;

                color: #C6C6C6;
            }
        }

        &__mobile-content-contacts{
            display: flex;
            flex-direction: column;
            align-items: center;

            &-img-geo{
                display: flex;
                align-items: center;
                gap: 7px;
                cursor: pointer;

                margin-bottom: 15px;

                span{
                    font-p-500(15px, #F5F1F8 !important);
                }

                svg{
                    path{
                        fill: white;
                    }
                }
            }

            &-address{
                margin-bottom: 40px;

                span{
                    font-p-500(15px, #FFFFFF !important)
                }
            }

            &-call{
                width: 100%;
                padding-bottom: 50px;
                border-bottom: 1px solid rgba(245, 241, 248, 0.25);
                margin-bottom: 50px;

                display: flex;
                justify-content: center;

                button{
                    background-color: transparent;

                    padding: 10px 30px;

                    border: 1px solid rgba(245, 241, 248, 0.25);
                    border-radius: 6px;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 25px;
                    letter-spacing: 0.05em;
                    color: #FFFFFF;
                }
            }
        }

        &__mobile-content-footer{
            display: flex;
            flex-direction: column;

            span, a{
                text-decoration: none;
                text-align: center;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 170%;
                letter-spacing: 0.02em;
                color: #C6C6C6;
            }

            &-links{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                margin-bottom: 40px;
            }
        }

        //  меню контакты
        &__mobile-contacts{
            background: #202020;

            position: absolute;
            height: fit-content;
            width: 100%;

            padding: 102px 30px 45px;

            left: 0;
            top: -1500px;
            // top: 0;

            transition: top 0.5s;
        }

        &__mobile-contacts.active{
            top: 0;
        }

        &__mobile-contacts-heading{
            margin-bottom: 49px;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .heading{
            font-h3(20px, #FFFFFF !important);
        }

        &__mobile-contacts-close{
            width: 15px;
            height: 15px;

            margin-right: 7px;

            position: relative;

            span{
                width: 21px;
                height: 1px;
                background-color: #848484;

                position: absolute;

                left: -3px;
                top: 7px;
            }

            span:first-child{
                transform: rotate(45deg);
            }

            span:last-child{
                transform: rotate(-45deg);
            }
        }

        &__mobile-contacts-links-card-header{
            transition: all 0.3s;

            padding-top: 30px;
            border-bottom: 1px solid rgba(245, 241, 248, 0.25);
            //margin-bottom: 25px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 170%;
                color: #FFFFFF;
            }

            &-img{
                margin-right: 10px;

                svg{
                    transition: all 0.3s;
                    transform: rotate(90deg);
                    path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &-img.active{
                svg{
                    transform: rotate(-90deg);
                }
            }
        }

        &__mobile-contacts-links-card-ul{
            display: flex;
            flex-direction: column;
            padding-top: 35px;


            transition: all 0.2s;

            *{
                transition: all 0.2s;
            }
        }

        &__mobile-contacts-links-card-ul.active{
            padding-top: 0px;

            *{
                height: 0px !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                margin-top: 0px !important;
                margin-bottom: 0px !important;
                border-bottom: transparent !important;
                border-top: transparent !important;
                color: transparent !important;
            }
        }

        &__mobile-contacts-phone{
            margin-bottom: 30px;

            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                font-p-300(15px, rgba(245, 241, 248, 0.45));
            }

            a{
                text-decoration: none;
                font-h3-ls(25px, #F5F1F8);
            }
        }

        &__mobile-contacts-button{
            button{
                background-color: transparent;

                padding: 10px 30px;
                margin-bottom: 40px;

                border: 1px solid rgba(245, 241, 248, 0.25);
                border-radius: 6px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.05em;
                // font-variant: all-small-caps;
                color: #FFFFFF;
            }
        }

        &__mobile-contacts-mail,
        &__mobile-contacts-support{
            margin-bottom: 25px;

            display: flex;
            flex-direction: column;
            gap: 5px;

            span{
                font-p-300(15px, rgba(245, 241, 248, 0.45));
            }

            a{
                text-decoration: none;
                font-p-500(15px, #F5F1F8)
            }
        }

        &__mobile-contacts-support{
            margin-bottom: 10px;
        }
    }

    @media (max-width: 425px) {
        button.menu-color {
            &__mobile-burger {
                padding : 0;
            }
        }

    }
}
