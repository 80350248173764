//  главная
.main-about{
    h2{
        // font-size: 30px;

        // margin-bottom: 55px;
    }
}

.main-data{
    h2{
        // font-size: 30px;

        // margin-bottom: 55px;
    }
}

.main-news{
    h2{
        // font-size: 30px;

        // margin-bottom: 55px;
    }

    .block-news{
        justify-content: flex-start;
        gap: 30px;
    }
}

.main-clients{
    h2{
        // font-size: 30px;

        // margin-bottom: 55px;
    }
}