.feedback{
  padding: 40px 0;

  &__img{
    z-index: 2;
  }

  .small-text{
    font-family: font-family-content;
    font-weight: 400;
  }

  &__form{
    background-color: gray;
    padding: 60px 0 50px;

    p{
      text-align: center;
      margin-bottom: 32px;
    }
  }

  .feedback__img-men{
    height: 283px;
    background: url(img/elements/feedback__men.webp) top center no-repeat;
    margin-bottom: -8px;

    @media (max-width: 768px){
      background-size: 300px auto;
      height: 160px;
    }
  }

  .feedback__img-woman{
    height: 221px;
    background: url(img/elements/feedback__women.webp) top center no-repeat;
    margin-bottom: -56px;
    margin-top: -167px;

    @media (max-width: 768px){
      background-size: 340px auto;
      height: 145px;
      margin-bottom: -32px;
    }
  }

  @media (max-width: 425px){
    padding: 0 10px;

    &__form{
      padding: 28px 0;
    }
  }

//usual
  &_usual{
    padding: 0 20px;

    .feedback__form{
      background-color: #fff;
    }

    &--gray{
      .feedback__form{
        background-color: #f5f5f5;
      }
    }
  }


//index
  &_index{
    padding: 0;
    padding-top: 40px;

    .feedback__form{
      margin: 0 !important;
    }
  }

//stock-corp
  &_stocks-corp{
    padding: 0 20px;

    .feedback__form{
      background-color: #fff;
      padding: 90px 0 108px;
    }

    @media (max-width: 768px){
      .feedback__form{
        padding: 37px 0 70px;
      }
    }
  }

//corp internet
  &_variant2{
    padding: 40px 0;

    .feedback__form{
      margin: 0;
    }

    .cont-form{
      max-width: 389px;
      margin-left: auto;

      +r(1100) {
        margin-right: auto;
      }

      .headline_h1{
        color: red;
        margin-bottom: 25px;
        text-align: left;
      }

      p{
        margin-bottom: 30px;
      }

      input{
        margin-bottom: 30px;
      }

      .small-text{
        font-family: $font-family-content;
        font-weight: 400;
      }

      .msg-form-error{
        margin-bottom: 20px;
        margin-top: -10px;
      }
    }

    @media (max-width: 1100px){
      background-image: none !important;
      background-color: gray !important;

      .cont-form {
        .headline, p, .small-text{
          text-align: center;
        }
      }
    }

    // Corp internet
    &.feedback_corp-internet{
      background: url(img/elements/feedback_corp-internet.webp) top center/cover no-repeat;

      @media (max-width: 1200px){
        background: url(img/elements/feedback_corp-internet.webp) 33% 50%/cover no-repeat;
      }
    }
  // Corp television
    &.feedback_corp-television{
      background: url(img/elements/feedback_corp-television.webp) 17% 50%/cover no-repeat;

      .cont-form{
        right: 307px;
      }
      @media (max-width: 2000px){
        .cont-form{
          right: 180px;
        }
      }
      @media (max-width: 1500px){
        .cont-form{
          right: 20px;
        }
      }
      @media (max-width: 1200px){
        background-position: 40% 50%;
      }
      @media (max-width: 900px){
        .cont-form{
          right: auto;
        }
      }
    }

    //Phiz internet
    &.feedback_phiz-internet{
      height: 598px;
      background: url(img/elements/feedback_phiz-internet.webp) 30% 50%/cover no-repeat;

      .cont-form{
        top: -25px;

        input, textarea{
          margin-bottom: 18px;
          width: 100%;
        }

        .a-button{
          margin-bottom: 10px !important;
        }

        .msg-form-error{
          margin-bottom: 13px;
        }
      }

      @media (max-width: 1200px){
        background-position: 33% 50%;
      }
    }
    &.feedback_phiz-telephony{
      height: auto;
      margin-top: -40px;

      .feedback__form{
        background-color: #fff;
      }

      @media (max-width: 836px){
        .feedback__form {
          .form {
            grid-template-columns: 1fr;
            grid-auto-rows: 60px;
            width: 400px;
            margin: 0 auto;

            textarea, input{
              width: 100%;
              grid-column: span 1;
            }
          }


        }
      }
      @media (max-width: 425px) {
        .feedback__form {
          .form {
            width: 100%;
            grid-auto-rows: 46px;
            grid-gap: 20px 0;

            textarea, input{
              width: 100%;
              grid-column: span 1;
            }
          }
        }
      }
    }
    &.feedback_phiz-television{
      background: url(img/elements/feedback_phiz-television.webp) 30% 50%/cover no-repeat;
      height: 699px;

      .cont-form{
        right: 372px;
        top: 37px;

        .js-form-block{
          input, textarea{
            width: 100%;
          }

          .msg-form-error{
              margin-top: 6px;
            margin-bottom: 10px;
          }

          .a-button{
            margin-bottom: 8px !important;
          }
        }
      }

      @media (max-width: 1180px){
        .cont-form{
          right: 5%;
        }
      }
      @media (max-width: 900px){
        .cont-form{
          right: auto;
        }
      }
    }
  }

  &_variant3{
    padding-top: 0;
    padding-bottom: 0;

    .form{
      display: block;
    }

    @media (max-width: 768px){
      .form{
        .contacts{
          width: auto;
          grid-template-areas: "input_name" "input_phone" "textarea";
          grid-template-columns: 360px;
          grid-template-rows: auto auto 120px;
          justify-content: center;

          input, textarea{
            margin-bottom: 0;
            width: auto;
          }
        }
      }
    }
    @media (max-width: 425px){
      .form{
        .contacts{
          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
      }
    }

    &.feedback_phiz-help{
      .feedback__img{
        background: url(img/elements/feedback_woman-cat.webp) top center no-repeat;
        height: 242px;
        margin-bottom: -56px;
      }
      .small-text{
        display: none;
      }

      @media (max-width: 768px){
        .feedback__img{
          background-size: 357px auto;
          height: 167px;
          margin-bottom: -39px;
        }

        .small-text{
          display: block;
        }
      }
      @media (max-width: 425px){
        .feedback__img{
          background-size: 345px auto;
          height: 162px;
          margin-bottom: -39px;
        }
      }
    }
  }

  &_birulovo{
    padding: 0;

    .logos{
      margin: 40px 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .fb-log_citi{
        width: 194px
      }

      .fb_plus{
        margin: 0 59px;
        color: #888888;
        font-size: 20px
        font-weight: bold;
      }

      @media (max-width: 600px){
        flex-direction: column;
        align-items: center;

        .fb_plus{
          margin: 20px 0;
        }
      }
    }

    .form-birulovo{
      padding: 60px 0 50px;
      background-color: #BACAD3;

      .form-birulovo__inner{
        max-width: 790px;
        padding: 0 20px;
        margin: 0 auto;

        @media (max-width: 425px){
          padding: 0 10px;
        }

        h2{
          color: #231F20 !important;
          font-weight: 600;
          text-align: center;
          line-height: initial;
        }

        .fb-form{
          margin-top: 50px;

          @media (max-width: 425px){
            margin-top: 25px;
          }

          .form-top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px

            @media (max-width: 650px){
              flex-direction column
            }

            .form-top__left{
              img{
                width: 194px;
                height: 243px;
                margin-bottom: 20px
                object-fit: cover
              }

              p{
                margin-bottom: 0
                &.fb-ft-pbold{
                  font-size: 20px
                  line-height: 24px
                  font-weight: bold
                }

                &.fb-ft-psm{
                  font-size: 14px
                  line-height: 22px
                }
              }

              @media (max-width: 650px){
                margin-bottom: 30px

                img{
                  margin: 0 auto 20px;
                  display: block
                }

                p{
                  text-align: center
                }
              }
            }

            .form-top__right{
              width: 486px;

              input, textarea{
                width: 100%;
              }
              input{
                margin-bottom: 30px
              }

              @media (max-width: 736px){
                width: 400px
              }

              @media (max-width: 650px){
                width: 100%
              }

              @media (max-width: 425px){
                input{
                  margin-bottom: 15px
                }
              }
            }
          }

          .form-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-bottom__left{
              font-size: 12px
              line-height: 18px
              width: 370px
            }

            button{
              background-color: #c20a45 !important;
              border-color: #c20a45 !important;
              &:hover{
                background-color: transparent !important;
                color: #c20a45 !important
              }
            }

            @media (max-width: 650px){
              flex-wrap: wrap;
              .form-bottom__left{
                width: 100%;
                margin-bottom: 20px
              }
              button{
                display: block
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

}

.no-webp .feedback{
  .feedback__img-men{ background-image: url(img/elements/feedback__men.png);}
  .feedback__img-woman{ background-image: url(img/elements/feedback__women.png);}

  //corp internet
  &_variant2{
    // Corp internet
    &.feedback_corp-internet{
      background-image: url(img/elements/feedback_corp-internet.png);

      @media (max-width: 1200px){
        background-image: url(img/elements/feedback_corp-internet.png);
      }
    }
    // Corp television
    &.feedback_corp-television{ background-image: url(img/elements/feedback_corp-television.png);}

    //Phiz internet
    &.feedback_phiz-internet{ background-image: url(img/elements/feedback_phiz-internet.png);}

    &.feedback_phiz-television{ background-image: url(img/elements/feedback_phiz-television.png);}
  }

  &_variant3{
    &.feedback_phiz-help{
      .feedback__img{ background-image: url(img/elements/feedback_woman-cat.png);}
    }
  }

}
