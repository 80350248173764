.section{
  &_img-ul{
    display: flex;
    flex-flow: row nowrap;

    img{
      margin-right: 106px;
      margin-top: -50px;
      align-self: flex-start;
    }
      
    h3{
      margin-top: 55px;
      color: black !important;
      text-align: left;
    }
      
    @media (max-width: 940px){
      img{
        margin-right: 34px;
      }
    }
    @media (max-width: 768px){
      flex-flow: row wrap;

      img{
        margin: 10px auto 30px;
      }
      .info{
        width: 600px;
        margin: 30px auto 0;

        h3{
          text-align: center;
        }
        button{
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
    @media (max-width: 425px){
      img{
        display: none;
      }
      .info{
        width: auto;
      }
    }

    &--realty-builder{
      img{
        margin-left: -30px;
        margin-right: 0;
      }

      .info{
        p, h4{
          margin-bottom: 30px;
        }
        .ul-normal{
          margin-bottom: 60px;
        }
      }
        
      @media (max-width: 1000px){
        flex-flow: row wrap;

        img{
          display: block;
          margin: 0 auto 30px;
        }

        .info{
          width: 600px;
          margin: 30px auto 0;
        }
      }

      @media (max-width: 600px){
        img{
          width: 100%;
          height: auto;
          margin-bottom: 0;
        }
      }
    }

    &--oper-network{
      margin-bottom: 10px;

      img{
        margin-top: -33px;
        margin-right: 70px;
      }

      p{
        margin-bottom: 50px;
      }
        
      @media (max-width: 1024px){
        flex-flow: row wrap;

        .info{
          width: 100%;
          margin-top: 30px;
        }

        img{
          display: block;
          margin: 0 auto;
        }
      }
      @media (max-width: 600px){
        img{
          width: 100%;
        }
      }
    }
  }

  &_europa{
    &--cloud-video{
      img{
        margin-top: -15px;
      }
      ul{
        margin-bottom: 77px;
      }
        
      @media (max-width: 768px){
        ul{
          margin-bottom: 40px;
        }
      }  
    }
  }
}






