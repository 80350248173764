.services{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .item{
    box-shadow: 4px 1px 4px 0 rgba(216, 214, 214, 0.75);
    border-radius: 6px;

    & > a{
      text-decoration: none;
    }

    .a-btn{
      span{
        line-height: 1.4;
      }
    }

    &__link{
      border-radius: 6px 6px 0 0;
      display: block;
      background-color: red;
      padding: 20px;
      color: #fff;
      text-align: center;
      line-height: 1.4;
      text-decoration: none;
    }

    &__info{
      border-radius: 0 0 6px 6px;
      border: border;
      border-top: none;
      background-color: #fff;
      padding: 30px 35px 46px;

      .a-button{
        border-radius: 6px;

        display: block;
        margin: 0 auto;
        padding: 12px 0 0 0;
        width: 223px;
        height: 46px;
        text-align: center;
      }

      ul{
        margin-bottom: 32px;
      }
    }
  }

//index
  &_index{

    .item{
      flex: 0 0 280px;

      &__link{
        font-size: 22px;
        font-weight: 400;
        font-family: 'Fira Sans',sans-serif;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      ul{
        padding: 33px 18px;
        border: 1px solid #d8d8d8;
        border-top: none;
        border-radius: 0 0 6px 6px;

        li{
          display: block;
          margin-bottom: 50px;
          height: 32px;

          &:last-child{
            margin-bottom: 0;
          }

          .svg{
            svg{
              max-width: 32px;
              max-height: 29px;
            }
          }

          a{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .svg{
              flex-basis: 45px;
              flex-shrink: 0;
            }

            span{
              color: black;
              line-height: 1;
            }
          }
        }
      }
    }

    @media (max-width: 1140px){
      width: 600px;
      margin: 0 auto;

      .item{
        margin-bottom: 30px;
      }
    }

    @media (max-width: 650px){
      width: 100%;
    }

    @media (max-width: 570px){
      .item{
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 425px){
      .item{
        width: 100%;
        flex-basis: auto;
      }
    }
  }

  &_divorce{
    .item{
      flex: 0 1 296px;
      border: none;
      box-shadow: none;

      &__info{
        border: none;
        padding: 0;
      }

      h3{
        height: 65px;
        margin-bottom: 0;
        text-align: center;
      }

      p{
        min-height: 95px;
        text-align: center;
      }

      .ul-normal {
        margin-bottom: 40px;

        li {
            margin-bottom: 10px;
            text-align: center;
        }
        li::before {
            position: relative;
            left: -6px;
            top: -1px;
        }
      }

      .svg{
        margin-bottom: 40px;
        text-align: center;

        svg{
          max-height: 80px;
          max-width: 100%;
          width: auto;
        }

        .svg_color{
          fill: #007a33;
        }
      }

      .a-button{
        //display: inline-block;
      }
    }
  }

  &_various{
    flex-flow: row nowrap;

    .item{
      &__link{
        font-size: 24px;
        cursor: default;
      }

      .item__info{
        padding: 0 20px 30px;
      }

      .services__img{
        padding: 33px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
          max-height: 50px;
          width: auto;
        }

      }
    }

    &--items4{
      .item{
        flex: 23% 0 0;
      }
        
      @media (max-width: 1140px){
        flex-flow: row wrap;
        justify-content: space-around;

        .item{
          margin-bottom: 30px;
        }
      }
    }
  }

  &_stocks{
    margin-top: 50px;

    .item {
      flex: 0 0 360px;

      p.description{
        min-height: 115px;
      }

      &__link {
        font-size: 24px;
        font-weight: 400;
        cursor: default;
      }

    }

    @media (max-width: 1200px){
      justify-content: center;

      .item{
        margin:20px 20px 0;
      }

    }

    @media (max-width: 900px){
      .item{
        flex: 0 0 280px;
      }

    }

    @media (max-width: 740px){
      .item{
        flex: 0 0 425px;
      }

    }

    @media (max-width: 425px){
      .item{
        flex: 0 0 100%;
        margin: 15px 0;

        &__info{
          padding: 20px;
        }
      }

    }

    &_2items{
      .services__item{
        flex: 550px 0 0;
      }
        
      @media (max-width: 600px){
        .services__item{
          flex: 100% 0 0;

          .div-ul{
            flex-flow: row wrap;

            .item{
              flex: 100% 0 0;
              margin-top: 0;

              .ul-normal{
                margin-bottom: 7px !important;
              }

              &:last-child{
                .ul-normal{
                  margin-bottom: 30px !important;
                }
              }
            }
          }
        }
      }
      @media (max-width: 425px){
        .services__item{
          .div-ul{
            .item{
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &--phiz{
      .item{
        &__info{
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    &--realty{
      .item{
        &__info{
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
    &--realty-managment{
      .item{
        &__info{
          padding-left: 25px;
          padding-right: 25px;

          p.description {
           min-height: 145px;
          }
        }
      }

    }
    &--realty-installation{
      .item{
        &__info{
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    &--oper{
      .services__item{
        .item__info{
          padding: 30px 25px 35px;

          p.description {
            min-height: fit-content;
          }
        }
      }

      .div-ul{
        flex-flow: row nowrap;

        .item{
          box-shadow: none;
          flex: auto;
        }
      }
    }
  }
}