.all-modals{
  z-index: 999;

  .modal{
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      background-color: rgba(0,0,0,0.4);
    }

    &__container {
      background-color: #fff;
      padding: 30px;
      max-width: 420px;
      width: 420px;
      max-height: 100vh;
      border-radius: 4px;
      overflow-y: auto;
      box-sizing: border-box;
      border: 1px solid #e5e4e4;
    }

    &__header{
      font-size: 16px;
      text-align: center;
    }

    &__content{
      margin-top: 20px;
      display: flex;
      flex-flow: column nowrap;

      input{
        margin-bottom: 20px;
        font-size: 16px;
      }

      .msg-form-error{
        margin-top: -10px;
        margin-bottom: 10px;
      }

      .wrap_input{
        input, textarea{
          display: block;
          width: 100%;
          font-size: 16px;
          margin-bottom: 18px;
        }

        .require_input{
          position: absolute;
          top: 7px;
          right: 6px;
          color: red;
        }
      }
    }

    &__close{
      position: absolute;
      top: 11px;
      right: 16px;
      width: 10px;
      height: 10px;
      cursor: pointer;

      &:before,&:after{
        position: absolute;
        display: block;
        content: '';
        width: 10px;
        height: 1px;
        top: 5px;
        background-color: black;
      }
      &:before{
        transform: rotate(45deg);
      }
      &:after{
        transform: rotate(-45deg);
      }
    }

    &_channels{
      .modal__container{
        max-width: 1170px;
        width: 100%;
        height: 700px;
      }

      .modal__content{
        height: calc(100% - 70px);

        .modal__loader{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          background-color: #fff;
        }

        .modal__list-channels{
          display: none;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-auto-rows: auto;
          justify-items: stretch;
          grid-gap: 15px;

          .channel_item{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            img{
              width: auto;
              height: 40px;
              margin-right: 20px;
            }

            .channel_name{
              font-size: 14px;
            }
          }
        }
      }
    }

    &_location{
      .modal__header {
        color: #202020;
      }

      .modal__container{
        max-width: 100%;
        width: 530px;

        .loc-choice{
          display: flex;
          flex-wrap: wrap;
          gap: 16px;

          a {
            flex: 1 1 220px;

            span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    @media (max-width: 1140px){
      .modal__content{
        .modal__list-channels{
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
    @media (max-width: 900px){
      .modal__content{
        .modal__list-channels{
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    @media (max-width: 600px){
      .modal__content{
        .modal__list-channels{
          grid-template-columns: 1fr;
          justify-content: center;
        }
      }
    }
  }
}





/**************************\
  Animation Style
\**************************/
@keyframes mmfadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
