.dashed-block {
  //border: 1px dashed var(--theme-color);
  background-color: #FEEFF3; // todo: pale theme color
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='20' ry='20' style='fill: none; stroke: red; stroke-width: 1; stroke-dasharray: 20 10; stroke-linejoin: round'/></svg>");
  padding: 40px 50px 50px;
  border-radius: 20px;

  @media (max-width: 1024px) {
    padding: 30px;
  }

  &--action-tv {
    @media (min-width: 769px) {
      margin-right: 20px;
    }
  }
}
