.modal-zhk{
    overflow-y: scroll;
    font-family: $font-family-content !important;

    input, textarea{
        border-radius: 6px;
        border: solid 1px green;

        font-family: $font-family-content;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #393939;
    }
    textarea, input{
        &:focus{
            outline: none;
            box-shadow: 0 0 1px 1px green;
        }
    }

    .modal__container {
        padding-top: 30px;
    }

    .modal__header{
        font-family: $font-family-heading !important;
        font-size: 18px;
    }

    .modal__close{
        right: 16px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        &:before{
            width: 16px;
            background-color: #b6b6b6;
        }
        &:after{
            width: 16px;
            background-color: #b6b6b6;
        }
    }

    button{
        font-family: $font-family-content !important;
        border-radius: 6px;
    }

    .require_input{
        color: #393939 !important;
    }
}

.wrap__select {
    margin-bottom: 18px;
}

.select-native {
    display: none;
}

.select-custom {
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    border: 1px solid green;
    border-radius: 6px;
    padding: 10px 16px;
    color: #767676;
    background-color: white;

    &__ul {
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
        // border: solid 1px black;
        border: solid 1px green;
        border-radius: 6px;
        cursor: pointer;
        position: absolute;
        z-index: 1002;
        width: 100%;
        background-color: white;
        // color: #1c1c1c;

        font-family: $font-family-content;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #393939;

        li {
            padding: 15px 16px;
            border: unset;

            &:hover{
                background-color: #007A33;
                color: white;
            }
        }

        display: none;

        &_active {
            display: block;
        }
    }

    &__ul2 {
        z-index: 1001;
    }

    &__arrow{
        width: 10px;
    }

    &__active {
        color: #1c1c1c;
    }

    &__error {
        margin-top: 7px !important;
    }

    &__require {
        position absolute;
        top: 7px;
        right: 6px;
        color: #393939;
    }

    @media(max-width: 425px) {
        font-size: 14px !important;
    }
}

