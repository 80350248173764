.tariffs
  display flex
  color #1c1c1c

  +r(1024)
    flex-wrap wrap
    margin 0 -10px

  &__item
    flex 1 1 0

    +r(1024)
      flex-basis 50%
      padding 10px

    +r(600)
      flex-basis 100%

    &--prop-names
      flex-grow 1.15

      +r(1024)
        display none

.tariff-props
  border 1px solid #ebebeb

  &__header
    font-size 16px
    display flex
    justify-content center
    align-items center
    text-align center
    line-height 1.4
    background-color #f5f5f5

    +r(1024)
      height auto !important

  &__item
    font-size 14px
    background-color #fff
    border-top 1px solid #ebebeb
    padding 5px 20px
    line-height 1.3
    display flex
    align-items center

    +r(1024)
      height auto !important

.tariff
  border 1px solid var(--theme-color)
  margin-left -1px
  text-align center

  +r(1024)
    margin-left 0

  &__header
    +r(1024)
      height auto !important

  &__name
    font-size 24px
    padding 14px 2px
    background-color #f5f5f5
    color var(--theme-color)

  &__price
    font-size 14px
    padding 17px 2px
    border-top 1px solid #ebebeb
    background-color #fff
    color #646464

  &__price-val
    display block
    font-size 24px
    margin-bottom 5px
    font-weight 700
    color var(--theme-color)

  &__prop
    display flex
    font-size 16px
    border-top 1px solid #ebebeb

    +r(1024)
      height auto !important
      position relative
      display none

      &:nth-child(2)
      &:nth-child(3)
      &:nth-child(4)
      ~/--open &
        display flex

      &::before
        content attr(data-prop-label)
        font-size 14px
        color #646464
        position absolute
        z-index 1
        top 10px
        right 2px
        left 2px
        text-align center

    +r(768)
      font-size 14px

  &__col-prop
    flex 0 0 50%
    display flex
    justify-content center
    align-items center
    padding 16px 2px
    background-color #fff

    +r(1024)
      padding 38px 2px 16px

    &:first-child
      border-right 1px solid #ebebeb

    &--head
      background-color #f5f5f5
      font-size 14px
      color #c8c8c8
      padding 11px 2px

    &--emphasis
      color var(--theme-color)

  &__col-prop-head-emphasis
    color #1c1c1c

  &__button
    padding 20px
    border-top 1px solid #ebebeb
    background-color #f5f5f5

  &__toggle
    display none
    margin-bottom 20px

    +r(1024)
      display block

  &__toggle-link
    font-size 14px
    color var(--theme-color)
    text-decoration none

  &__toggle-icon
    display inline-block
    color var(--theme-color)

    svg
      display block

    &--active
      transform rotate(180deg)
