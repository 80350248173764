for val, color in mapColors {
  .theme-color-{val} {
    .packets{

      .sticky-price{
        &__price{
          border-color: color !important;
        }
      }
    }
  }
}

.packets{
  &_block{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;
    }

  }

  &_europa--main, &_physical{
    .packet_main{
      flex: 49% 0 0;

      @supports (display: grid) {
        flex: 1;
      }
    }

    @media (max-width: 670px){
      .packet_main{
        flex: 100% 0 0;

        @supports (display: grid) {
          flex: 1;
        }
      }
    }
  }

  &_physical--main {
    @supports (display: grid) {
      grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));

      @media (max-width: 425px){
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
  }

  .sticky-price{
    background-color: rgba(255,255,255,.9);
    height: 100px;
    position: sticky;
    bottom: 0;
    z-index: 99;
    display: none;
    text-align: center;
    padding: 25px 0;
    margin-top: -20px;

    &__container{
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    &__price{
      border: 1px solid black;
      padding: 15px 40px;
      width: 223px;
      margin-right: 40px;
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      height: 46px;

      span{
        font-weight: 700;
        font-size: 20px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .packet{
    border-radius: 6px;
    
    border: border;
    height: 260px;
    width: 32%;
    margin-bottom: 30px;

    @supports (display: grid) {
      width: auto;
      margin-bottom: 0;
    }


    &--active{
      border-color: red;

      .a-button-add{
        background-color: #e6e6e6 !important;
        border: 1px solid #e6e6e6 !important;

        &:hover{
          background-color: #e6e6e6 - 30 !important;
        }

        &:before{
          top: calc(50% - 2px);
          left: calc(80% - 33px);
          width: 22px;
          height: 4px;
          background-image: url(/src/img/svg/minus.svg);
        }
      }
    }

    &_second{
      .packet__head{
        .svg{
          svg{
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__head{
      color: red;
      font-size: 24px;
      height: 65px;
      padding: 20px 0;
      z-index: 2;

      span{
        display: block;
        text-align: center;
      }
      .svg{
        position: absolute;
        top: calc(50% - 14px);
        right: 20px;

        &:hover{
          .explain-tip{
            display: block;
          }
        }

        svg{
          width: 20px;
          height: 16px;
        }

        .explain-tip{
          display: none;
          position: absolute;
          padding: 7px;
          background-color: #fff;
          opacity: .9;
          font-size: 14px;
          border: border;
          top: 100%;
          left: 100%;
          color: black;
          width: 188px;
        }
      }
    }

    .img-back{
      img{
        width: 100%;
        object-fit: cover;
        height: 105px;

        @media (min-width: 426px){
          &.img-desc{ display: block;}
          &.img-mob{ display: none}
        }
        @media (max-width: 425px){
          &.img-desc{ display: none}
          &.img-mob{ display: block}
        }

      }
    }

    .info{
      padding: 22px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .count-channels{
        .quantity{
          font-size: 18px;
          margin-bottom: 10px;
          display: block;
          margin-right: 10px;
          text-align: center;

          svg{
            width: 20px;
            height: 20px;
          }
        }
        .link__arrows{
          a{
            span{
              text-decoration: none;
            }
          }
        }
      }
      .cost{
        font-size: 14px;

        span{
          display: block;
          margin-bottom: 10px;
          color: red;
          font-size: 24px;
          font-weight: 700;
          text-align: center;

          svg{
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .fake-packet{
    &--active{
      border-color: #ebebeb;

      .a-button-add{
        background-color: #e6e6e6 !important;
        border: none !important;

        &:hover{
          background-color: #e6e6e6 !important;
          border: none !important;
        }

        &:before{
          top: calc(50% - 10px);
          left: calc(80% - 33px);
          width: 20px;
          height: 20px;
          background-image: url(/src/img/svg/plus.svg);
          border: none !important;
        }
      }
    }
  }

  @media (max-width: 900px){
    &_block{
      .packet{
        width: 49%;
        margin-bottom: 30px;

        @supports (display: grid) {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 670px){
    &_block{
      .packet{
        width: 100%;
        margin-bottom: 30px;

        @supports (display: grid) {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 370px){
    &_block{
      .packet{
        &__head{
          font-size: 20px;
        }
        .info{
          padding: 15px;
        }
      }
    }
  }
}

.packets-form{
  border: 1px solid red;
  padding: 0 22px 22px;
  display: none;

  &__head{
    padding: 22px;

    span{
      display: block;
      text-align: center;
      color: red;
      font-size: 30px;
    }

    .packets-form__close{
      width: 18px;
      height: 18px;
      position: absolute;
      cursor: pointer;
      top: 31px;
      right: 29px;

      &:before, &:after{
        content: '';
        display: block;
        width: 18px;
        height: 2px;
        background-color: #e7e4e7;
        position: absolute;
        top: 50%;
      }

      &:before{
        transform-origin: center;
        transform: rotate(45deg);
      }
      &:after{
        transform-origin: center;
        transform: rotate(-45deg);
      }
    }
  }

  .packets-form__content{
    .packets-form__list{
      display: none;

      &_public{
        border-top: 1px solid red;
        padding: 15px 0;
      }
      &_closure{
        margin-bottom: 0;
        padding-bottom: 10px;

        .list__packet-secondary{
          .block-packets{
            &:last-child{
              border-bottom: none;
            }
          }
        }
      }
      &_physical{
        padding: 15px 0;
      }

      .min-head{
        display: inline-block;
        line-height: 1.3;
        color: red;
      }

      .block-packets{
        padding: 16px 0;
        border-bottom: 1px solid #e7e4e7;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .name-packet{
          flex: 1;

          span{
            display: inline-block;
            margin-left: 4px;
            &.empty-packet{
              color: red;
            }
          }
        }
        .cost-packet{
          flex: 22% 0 1;
          text-align: center;
          font-size: 14px;

          span{
            font-size: 24px;
          }
        }
        .option{
          flex: 77px 0 0;
          text-align: center;

          div{
            display: inline-block;
            
            &.opt_minus{
              width: 21px;
              height: 21px;
              background-color: #e7e4e7;
              border-radius: 50%;
              cursor: pointer;

              &:after{
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 6px);
                display: block;
                content: '';
                width: 12px;
                height: 2px;
                background: url(/src/img/svg/minus.svg) center no-repeat;
              }
            }

            &.opt_warning{
              width: 21px;
              height: 19px; 
              background: url(/src/img/svg/warning.svg) center no-repeat;
              animation: pulse 1s alternate infinite;
            }
          }
        }
      }
    }

    .packets-form__result{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .res{
        flex: 1;
        font-size: 24px;
        color: red;
      }
      .res-cost{
        flex: 22% 0 1;
        text-align: center;
        font-size: 14px;

        span{
          font-size: 24px;
          font-weight: 700;
          color: red;
        }
      }
      .option{
        flex: 77px 0 0;
        text-align: center;
      }
    }
  }
    
  @media (max-width: 1000px){
    &__head {
      .packets-form__close {
        top: 20px;
        right: 0;
      }
    }
  }
  @media (max-width: 600px){
    padding-left: 0;
    padding-right: 0;

    &__head{
      span{
        font-size: 24px;
      }

      .packets-form__close{
        right: 10px;
        top: 10px;
      }
    }

    &__content{
      padding-right: 10px;
      padding-left: 10px;

      .packets-form__list{
        .block-packets{
          font-size: 14px;

          .cost-packet{
            span{
              display: block;
              font-size: 20px !important;
            }
          }
          .option{
            flex: 30px 0 0 !important;
          }
        }
      }

      .packets-form__result{
        .res-cost{
          flex: 46% 0 1 !important;

          span{
            display: block;
          }
        }
      }
    }
  }
}







