.physical-order-sydney {
  --white: #FFF;
  --off-white: #F8F8F8;
  --green-pantone: #007A33;
  --green-body-on-dark: #E9FCEE;
  --green-secondary-body-on-dark: #89B293;
  --white-stroke: rgba(255, 255, 255, 0.35);
  --green-bright-stroke: rgba(18, 184, 69, 0.60);
  --green-bright: #12B845;
  --green-darkest: #00551D;
  --button-bg-hover: var(--green-pantone);
  --button-bg-active: var(--green-bright);
  --button-bg-gradient: linear-gradient(180deg, #3EAB60 0%, #007A33 100%);
  --green-button-hover-on-dark: #0C341C;
  --green-new-dark-glass-cards: linear-gradient(8deg, rgba(9, 58, 29, 0.00) 11.73%, rgba(9, 58, 29, 0.50) 40.74%, rgba(12, 39, 23, 0.79) 65.60%, rgba(13, 34, 22, 0.87) 79.34%, #0E1A13 100%), linear-gradient(332deg, #12B845 0%, #007A33 57.44%, #00551D 100%);
  --green-dark-green-cards: #0D2217;
  --border-radius: 6px;

  background: #0E1A13;
  color: var(--off-white);
  overflow-x: hidden;

  .h1, .h2 {
    color: var(--off-white);
    font-family: Rubik, Fira Sans, sans-serif;
    font-style: normal;
    font-weight: 500;

    h1,h2,h3,h4,h5,h6 {
      color: inherit !important;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-align: inherit;
      text-transform: inherit;
      padding: 0;
      margin: 0;
    }
  }

  .h1 {
    font-size: 52px;
    line-height: 1.2;
    margin-bottom: 80px;

    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }

  .h2 {
    font-size: 36px;
    line-height: 1.6;
    margin-bottom: 25px;

    @media (max-width: 1024px) {
      font-size: 26px;
    }
  }

  .color-green-bright {
    color: var(--green-bright);
  }

  p {
    color: var(--green-body-on-dark);
    letter-spacing: .51px;
    font-weight: 300;
  }

  .font-500 {
    font-weight: 500;
  }

  .mt-60 {
    margin-top: 60px !important;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .mb-60 {
    margin-bottom: 60px !important;
  }

  @media (min-width: 1025px) {
    .lg{'\:'}mt-100 {
      margin-top: 100px !important;
    }

    .lg{'\:'}mb-80 {
      margin-bottom: 80px !important;
    }

    .lg{'\:'}mb-100 {
      margin-bottom: 100px !important;
    }

    .lg{'\:'}mb-110 {
      margin-bottom: 110px !important;
    }
  }

  .section {
    padding-top: 110px;
    padding-bottom: 110px;

    @media (max-width: 1024px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    @media (max-width: 640px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .container {
    max-width: 1490px;
    padding-left: 80px;
    padding-right: 80px;

    @media (max-width: 1024px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media (max-width: 640px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .btn {
    display: inline-flex;
    vertical-align: middle;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    font-family: 'Fira Sans', sans-serif;
    color: var(--white);
    background: var(--button-bg-hover);
    cursor: pointer;
    border: none;
    outline: none;
    transition: all .2s;
    padding: 20px 40px;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .25px;

    &--padding-2 {
      padding: 15px 40px;
    }

    &--padding-3 {
      padding: 10px 35px;
    }

    &--outline-white {
      background: transparent;
      box-shadow: inset 0 0 0 1px var(--white-stroke);

      &::before {
        display: none;
      }

      &:hover {
        background: var(--button-bg-hover);
        box-shadow: none;
      }
    }

    &--outline-green {
      background: transparent;
      box-shadow: inset 0 0 0 1px var(--green-bright-stroke);
      color: var(--green-bright);

      &::before {
        display: none;
      }

      &_hover,
      &:hover {
        background: var(--button-bg-hover);
        color: var(--white);
        box-shadow: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--button-bg-gradient);
      transition: opacity .2s;
    }

    &:hover::before {
      opacity: 0;
    }

    &:active {
      background: var(--button-bg-active);
    }

    > span {
      position: relative;
      padding-top: 0.1em;
      margin: -0.05em 0;
    }

    &--small-caps {
      font-size: 16px;
      font-style: normal;
      line-height: 1.5625;
      font-variant: all-small-caps;
      letter-spacing: .8px;

      > span {
        padding-top: 0;
        margin: 0;
      }
    }

    svg {
      position: relative;
    }
  }

  .button-shadow-1 {
    box-shadow: 0px 40px 160px 0px rgba(1, 84, 29, 0.90), 0px 20px 48px 0px rgba(1, 84, 29, 0.40), 0px 2px 6px 0px rgba(1, 84, 29, 0.80);
  }

  .hero {
    display: flex;
    padding-top: 394px;

    @media (max-width: 1279px) {
      padding-top: 500px;
    }

    @media (max-width: 480px) {
      padding-top: 450px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 1245px;
      height: 1200px;
      top: -103px;
      left: 50%;
      transform: translateX(-9.7%);
      background: url(/src/img/pages/physical-order-sydney/hero-image.png) 50% 50%/contain no-repeat;

      @media (resolution: 1dppx) {
        image-rendering: pixelated;
      }

      @media (max-width: 1400px) {
        width: 1000px;
        height: 1000px;
        top: -25px;
        image-rendering: auto;
      }

      @media (max-width: 1279px) {
        transform: translateX(-43%);
        width: 900px;
        height: 900px;
        top: -190px;
      }

      @media (max-width: 900px) {
        width: 700px;
        height: 700px;
        top: -110px;
      }

      @media (max-width: 480px) {
        width: 600px;
        height: 600px;
        top: -70px;
      }
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__inner {
      position: relative;
      max-width: 658px;

      &::before {
        content: '';
        position: absolute;
        width: 1246px;
        height: 1121px;
        top: 0;
        left: 30%;
        transform: translate(-50%, -50%);
        background: url(/src/img/pages/physical-order-sydney/hero-text-shadow.png) 0 0 no-repeat;

        @media (resolution: 1dppx) {
          image-rendering: pixelated;
        }
      }
    }

    &__heading {
      font-size: 52px;
      color: inherit !important;
      margin: 0 0 25px;
      letter-spacing: 0;

      @media (max-width: 1024px) {
        font-size: 30px;
      }
    }

    &__text {
      margin-bottom: 65px;
    }
  }

  .plate {
    padding: 20px;
    border-radius: var(--border-radius);
    border: 1px solid var(--green-button-hover-on-dark);
    background: var(--green-new-dark-glass-cards);
    box-shadow: -1px 4px 8px 0px rgba(35, 58, 41, 0.20) inset, -1px 8px 70px 0px rgba(137, 255, 173, 0.10) inset;

    &--dark {
      background: var(--green-dark-green-cards);
      box-shadow: none;
    }
  }

  .sticking-out-block {
    padding: 65px 80px;
    margin-left: -80px;
    margin-right: -80px;

    @media (max-width: 1024px) {
      padding: 40px;
      margin-left: -40px;
      margin-right: -40px;
    }

    @media (max-width: 640px) {
      padding: 20px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .cable-tv-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px 65px;

    &__text {
      color: var(--white, #FFF);
      font-family: Rubik, Fira Sans, sans-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35;
      letter-spacing: 0.25px;
    }
  }

  .internet-tariff-plates {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    &__item {
      flex: 1 1 0;
      min-width: 260px;
      display: flex;
      padding: 85px 0 75px;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
    }

    &__speed-img {
      position: relative;
      margin: -85px 0 0;
      align-self: stretch;
      height: 261px;

      @media (max-width: 1279px) {
        height: 180px;
      }

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: initial;

        @media (resolution: 1dppx) {
          image-rendering: pixelated;
        }

        @media (max-width: 1279px) {
          width: 300px;
          image-rendering: auto;
        }
      }
    }

    &__desc {
      padding: 0 65px;
      font-family: Rubik, Fira Sans, sans-serif;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.28px;
      white-space: nowrap;
      margin-bottom: 64px;

      @media (max-width: 1279px) {
        margin-bottom: 30px;
      }
    }
  }

  .internet-tv {
    color: var(--green-body-on-dark);

    .accordion-rates {
      max-width: 100%;
      flex-grow: 1;
    }

    .accordion-rates__heading {
      border-bottom-color: rgba(137, 178, 147, 0.30);

      span {
        color: var(--green-secondary-body-on-dark);
      }
    }

    .accordion-rates__item, .accordion-rates__item-cabel {
      border-color: #1A4029;
      box-shadow: none;
    }

    .accordion-rates__showcase1 span {
      color: var(--green-bright);
    }

    .accordion-rates__button_passive button {
      background-color: transparent;
      border-color: var(--green-bright-stroke);
      color: var(--green-bright);

      &:hover {
        background: var(--button-bg-hover);
        color: var(--white);
        border-color: transparent;
      }

      &:active {
        background: var(--button-bg-active);
      }
    }

    .accordion-rates__button-close button {
      color: var(--green-secondary-body-on-dark);
      border-bottom-color: rgba(137, 178, 147, 0.50);

      path {
        stroke: currentColor;
      }
    }

    .accordion-rates__content {
      background-color: transparent;

      .spanheadingmini {
        color: var(--green-secondary-body-on-dark);
        border-bottom-color: rgba(137, 178, 147, 0.30);
      }

      button .spanheadingmini {
        color: var(--green-bright);

        &:hover {
          border-bottom-color: rgba(137, 178, 147, 0.50);
        }
      }

      .spanheadingminimarkcolor {
        color: inherit;
      }

      .accordion-rates__content2-subscriptions,
      .spanbottom {
        color: var(--green-secondary-body-on-dark);
      }

      .span-arrow-right path {
        fill: var(--green-secondary-body-on-dark);
      }

      span {
        color: var(--green-secondary-body-on-dark);
      }

      .spanbold {
        color: var(--green-body-on-dark);
      }
    }

    .accordion-rates__showcase2-0 {
      border-bottom-color: rgba(137, 178, 147, 0.30);

      span {
        color: var(--green-secondary-body-on-dark);
      }
    }

    .accordion-rates__additional-content {
      .spanheadingmini {
        color: var(--green-secondary-body-on-dark);
        border-bottom-color: rgba(137, 178, 147, 0.30);
      }

      button .spanheadingmini {
        color: var(--green-bright);

        &:hover {
          border-bottom-color: rgba(137, 178, 147, 0.50);
        }
      }

      .spanheadingminimarkcolor {
        color: inherit;
      }

      .span-arrow-right path {
        fill: var(--green-secondary-body-on-dark);
      }

      span {
        color: var(--green-secondary-body-on-dark);
      }

      .spanbold {
        color: var(--green-body-on-dark);
      }
    }
  }

  .internet-tv-video__content {
    border-color: var(--green-button-hover-on-dark);
    background: var(--green-dark-green-cards);
    box-shadow: none;

    .internet-tv-video__imgcontent {
      margin: -1px 0 -1px -1px;
    }

    .internet-tv-video__textcontent {
      span {
        color: var(--green-secondary-body-on-dark);
      }

      .span-arrow-right path {
        fill: var(--green-secondary-body-on-dark);
      }

      .spanheading {
        color: var(--green-bright);
      }

      .spanheadingmini {
        color: var(--green-secondary-body-on-dark);
        border-color: rgba(137, 178, 147, 0.30);
      }

      .spanbold {
        color: var(--green-body-on-dark);
      }

      .internet-tv-video__textcontent1 button {
        background: none;

        .spanheadingmini {
          color: var(--green-bright);

          &:hover {
            border-bottom-color: rgba(137, 178, 147, 0.50);
          }
        }
      }
    }
  }

  .telephony-physical-rates__header {
    border-bottom-color: rgba(137, 178, 147, 0.30);

    span {
      color: var(--green-secondary-body-on-dark);
    }

    .telephony-physical-rates__header_3 {
      display: flex;
    }
  }

  .telephony-physical-rates__block {
    border-color: #1A4029;
    box-shadow: none;
  }

  .telephony-physical-rates__block_1 span {
    color: var(--green-bright);
  }

  .telephony-physical-rates__block_2 span,
  .telephony-physical-rates__block_3-price span {
    color: var(--green-body-on-dark);
    font-weight: 300;
  }

  .telephony-physical-rates__button button {
    background-color: transparent;
    border-color: var(--green-bright-stroke);
    color: var(--green-bright);

    &:hover {
      background: var(--button-bg-hover);
      color: var(--white);
      border-color: transparent;
    }

    &:active {
      background: var(--button-bg-active);
    }
  }

  .telephony-physical-rates__block_2-1-header {
    color: var(--green-secondary-body-on-dark);
    border-bottom-color: rgba(137, 178, 147, 0.30);
  }

  .telephony-physical-rates__block_2-1-content span {
    color: var(--green-body-on-dark);
  }

  @media (max-width: 1279px) {
    .surveillance-rates__cards-wrap {
      height: 490px;
    }
  }

  .surveillance-rates__card {
    @extends .physical-order-sydney .plate;

    overflow: hidden;
    box-shadow: none;
    min-width: 424px;
    width: 424px;

    @media (max-width: 1279px) {
      min-width: 300px;
      width: 300px;
    }
  }

  .surveillance-rates__card-img {
    position: relative;
    margin: -10px -10px 0;
    align-self: stretch;
    height: 325px;

    @media (max-width: 1279px) {
      height: 200px;
    }

    img {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: initial;

      @media (resolution: 1dppx) {
        image-rendering: pixelated;
      }

      @media (max-width: 1279px) {
        width: 300px;
        image-rendering: auto;
      }
    }
  }

  .surveillance-rates__card-heading {
    margin-bottom: 11px;

    h3 {
      color: var(--off-white) !important;
    }
  }

  .surveillance-rates__card-content {
    margin-bottom: 53px;
    text-align: center;
    color: var(--green-body-on-dark);
    font-weight: 300;

    @media (max-width: 1279px) {
      margin-bottom: 32px;
    }
  }

  .surveillance-rates__card-button {
    margin-bottom: 55px;

    @media (max-width: 1279px) {
      margin-bottom: 0;
    }
  }

  .package-offers-equipment__card,
  .package-offers-equipment__card-heading,
  .package-offers-equipment__card-price_green {
    border-color: var(--green-bright-stroke);
  }

  .package-offers-equipment__card-heading h3 {
    color: inherit !important;
  }

  .package-offers-equipment__card-price_green span,
  .package-offers-equipment__card-price_green-small {
    color: var(--green-bright);
  }

  .package-offers-equipment__card-text.active p {
    color: var(--green-body-on-dark);
  }

  .package-offers-equipment__card-heading svg path,
  .package-offers-equipment__card-heading svg.active path {
    stroke: var(--green-bright-stroke);
  }

  .package-offers-equipment__card {
    gap: 60px;
    align-items: flex-start;
    padding: 100px 0;

    @media (max-width: 1279px) {
      padding: 60px 0;
    }

    &:first-child {
      padding-top: 0;
      border-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .package-offers-equipment__card-price {
    margin-bottom: 0;
  }

  .package-offers-equipment__card-img {
    flex: 0 0 262px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: initial;
    max-width: initial;
    overflow: hidden;
    border-radius: var(--border-radius);
    background: var(--white);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .package-offers-equipment__card-price_green:last-child {
    border: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .glare-1 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -144px;
      left: -68px;
      width: 417px;
      height: 240.127px;
      transform: rotate(16.098deg);
      border-radius: 417px;
      opacity: 0.45;
      background: var(--green-darkest);
      filter: blur(125px);
    }
  }

  .glare-2 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -70px;
      right: -200px;
      width: 764.163px;
      height: 376.176px;
      transform: rotate(-17.644deg);
      border-radius: 764.163px;
      opacity: 0.4;
      background: #065236;
      filter: blur(125px);
    }
  }

  .glare-3 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -93px;
      right: -236px;
      width: 692.171px;
      height: 340.736px;
      transform: rotate(-17.644deg);
      border-radius: 692.171px;
      opacity: 0.4;
      background: #065236;
      filter: blur(125px);
    }
  }

  .glare-4 {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -235px;
      left: -60px;
      width: 417px;
      height: 286px;
      border-radius: 417px;
      opacity: 0.45;
      background: var(--green-darkest);
      filter: blur(125px);
    }
  }
}

.accordion-rates__cable {
  justify-content: end!important;
}
