$grid-columns = 12
$grid-column-width = 100 / $grid-columns

.row
  display flex
  flex-flow row wrap
  justify-content flex-start
  align-items stretch
  margin-left -($gutter)
  margin-right -($gutter)

  /.ie-9 &
    clearfix()

column()
  flex 1 1 0
  max-width 100%
  padding-left $gutter
  padding-right $gutter

  /.ie-9 &
    float left

.col
  column()

  &--auto
    flex 1 1 auto

  &--none
    flex none
    width auto

for i in (1..$grid-columns)
  .col-{i}
    column()
    flex 0 0 ($grid-column-width * i)%
    max-width ($grid-column-width * i)%
    /.ie-9 &
      width ($grid-column-width * i)%

breakpointCols(bpName, cols, colWidth)
  for i in (1..cols)
    +rmin(lookup('$bp-' + bpName) + 1)
      .col-{bpName}-{i}
        column()
        flex 0 0 (colWidth * i)%
        max-width (colWidth * i)%
        /.ie-9 &
          width (colWidth * i)%

for i in 'xs' 'sm' 'md' 'lg' 'xl'
  breakpointCols(i, $grid-columns, $grid-column-width)