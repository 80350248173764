.check-connection
  display flex
  margin 0 -15px
  justify-content center
  align-items flex-end
  flex-wrap wrap

  &__item
    flex none
    padding 0 15px 15px
    max-width 100%

    &--street
      width 53%

      @media (max-width 1024px)
        width 70%

      @media (max-width 768px)
        width 550px

    &--house
      width 22%

      @media (max-width 1024px)
        width 30%

      @media (max-width 768px)
        width 550px

    &--button
      width 25%

      @media (max-width 1024px)
        width 250px
        order 1

    &--response
      width 100%
      padding 0

  &__label
    display block
    padding 0 0 10px 0

  &__input
    display block
    width 100%
    height 53px

    @media (max-width 768px)
      height 46px

  &__response
    padding 0 15px 15px
    text-align center

    &:empty
      padding 0

    &--error
      color #ed1f57
      font-weight 700

    a
      color #007a33
      font-weight 700

  &__icon
    margin-right 7px