.attachment-btn {
  font-size: 12px;
  line-height: 1.25;
  margin-bottom: 20px;
  color: #999;
  text-align: left;
  position: relative;
  display: flex;

  &__label {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    max-width: 100%;
  }

  &__icon {
    flex: none;
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 38px;
    cursor: pointer;
    margin-right: 12px;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100%;

    /.ie & {
      flex-basis: 200px;

      &--has-files {
        flex-basis: auto;
      }
    }
  }

  &__remove,
  /.theme-color-red .modal button.attachment-btn__remove {
    display: none;
    vertical-align: middle;
    background: transparent !important;
    font-size: 16px;
    color: #d83258;
    position: relative;
    -webkit-appearance: none;
    border: 0;
    margin: 0 0 0 .4em !important;
    padding: .7em;
    outline: none;
    cursor: pointer;
    width: initial !important;
    height: initial !important;

    &--active {
      display: inline-block;
    }

    &:hover {
      opacity: .8;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 80%;
      top: 50%;
      left: 10%;
      height: 2px;
      background-color: currentColor;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.attachment-btn-message {
  padding: 10px 0 20px;
  color: #c20a45;

  &:empty {
    display: none;
  }
}
