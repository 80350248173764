.infoblock{
  border-radius: 6px;

  display: flex;
  flex-flow: row nowrap;
  border: border;
  margin-bottom: 30px;

  &__img{
    flex: 0 0 31.6%;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    img{
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .info{
    flex-basis: auto;
    padding: 24px 23px 15px 30px;

    &__header{
      margin-bottom: 15px;
    }

    time{
      color: #d5d5d3;
      font-size: 16px;
    }

    &__description{
      margin-top: 22px;
      margin-bottom: 15px;
      line-height: 1.4;
    }
  }

  @media (max-width: 768px){
    flex-flow: column nowrap;

    &__img{
      flex-basis: 170px;
    }
  }

  &_end{
    filter: grayscale(100%);
  }

//stock
  &_stock{
    .infoblock__info{
      flex-basis: auto;
      padding: 24px 23px 15px 30px;

      .info__header{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 0;

        .a-button{
          padding: 0;
          width: 223px;
          height: 46px;
        }

        .item-status{
          margin-top: 12px;
          margin-bottom: 20px;
          flex-basis: 100%;
          color: red;
        }
      }

      .info__description{
        margin-bottom: 15px;
        margin-top: 0;
        line-height: 1.4;
      }
    }

    @media (min-width: 1050px){
      .infoblock__small-info{
        display: none;
      }
    }

    @media (max-width: 1049px){
      flex-flow: row wrap;

      img{
        width: 300px;
        height: 200px;
        flex: 0 0 auto;
      }

      .infoblock__info{
        flex-basis: calc(100% - 300px);
        padding: 25px;

        .info__header{
          .a-button{
            order: 2;
          }
        }

        & > .info__description, a{
          display: none;
        }
      }

      .infoblock__small-info{
        display: block;
        flex-basis: 100%;
        padding: 30px 18px;
        line-height: 1.4;

        .info__description{
          margin-bottom: 8px;
          margin-top: 0;
        }
      }
    }

    @media (max-width: 650px){
      img{
        width: 200px;
        height: 175px;
        align-items: flex-start;
      }

      .infoblock__info{
        padding: 15px;
        flex-basis: calc(100% - 200px);
      }
    }

    @media (max-width: 500px){
      img{
        width: 100%;
        height: auto;
      }
      .infoblock__info{
        flex-basis: 100%;
        padding: 19px;

        .info__header{
          flex-direction: column;

          h2, p{
            text-align: center;
          }

          .a-button{
            display: block;
            margin: 0 auto;
          }
        }
      }

      .infoblock__small-info{
        flex-basis: auto;
        width: 95%;
        margin: 0 auto;
        padding: 22px 6px 27px;
        border-top: border;

        .info__description{
          font-size: 14px;
        }
      }
    }
  }
}

.deployblock{
  border: border;
  padding: 30px;

  &__header{
    margin-bottom: 25px;

    .deployblock__status{
      margin-top: 33px;
    }
  }

  time{
    color: #d5d5d3;
  }

  &__info{
    margin-top: 30px;

    img{
      width: 358px;
      height: auto;
      float: right;
      margin-left: 12px;
      margin-bottom: 12px;
    }

    p, ul {
      line-height: 1.4;
    }

    p:not(:last-child), ul:not(:last-child) {
      margin-bottom: 1em;
    }

    ul {
      margin-left: 1em;
    }

    li {
      list-style: disc outside;
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  @media (max-width: 700px){
    &__header{
      h2,h4{
        text-align: center;
      }
    }

    time{
      display: block;
      text-align: center;
    }

    &__info{
      img{
        display: block;
        margin: 0 auto;
        float: none;
        margin-bottom: 30px;
      }
    }
  }
  @media (max-width: 550px){
    padding: 15px;
  }

  @media (max-width: 425px){
    padding: 28px 10px;

    &__header{
      h2{
        font-size: 26px !important;
      }
    }

    &__info{
      img{
        width: calc(100% + 20px);
        margin-left: -10px;
      }
    }
  }

  &_new{
    .deployblock__header{
      h2{
        font-size: 30px;
      }
    }
  }
}
