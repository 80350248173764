.card-plate {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 24px 90px;
  border-radius: 4px;
  border: 1px solid var(--monochrome-off-white, #F8F8F8);
  background: var(--monochrome-white, #FFF);
  box-shadow: 0 8px 20px 0 rgba(210, 210, 210, 0.30);
  transition: box-shadow .25s;
  text-align: center;

  &:hover {
    box-shadow: 0 8px 20px 0 rgba(210, 210, 210, 0.60);
  }

  @media (max-width: 1024px) {
    padding: 50px 24px 60px;
  }

  &__heading {
    color: var(--black, #202020);
    text-align: center;
    font-family: $font-family-heading;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .28px;
  }

  &__description {
    margin-top: 15px;
    font-size: 17px;
    line-height: 1.7;
  }

  &__footer {
    margin-top: auto;
    padding-top: 50px;
  }

  &__old-price {
    margin-bottom: 25px;
    color: var(--gray-body, #535353);
    font-size: 20px;
    font-weight: 600;
    line-height: 1.45;
    letter-spacing: .4px;
    text-decoration: line-through;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
