.corporate-television{
    p{
        margin-bottom: 65px;
    }

    h4{
        margin-bottom: 35px;
        text-align: center;

        font-family: $font-family-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #000000 !important;
    }

    h5{
        margin-bottom: 20px;
        text-align: center;

        font-family: $font-family-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #1C1C1C !important;
    }

    .packets_block{
        display: flex;
        gap: 22px;
        justify-content: center;

        margin-bottom: 50px;

        .packet{
            max-width: 375px;
            min-width: 375px;

            height: auto;
        }
    }

    .packet__head{
        height: auto;

        text-align: center;
        display: flex;
        justify-content: center;

        span{
            width: 259px;
        }
    }
}
