.btn-2 {
  display: inline-flex;
  vertical-align: middle;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  font-family: 'Fira Sans', sans-serif;
  color: #F5F1F8;
  background: #5B40A7;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .2s;
  padding: 15px 30px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .4px;
  text-decoration: none;
  text-align center;
  backface-visibility: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(146deg, #613BB2 14.67%, #331960 62.87%, #1D0E2F 121.86%);
    transition: opacity .2s;
    border-radius: 6px;
  }

  &--blue {
    background: #4750A7;

    &::before {
      background: linear-gradient(144deg, #535DBC 14.47%, #353C7F 71.69%, #0A104F 127.35%);
    }
  }

  span {
    position: relative;
  }

  &:hover::before {
    opacity: 0;
  }

  &:active {
    opacity: .9;
  }
}
