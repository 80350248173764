.form {
  margin: 0 auto;
  max-width: 400px;

  +r(425){
    padding: 0 10px;
  }

  input, button, textarea {
    width 100%;
    margin-bottom: 20px;
  }

  button {
    background-color: purple;
    height: 53px;

    margin-left: auto;
    margin-right: auto;
  }

  &__response-msg {
    text-align center;
    background-color: var(--theme-color);
    color: #ffffff;
    border-radius: 6px;


    &:not(:empty) {
      padding: 10px;
      margin-bottom: 20px;
    }

    +rmin(801) {
      order: 1;
      flex: 0 0 100%;
      grid-column: span 3;

      &:not(:empty) {
        margin-top: 10px;
      }
    }
  }
}

.required-input {
  position: relative;

  &:before{
    content: '*';
    position: absolute;
    top: 5px;
    right: 7px;
    display: block;
    color: #c20a45;
    z-index: 2;
  }

  input {
    width: 100%;
  }
}
