.package-offers{
    background: url(/src/img/pages/headerPackageOffers.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading{
        //padding-top: 275px;
        padding-top:175px;
        margin-bottom: 20px;

        h1{
            margin-bottom: 0px;
            padding-top: 0px;

            font-h1(52px, font-color-light);
        }
        @media(max-width: 480px){
            padding-top:300px !important;
        }
    }

    &__content{
        max-width: 658px;

        padding-bottom: 45px;
        border-bottom: solid 1px rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        p{
            margin-bottom: 0;

            font-p-300(17px, font-color-light);
        }
    }

    li{
        position relative;

        display: flex;
        align-items: center;

        padding-left: 10px;
        margin-bottom: 5px;

        font-p-300(17px, font-color-light);

        &:before{
            content: '';
            min-height: 3px;
            min-width: 3px;
            background: rgba(245, 241, 248, 0.45);
            border-radius: 50%;

            top: 12px;
            left: 0;

            position: absolute;
        }
    }

    @media (max-width: 1679px){
        .heading{
            padding-top: 250px;
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/headerMobilePackageOffers.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 841px;

        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 0 30px;

            position relative;
        }

        .heading{
            padding-top: 357px;
            margin-bottom: 25px;

            h1{
                margin-bottom: 0!important;

                text-align: left;
                font-size: 30px;
            }
        }

        &__content{
            max-width: 100%;

            padding-bottom: 40px;
            border-bottom: solid 1px rgba(245, 241, 248, 0.45);
            margin-bottom: 25px;
        }

        ul{
            margin-bottom: 80px;
        }
    }
}

.internet-tv-video{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 157px 55px 0;

        font-p(17px, font-color-normal);
    }

    .heading{
        margin-bottom: 25px;
    }

    &__introduction{
        margin-bottom: 110px;
        max-width: 927px;

        p{
            margin-bottom: 0px;

            font-p(17px, font-color-normal);
        }
    }

    &__content{
        margin-bottom: 200px;

        border: 1px solid #F8F8F8;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 4px;

        display: flex;

        width: fit-content;
    }

    &__imgcontent{
        position: relative;
        max-width: 599px;

        &:after{
            content: '';
            position: absolute;
            right: -29px;
            top: 186px;
            border: 15px solid transparent;
            border-left: 15px solid #39b04b;
        }
    }

    &__img, &__img-mobile{
        border-radius: 4px;
        overflow: hidden;

        img{
            display: block;
            width: auto;
            height: 100%;
        }
    }

    &__img-mobile{
        display: none;
    }

    &__imgheader{
        position: absolute;
        bottom: 76px;
        left: 90px;

        width: 127px;

        span{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 135%;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }

    &__imgbutton{
        position: absolute;
        right: 89px;
        bottom: 71px;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 14px;

            padding: 20px 40px;
            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            box-shadow: 0px 2px 6px rgba(1, 84, 29, 0.8), 0px 20px 48px rgba(1, 84, 29, 0.4), 0px 40px 160px rgba(1, 84, 29, 0.9);

            border-radius: 6px;
            background-color: transparent;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 25px !important;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF;

            svg path{
                fill: #ffffff;
            }

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }
        }
    }

    &__textcontent{
        padding: 30px 75px 0px 91px;

        display: flex;
        gap: 65px;

        span{
            display: block;

            font-p(17px, #848484);
        }

        &1{
            width: 200px;

            button{
                background-color: white;
                width: 100%;

                display: flex;

                .spanheadingminicolor{
                    display: flex;
                    justify-content: start;
                    align-items: flex-start;
                    gap: 5px;

                    width: 100%;
                    &:hover{
                        border-bottom: 1px solid #6d6d6d;
                        transition: all 0.2s;
                    }
                }
            }

            span{
                svg{
                    vertical-align: middle;
                }
            }
        }

        &2{
            width: 340px;
        }

        &3{
            display: flex;

            span{
                margin-bottom: 10px;

                :last-of-type{
                    margin-bottom: 0px;
                }
            }

            &1{
                margin-right: 13px;
                min-width: 130px;
            }
            &2{
                margin-right: 8px;
                min-width: 110px;
            }
            &3{
                min-width: 70px;
            }
        }

        .spanheading{
            margin-bottom: 62px;

            font-family: 'Rubik', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px !important;
            line-height: 150%;
            color: #007A33;
        }

        .spanheadingmini{
            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 20px !important;

            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #848484;

            &color{
                color: #007A33;
            }
        }

        .spanheadingminimarkcolor{
            display: inline;
            position relative;
            top: 0px;

            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #007A33;
        }

        .spanbold{
            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }

        .spanbottom{
            margin-bottom: 30px;
        }

        .spaninline{
            display: inline;
        }
    }

    @media (max-width: 1679px){
        &__imgcontent{
            max-width: 559px;
        }

        &__img{
            display: flex;
            justify-content: center;
        }

        &__textcontent{
            padding: 30px 45px 0;

            gap: 25px;

            &1{
                width: 180px;
            }

            &2{
                width: 310px;
            }

            &3{
                &1{
                    margin-right: 5px;

                    span{
                        min-width: 130px;
                    }
                }
                &2{
                    margin-right: 0;
                    span{
                        min-width: 90px;
                    }
                }
                &3{
                    span{
                        min-width: 70px;
                    }
                }
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            padding: 80px 10px 0;

            max-width: 100%;
        }

        .heading.package{
            padding: 0 20px;
            margin-bottom: 20px;
        }

        &__content{
            margin-bottom: 80px;
            flex-direction: column;
            width: unset;
        }

        &__introduction{
            padding: 0 20px;
            margin-bottom: 55px;

            p{
                font-p(15px, font-color-normal);
            }
        }

        &__imgcontent{
            position: relative;
            max-width: 100%;

            &:after{
                right: unset;
                top: unset;
                bottom: -29px;
                left: 36px;
                border-left: 15px solid #1f7232;

                transform: rotate(90deg);
            }
        }

        &__img{
            display: none;
        }

        &__img-mobile{
            height: 395px;

            display: flex;
            justify-content: center;

            img{
                display: block;
                width: auto;
                height: 100%;
            }
        }

        &__imgbutton{
            position: absolute;
            right: unset;
            bottom: 50px;
            width: 100%;
            display: flex;
            justify-content: center;

            button{
                box-shadow: 0px 2px 6px rgba(1, 84, 29, 0.8), 0px 20px 48px rgba(1, 84, 29, 0.4), 0px 20px 20px rgba(1, 84, 29, 0.9);
            }
        }

        &__imgheader{
            position: absolute;
            bottom: 160px;
            left: unset;

            width: 100%;

            span{
                display: block;
                width: 100%;
                text-align: center;
            }
        }

        &__textcontent{
            flex-direction: column;
            gap: 30px;
            padding: 55px 20px 45px;

            &1{
                width: 100%;

                button{
                    padding: 0;
                }
            }

            &2{
                width: 100%;
            }
            &3{
                &1{
                    margin-right: 0;
                }
                &2{
                    margin-right: 0;

                    min-width: 85px;
                    span{
                        font-size: 15px !important;
                    }
                }

                &3{
                    span{
                        font-size: 15px !important;
                    }
                }
            }
        }

        .spanheading{
            margin-bottom: 30px;
        }

        .spanheadingmini{
            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 5px !important;
        }

        .spanbold{
            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px !important;
            line-height: 170%;
            color: #535353;
        }
    }
}

.span-arrow-right{
    display: inline-block;
    position relative;
    bottom: 2px;
}

.internet-tv{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;
        width: 100%

        padding: 0 55px;
        margin-bottom: 240px;

        font-p(17px, font-color-normal);
    }

    .heading{
        margin-bottom: 25px;
    }

    &__introduction{
        margin-bottom: 110px;
        max-width: 927px;

        p{
            margin-bottom: 0px;

            font-p(17px, font-color-normal);
        }
    }

    &__products, &__cabel{
        position: static;
    }

    &__item-cabel--border-b {
        border-bottom: solid 1px #e5e5e5;
    }

    .accordion-rates{
        a{
            color: #007a33;
            cursor: pointer;
        }

        &__heading{
            display: flex;
            padding-bottom: 10px;
            border-bottom: solid 1px #E5E5E5;

            span{
                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;
            }

            &1, &2, &3{
                display: flex;
                justify-content: space-between;
            }

            &1{
                width: 388px;
                margin-right: 150px;
            }

            &2{
                width: 254px;
                margin-right: 104px;
            }
        }

        &__item{
            border-bottom: solid 1px #E5E5E5;
        }

        &__showcase{
            height: 120px;
            display: flex;
            align-items: center;

            &1, &2, &3{
                display: flex;
                justify-content: space-between;
            }
            &4{
                display: flex;
                justify-content: end;
            }

            &1{
                width: 388px;
                margin-right: 150px;

                span{
                    font-family: 'Fira Sans', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 150%;
                    color: #007A33;
                }
            }

            &2-0{
                display: none;
            }

            &2{
                width: 254px;
                margin-right: 104px;
                position relative;
            }

            &3{
                width: 378px;
                margin-right: 56px;
            }
            &4{
                width: inherit;
                margin-right: 56px;
                flex-grow: 1;
            }
        }

        &__additional-content{
            display: none;
        }

        &__button{
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;

                width: 203px;
                height: 45px;4
                border-radius: 6px;

                transition: all 0.2s;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 0;
                letter-spacing: 0.05em;

                &:hover{
                    border: unset;
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: #FFFFFF;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }
                }
            }

            &_active{
                button{
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: white;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }

                    &:hover{
                        background: linear-gradient(180deg, #12B845 100%, #12B845 100%);
                    }
                }
            }

            &_passive{
                button{
                    background: white;
                    color: #007A33;
                    border: 1px solid #007A33;
                    border-radius: 6px;

                    svg path{
                        fill: #007A33;
                    }
                }
            }
        }

        &__button-close{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor pointer;

            button{
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                border-bottom: 1px dashed #C6C6C6;
                padding: 0px !important;

                background-color: transparent;
                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;
            }

            &_passive{
                svg{
                   transform: rotate(90deg);
                   transition: transform 0.2s;
                }

                &:hover{
                    button{
                        border-bottom: 1px dashed #E5E5E5;
                        color: #C6C6C6;
                    }

                    svg path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &_active{
                svg{
                   transform: rotate(-90deg);
                   transition: transform 0.3s;
                }

                button{
                    border-bottom: 1px dashed #E5E5E5;
                    color: #C6C6C6;
                }

                svg path{
                    stroke: #C6C6C6;
                }
            }
        }

        &__content{
            background: #FBFBFB;
            padding: 70px 134px 75px;
            transition: all 0.2s;

            display: flex;

            &_passive{
                height: 0px;
                padding: 0px 0px 0px 134px !important;
                span{
                    color: transparent !important;
                    border-bottom: 0px solid transparent !important;
                }

                .span-arrow-right{
                    display: none;
                }
            }

            &_active{
                height: auto;
                padding: 70px 134px 75px;
                color: #535353;
            }

            &1{
                flex: 1 0 100%;
                max-width: 254px;
                margin-right: 150px;

                span{
                    display: block;
                    margin-bottom: 8px;
                }

                button{
                    background-color: transparent;
                    width: 100%;
                    display: flex;

                    .spanheadingminicolor{
                        display: flex;
                        justify-content: start;
                        align-items: flex-start;
                        gap: 5px;

                        width: 100%;
                        &:hover{
                            border-bottom: 1px solid #6d6d6d;
                            transition: all 0.2s;
                        }
                    }

                    span{
                        display: block;
                        margin-bottom: 0px;
                    }
                }
            }

            &2{
                flex-basis: 100%;

                span{
                    display: block;
                }

                &-subscriptions{
                    display: flex;
                    flex-wrap: wrap;
                }

                &-subscription{
                    display: flex;
                    position relative;

                    span:nth-child(2){
                        position absolute;
                        left: 140px;
                        width: 200px;
                        color: #848484;
                    }

                    span:nth-child(3){
                        position absolute;
                        left: 234px;
                        width: 100px;
                        color: #848484;
                    }
                }

                &-subscription:nth-child(2n+1){
                    width: 350px;
                }
            }

            .spanheadingmini{
                display: block;
                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 20px;

                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #848484;

                &color{
                    color: #007A33;
                }
            }

            .spanheadingminimarkcolor{
                display: inline;
                position relative;
                top: 0px;

                font-size: 16px;
                color: #007A33;
            }

            .spanbold{
                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }

            .spanbottom{
                margin-bottom: 30px;
            }

            .spaninline{
                display: inline;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px 0px;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0px 30px;
            margin-bottom: 20px;
        }

        &__introduction{
            padding: 0px 30px;
            margin-bottom: 45px;

            p{
                font-p(15px, font-color-normal);
            }
        }

        &__products-wrap{
            overflow: hidden;

            padding: 10px 0px 20px;
            margin-bottom: 25px;
        }

        &__cabel-wrap{
            overflow: hidden;

            padding: 10px 0px 20px;
            margin-bottom: 25px;
        }

        &__products {
            display: flex;
            gap: 10px;

            position relative;
            left: 10px;

            transition: left 1s;
        }

        &__cabel {
            display: flex;
            gap: 10px;

            position relative;
            left: 10px;

            transition: left 1s;
        }

        .accordion-rates{
            &__heading{
                display: none;
            }

            &__item, &__item-cabel{
                min-width: 300px;
                max-width: 300px;
                // height: 269px;
                height: fit-content;

                border: 1px solid #F8F8F8;
                box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);
                border-radius: 6px;

                padding: 30px 20px 45px;
            }

            &__showcase{
                height: auto;

                flex-direction: column;

                &1, &2, &3{
                    width: 100%;
                    margin-right: 0px;
                }

                &1{
                    margin-bottom: 25px;

                    &--column {
                        flex-direction: column;
                    }
                }

                &2-0{
                    width: 100%;

                    display: flex;
                    justify-content: space-between;


                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;
                    margin-bottom: 5px;

                    span{
                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                        letter-spacing: 0.01em;
                        color: #848484;
                    }

                    &_passive{
                        padding: 0 !important;
                        margin-bottom: 0 !important;
                        font-size: 0 !important;
                        // line-height: 0 !important;

                        color: transparent !important;
                        border-bottom: 0px solid transparent !important;
                        *{
                            // height: 0 !important;
                            padding: 0 !important;
                            margin-bottom: 0 !important;
                            font-size: 0 !important;

                            color: transparent !important;
                            border-bottom: 0px solid transparent !important;
                        }
                    }
                }

                &2{
                    margin-bottom: 40px;

                    span{
                        font-size: 15px;
                    }

                    &_passive{
                        display: none;
                    }
                }
            }

            &__additional-content{
                display: block;
                width: 100%;

                span{
                    display: block;
                    transition: all 0.2s linear;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 170%;
                    color: #848484;
                }

                &_passive{
                    *{
                        padding: 0 !important;
                        margin-bottom: 0 !important;
                        font-size: 0 !important;

                        color: transparent !important;
                        border-bottom: 0px solid transparent !important;
                    }
                    .span-arrow-right{
                        display: none;
                    }

                    .accordion-rates__additional-content_2{
                        display: none;
                    }
                }

                &_1{
                    width: 100%;

                    button{
                        background-color: transparent;
                        width: 100%;
                        display: flex;
                        padding: 0;

                        .spanheadingminicolor{
                            display: flex;
                            justify-content: start;
                            align-items: flex-end;
                            gap: 5px;

                            width: 100%;
                            &:hover{
                                border-bottom: 1px solid #6d6d6d;
                                transition: all 0.2s;
                            }
                        }

                        .spanheadingminimarkcolor{
                            top: 4px;
                        }

                        span{
                            display: block;
                            margin-bottom: 0px;
                        }
                    }
                }

                &_2{
                    margin-bottom: 40px;

                    &-subscription{
                        display: flex;
                        position relative;

                        span:nth-child(2){
                            // position absolute;
                            // left: 120px;
                            // width: 200px;
                            // color: #848484;
                        }

                        span:nth-child(3){
                            // position absolute;
                            // left: 195px;
                            // color: #848484;
                        }
                    }
                }

                .spanheadingmini{
                    display: block;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;
                    margin-bottom: 5px;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px !important;
                    line-height: 150%;
                    letter-spacing: 0.01em;
                    color: #848484;

                    &color{
                        color: #007A33;
                    }
                }

                .spanheadingminimarkcolor{
                    display: inline;
                    position relative;
                    top: 0px;

                    font-size: 16px;
                    color: #007A33;
                }

                .spanbold{
                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 170%;
                    color: #535353;
                }

                .spanbottom{
                    margin-bottom: 30px;
                }

                .spanbottom-mini{
                    margin-bottom: 20px;
                }

                .spaninline{
                    display: inline;
                }
            }

            &__button{
                button{
                    width: 173px;
                    height: 45px;
                }
            }

            &__content{
                display: none;
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }

    }}

    .accordion-rates__content2-subscription-2{
        position absolute;
        left: 115px !important;
        width: 200px;
        color: #848484;
    }

    .accordion-rates__content2-subscription-3{
        position absolute;
        left: 195px !important;
        color: #848484;
    }

}

.internet-apartment{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;

        font-p(17px, font-color-normal);
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: solid 1px #E5E5E5;
        margin-bottom: 110px;
    }

    &__content{
        display: flex;
        gap: 40px;

        margin-bottom: 240px;
    }

    &__card{
        width: 281px;
    }

    &__img{
        margin-bottom: 25px;
        height: 91px;

        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }

    p{
        margin-bottom: 0;

        font-p(17px, font-color-normal);
    }

    .phone{
        text-decoration: none;
        color: #202020;
        font-weight: 700;
    }

    .mail{
        text-decoration: none;
        color: #007A33;
    }

    @media (max-width: 1679px){
        &__content{
            gap: 25px;

            flex-wrap: wrap;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px 30px;
            margin-bottom: 80px;
        }
        .heading{
            padding-bottom: 10px;
            margin-bottom: 30px;
        }

        &__content{
            gap: 30px;
            margin-bottom: 0px;
        }

        &__card{
            width: 100%;
        }

        p{
            font-size: 15px !important;
        }
    }
}

.tariff-options{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;

        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;

        font-p(17px, font-color-normal);
    }

    .heading{
        margin-bottom: 75px;
    }

    &__products-wrap{
        position: static;
    }

    &__products{
        display: flex;
        gap: 30px;

        position: static;
    }

    &__card {
        height: 520px;
        width: 423px;
        padding: 144px 65px 0;

        border-radius: 6px;

        background: linear-gradient(170.97deg, #12B845 6.87%, #007A33 44.45%, #00551D 72.29%);

        position: relative;
        overflow: hidden;
    }
    &__card-img{
        position: absolute;
        right: 0;
        top: 0;

        display: block;
        //width: 87%;
        width: 330px;
    }
    &__card-header{
        width: 160px;
        margin-bottom: 60px;

        span{
            font-family: 'Rubik', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }

    &__card-button{
        padding-bottom: 65px;
        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-bottom: 20px;

        button{
            transition: all 0.2s;
            height: 50px;
            padding: 0 29px 0 25px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            box-shadow: 0 2px 6px rgba(1, 84, 29, 0.8), 0 20px 48px rgba(1, 84, 29, 0.4), 0 40px 160px rgba(1, 84, 29, 0.9);
            border-radius: 6px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px !important;
            line-height: 150%;
            color: #FFFFFF;

            svg path{
                fill: white
            }

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }
        }
    }
    &__card-content{
        margin-bottom: 75px;

        p{
            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #E9FCEE;
        }
    }

    &__products-buttons{
        display: none;
    }

    @media (max-width: 1679px){
        &__products{
            gap: 10px;
        }

        &__card{
            width: 379px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0 30px;
            margin-bottom: 30px;
        }

        &__card{
            width: 300px;
            height: 322px;

            padding: 45px 30px 0;

            background-size: 105% auto;

            &-header{
                width: 160px;
                margin-bottom: 30px;

                span{
                    font-size: 17px;
                }
            }

            &-button{
                padding-bottom: 45px;
                border-bottom: 1px solid rgba(245, 241, 248, 0.25);
                margin-bottom: 15px;
            }
        }

        &__products-wrap{
            position relative;

            height: 322px;

            overflow: hidden;

            margin-bottom: 45px;
        }

        &__products{
            position absolute;
            top: 0;
            left: 10px;

            transition: left 1s;
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.package-offers-accordion{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px 240px;
    }

    .accordion{
        &__item{
            &:last-child{
                margin-bottom: 0;
            }
        }

        &__button{
            justify-content: flex-start;
            height: 118px;

            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 17px!important;
            line-height: 170%;
            color: #535353;

            &-content{
                width: 100%;
                display: flex;
                justify-content: space-between;
                span{
                    &:first-child{
                        padding-right: 10px;
                    }
                }
            }
        }

        &__number{
            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #007A33;
        }

        &__content{
            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;

            li{
                position relative;

                display: flex;
                align-items: center;

                &:before{
                    content: '';
                    height: 3px;
                    width: 3px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                img{
                    display: none;
                }
            }

            &_passive{
                li{
                    &:before{
                        background: transparent;
                    }
                }
            }

            &_active{
                li{
                    &:before{
                        background: #535353;
                    }
                }
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 0px 30px 80px;
        }

        .accordion{
            .heading.package{
                margin-bottom: 30px;
            }

            &__button{
                height: fit-content;
                padding: 20px 0;

                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px!important;
                line-height: 170%;
                color: #535353;
            }

            &__content{
                font-size: 15px;

                li{
                    position relative;

                    display: flex;
                    align-items: center;

                    padding-left: 33px;

                    &:before{
                        content: '';
                        height: 3px;
                        width: 3px;
                        min-width: 3px;

                        border-radius: 50%;
                        margin-right: 10px;

                        position absolute;
                        top: 11px;
                        left: 20px;
                    }
                }
            }
        }
    }
}

.package-offers-equipment{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;

        margin-bottom: 160px;
    }

    .heading{
        margin-bottom: 15px;
    }

    &__card{
        display: flex;

        border-top: 1px solid #E5E5E5;
        padding-top: 115px;

        &-img{
            max-width: 322px;
            min-width: 322px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &-heading{
            margin-bottom: 25px;

            h3{
                text-align: left;

                font-family: 'Rubik', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }

            svg{
                display: none;
            }
        }

        &-text{
            margin-bottom: 45px;

            p{
                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }
        }

        &-price{
            margin-bottom: 81px;

            display: flex;
            align-items: center;

            &_green{
                padding-right: 35px;
                border-right: 1px solid #E5E5E5;
                margin-right: 35px;
                span{
                    font-family: 'Rubik', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 160%;
                    letter-spacing: 0.01em;
                    color: #007A33;
                }
            }

            &_green-small{
                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 140%;
                color: #007A33;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0 30px;

            margin-bottom: 0px;
        }

        .heading{
            padding-bottom: 10px;
            border-bottom: 1px solid #E5E5E5;
        }

        &__card{
            flex-direction: column;

            border-top: unset;
            padding-top: 0;

            &-heading{
                display: flex;
                justify-content: space-between;

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 0;

                h3{
                    text-align: left;
                    padding-right: 10px;
                    margin-bottom: 0;

                    font-size: 17px;
                    line-height: 150%;
                }

                svg{
                    display: block;

                    transition: all 0.3s;
                    align-self: flex-end;

                    margin-right: 6px;
                    margin-bottom: 7px;

                    transform: rotate(90deg);
                }

                svg.active{
                    transform: rotate(270deg);

                    path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &-text{
                overflow: hidden;

                margin-bottom: 25px;

                p{
                    transition: all 0.3s;

                    font-size: 15px;

                    padding-top: 0px;
                    margin-bottom: 0;

                    line-height: 0%;

                    color: transparent;
                }
            }
            &-text.active{
                p{
                    padding-top: 20px;
                    line-height: 170%;
                    color: #535353;
                }
            }

            &-text-inner {
                max-height: 0;
                overflow: hidden;
            }

            &-text.active &-text-inner {
                max-height: initial;
            }

            &-price{
                margin-bottom: 81px;

                display: flex;
                align-items: center;

                &_green{
                    padding-right: 35px;
                    border-right: 1px solid #E5E5E5;
                    margin-right: 35px;
                    span{
                        font-size: 20px;
                        line-height: 150%;
                    }
                }

                &_green-small{
                    font-size: 15px;
                    line-height: 170%;
                }
            }
        }
    }
}

.block2{
    .heading{
        h2{
            margin-bottom: 36px;
        }
    }

    &__introduction{
        margin-bottom: 88px;
        max-width: 767px;
    }

    &__content{
        margin-bottom: 172px;

        border: 1px solid #F8F8F8;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 4px;

        display: flex;
        flex-wrap: wrap;
    }

    &__imgcontent{
        position: relative;
        height: auto;
        max-width: 510px;

        &:after{
        content: '';
        position: absolute;
        right: -16px;
        bottom: 202px;
        border: 8px solid transparent;
        border-left: 8px solid rgb(26, 189, 77);
        }
    }

    &__img{
        border-radius: 4px;
        overflow: hidden;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__imgheader{
        position: absolute;
        bottom: 58px;
        left: 72px;

        width: 160px;

        span{
            // font-family: 'PT Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: 0.005em;
            color: #FFFFFF;
        }
    }

    &__imgbutton{
        position: absolute;
        right: 92px;
        bottom: 54px;

        span{
            padding-left: 25px;

            //font-family: 'PT Sans', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 22px;
            line-height: 22px;
            text-align: right;
            color: #b4dfbf;

            text-decoration-line:line-through;
        }

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

            width: 139px;
            height: 46px;
            border: 1px solid #7DD191;
            border-radius: 6px;
            background-color: transparent;

            //font-family: 'PT Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            text-align: right;
            color: #FFFFFF;

            svg path{
                fill: #ffffff;
            }

            &:hover{
                border: 1px solid #ffffff;
            }
        }
    }

    &__textcontent{
        padding: 20px 0 0 40px;

        display: flex;
        gap: 20px;

        span{
            display: block;

            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #848484;
        }

        &1{
            width: 160px;

            button{
                background-color: white;
                width: 100%;

                display: flex;

                .spanheadingminicolor{
                    display: flex;
                    justify-content: start;
                    align-items: flex-start;
                    gap: 5px;

                    width: 100%;
                    &:hover{
                        border-bottom: 1px solid #6d6d6d;
                        transition: all 0.2s;
                    }
                }
            }
        }

        &2{
            // width: 304px;
        }

        &3{
            display: flex;

            span{
                margin-bottom: 8px;

                :last-of-type{
                    margin-bottom: 0px;
                }
            }

            &1{
                margin-right: 13px;
            }
            &2{
                margin-right: 8px;
            }
        }

        .spanheading{
            margin-bottom: 28px;

            //font-family: 'PT Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #007A33;
        }

        .spanheadingmini{
            padding-bottom: 4px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 12px;

            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 16px;
            color: #848484;

            &color{
                color: #007A33;
            }
        }

        .spanheadingminimarkcolor{
            display: inline;
            position relative;
            top: 0px;

            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #007A33;
        }

        .spanbold{
            font-family: $font-family-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #535353;
        }

        .spanbottom{
            margin-bottom: 24px;
        }

        .spaninline{
            display: inline;
        }
    }

    @media (max-width: 1200px) {
        margin-bottom: 72px;

        .heading{
            h3{
                text-align: left !important;
            }
        }

        &__content{
            border: none;
            box-shadow: none;

            flex-direction: column;
        }

        &__textcontent{
            padding: 52px 0px;
            flex-wrap: wrap;
        }

        &__imgcontent{
            &:after{
            border: unset;
            }
        }
    }

    @media (max-width: 542px) {
        margin-bottom: 0px;

        &__imgheader{
            bottom: 208px;
            left: 15px;
        }

        &__imgbutton{
            position: absolute;
            right: auto;
            left: 15px;
            bottom: 104px;
        }

        &__textcontent{
            &1, &2{
                width: 100%;
            }
        }
    }

    @media (max-width: 450px) {
        &__imgheader{
            bottom: auto;
            left: 15px;
            top: 50px;
        }

        &__imgbutton{
            right: auto;
            left: 15px;
            bottom: auto;
            top: 170px;
        }
    }

    @media (max-width: 425px) {
        &__imgbutton{
            top: 150px;
            span{
                padding-left: 30px;

                font-size: 16px;
            }
        }
    }
}

.block3{
    .heading{
        h2{
            margin-bottom: 36px;
        }
    }

    &__introduction{
        margin-bottom: 88px;
        max-width: 767px;
    }

    .accordion-rates{
        margin-bottom: 144px;

        a {
            color: #1f7232;
            cursor: pointer;
        }

        &__heading{
            display: flex;
            padding-bottom: 8px;
            border-bottom: solid 1px #E5E5E5;

            span{
                //font-family: $font-family-content;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: right;
                color: #848484;
            }

            &1, &2, &3{
                display: flex;
            }

            &1{
                justify-content: space-between;
                width: 340px;
                margin-right: 98px;
            }

            &2{
                width: 304px;
                margin-right: 52px;
                position relative;

                span:nth-child(2){
                    position absolute;
                    left: 144px;
                }
            }
        }

        &__item{
            border-bottom: solid 1px #E5E5E5;
        }

        &__showcase{
            height: 120px;
            display: flex;
            align-items: center;

            &1, &2, &3, &4{
                display: flex;
            }

            &1{
                justify-content: space-between;
                width: 340px;
                margin-right: 98px;
                flex: 1 0 auto;

                span{
                    //font-family: 'PT Sans', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 22px;
                    color: #007A33;
                }

                &-price{
                    span:nth-child(1){
                        //font-family: 'PT Sans', sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 22px;
                        text-align: right;
                        color: #7c7c7c;

                        text-decoration-line:line-through;
                        padding-right: 10px;
                    }
                }
            }

            &2{
                width: 304px;
                margin-right: 52px;
                position relative;

                span{
                    font-family: $font-family-content;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #535353;
                }

                span:nth-child(2){
                    position absolute;
                    left: 144px;
                }
            }

            &3{
                justify-content: space-between;
                width: 376px;

                padding-right: 34px;
            }

            .just-left{
                justify-content: flex-end;
            }
        }

        &__button{
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                width: 181px;
                height: 48px;
                border-radius: 4px;

                transition: all 0.2s;

                &:hover{
                    background-color: #007A33;
                    color: #FFFFFF;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }
                }
            }

            &_active{
                button{
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: white;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }

                    &:hover{
                        background: linear-gradient(180deg, #007A33 100%, #007A33 100%);
                    }
                }
            }

            &_passive{
                button{
                    background: white;
                    color: #007A33;
                    border: 1px solid #007A33;
                    border-radius: 6px;

                    svg path{
                        fill: #007A33;
                    }
                }
            }
        }

        &__button-close{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor pointer;

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;

                border-bottom: 1px dashed #C6C6C6;

                background-color: transparent;
                font-family: $font-family-content;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #848484;
            }

            &_passive{
                svg{
                   transform: rotate(90deg);
                   transition: transform 0.2s;
                }

                &:hover{
                    button{
                        border-bottom: 1px dashed #E5E5E5;
                        color: #C6C6C6;
                    }

                    svg path{
                        stroke: #C6C6C6;
                    }
                }
            }

            &_active{
                svg{
                   transform: rotate(-90deg);
                   transition: transform 0.3s;
                }

                button{
                    border-bottom: 1px dashed #E5E5E5;
                    color: #C6C6C6;
                }

                svg path{
                    stroke: #C6C6C6;
                }
            }
        }

        &__content{
            background: #FBFBFB;
            padding: 44px 72px 44px;
            transition: all 0.2s;

            display: flex;

            &_passive{
                height: 0px;
                padding: 0px 0px 0px 72px !important;
                span{
                    color: transparent !important;
                    border-bottom: 0px solid transparent !important;
                }
            }

            &_active{
                height: auto;
                padding: 44px 0px 44px 72px;
                color: #535353;
            }

            &1{
                width: 268px;
                margin-right: 98px;
                span{
                    display: block;
                    margin-bottom: 8px;
                }
            }
            &2{
                width: 316px;
                margin-right: 40px;

                button{
                    background-color: transparent;
                    width: 100%;
                    display: flex;

                    .spanheadingminicolor{
                        display: flex;
                        justify-content: start;
                        align-items: flex-start;
                        gap: 5px;

                        width: 100%;
                        &:hover{
                            border-bottom: 1px solid #6d6d6d;
                            transition: all 0.2s;
                        }
                    }
                }

                span{
                    display: block;
                }
            }
            &3{
                width: 304px;
                position relative;

                span:nth-child(2){
                    position absolute;
                    left: 140px;
                }

                span:nth-child(3){
                    position absolute;
                    left: 224px;
                }
            }

            .spanheadingmini{
                display: block;
                padding-bottom: 4px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 12px;

                font-family: $font-family-content;
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 16px;
                color: #848484;

                &color{
                    color: #007A33;
                }
            }
            .spanheadingminimarkcolor{
                display: inline;
                position relative;
                top: 0px;

                font-family: $font-family-content;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #007A33;
            }
            .spanbold{
                font-family: $font-family-content;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #535353;
            }
            .spanbottom{
                margin-bottom: 24px;
            }
            .spaninline{
                display: inline;
            }
        }
    }

    @media (max-width: 1200px) {
        .accordion-rates{
            &__heading{
                flex-direction: column;

                &1, &2{
                    margin-right: 0px;
                }
            }

            &__showcase{
                padding-top: 20px;
                margin-bottom: 20px;

                align-items: flex-start;
                flex-direction: column;

                &1, &2, &3, &4{
                    margin-right: 0px;
                }

                &3, &4{
                    width: auto;

                }
            }

            &__button{
                margin-right: 30px;
            }

            &__content{
                padding: 44px 0px 44px;

                flex-direction: column;

                &_passive{
                    padding: 0px 0px 0px !important;
                }

                &1, &2, &3{
                    margin-right: 0px;
                    width: 100%;
                }
            }
        }

    }
    @media (max-width: 370px) {
        .accordion-rates{
            &__showcase{
                padding-top: 20px;
                margin-bottom: 20px;
                height: auto;
                align-items: flex-start;
                flex-direction: column;

                &1, &2, &3, &4{
                    width: auto;
                    margin-right: 0px;
                    flex-direction: column;
                }
                &1{
                    gap: 5px;
                }
                &2{
                    span:nth-child(2) {
                        position: static;
                    }
                }
            }

            &__heading{
                flex-direction: column;

                &1, &2{
                    margin-right: 0px;

                    flex-direction: column;
                    align-items: flex-start;

                    width: auto;
                }

                &2{
                    span:nth-child(2) {
                        position: static;
                    }
                }
            }

            &__button-close{
                justify-content: flex-start;
            }
        }
    }
}
.block4{
    .heading{
        h2{
            margin-bottom: 36px;
        }
    }
    &__content{
        margin-bottom: 72px;
    }
    &__rates{
        margin-bottom: 144px;

        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        &-card{
            max-width: 376px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 100%;
            padding: 64px 56px 64px;

            border: 1px solid #F8F8F8;
            border-radius: 4px;
            box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);

            &:hover{
                box-shadow: 0px 8px 20px rgba(173, 173, 173, 0.3);
            }
        }

        &-heading{
            margin-bottom: 44px;

            //font-family: 'PT Sans', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #202020;
        }

        &-button{
            width: 100%;
            padding-bottom: 64px;
            border-bottom: solid 1px #E5E5E5;
            margin-bottom: 24px;

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                width: 142px;
                height: 46px;
                border: 1px solid #007A33;
                border-radius: 6px;
                background-color: white;

                //font-family: 'PT Sans', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                text-align: right;
                color: #007A33;

                &:hover{
                    box-shadow: 0px 2px 10px rgba(0, 122, 51, 0.5);
                    // box-shadow: 0px 2px 10px rgba(0, 122, 51, 0.25);
                }
            }
        }

        &-content{
            li{
                display: flex;
                align-items: center;
                gap: 8px;

                margin-bottom: 8px;
            }
        }
    }
}

@media (max-width: 420px) {
    .action_tariff.block3{
        h3 {
            //color: red!important;
        }
        .accordion-rates {
            &__showcase{
                margin-bottom: 50px;

                &1,&2 {
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 15px;
                }
                &3, &4{
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 15px;
                }

            }
        }
    }
}
