.help-physical {
    background: url(/src/img/pages/BG_help.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading {
        padding-top: 305px;
        margin-bottom: 20px;

        h1 {
            margin: 0 !important;
            padding: 0 !important;

            font-h1(52px, font-color-light);
        }
    }

    &__content {
        max-width: 658px;

        margin-bottom: 65px;

        p {
            margin-bottom: 0;

            font-p-300(17px, font-color-light);
        }
    }

    @media (max-width: 1279px) {
        background: url(/src/img/pages/BG_help_mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 642px;

        &__page {
            max-width: 100%;

            padding: 0 30px;
        }

        .heading {
            padding-top: 357px;
            margin-bottom: 25px;

            h1 {
                font-h1(30px, font-color-light);
            }
        }

        &__content {
            max-width: 100%;

            margin-bottom: 40px;
        }
    }
}

.help-physical-questions {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 160px;
    }

    .heading {
        margin-bottom: 95px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__buttons-wrap {
        padding-bottom: 80px;
        border-bottom: 1px solid #E5E5E5;

        display: flex;
        gap: 20px;
    }

    &__select-wrap {
        display: none;
    }

    &__button {
        cursor: pointer;

        border-bottom: 1px solid #007A33;

        button {
            border-radius: 0;
            border-bottom: 2px solid transparent;

            padding: 15px 85px 10px 0;
            background-color: white;

            font-p-20-500(font-color-green);
        }
    }

    &__button.active,
    &__button:hover {
        border-bottom: 1px solid #12B845;

        button {
            font-p-20-500(font-color-green-hover);
            border-bottom: 2px solid #12B845 !important;
        }
    }

    &__item {
        //border-bottom: 1px solid #E5E5E5;
    }

    &__item-button {
        height: 118px;
        width: 100%;

        display: flex;
        align-items: center;
        background: none;
    }

    &__item-button-number {
        min-width: 71px;
        text-align: left;

        span {
            font-p-25-500(font-color-green)
        }
    }

    &__item-button-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;

        span {
            font-p-500(17px, font-color-normal);
        }
    }

    &__item-button-plus {
        height: 17px;
        width: 17px;

        align-self: flex-end;

        margin-left: 12px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;

        span {
            transition: transform 0.3s;

            width: 17px;
            height: 1px;
            background-color: font-color-green;

            position: absolute;
            top: 8px;
            right: 0;
        }

        span:first-child {
            transform: rotate(90deg);
        }
    }

    &__item-button-plus.active {
        span:first-child {
            transform: rotate(0deg);
        }
    }

    &__item-button-plus:hover {
        span {
            background-color: font-color-green-hover;
        }
    }

    &__item-content {
        transition: all 0.3s;

        background: #FBFBFB;

        padding: 0 75px;
        border-bottom: 1px solid #E5E5E5;

        p {
            transition: all 0.3s;

            margin-bottom: 0;
            max-width: 896px;

            font-p(17px, font-color-normal);
            line-height: 0%;
            color: transparent !important;
        }

        ul {
            li {
                transition: all 0.3s;

                margin-bottom: 0px;

                marker-3();
                font-p(17px, font-color-normal);
                line-height: 0%;
                color: transparent !important;

                &:before {
                    min-height: 0px;
                }
            }

            li:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__item-content.active {
        padding: 55px 75px 75px;

        p {
            line-height: 170%;
            color: font-color-normal;
        }

        li {
            margin-bottom: 5px;

            line-height: 170%;
            color: font-color-normal;

            &:before {
                min-height: 3px;
            }
        }
    }


    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 80px;
        }

        .heading {
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__buttons-wrap {
            display: none;
        }

        &__select-wrap {
            display: block;
            padding-bottom: 30px;
            border-bottom: 1px solid #E5E5E5;
        }
        &__select{
            width: 100%;
            padding: 10px 20px;

            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 6px;

            &:focus {
                outline: none;
            }

            option{
                font-p(15px, font-color-normal);
            }
        }
        &__item-button {
            height: auto;
            padding-top: 20px;
            margin-bottom: 20px;

            flex-direction: column;
            align-items: flex-start;
        }

        &__item-button-content {
            span {
                font-p-500(15px, font-color-normal);
            }
        }

        &__item-button-number {
            margin-bottom: 5px;
        }

        &__item-content {
            padding: 0 20px;

            p {
                font-p(15px, font-color-normal);
                line-height: 0%;
                color: transparent !important;
            }
        }

        &__item-content.active {
            padding: 35px 20px 40px;
        }
    }
}

.help-physical-support {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 110px;
    }

    .heading {
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 25px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__content {
        max-width: 927px;

        p {
            margin-bottom: 20px;

            font-p(17px, font-color-normal);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 1679px) {
        .heading {
            margin-bottom: 55px;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 45px;
        }

        .heading {
            margin-bottom: 20px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__content {
            max-width: 100%;

            p {
                font-p(15px, font-color-normal);
            }
        }
    }
}

.help-physical-advantages {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;

        display: flex;
        gap: 15px;
    }

    &__card {
        min-width: 321px;

        &-img {
            height: 91px;

            display: flex;
            align-items: center;

            margin-bottom: 25px;

            img {
                display: block;
                height: 100%;
                width: auto;
            }
        }

        &-text {
            width: 215px;

            p {
                margin-bottom: 0;
                font-p(17px, font-color-normal);
            }
        }
    }

    @media (max-width: 1679px) {
        &__page {
            margin-bottom: 160px;
        }

        &__card {
            min-width: 281px;

            &-img {
                margin-bottom: 30px;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            gap: 30px;
            flex-direction: column;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        &__card {
            min-width: 100%;

            &-img {
                margin-bottom: 10px;
            }

            &-text {
                width: 100%;

                p {
                    font-p(15px, font-color-normal);
                }
            }
        }
    }
}

.help-physical-contact {
    background: url(/src/img/pages/BG_help_contact.jpg) no-repeat center, linear-gradient(180deg, #0B2616 0%, #092C18 28%, #08341A 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 460px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading {
        padding-top: 105px;
        margin-bottom: 25px;

        h2 {
            font-h3-ls(28px, font-color-light);
        }
    }

    &__content {
        max-width: 792px;

        padding-bottom: 45px;
        border-bottom: 1px solid rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        p {
            max-width: 598px;
            margin-bottom: 0;

            font-p-300(17px, font-color-light);
        }
    }

    &__messengers {
        display: flex;
        gap: 55px;
    }

    &__telegram, &__mail, &__whatsapp {
        a {
            display: flex;
            align-items: center;
            gap: 10px;

            font-p-20-600();

            text-decoration: none;
        }
    }

    @media (max-width: 1279px) {
        background: url(/src/img/pages/BG_help_contact_mobile.jpg) no-repeat center, linear-gradient(180deg, #0B2616 0%, #092C18 28%, #08341A 52.86%, #073A1C 73.29%, #073A1C 100%);

        height: 755px;

        &__page {
            gap: 30px;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        .heading {
            padding-top: 282px;
            margin-bottom: 15px;

            h2 {
                font-h3(17px, font-color-light);
            }
        }

        &__content {
            max-width: 100%;

            padding-bottom: 35px;

            p {
                max-width: 100%;
                font-p(15px, font-color-light);
            }
        }

        &__messengers {
            flex-direction: column;
            gap: 32px;
        }

        &__telegram, &__mail, &__whatsapp {
            a {
                font-p-500(15px, font-color-light);
            }
        }
    }
}

.help-physical-specialists {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 240px 55px 0;
        margin-bottom: 240px;
    }

    .heading {
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 80px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__card {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 5px 15px;

            li {
                flex: 1 0 100%;
                max-width: 367px;

                font-p(17px, font-color-normal);
                marker-3();
            }
        }
    }

    @media (max-width: 1679px) {
        &__page {
            padding: 160px 55px 0;
            margin-bottom: 240px;
        }
    }
    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 83px;
        }

        .heading {
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__card {
            ul {
                gap: 8px;

                li {
                    max-width: 100%;

                    font-p(15px, font-color-normal);
                }
            }
        }
    }
}
