.table{
  margin-bottom: 50px;

  .text-mobile{
    display: none !important;
  }

  .th-col{
    flex: 14.5% 0 0;
    color: #5f5f5f;
    font-size: 16px;

    span{
      display: block;
      // margin-bottom: 11px;
    }

    &_tariff{
      flex: 17.4% 0 0;
      font-size: 24px;
      color: red;

      span{
        margin-bottom: 0;
      }

      &:before{
        position: absolute;
        content: '';
        top: 5%;
        right: 0;
        width: 1px;
        height: 90%;
        display: block;
        background-color: #ebebeb;
      }
    }

    &_tariff-asterisk {
      display: inline !important;
      color: #1c1c1c;
    }

    &_theme-span{
      span{
        font-size: 24px;
      }
    }

    &_serv{
      span{
        margin-top: 5px;
      }
    }
    &_minutes{
      flex: 16.4% 0 0;

      span{
        font-size: 24px;
        color: red;
      }

      &:before{
        position: absolute;
        content: '';
        top: 5%;
        left: 0;
        width: 1px;
        height: 90%;
        display: block;
        background-color: #ebebeb;
      }
    }
    &_cost{
      flex: 9.5% 0 0;

      span{
        font-size: 24px;
        font-weight: 700;
        color: red;
      }
    }
    &_connect{
      flex: 27.8% 0 0;

      button{
        height: 50px;
      }
    }
  }

  .thead{
    display: flex;
    flex-flow: row nowrap;

    .th-col{
      padding: 22px 0 !important
      color: (#d4d4d4 - 30) !important;
      font-size: 14px;
      text-align: center;

      &_tariff{
        &:before{
          display: none;
        }
      }
      &_minutes{
        &:before{
          display: none;
        }
      }
    }
  }

  .tbody{
    border: border;

    .trow{
      border-bottom: border;
      display: flex;
      flex-flow: row nowrap;

      &:last-child{
        border-bottom: none;
      }

      .th-col{
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
      }
    }

    &_2{
      display: none;
    }
  }

  .switcher{
    margin: 0 auto;
    margin-bottom: 25px;
    border: border;
    border-radius: 18px;
    height: 56px;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 246px;
    cursor: pointer;
    padding: 2px;

    .variant{
      color: #797979;
      text-align: center;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .5s;
      z-index: 2;

      &_active{
        color: #fff;
      }
    }

    .handler{
      position: absolute;
      z-index: 1;
      width: 52%;
      border-radius: 16px;
      height: calc(100% - 4px);
      left: 0;
      background-color: #c20a45;
      transition: all .5s;
      transform: translateX(2%);

      &_2{
        transform: translateX(91%);
      }
    }
  }

  @media (max-width: 880px){
    .tbody{
      .trow{
        .th-col{
          padding-top: 40px;
          padding-bottom: 40px;

          &_theme-span{
            span{
              font-size: 20px;
            }
          }
          &_tariff{
            font-size: 18px;
            line-height: 1.5;
          }
          &_minutes{
            &:before{
              display: none;
            }
          }
          &_connect{
            button{
              width: 150px;
            }
          }
        }
      }
    }

    .thead{
      .th-col{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }
  @media (max-width: 680px){
    .thead{
      display: none;
    }
    .tbody{
      .trow{
        flex-flow: row wrap;

        .th-col{
          flex: 33% 0 0;
          padding-top: 16px;
          padding-bottom: 16px;

          .text-mobile{
            display: block !important;
            color: #5f5f5f;
            font-size: 16px;
          }

          &_tariff{
            order: 1;
            &:before{
              display: none;
            }
          }
          &_cost{
            order: 2;

            span{
              font-size: 24px;
            }
          }
          &_connect{
            order: 3;
          }
          &_serv{
            padding-top: 0;
            order: 4;

            span{
              display: inline-block;
              margin-bottom: 0;
            }
          }
          &_minutes{
            padding-top: 0;
            order: 5;
            color: red;

            span{
              display: inline-block;
              margin-bottom: 0;
            }
            .text-mobile{
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
    @media (max-width: 490px){
      .tbody{

        .trow{
          padding: 12px;

          .th-col{
            font-size: 14px;
            padding-top: 0;

            &_tariff,&_serv:nth-of-type(2){
              text-align: left;
            }
            &_cost,&_serv:nth-of-type(3){
              text-align: center;
            }
            &_connect,&_minutes{
              text-align: right;
            }
            &_connect{
              button{
                margin-right: 0;
                margin-left: auto !important;
              }
            }

            span{
              font-size: 16px;
            }

            .text-mobile{
              font-size: 14px !important;
            }

            &_tariff{
              font-size: 18px;

              span{
                font-size: 18px;
              }
            }
            &_cost{
              span{
                font-size: 24px;
              }
            }
            &_minutes{
              span{
                margin-top: 3px;
              }
            }
            &_connect{
              button{
                text-indent: -99999px;
                width: 78px;
                height: 45px;

                &:before{
                  content: '';
                  position: absolute;
                  top: calc(50% - 13px);
                  left: calc(80% - 39px);
                  display: block;
                  width: 29px;
                  height: 26px;
                  background-image: url(/src/img/elements/cart_button.png);
                }
              }
            }
          }
        }
      }
    }

  &_lines{
    .th-col{
      &_lines{
        flex: 10.8% 0 0;

        span{
          font-size: 24px;
          color: red;
        }

        &:before{
          position: absolute;
          content: '';
          top: 5%;
          left: 0;
          width: 1px;
          height: 90%;
          display: block;
          background-color: #ebebeb;
        }
      }
      &_minutes{
        flex: 10.8% 0 0;

        &:before{
          display: none;
        }
      }
      &_cost{
        flex: 11% 0 0;
      }
      &_connect{
        flex: 21.2% 0 0;

        button{
          width: auto;
          padding-left: 16%;
          padding-right: 16%;
        }
      }
    }

    .tbody{
      &_2{
        display: none;
      }
    }

    .thead{
      .th-col{
        &_lines{
          &:before{
            display: none;
          }
        }
      }
    }

    @media (max-width: 1024px){
      .th-col{
        &_lines{
          display: none !important;
        }
        &_minutes{
          flex: 16.4% 0 0;
        }
        &_cost{
          flex: 9.5% 0 0;
        }
        &_connect{
          flex: 27.8% 0 0;

          button{
            padding-left: 0 !important;
            padding-right: 0 !important;
            width: 223px;
          }
        }
      }
    }
  }

  &_traffic{
    .trow, .thead{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .thead{
      @media (max-width: 680px){
        display: none
      }
    }

    .th-col{
      &_tariff{
        display: flex;
        align-items: center;
        justify-content: center;
        &:before{
          display: none;
        }
      }
      &_traffic{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &_speed_channels{
        flex: 215px 0 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .separator-plus{
          margin: 4px 5px 0;
        }

        a{
          text-decoration: none;
          color: inherit

          &[data-show-list-channels]{
            span{
              max-width: 42px;
              margin: 0 auto 11px;
              border-bottom: 1px dashed #b1d237;
            }
          }
        }
        img{
          width: 40px;
          margin-top: -3px;
          margin-left: 7px;
        }
      }
    }

    @media (max-width: 680px){
      .th-col{
        &_traffic{
          order: 4;
          flex: 50% 0 0 !important;
          flex-flow: column nowrap;
        }
        &_speed_channels{
          order: 5;
          flex: 50% 0 0 !important;

          span{
            display: inline-block;
            margin-bottom: 0;
          }
        }
      }
    }
    @media (max-width: 490px){
      .th-col{
        &_traffic{
          place-items: flex-start;
          padding-bottom: 0 !important;
        }
        &_speed_channels{
          text-align: right !important;
          padding-bottom: 0 !important;
        }
      }
    }
    @media (max-width: 425px){
      .th-col{
        &_traffic{
          flex: 100% 0 0 !important;
          place-items: inherit;
          margin-bottom: 15px
        }
        &_speed_channels{
          flex: 100% 0 0 !important;
          justify-content: center;

          .separator-plus{
            margin-top: 2px;
          }
          img{
            margin-top: -7px
          }
        }
      }
    }
  }

  &_zones{
    .checkboxes{
      display: none;
      flex-flow: row wrap;
      justify-content: space-between;

      .variant{
        display: inline-block;
        margin-bottom: 20px;

        input{
          position: absolute;
          visibility: hidden;
        }

        & [type='checkbox']:checked + label, & [type='checkbox']:not(:checked) + label{
          position: relative;
          padding-left: 36px;
          cursor: pointer;
          display: inline-block;

          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: -3px;
            width: 20px;
            height: 20px;
            border: 1px solid #c1c1c1;
            border-radius: 1px;
            background: #fff;
          }

          &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 5px;
            display: block;
            width: 21px;
            height: 15px;
            background: url(/src/img/elements/tick.png) 50% 50%/cover no-repeat;
            transition: all 0.2s ease;
          }
        }

        & [type='checkbox']:not(:checked) + label{
          &:after{
            opacity: 0;
            transform: scale(0);
          }
        }
      }

      @media (max-width: 920px){
        justify-content: flex-start;

        .variant{
          flex: 25% 0 0;
        }
      }
      @media (max-width: 768px){
        .variant{
          flex: 33% 0 0;
        }
      }
      @media (max-width: 500px){
        .variant{
          flex: 50% 0 0;
        }
      }
      @media (max-width: 425px){
        label{
          font-size: 14px;
        }
      }
    }

    .thead{
      .th-col{
        &_cost{
          &:before{
            display: none;
          }
        }
      }
    }
    .th-col{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      &_tariff{
        flex: 19.8% 0 0;
      }
      &_cost{
        flex: 10.7% 0 0;
        display: block;

        &:before{
          position: absolute;
          content: '';
          top: 5%;
          right: 0;
          width: 1px;
          height: 90%;
          display: block;
          background-color: #ebebeb;
        }
      }
      &_places{
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1.3;
      }
    }

    @media (max-width: 768px){
      .checkboxes{
        margin: 30px 0;
      }

      .th-col{
        &_tariff{
          flex: 25.3% 0 0;
        }
        &_cost{
          flex: 15.7% 0 0;
        }
      }
    }
    @media (max-width: 680px){
      .checkboxes{
        margin: 30px 0;
      }

      .th-col{
        flex-flow: row wrap;
        padding: 10px;

        &_tariff{
          order: 1;
          flex: 50% 0 0 !important;
          justify-content: flex-start;
        }
        &_cost{
          order: 2;
          flex: 50% 0 0 !important;
          text-align: right !important;

          &:before{
            display: none;
          }
        }
        &_places{
          order: 3;
          flex: 100% 0 0 !important;
          border-top: border;
        }
      }
    }
    @media (max-width: 490px){
      .th-col{
        &_places{
          padding: 25px 0 !important;
        }
      }
    }
  }

  &_telephony-europa{
    .th-col_cost{
      z-index: 3;
    }

    .tip-number{
      position: absolute;
      left: calc(100% - 10px);
      top: 0;
      cursor: pointer;
      transform-origin: center;
      transform: rotate(90deg);
      transition: all .3s;

      svg{
        width: 19px;
        height: 22px;

        .svg_tip-info{
          transform-origin: 50% 44%;
          transform: rotate(-90deg);
          transition: all .3s;
        }
      }

      .include-number{
        display: none;
        border: border;
        border-radius: 6px;
        box-shadow: shadow;
        position: absolute;
        top: 100%;
        background-color: #fff;
        padding: 13px;
        width: 180px;

        .head{
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 15px;
        }
        .info{
          &__item{
            display: flex;
            justify-content: space-between;

            &:last-child{
              span{
                margin-bottom: 0;
              }
            }

            span{
              font-size: 14px;
              font-weight: 400;
              color: black;
            }
          }
        }
      }

      &_active{
        transform: rotate(0deg);

        svg{
          .svg_tip-info{
            transform: rotate(0deg);
          }
        }

        .include-number{
          display: block;
        }
      }

      @media (max-width: 700px){
        .include-number{
          left: -80px;
        }
      }
    }
  }

  &_operators{
    .th-col{
      padding: 25px 0 !important;

      &_tariff{
        flex: 17.2% 0 0;
      }
      &_port{
        flex: 14.1% 0 0;
      }
      &_cost-connect{
        flex: 13.6% 0 0;
      }
      &_type{
        flex: 14.5% 0 0;
      }
      &_cost{
        display: block !important;
        flex: 17.6% 0 0;
      }
      &_connect{
        flex: 1;
      }
    }
    .tbody{
      .th-col{
        display: flex;
        justify-content: center;
        align-items: center;

        &_type{
          &:before{
            position: absolute;
            content: '';
            top: 5%;
            right: 0;
            width: 1px;
            height: 90%;
            display: block;
            background-color: #ebebeb;
          }
        }
      }
    }

    @media (max-width: 1024px){
      .th-col{
        &_port, &_cost-connect, &_type{
          flex: 12% 0 0;
        }
      }
    }
    @media (max-width: 768px){
      .th-col{
        &_port, &_cost-connect, &_type{
          flex: 13% 0 0;
        }
      }
    }
    @media (max-width: 680px){
      .tbody{
        .th-col{
          &_type{
            &:before{
              display: none !important;
              //opacity: 0;
            }
          }
        }
      }

      .text-mobile{
        display: block;
        color: inherit !important;
        width: 100%;
        margin: 5px 0;
        font-size: 14px !important;
      }

      .th-col{
        flex-wrap: wrap;

        &_tariff{
          .text-mobile{
            font-size: 18px !important;
          }
        }
        &_port{
          order: 4;
        }
        &_cost-connect{
          order: 5;
        }
        &_type{
          order: 6;

          &:before{
            display: none;
          }
        }
      }
    }
    @media (max-width: 490px){
      .tbody{
        .th-col{
          padding-top: 0 !important;
          padding-bottom: 20px !important;

          &_tariff{
            justify-content: flex-start;
          }
          &_cost{
            span{
              margin-top: 7px;
            }
          }
          &_port{
            justify-content: flex-start;
            .text-mobile{
              text-align: left;
            }
          }
          &_type{
            justify-content: flex-end;
            .text-mobile{
              text-align: right;
            }
          }
        }
      }
    }
  }

  &_channel{
    margin-top: 20px;

    .th-col{
      padding: 25px 0 !important;
      font-size: 14px;

      span{
        font-size: 16px;
        color: #5f5f5f;
        font-weight: 400;

        b{
          font-size: 24px;
        }
      }

      &_speed{
        flex: 23.4% 0 0;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &_cost{
        flex: 29.9% 0 0;

        span{
          margin-top: 4px;
        }
      }
      &_rent{
        flex: 23.7% 0 0;
      }
      &_connect{
        flex: 1;
      }
    }

    .tbody{
      .th-col{
        &_speed, &_cost{
          &:before{
            position: absolute;
            content: '';
            top: 5%;
            right: 0;
            width: 1px;
            height: 90%;
            display: block;
            background-color: #ebebeb;
          }
        }
      }
    }

    @media (max-width: 1024px){
      .th-col:not(.th-col_connect){
        flex: 23% 0 0;
      }
    }
    @media (max-width: 680px){
      .th-col{
        &_speed{
          order: 1;
          flex-wrap: wrap;

          .text-mobile{
            flex: 100% 0 0;
            color: inherit !important;
            font-size: 24px !important;
          }
        }
        &_rent{
          order: 2;
        }
        &_connect{
          order: 3;
        }
        &_cost{
          order: 4 !important;
          flex: 100% 0 0 !important;
          font-size: 16px !important;
          text-align: left;

          span, div{
            display: inline-block !important;
            font-size: 16px !important;
          }
        }

        &_speed, &_cost{
          &:before{
            display: none !important;
          }
        }
      }
    }
    @media (max-width: 490px){
      .th-col{
        padding-top: 0 !important;

        &_speed{
          font-size: 18px !important;
          justify-content: flex-start;
          .text-mobile{
            font-size: 18px !important;
            text-align: left;
          }
        }
        &_cost{
          padding-bottom: 0 !important;

          font-size: 16px !important;
          .text-mobile{
            font-size: 16px !important;
          }
        }
      }
    }
  }

  &_pi-as{
    .th-col{
      &_name{
        flex: 33% 0 0;
      }
      &_term{
        flex: 21.1% 0 0;
      }
      &_period{
        flex: 9.7% 0 0;
      }
      &_cost{
        flex: 13.5% 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &_connect{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }

    .tbody{
      .th-col{
        padding: 20px 0 !important;

        &_name, &_term, &_period{
          display: flex;
          justify-content: center;
          align-items: center;

          &:before{
            position: absolute;
            content: '';
            top: 5%;
            right: 0;
            width: 1px;
            height: 90%;
            display: block;
            background-color: #ebebeb;
          }
        }

        &_name{
          font-size: 24px;
        }
        &_term{
          font-size: 14px;
          line-height: 1.3;
        }
        &_cost{
          font-size: 14px !important;

          span{
            color: #5f5f5f !important;
            font-size: 14px !important;
            font-weight: 400;

            b{
              font-size: 24px;
            }
          }
        }
      }
    }

    @media (max-width: 1024px){
      .th-col{
        &_name{
          flex: 24% 0 0;
        }
        &_term{
          flex: 24% 0 0;
        }
        &_cost{
          flex: 15.5% 0 0;
        }
      }
    }
    @media (max-width: 680px){
      .tbody .trow .th-col .text-mobile{
        display: inline-block !important;
      }

      .th-col{
        &_name, &_term, &_period{
          &:before{
            display: none !important;
          }
        }

        &_name{
          order: 1;
        }
        &_period{
          display: none !important;
        }
        &_cost{
          order: 2;
        }
        &_connect{
          order: 3;
        }
        &_term{
          order: 4;
          flex: 100% 0 0 !important;
        }
      }
    }
    @media (max-width: 490px){
      .tbody .trow .th-col .text-mobile{
        display: inline-block !important;
      }
      .tbody .th-col{
        padding-top: 0 !important;
      }
      .th-col{
        &_name{
          font-size: 18px !important;
        }
        &_cost{
          flex: 35% 0 0 !important;
        }
        &_term{
          flex: 100% 0 0 !important;
          justify-content: flex-start !important;
          text-align: left !important;
        }
        &_connect{
          flex: 30% 0 0 !important;
        }
      }
    }
  }
}

table.requisite-table {
  border-collapse: collapse;
  width: 100%

  td {
    border: 1px solid grey;
    padding: 10px
  }
}

  .table-responsive {
    @media (max-width:480px) {
      overflow-x: auto;
    }
  }



