.alarm {
  border: 2px solid transparent;
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 10px;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  &_physics {
    border-color: #b1d237;

    p {
      text-align: center;
    }

    .a-button {
      margin: 0 auto 20px;
      display: block;
    }
  }
}
