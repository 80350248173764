.download-link {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  outline: none;

  &__icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 11px;
    background: url(/src/img/svg/download.svg) 0 0 no-repeat;

    &--svg {
      background: none;

      circle {
        fill: #B1D237;

        ~/:hover & {
          fill: #93B60D;
        }
      }
    }
  }

  &__link {
    text-decoration: underline;
    font-weight: 700;
  }

  &__desc {
    margin-left: 20px;
  }
}
