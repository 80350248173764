:root {
  --theme-color: #1c1c1c;
}

font-family-heading = 'Rubik', sans-serif;
$font-family-heading = 'Rubik', sans-serif;

font-family-content = 'Fira Sans', sans-serif;
$font-family-content = 'Fira Sans', sans-serif;

html,body{
  font-family: $font-family-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  font-style: normal;
  font-size: 17px !important;
  line-height: 170%;
  color: #535353 !important;
}

h1,h2,h3,h4,h5,h6{
  font-family: $font-family-heading;
  color: #202020 !important;
}

for val, color in mapColors{
  .theme-color-{val}{
    --theme-color: color;

    // h1,h2,h3,h4,h5,h6{
    //   color: color !important;
    //   font-family: $font-family-heading;
    // }
    ol{
      li{
        &:before{
          color: color !important;
        }
      }
    }
    .p_theme, .a_theme{
      color: color !important;
    }
    .ul-normal{
      li:before{
        color: color;
      }
    }
    .a-button{
      background-color: color !important;
      border: 1px solid color;

      &:hover{
        background-color: transparent !important;
        color: #ffffff;
      }

      &.a-button-reverse{
        background-color: transparent !important;
        border: 1px solid color;
        color: color;

        &:hover:not([disabled]){
          background-color: color !important;
          color: #fff;
        }
      }
      &.a-button-buy, &.a-button-add{
        &:hover{
          background-color: color - 30 !important;
        }
      }
    }
    .rColor{
      color: color !important;
    }

    .header{
      .phone-main{
        color: color !important;
      }
      &__email{
        color: color !important;
      }
      .log-in{
        border-color: color !important;

        svg{
          .path-poiner{
            fill: color !important;
          }
        }

        &:hover{
          background-color: color !important;

          if color == green{
            span{
              color: rgb(255, 255, 255) !important;
            }
            svg{
              .path-poiner{
                fill: rgb(255, 255, 255) !important;
              }
            }
          }
          else if color == yellow{
            span{
              color: black !important;
            }
            svg{
              .path-poiner{
                fill: black !important;
              }
            }
          } else {
            span{
              color: #fff !important;
            }
            svg{
              .path-poiner{
                fill: #fff !important;
              }
            }
          }
        }
      }
    }

    .infoblock{
      .info{
        &__header{
          .item-status{
            color: color !important;
          }
        }
      }
    }

    svg{
      .svg_theme-path{
        fill: color;
      }
    }


    .modal{
      &__header{
        color: color;
      }
      button{
        background-color: color;
      }
    }

    .pagination{
      a{
        &:hover{
          border: 1px solid (color+80);
        }

        &.active{
          border-color: color;
        }
      }

      &__next, &__prev{
        &:before, &:after{
          background-color: color;
        }
      }
    }

    .link{
      &__arrows{
        .arrows{
          svg{
            path{
              fill: color !important;
            }
          }
        }
      }

      &_color{
        color: color !important;
      }
    }

    .headline{
      color: color !important;
    }

    .services{
      .item{
        &__link{
          background-color: color;
        }
      }
    }

    //table
    .table{
      .switcher{
        .handler{
          background-color: color;
        }
      }

      .th-col{
        &_minutes{
          @media (max-width: 680px){
            color: color !important;
          }
        }

        &_theme{
          color: color;

          &-span{
            span{
              color: color;
            }
          }
        }
      }
    }

    //packet
    .packet{
      &--active{
        border-color: color !important;

        .packet__head{
          .svg{
            svg{
              path{
                fill: color !important;
              }
            }
          }
        }
      }
    }

    //packet form
    .packets-form{
      border-color: color !important;

      &__head{
        span{
          color: color !important;
        }
      }
      &__result{
        .res{
          color: color !important;
        }
        .res-cost{
          span{
            color: color !important;
          }
        }
      }
    }
  }
}

//  новые цвета
mapCardColors = {
    red: linear-gradient(143deg, rgb(106 3 28 / 90%), #C20A45, rgb(106 3 28 / 90%)),
    green: linear-gradient(143deg,#004c04,#007a33,#004c03),
    purple: linear-gradient(143deg,#330e05,#6331ae,#330e03),
    yellow: linear-gradient(143deg,#d53b01,#e5c82a,#da6a01),
}

for val, CardColors in mapCardColors {
  .theme-color-{val}{

    .services{
      .item{
        &__link{
          background: CardColors;
        }
      }
    }

    .a-button{
      background: CardColors !important;
      border: 1px solid transparent;
      border-radius: 6px;
      color: white;

      &:hover{
        background: transparent !important;
        border: 1px solid gray-black;
        color: gray-black;
      }

      &.a-button-reverse{
        background: transparent !important;

        &:hover:not([disabled]){
          background: CardColors !important;
        }
      }
      &.a-button-buy, &.a-button-add{
        &:hover{
          background: CardColors !important;
        }
      }
    }
  }
}

// color buttons

.a-button.a-button-red {
    background-color: #C20A45;
    background: linear-gradient(143deg, #6a0301, #C20A45, #6a0301) !important;

  &:hover{
    background: transparent !important;
    border: 1px solid #C20A45;
    color: #C20A45;
  }

  &.a-button-reverse{
    background: transparent !important;
    border: 1px solid #C20A45;
    color: #C20A45;
  }

}

.a-button.a-button-yellow {
    background-color: #e5c82a;
    background: linear-gradient(143deg,#d53b01,#e5c82a,#da6a01);
    border: 1px solid transparent;
    color: white;

  &:hover{
    background: transparent !important;
    border: 1px solid #d53b01;
    color: #d53b01;
  }

  &.a-button-reverse{
    background: transparent !important;
    border: 1px solid #d53b01;
    color: #d53b01;
  }
}

.a-button.a-button-purple {
    background-color: #6331ae;
    background: linear-gradient(143deg,#330e05,#6331ae,#330e03);
    border: 1px solid transparent;
    color: white;

  &:hover{
    background: transparent !important;
    border: 1px solid #6331ae;
    color: #6331ae;
  }

  &.a-button-reverse{
    background: transparent !important;
    border: 1px solid #6331ae;
    color: #6331ae;
  }
}

.a-button.a-button-green {
    background-color: #007a33;
    background: linear-gradient(143deg,#004c04,#007a33,#004c03);
    border: 1px solid transparent;
    color: white;

  &:hover{
    background: transparent !important;
    border: 1px solid #007a33;
    color: #007a33;
  }

  &.a-button-reverse{
    background: transparent !important;
    border: 1px solid #007a33;
    color: #007a33;
  }
}

body .button-green--lg {
  height: initial;
  padding: 15px 30px;
  font-size: 25px !important;
  line-height: 1.5;
  letter-spacing: .25px;
}


*{
  position: relative;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

//H
h1 {
    font-weight: 500;
    font-size: 42px;
    letter-spacing: .01em;
    line-height: 120%;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 60px;
}

h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: .01em;
    text-align: left;
    margin-bottom: 30px;
}

h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 34px;
  text-align: center;
}

h4{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 1.4;
}

@media (max-width: 425px){
  h1{
    font-size: 24px;
    line-height: 1;
    margin-bottom: 28px !important;
  }
  h2{
    font-size: 18px;
  }
  h4{
    font-size: 14px;
  }

  .headline{
    &_h2{
      font-size: 18px !important;
    }
  }
}

//tags
p{
  // line-height: 1.5;
  margin-bottom: 20px;
}

a{
  color: black;
}

ul{
  padding: 0;
}
li{
  list-style: none;
}

ol{
  list-style-type: none;
  counter-reset: num;

  li{
    margin-bottom: 30px;
    line-height: 1.4;
    color: black;
    padding-left: 30px;

    &:before{
      position: absolute;
      left: 0;
      top: 0;
      counter-increment: num;
      content: counter(num) '. ';
    }
  }

  &.ol-normal{
    &-padding{
      padding-left: 35px;
    }
  }

  @media (max-width: 425px){
    li{
      padding-left: 20px;
      margin-bottom: 20px;
    }
    &.ol-normal{
      &-padding{
        padding-left: 15px;
      }
    }
  }
}

input{
  border-radius: 6px;

  font-size: 17px;
  font-family: $font-family-content;

  color: black;
  border: 1px solid #c4c4c6;
  padding: 15px 16px;

  &.input_error{
    outline: 1px solid red;
  }

  &::placeholder{
    color: #7d7d7d;
  }

  &:focus {
    box-shadow: 0 0 1px 1px #89aae2;
    outline: none
  }

  &:disabled, &:disabled:hover {
    background-color: #ebebeb;
    cursor: not-allowed;
  }
}
button{
  border-radius: 6px;

  font-size: 17px;
  font-family: $font-family-content;

  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .2s;

  &:hover{
    background-color: transparent;
  }

  @media (max-width: 425px){
    font-size: 16px !important;
  }
}
textarea{
  border-radius: 6px;

  font-size: 17px;
  font-family: $font-family-content;

  border: 1px solid #c4c4c6;
  padding: 17px 16px;
  resize: none;
}

main{
  //margin: 50px 0 0;
  margin: 0;
}

@media (max-width: 425px){
  main{
    //margin-top: 20px;
    margin-top: 0;
  }

  p,li,a, .clarification{
    font-size: 14px;
  }
  input, textarea{
    font-size: 14px !important;
    padding: 14px 16px;
  }
  button{
    padding: 13px 0;
  }
}

/*
styles
*/

.tac{
  //text-align: left;
}
.db{
  display: block;
}
.div-center{
  display: block;
  margin: 20px auto;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-bold{
  font-weight: 700;
}
.text-500 {
  font-weight: 500;
}

.list-dot {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;
  line-height: 1.7;

  &:last-child {
    margin-bottom: 0;
  }

  li {
    padding: 0 0 0 13px;
    position: relative;
  }

  li + li {
    margin-top: 5px;
  }

  li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='30' viewBox='0 0 3 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.5' cy='15.2363' r='1.5' fill='%23535353'/%3E%3C/svg%3E%0A");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 3px;
    height: 1.5em;
  }
}

.m-lr-auto{
  margin-left: auto;
  margin-right: auto;
}

.fs-14 {
  font-size: 14px !important
}

//padding mobile
.pm{
  @media (max-width: 700px){
    &t-0{
      padding-top: 0 !important;
    }
  }
}

.clearfix{
  &:after{
    display: table;
    content: '';
    clear: both;
  }
}

//headlines
.headline{
  text-align: center;
  color: black;
  display: block;

  &_h1{
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  &_h2{
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 80px;
  }

  &_h3{
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 40px;
  }
}


.not-theme{
  color: black !important;
}

.column-count{
  &_2{
    column-count: 2;

    @media (max-width: 650px){
      column-count: 1;
    }
  }

  &_3{
    column-count: 3;

    +r(650) {
      column-count: 1;
    }
  }

  &_4{
    column-count: 4;

    +r(1024) {
      column-count: 3;
    }

    +r(650) {
      column-count: 1;
    }
  }
}
.column-gap{
  &_50{
    column-gap: 50px !important;

    @media (max-width: 650px){
      column-gap: 0 !important;
    }
  }
}

.big__text{
  font-size: 36px;
  font-weight: 700;

  @media (max-width: 425px){
    font-size: 26px;
  }
}
.small-text{
  font-size: 12px;
}



.container{
  width: 100%;
  //max-width: 1200px;
  margin: 0 auto;
  //padding: 0 15px;

  max-width: 1440px;
  padding: 0 55px;
  @media(max-width:480px) {
    padding: 0 35px;
  }
  &_top{
    @media (max-width: 640px){
      padding: 0
    }
  }
}

.container-2 {
  width: 100%;
  max-width: 1440px;
  padding: 0 55px;
  margin: 0 auto;

  @media (max-width: 1279px) {
    padding: 0 30px;
  }
}

.block{
  width: 100%;
  background-color: gray;
  padding: 60px 0;

  &_services-corp{
    padding: 54px 15px 230px;
  }
}

.adaptation{
  display: none;
}

.ul-normal{
  list-style: circle;
  margin-bottom: 25px;
  line-height: 1.5;

  li{
    margin-bottom: 10px;
    padding-left: 17px;

    &:before{
      color: color;
      content: '●';
      position: absolute;
      left: 0;
      top: 0px;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  @media (max-width: 425px){
    li{
      margin-bottom: 10px;
    }
  }

  &_numeric{
    li{
      list-style-type: decimal;

      &:before{
        display: none;
      }
    }
  }

  &-default_li{
    li{
      &:before{
        content: '•';
        color: #fff !important;
        top: 1px;
      }
    }
  }
}
.ul-near-li{
  li{
    margin-bottom: 10px;
  }
}

.center-link{
  display: flex;
  justify-content: center;
  align-items: center;

  a{
    color: black;
    cursor: pointer;
  }
}

//elements
.mini-element{
  &_plus{
    svg{
      width: 20px;
      height: 20px;
    }
  }
}
.link{
  text-decoration: none;
  display: block;
  margin-bottom: 5px;

  &_color{
    color: red;
  }
}
.a-btn{
  text-decoration: none;
}
.a-button {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background-color: red;
  text-decoration: none;
  transition: all .3s;
  width: 223px;
  text-align: center;
  line-height: 1.25;
  padding: 11px 20px 13px;

  &:focus {
    box-shadow: 0 0 1px 1px #89aae2;
    outline: none
  }

  &:disabled, &:disabled:hover {
    background-color: #c7c7c7 !important;
    border-color: #c7c7c7 !important;
    color: #fff !important;
    cursor: not-allowed;
  }

  &--width-auto {
    width: auto;
  }

  &.a-button-center{
    display: block;
    margin: 0 auto;
  }
  &.a-button-100{
    width: 100%;
  }
  &.a-button-sm{
    @media (max-width: 425px){
      width: 100%;
      height: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.a-button-buy, &.a-button-add, &.a-button-remove {
    width: 77px;
    height: 44px;
    background-color: red;

    &:hover {
      background-color: red - 30 !important;
    }

    &:before{
      display: block;
      content: '';
      position: absolute;
    }
  }
  &.a-button-buy{
    &:before{
      top: calc(50% - 13px);
      left: calc(80% - 39px);
      width: 29px;
      height: 26px;
      background-image: url(/src/img/elements/cart_button.png);
    }
  }
  &.a-button-add{
    &:before{
      top: calc(50% - 10px);
      left: calc(80% - 33px);
      width: 20px;
      height: 20px;
      background-image: url(/src/img/svg/plus.svg);
    }
  }

  &.a-button-processing{
    text-indent: -999999px;
    pointer-events: none;

    &:after{
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background-image: url(/src/img/svg/loader.svg);
      background-size: 20px 20px;
    }
  }

  &.a-link{
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  &--padding{
    padding-right: 40px;
    padding-left: 40px;
    width: auto;
  }
}

.input{
  &-100{
    width: 100%;
  }
}

//background
.bgc-{
  &red{
    background-color: red !important;
    background: linear-gradient(143deg, #6a0301, #C20A45, #6a0301);
  }
  &green{
    background-color: green !important;
    background: linear-gradient(143deg, rgb(0 76 92 / 60%), #007a33, rgb(0 76 92 / 90%));
  }
  &purple{
    background-color: purple !important;
    background: linear-gradient(143deg, rgb(51 14 111 / 60%), #6331AE, rgb(51 14 111 / 90%));
  }
  &yellow{
    background-color: yellow !important;
    background: linear-gradient(143deg, rgb(213 59 27 / 90%) , #E5C82A, rgb(218 106 38 / 90%));
  }
}

//color fonts
.color-{
  &red{
    color: red !important;
  }
  &green{
    color: green !important;
  }
  &purple{
    color: purple !important;
  }
  &yellow{
    color: yellow !important;
  }
  &body&body {
    color: #1c1c1c !important;
  }
}
.f-{
  &white{
    color: #fff !important;
  }
  &gray{
    color: #505050 !important;
  }
}

//li-style-color
.ul-normal{
  .li-style-color-{
    &red:before{
      color: red !important;
    }
    &green:before{
      color: green !important;
    }
    &purple:before{
      color: purple !important;
    }
    &yellow:before{
      color: yellow !important;
    }
  }
}


//flex elements
.flex-div{
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.flex-div-acenter {
  align-items: center;
  justify-content: start;
}

.flex_inner-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-block{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 50px;

  &_3{
    .item{
      flex: 300px 0 0;
    }

    @media (max-width: 850px){
      flex-wrap: wrap;

      .item{
        margin-bottom: 30px;
      }
    }
  }

  &.flow--img-p{
    img{
      width: 240px;
      height: 240px;
      border-radius: 100%;
      margin-bottom: 30px;
    }

    p{
      text-align: center;
    }
  }
}

.msg-form-error{
  color: red;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  visibility: hidden;
}

//banners
.banner{
  height: 540px;
  overflow: hidden;

  .appeal{
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 28px;
    display: block;

    &_small{
      font-size: 30px;
      line-height: 1.6;
    }
  }

  .ban-container {
    height: 100%;

    .banner_ul{
      margin-top: 50px;

      li{
        font-size: 24px;
        padding-left: 22px;
        line-height: 1;
      }
    }
  }

  @media (max-width: 768px){
    height: 470px;
  }

  @media (max-width: 600px){
    height: 456px;
  }

  &_sokol{
    background-color: #F0ECE0;

    .ban-container{
      padding: 84px 70px 0;

      & > *{
        z-index: 2;
      }
    }

    .big-text{
      font-size: 45px
      font-weight: 700
      line-height: 52px
    }
    .middle-text{
      font-size: 30px
      line-height: 45px
      color: #49575E
      padding: 25px 0 54px
    }

    img{
      position: absolute;
      top: 0;
      right: -85px;
      z-index: 1 !important;
      filter: grayscale(1);
      animation: grayToColor 5s forwards 1s;
    }

    @media (max-width: 700px){
      .ban-container{
        padding: 25px 20px 0;
      }

      img{
        width: 600px;
        top: auto;
        bottom: 0;
      }
    }

    @media (max-width: 425px){
      .big-text{
        font-size: 28px;
        line-height: 40px;
      }
      .middle-text{
        font-size: 24px;
        line-height: 33px;
        padding-bottom: 150px;
      }

      img{
        opacity: .6;
      }
    }
  }

  &_corp-internet{
    background: #fff url(img/elements/banner_corp-internet.webp) 50% 50%/cover no-repeat;
    padding: 67px 0 34px;

    img{
      margin-bottom: 212px;
    }

    .note{
      color: black;
      width: 314px;
      line-height: 24px;
    }

    @media (max-width: 1100px){
      text-align: center;
      .appeal{
        text-align: center;
        font-size: 26px;
        margin-top: -30px;
      }
      img{
        margin-top: 280px;
        margin-bottom: 10px;
        width: 321px;
      }
      .note{
        margin: 0 auto;
        font-size: 12px;
      }
    }

    @media (max-width: 768px){
      img{
        margin-top: 200px;
      }
    }

    @media (max-width: 425px){
      .appeal{
        font-size: 20px;
      }
      img{
        margin-top: 240px;
        width: 290px;
      }
      .note{
        width: auto;
        line-height: 1;
      }
    }

  }

  &_corp-telephony{
    background: #fff url(img/elements/banner_corp-telephony.webp) 50% 50%/cover no-repeat;
    padding: 60px 0;

    .appeal{
      margin-bottom: 60px;
    }

    img{
      margin-bottom: 65px;
    }

    .note{
      color: #505050;
    }

    @media (max-width: 1023px){
      background-position: 80% 50%;

      .appeal, .note{
        text-align: center;
      }
      .appeal{
        margin-bottom: 270px;
      }
      .note{
        color: #fff;
      }
      img{
        display: block;
        margin: 0 auto;
        width: 350px;
      }
    }
    @media (max-width: 768px){
      padding: 20px 0;

      .appeal{
        font-size: 26px;
        width: 265px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 225px;
      }
      .note{
        width: 365px;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
      }
    }
    @media (max-width: 530px){
      background-position: 72% 50%;
    }
    @media (max-width: 425px){
      background-position: 72% 50%;

      img{
        width: 287px;
      }
      .note{
        width: 293px;
      }
      .appeal{
        margin-bottom: 240px;
      }
    }
  }

  &_vpnoffice {
    background: #fff url(img/elements/banner_vpnoffice.webp) 50% 50%/auto 100% no-repeat;
    padding: 80px 0;

    .no-webp & {
      background-image: url(img/elements/banner_vpnoffice.jpg);
    }

    @media (max-width: 1200px) {
      padding: 60px 0;
      height: auto;
    }

    @media (max-width: 768px) {
      padding: 20px 0;
    }

    .banner__heading {
      font-weight: bold;
      font-size: 45px;
      line-height: 1;
      color: #c20a45;
      margin-bottom: 1em;

      @media (max-width: 1200px) {
        font-size 36px;
      }

      @media (max-width: 768px) {
        font-size 28px;
        text-shadow: 1px 1px 0 #fff, 1px 0 0 #fff;
      }
    }

    .banner__list {
      font-size: 30px;
      line-height: 1.83;
      color: #49575E;
      margin-bottom: 2.5em;

      @media (max-width: 1200px) {
        font-size 24px;
      }

      @media (max-width: 768px) {
        font-size 20px;
        text-shadow: 1px 1px 0 #fff, 1px 0 0 #fff;
      }

      li {
        padding-left: 1.4em;
        margin-bottom: 0;
      }
    }

    .banner__button {
      @media (min-width: 1201px) {
        min-width: 280px;
      }
    }
  }

  &_action_tv {
    color: #fff;
    background: #ED2058;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    a.container {
      text-decoration: none;
      color: inherit;
      outline: none;
    }

    @media (min-width: 601px) and (max-width: 1024px) {
      .container {
         justify-content: center;
       }
    }

    .banner__img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;

      @media (max-width: 600px) {
        &--desktop {
          display: none;
        }
      }

      @media (min-width: 601px) {
        &--mobile {
          display: none;
        }
      }
    }

    .banner__disclaimer {
      font-weight: 600;
      font-size: 8px;
      line-height: 1.5;
      text-align: center;
      margin-top: 30px;
      padding: 0 0 10px;

      @media (max-width: 768px) {
        font-size: 6px;
      }

      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  &_action_skidka-50 {
    color: #fff;
    background: #1B0326;

    @media (max-width: 425px){
      height: 547px;
    }

    .banner__img {
      position: absolute;

      &--fry {
        top: 20px;
        z-index: 1;

        @media (max-width: 768px){
          width: 430px;
        }

        @media (max-width: 425px){
          width: 313px;
          top: 8px;
          position: relative;
          margin: 0 auto;
          display: block;
        }
      }

      &--fire50 {
        top: -210px;
        right: -75px;

        @media (max-width: 1100px){
          right: -170px;
          opacity: .7;
        }

        @media (max-width: 425px){
          width: 480px;
          opacity: 1;
          top: 45px;
          right: 0;
        }
      }
    }

    .banner__content {
      position: absolute;
      bottom: 83px;
      left: 12px;

      @media (max-width: 425px){
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 300px;
      }
    }

    .banner__bigtext {
      color: #FFC90A;
      font-weight: 700;
      font-size: 42px;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media (max-width: 425px){
        font-size: 28px;
        margin-bottom: 10px;
      }
    }

    .banner__text {
      font-size: 24px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      line-height: 150%;
      font-weight: 600;

      @media (max-width: 425px){
        font-size: 16px;
      }
    }
  }

  &_phiz-internet{
    background: #fff url(img/elements/banner_phiz-internet.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 110px;
    }

    @media (max-width: 670px){
      .ban-container{
        padding-top: 20px;
      }
    }
    @media (max-width: 560px){
      background-position: 67% 50%;

      .ban-container{
        padding-top: 5px;

        .banner_ul{
          display: none;
        }
        .appeal{
          text-align: center;
          font-size: 26px;
        }
      }
    }
    @media (max-width: 425px){
      background-position: 63% 50%;
    }
  }

  &_phiz-telephony{
    background: #fff url(img/elements/banner_phiz-telephony.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 106px;

      .banner_ul{
        margin-top: 80px;
      }
    }

    @media (max-width: 1040px){
      background: #fff url(img/elements/banner_phiz-telephony_small.webp) 50% 50%/cover no-repeat;

      .ban-container{
        padding-top: 25px;
      }
    }
    @media (max-width: 810px){
      background-position: 0 50%;

      .appeal{
        text-align: center;
      }
      .banner_ul{
        display: none;
      }
    }
    @media (max-width: 758px){
      background-position: 50% 50%;

      .appeal{
        font-size: 26px;
      }
    }
  }
  &_phiz-television{
    background: #fff url(img/elements/banner_phiz-television.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 60px;

      .appeal{
        margin-bottom: 42px;
      }
    }

    @media (max-width: 1000px){
      background-position: 55% 50%;

      .appeal{
        text-align: center;

        &_small{
          display: none;
        }
      }
    }

    @media (max-width: 768px){
      .appeal{
        font-size: 26px;
      }
    }
    @media (max-width: 768px){
      .ban-container{
        padding-top: 25px;
      }
    }
  }
  &_phiz-europa{
    background: #fff url(img/elements/banner_phiz-europa.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 73px;

      .appeal{
        text-align: center;
        line-height: 1.3;
      }
      .ul-normal{
        width: 444px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 880px){
      .ban-container{
        padding-top: 30px;
      }
    }
    @media (max-width: 768px){
      .ban-container{
        padding-top: 30px;

        .ul-normal{
          margin-top: 25px;
        }
      }
    }
    @media (max-width: 624px){
      .ban-container{
        padding-top: 30px;

        .appeal{
          font-size: 24px;
        }
        .ul-normal{
          width: 333px;

          li{
            font-size: 18px;
          }
        }
      }
    }
    @media (max-width: 360px){
      .ban-container{
        padding-top: 30px;

        .appeal{
          font-size: 22px;
        }
        .ul-normal{
          width: 320px;
        }
      }
    }
  }

  &_realty-builder{
    background: #fff url(img/elements/banner_realty-builder.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 74px;

      .appeal{
        color: #fff;
      }

      .banner_ul{
        margin-top: 80px;

        li{
          color: #fff;

          &:before{
            content: '•';
            color: #fff !important;
            top: 1px;
          }
        }
      }
    }

    @media (max-width: 768px){
      background-position: 78% 50%;

      .ban-container{
        padding-top: 45px;

        .appeal{
          font-size: 26px;
          text-align: center;
        }

        .banner_ul{
          display: none;
        }
      }
    }
    @media (max-width: 425px){
      background-position: 70% 50%;
    }
  }
  &_realty-installation{
    background: #fff url(img/elements/banner_realty-installation.webp) 50% 50%/cover no-repeat;

    .ban-container{
      padding-top: 80px;
      padding-left: 27%;
      padding-right: 0;

      .appeal{
        color: #fff;
        line-height: 1.3;
      }

      .banner_ul{
        margin-top: 50px;

        li{
          color: #fff;

          &:before{
            content: '•';
            color: #fff !important;
            top: 1px;
          }
        }
      }
    }

    @media (max-width: 768px){
      background: #fff url(img/elements/banner_realty-installation-small.webp) 50% 50%/cover no-repeat;

      .ban-container{
        padding-top: 45px;
        padding-left: 0;

        .appeal{
          text-align: center;
          line-height: 1;
          font-size: 26px;
        }

        .banner_ul{
          display: none;
        }
      }
    }
  }


  &_vacancy{
    background: #fff url(img/elements/banner_vacancy.webp) 50% 50%/cover no-repeat;

    .big__text{
      text-align: center;
      top: 110px;
    }

    @media (max-width: 600px){
      .big__text{
        top: 60px;
      }
    }
    @media (max-width: 425px){
      .big__text{
        width: 200px;
        margin: 0 auto;
      }
    }
  }
}

.no-webp .banner{
  &_corp-internet{ background-image: url(img/elements/banner_corp-internet.png);}
  &_corp-telephony{ background-image: url(img/elements/banner_corp-telephony.png);}
  &_phiz-internet{ background-image: url(img/elements/banner_phiz-internet.png);}
  &_phiz-telephony{
    background-image: url(img/elements/banner_phiz-telephony.png);
    @media (max-width: 1040px){  background-image: url(img/elements/banner_phiz-telephony_small.png);  }
  }
  &_phiz-television{ background-image: url(img/elements/banner_phiz-television.png);}
  &_phiz-europa{ background-image: url(img/elements/banner_phiz-europa.png);}
  &_realty-builder{ background-image: url(img/elements/banner_realty-builder.png);}
  &_realty-installation{
    background-image: url(img/elements/banner_realty-installation.png);
    @media (max-width: 768px){  background-image: url(img/elements/banner_realty-installation-small.png);  }
  }
  &_vacancy{ background-image: url(img/elements/banner_vacancy.png);}
}

//claim
.claim{
  background-color: #fff;
  box-shadow: shadow;
  padding: 59px 65px 62px 59px;
  margin-bottom: 59px;

  &__form-data{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .cont{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      flex: 305px 0 1;

      input{
        max-width: 200px;
      }

      label{
        color: black;

        i{
          color: red;
          font-style: normal;
        }
      }

      &_address{
        flex: 100% 1 1;
        margin-top: 20px;
        margin-bottom: 35px;

        label{
          margin-right: 51px;
        }

        textarea{
          height: 60px !important;
          resize: none;
          flex: auto;
        }
      }
    }
  }

  &__parameters{

    .parameters__data{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-bottom: 42px;
      margin-top: 39px;

      .bl1, .bl2{
        .cont {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
        }
      }
      .bl1{
        flex: 380px 0 0;

        .cont{
          input{
            width: 120px;
          }

          &:nth-child(1){
            margin-top: 21px;
          }

          &:nth-child(2){
            margin-top: 66px;
          }
        }
      }
      .bl2{
        flex: 550px 0 0;
        margin-top: 13px;

        .cont{
          label{
            align-self: flex-start;
            margin-top: 8px;
          }

          textarea{
            flex: 393px 0 1;
            height: 143px;
          }
        }
      }
    }
  }
}

.link__arrows{
  text-align: center;

  a{
    text-decoration: none;
    outline: none;

    span{
      text-decoration: underline;
    }

    .arrows{
      display: inline-block;
      top: 3px;

      i{
        svg{
          transition: all .5s;
        }

        &:last-child{
          right: 7px;
        }
      }

      svg{
        width: 7px;
        height: 13px;
      }
    }
  }

  &_14{
    span{
      font-size: 14px;
    }

    .arrows{
      top: 2px !important;

      svg{
        width: 6px !important;
        height: 10px !important;
      }
    }
  }

  &:hover{
    .arrows{
      i{
        &:first-child{
          svg{
            transform: translateX(2px);
          }
        }
        &:last-child{
          svg{
            transform: translateX(6px);
          }
        }
      }
    }
  }
}

.plashka_mini{
  text-align: center;

  .plashka__head{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    text-align: center
    margin-bottom: 37px;

    svg{
      width: auto;
      height: 24px;
    }

    p{
      margin-left: 10px;
      font-size: 18px;
      margin-bottom: 1px;
    }
  }


  @media (max-width: 1080px){
      margin-bottom: 30px;
  }

  @media (max-width: 570px){
    margin-bottom: 20px;

    .plashka__head{
      margin-bottom: 20px;
    }
  }
}

//contacts

.office {
  .office-icon {
    width: 44px;
    height: 44px;
    display:block;
  }
  .office-icon.address{
    width: 32px;
    height: 32px;
    display:block;
    left: 8px;
  }
  .font-30 {
    font-size : 30px;
  }
  .flex-div-item {
    &1,&2{
      width:50%;
    }
  }
  .office-block-icon {
    width: 52px;
    min-width: 52px;
    height: 52px;
  }
    .office-block-content {
    }
    .office-block-content p {
      margin-bottom: 2px;
    }

  @media (max-width: 640px){
    .flex-div {
      flex-flow :column;
    }
    .flex-div-item {
      &1,&2{
        width:100%;
      }
    }
    .support {
      margin-top : 30px;
      font-weight : bold;
    }
    .support p,
    .support a {
      margin-bottom : 4px !important;
      font-size : 18px !important;
      text-align : center;
    }
  }

}


.contacts-list{
  flex-flow: row wrap;

  .item{
    flex: 25% 0 0;
    text-align: center;

    &__head{
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      text-align: center
      margin-bottom: 37px;

      svg{
        width: auto;
        height: 24px;
      }

      p{
        margin-left: 10px;
        font-size: 18px;
        margin-bottom: 1px;
      }
    }
  }

  @media (max-width: 1080px){

    .item{
      flex: 50% 0 0;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 570px){

    .item{
      flex: 100% 0 0;
      margin-bottom: 20px;

      &__head{
        margin-bottom: 20px;
      }
    }
  }

  &_2{
    justify-content: space-around;

    .plashka_mini{
      flex: 320px 0 0;

      .plashka__head{
        margin-bottom: 23px;

        p{
          font-weight: 600;
        }
      }

    }
  }
}

//pagination
.pagination{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &__pages{
    display: flex;
    flex-flow: row nowrap;

    a{
      display: inline-block;
      padding: 6px 9px;
      cursor: pointer;
      border: 1px solid transparent;
      text-decoration: none;

      &:hover{
        border: 1px solid (red+180);
      }

      &.active{
        border: 1px solid red;
      }
    }
  }

  &__prev, &__next{
    width: 7px;
    height: 12px;

    &:hover{
      border: none !important;
    }

    &:before,&:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      width: 5px;
      height: 1px;
      background-color: red;
    }
  }

  &__prev{
    margin-right: 10px;

    &:before,&:after{
      transform-origin: left center;
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }
  }

  &__next{
    margin-left: 10px;

    &:before,&:after{
      transform-origin: right center;
    }
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }
  }
}

.maybeinteresting{
  margin: 0 auto;
  width: 300px;
  margin-top: -111px;
  margin-bottom: -44px;
  z-index: 3;

  h2{
    color: gray-black !important;
  }

  .svg{
    margin: 0 auto;
    margin-top: 25px;
    width: 99px;

    svg{
      width: 98px;
      height: 103px;
      transform: rotate(90deg);
    }
  }

  @media (max-width: 1200px){
    background-color: #f5f5f5;
    margin: 0;
    width: auto;
    padding-top: 50px;

    h2{
      text-align: center;
    }
  }
}

.faq-block{
  .question{
    .headline{
      text-align: left;
      border-bottom: 1px dotted #93b60d;
      display: inline-block;
      line-height: 1.8;
      margin-bottom: 18px;
    }

    .link__arrows{
      text-align: left;

      a{
        line-height: 1.5;

        span{
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 768px){
    .question{
      .headline{
        border: none;
        line-height: 1;
      }
    }
  }
}

.payment{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 50px;

  .item{
    flex: 31% 0 0;
    border: border;
    margin-bottom: 30px;
    cursor: pointer;

    &:hover{
      .head{
        background-color: green;
        color: #fff;
      }
    }

    .info{
      display: none;
    }

    .head{
      padding-top: 18px;
      padding-bottom: 14px;
      color: green;
      font-size: 18px;
      transition: all .2s;
      text-align: center;
      cursor: pointer;
    }

    .picture{
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        max-height: 82px;
      }
    }

    &_active{
      .head{
        background-color: green;
        color: #fff;
      }
    }
  }

  @media (max-width: 900px){
    .item{
      flex: 48% 0 0;
    }
  }
  @media (max-width: 580px){
    .item{
      flex: 100% 0 0;
    }
  }
  @media (max-width: 425px){
    .item{
      margin-bottom: 5px;

      &:hover{
        .head{
          background-color: #fff;
          color: #5c5c5c;
        }
      }

      .head{
        text-align: left;
        color: #5c5c5c;
        padding: 13px;

        &:before{
          content: '';
          position: absolute;
          top: calc(50% - 5px);
          right: 16px;
          display: block;
          width: 10px;
          height: 10px;
          background: url(img/svg/arrow_mobile.svg) no-repeat;
          transform: rotate(-90deg);
        }
      }

      .info{
        display: block;
        padding: 15px;

        ol{
          padding-left: 0;
        }

        .instruction{
          .item{
            border: none;

            p{
              i{
                color: green;
                font-style: normal;
                margin-right: 5px;
              }
            }
            img{
              width: calc(100% + 30px);
              margin-left: -15px;
            }
          }
        }
      }

      .content{
        display: none;
      }

      &_active{
        .head{
          background-color: #fff;
          transition: all .5s;

          &:before{
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &_detail{
    .arrow-payment{
      text-align: center;
      margin-bottom: 20px;

      svg{
        width: 57px;
        height: 65px;
        transform: rotate(90deg);
      }
    }

    .details{
      border: 1px solid green;
      padding: 30px 35px;

      .instruction{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 25px;

        .item{
          flex: 47% 0 0;
          margin-bottom: 20px;

          p{
            margin-bottom: 10px;
            height: 45px;

            i{
              color: green;
              margin-right: 12px;
              font-style normal;
            }
          }

          img{
            height: 350px;
            max-width: 100%;
            width: auto;
          }
        }
      }

      .whiteback{
        width: 90%;
        background-color: #fff;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 5%;
        right: 5%;
      }
    }

    @media (max-width: 1200px){
      .details{
        .instruction{
          .item{
            flex: 100% 0 0;
            margin-bottom: 40px;

            p{
              height: auto;
            }

            img{
              display: block;
              height: auto;
              margin: 0 auto;
            }
          }
        }
      }
    }
    @media (max-width: 1024px){
      .details{
        padding: 20px 15px;

        .text{
          ol{
            padding-left: 0;
          }
        }
      }
    }
    @media (max-width: 425px){
      display: none;
    }
  }
}

.flow{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &--img-h-p{
    .item{
      flex: 24% 0 0;

      img{
        width: 231px;
        height: 231px;
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
      }

      .headline{
        margin-bottom: 20px;
        height: 36px;
      }
    }

    @media (max-width: 1180px){
      flex-flow: row wrap;

      .item{
        flex: 45% 0 0;
        margin-bottom: 30px;
      }
    }
    @media (max-width: 600px){
      .item{
        flex: 100% 0 0;
      }
    }
    @media (max-width: 425px){
      .item{
        img{
          width: 180px;
          height: 180px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.div-ul{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &_2{
    .item{
      flex: 48% 0 0;
    }

    @media (max-width: 768px){
      .item{
        flex: 100% 0 0;
      }
    }
  }
}

.nodes{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 55px;

  .left-side, .right-side{
    flex: 38.5% 0 0;
    height: 325px;

    border: 1px solid yellow;

    .list{
      position: absolute;
      background-color: #f5f5f5;
      top: -1px;
      bottom: -1px;
      left: 3%;
      right: 3%;
      padding: 15px 10px;
      display: none;
      align-items: center;

      li{
        margin-bottom: 14px;
      }
    }
  }

  .center-side{
    display: none;

    .slider{
      .slide{
        display: none;
      }
    }
  }

  .switcher{
    flex: 175px 0 0;
    margin-top: 14px;
    z-index: 3;
    height: 75px;

    .variant{
      text-align: center;
      font-size: 18px;
      padding: 7px 0;
      margin: 3px 0;
      cursor: pointer;
      transition: all .5s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &_active{
        border: 1px solid yellow;
        border-radius: 6px;
        transform: translateY(36px);
      }
    }
  }

  &_moscow{
    .left-side, .right-side{
      .list{
        &_moscow{
          display: flex;
        }
      }
    }
  }
  &_spb{
    .left-side, .right-side{
      .list{
        &_spb{
          display: flex;
        }
      }
    }
  }

  img{
    position: absolute;
    bottom: 0;
    left: calc(50% - 120px);
  }

  @media (max-width: 1200px){
    flex-flow: column nowrap;
    margin-top: 0;

    .left-side, .right-side{
      display: none;
    }

    .switcher{
      flex: 75px 0 0;
      width: 175px;
      z-index: 3;
      height: auto;
      background-color: #f5f5f5;
    }

    .center-side{
      display: block;
      border: 1px solid yellow;
      margin-top: -20px;
      height: 440px;
      width: 340px;
      z-index: 2;
      background-color: #f5f5f5;

      .list{
        padding: 30px 20px;
        position: absolute;
        top: -2px;
        left: 8%;
        right: 8%;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        bottom: 0;

        li{
          line-height: 1.9;
          font-size: 13px;
        }
      }
    }

    img{
      position: relative;
      z-index: 1;
      left: auto;
      margin-top: -87px;
    }
  }
  @media (max-width: 480px){
    img{
      display: none;
    }
  }
}

//Arrow to top page
.page-up{
  border-radius: 6px;
  border: 1px solid #5F5F5F;
  overflow: hidden;

  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 999;
  cursor: pointer;
  opacity: 0;

  svg{
    width: 40px;
    height: 40px;
    position relative;
    left: -1px;
    top: -1px;

    path{
      transition: all .5s;
    }
  }

  &:hover{
    svg{
      .page-up__arrow{
        &_1{
          transform: translateY(-5px);
        }
        &_2{
          transform: translateY(-3px);
        }
      }
    }
  }

  @media (max-width: 425px){
    display: none;
  }
}

.city-map-bg {
  background: #fff url(/src/img/city-map-bg.webp) 50% 50%/cover no-repeat;
}
.no-webp .city-map-bg {
  background-image: url(/src/img/city-map-bg.jpg);
}


.ui-autocomplete {
  background: #fff;
  border: 1px solid #c4c4c6;
  z-index: 100;
  width: 200px; // default for plugin
  max-height: 250px;
  overflow: auto;
}

.ui-menu-item {
  padding: 8px 16px;
  cursor: pointer;

  &--active, &:hover {
    background-color: #ddd;
  }
}


.processing {
  position: relative;
  user-select: none;
  pointer-events: none;
}

.processing::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255,255,255,0.5);
  z-index: 9999;
}
.processing::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  margin-top: -23px;
  border-radius: 50%;
  border: 7px solid;
  border-color: mapColors['red'] mapColors['purple'] mapColors['green'] mapColors['yellow'];
  animation: rotation 1s linear infinite;
  z-index: 10000;
}
.processing--sm::after {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  border-width: 3px;
}
.processing--right::after {
  left: auto;
  right: 15px;
  margin-left: 0;
}

.local-jivosite-btn {
  position: fixed !important;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0 19px 38px 0 rgba(34, 36, 43, 0.3);
  background: #505050;

  &::before {
    content: '';
    display: block;
    width: 35px;
    height: 31px;
    margin: 17px 0 0 15px;
    background-position: 2px 0;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2230%22%20height%3D%2231%22%20viewBox%3D%220%200%2030%2031%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M2.967%2022.226l-.025.008s7.698%2013.9%2026.975%205.546c0%200-1.495-1.752-4.384-3.52a14.067%2014.067%200%200%200%202.588-14.047c-2.655-7.297-10.7-11.07-17.964-8.425C2.89%204.433-.847%2012.492%201.81%2019.79c.313.863.703%201.677%201.157%202.436z%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E%0A");
  }

  &.processing::before {
    background: none;
  }
}

@-webkit-keyframes rotation {
  100% {
    transform: rotate(360deg)
  }
}
@keyframes rotation {
  100% {
    transform: rotate(360deg)
  }
}

hr
  display block
  width 100%
  height 1px
  border 0
  background #B6B6B6

  /.block &
    background-color #fff

.img-responsive
  display block
  max-width 100%
  height auto

  &--center
    margin-left auto
    margin-right auto


.lh-expanded
  line-height 1.875


.inlay-block {
  display: flex;
  align-items center;
  justify-content space-between;
  flex-wrap: wrap;

  &__text {
    padding: 10px 20px 10px 0;
    flex: 0 1 690px;
    line-height: 1.5;
  }

  &__button {
    padding: 10px 0;
    flex: 0 0 280px;
  }
}

.simple-table {
  border-collapse: collapse;

  th, td {
    padding: 4px 6px;
    border: 1px solid #ddd;
  }
}
