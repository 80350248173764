.physical-internet{
    background: url(/src/img/pages/BG_internet.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;
        padding: 0 55px;
    }

    .heading{
        padding-top: 175px;
        margin-bottom: 20px;

        h1{
            margin-bottom: 0;
            padding-top: 0;
            font-h1(52px, font-color-light);
        }

        @media(max-width: 480px){
            padding-top:300px !important;
        }
    }

    &__content{
        max-width: 658px;

        padding-bottom: 45px;
        border-bottom: solid 1px rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        p{
            margin-bottom: 0;

            font-p-300(17px, font-color-light);
        }
    }

    li{
        position relative;

        display: flex;
        align-items: center;

        &:before{
            content: '';
            height: 3px;
            width: 3px;
            background: rgba(245, 241, 248, 0.45);
            border-radius: 50%;
            margin-right: 10px;

            position: relative;
            top: -1px;
        }

        font-p-300(17px, font-color-light);
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/BG_internet_mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 783px;

        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 0 30px;
        }


        .heading{
            padding-top: 357px;

            h1{
                text-align: left;
                font-size: 30px;
            }
        }

        &__content{
            max-width: 100%;

            padding-bottom: 40px;
            border-bottom: solid 1px rgba(245, 241, 248, 0.45);
            margin-bottom: 25px;
        }

        ul{
            margin-bottom: 80px;
        }
    }
}

.physical-internet-rates{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 240px;
    }

    .heading{
        margin-bottom: 25px;

        h2{
            font-family: 'Rubik',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        margin-bottom: 110px;
        max-width: 927px;

        p{
            margin-bottom: 0;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    &__wrap{
        position: static;
    }

    &__block{
        display: flex;
        gap: 30px;

        position: static;
    }

    &__card{
        width: 424px;

        display: flex;
        flex-direction: column;
        align-items: center;

        border: 1px solid #F8F8F8;

        box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 4px;


        padding-top: 80px;

        &-img{
            margin-bottom: 30px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &-speed{
            margin-bottom: 65px;

            span{
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020;
            }
        }

        &-button{
            padding: 15px 30px;
            margin-bottom: 90px;

            background: white;
            border: 1px solid #007A33;
            border-radius: 6px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #007A33;

            &:hover{
                background-color: #007A33;
                color: #FFFFFF;

                svg path{
                    fill: white;
                    transition: all 0.2s;
                }
            }
        }
    }

    @media (max-width: 1679px){
        &__card{
            width: 370px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 80px 0 0;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0 30px;
            margin-bottom: 20px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            padding: 0 30px;
            margin-bottom: 70px;
            max-width: 100%;

            p{
                font-size: 15px;
            }
        }

        &__wrap{
            width: 100%;
            overflow: hidden;

            margin-bottom: 25px;

            position relative;
            height: 365px;
        }

        &__block{
            display: flex;
            gap: 10px;

            position absolute;
            top: 10px;
            left: 10px;

            transition: left 1s;
        }

        &__card{
            width: 300px;
            min-width: 300px;

            padding-top: 35px;

            &-img{
                margin-bottom: 15px;
            }

            &-speed{
                margin-bottom: 45px;

                span{
                    font-size: 25px;
                    line-height: 150%;
                }
            }

            &-button{
                padding: 10px 25px;
                margin-bottom: 45px;

                font-size: 20px !important;
            }

        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.connection-address{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: solid 1px #e5e5e5;
        margin-bottom: 110px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    span{
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 170%;
        color: #535353;
    }

    input, button{
        border: 1px solid #007a33;
        border-radius: 6px;
    }

    input{
        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 170%;
        color: #535353;

        background-color: white;

        &:hover{
            background-color: white;
        }
    }

    .check-connection__item--button{
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            background: linear-gradient(180deg, #3eab60 7.5%, #007a33 120%);

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px !important;
            line-height: 150%;
            color: #FFFFFF;

            &:hover {
                background: linear-gradient(180deg, #12b845 7.5%, #12b845 120%);
                color: #FFFFFF !important;
            }

            svg path{
                fill: white;
            }
        }
    }

    input:focus {
        box-shadow: 0 0 1px 1px #007a33;
    }

    @media (max-width: 1279px) {
        &__page{
            max-width: 100%;

            padding: 0px 30px;
            margin-bottom: 50px;
        }

        .heading{
            padding-bottom: 10px;
            // border-bottom: solid 1px #e5e5e5;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        .check-connection{
            display: inline;
        }

        .check-connection__item--street, .check-connection__item--house{
            width: 100%;
        }

        .check-connection__item--house{
            margin-bottom: 20px;
        }

        .check-connection__item{
            padding: 0px;
            padding-bottom: 15px;
        }

        .check-connection__response{
            text-align: left;
            padding: 0;
        }
    }
}

.introduction{
    max-width: 767px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 28px;

    p{
        span{
            vertical-align: top;
        }

        &:nth-child(1){
            margin-bottom: 23px;
        }
    }

    &__footnote{
        padding-top: 44px;
        padding-bottom: 22px;
        font-size: 12px;
    }
}

.advantages{
    margin-bottom: 120px;

    li{
        display: flex;
        align-items: center;
        gap: 8px;

        margin-bottom: 8px;
    }
}

.rates{
    .heading{
        margin-bottom: 72px;

        h3{
            margin-bottom: 0px;
        }
    }

    &__list{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        margin-bottom: 144px;
    }

    &__block{
        max-width: 376px;

        border: 1px solid #F8F8F8;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 4px;

        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 0 100%;

        padding-top: 72px;

        &:hover{
            box-shadow: 0px 8px 20px rgba(173, 173, 173, 0.3);
        }
    }

    &__img{
        margin-bottom: 28px;
    }

    &__speed{
        margin-bottom: 68px;

        span{
            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__button{
        margin-bottom: 68px;
        height: 46px;
        width: 142px;
        background: white;
        border: 1px solid #007A33;
        border-radius: 6px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        text-align: right;
        color: #007A33;

        &:hover{
            background-color: #007A33;
            color: #FFFFFF;

            svg path{
                fill: white;
                transition: all 0.2s;
            }
        }
    }

    &__old-price{
        height: 20px;
        margin-bottom: 30px;

        span{
            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            text-align: right;
            color: #7c7c7c;

            text-decoration-line:line-through;
        }
    }

    @media (max-width: 801px) {
        &__list{
            flex-direction: column;
            align-items: center;
        }

        &__block{
            min-width: 376px;
        }
    }

    @media (max-width: 425px) {
        &__block{
            max-width: auto;
            min-width auto;
            width: 100%;
        }
    }
}

.connect-apartment{
    .heading{
        margin-bottom: 40px;
    }

    p{
        margin-bottom: 40px;
        max-width: 580px;
        line-height: 28px;
    }

    ul{
        margin-bottom: 40px;

        li{
            max-width: 755px;

            display: flex;
            align-items: center;
            gap: 8px;

            margin-bottom: 8px;

            &:nth-child(3){
                img{
                    position relative;
                    top: -12px;
                    left: 0px;
                }
            }
        }
    }

    .phone{
        text-decoration: none;
        color: #202020;
        font-weight: 700;
    }
    .mail{
        text-decoration: none;
        color: #007A33;
    }

    p:last-child{
        margin-bottom: 144px;
    }
}

.options-add{
    .heading{
        margin-bottom: 72px;
    }

    &__products{
        margin-bottom: 144px;

        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    @media (max-width: 801px) {
        &__products{
            flex-direction: column;
            align-items: center;
        }
    }
}

.equipment{
    .heading{
        margin-bottom: 40px;
    }

    &__content{
        max-width: 747px;
        margin-bottom: 96px;
    }

    &__products{
        margin-bottom: 156px;
    }

    &__products2{
        margin-bottom: 172px;

        display: flex;
        gap: 20px;
    }

    @media (max-width: 1200px) {
        &__products{
            // margin-bottom: 156px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__products2{
            flex-wrap: wrap;
        }
    }
}

.productcard-medium{
    max-width: 575px;
    display: flex;
    gap: 60px;
    flex: 1 0 100%;

    border: 1px solid #F8F8F8;
    box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
    border-radius: 4px;

    padding: 20px 0px 28px 0px;

    &:hover{
        box-shadow: 0px 8px 20px rgba(173, 173, 173, 0.3);
    }

    &__img{
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__description{
    }

    &__name{
        padding-top: 44px;
        margin-bottom: 32px;

        span{
            display: block;
        }
    }

    &__price{
        span{
            display: block;
        }
    }

    &__type, &__price-type{
        font-family: $font-family-content;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #848484;
    }

    &__type{
        margin-bottom: 12px;
    }

    &__price-type{
        margin-bottom: 8px;
    }

    &__price-numbers{
        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #007A33;
    }

    &__brand, &__model{
        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #202020;
    }

    @media (max-width: 550px) {
        // flex-direction: column;
        display: inline;

        max-width: 367px;

        padding: 20px 56px 72px;
    }
}

.productcard-option{
    max-width: 376px;
    padding: 64px 56px 0px;

    border: 1px solid #F8F8F8;
    box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
    border-radius: 4px;

    &:hover{
        box-shadow: 0px 8px 20px rgba(173, 173, 173, 0.3);
    }

    &__header{
        margin-bottom: 44px;

        span{
            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__button{
        padding-bottom: 64px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 24px;

        button{
            transition: all 0.2s;
            height: 46px;
            padding: 0px 28px 0px 24px;

            background: white;
            border: 1px solid #007A33;
            border-radius: 6px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            font-family: 'Fira Sans',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            text-align: right;
            color: #007A33;

            &:hover{
                background-color: #007A33;
                color: #FFFFFF;

                svg path{
                    fill: white;
                    transition: all 0.2s;
                }
            }
        }
    }

    &__content{
        margin-bottom: 72px;
    }
}

.productcard{
    display: flex;
    align-items: center;

    border: 1px solid #F8F8F8;
    box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
    border-radius: 4px;

    padding: 28px 0 28px 64px;

    &:hover{
        box-shadow: 0px 8px 20px rgba(173, 173, 173, 0.3);
    }

    &__img{
        max-width: 262px;
        height: auto;

        margin-right: 96px;

        img{
            display: block;
            height: auto;
            width: 100%
        }
    }

    &__border{
        margin-right: 132px;

        span{
            display: block;
            height: 200px;
            width: 1px;
            background-color: #E5E5E5;
        }
    }

    &__description{
        width: 264px;
        display: flex;
        flex-direction: column;

        margin-right: 36px;
    }

    &__type, &__name{
        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #202020;
    }

    &__rate{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__rate-comment{
        font-family: $font-family-content;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #848484;
    }

    &__price{
        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #007A33;
    }

    @media (max-width: 1200px) {
        max-width: 367px;

        flex-direction: column;
        align-items: flex-start;

        padding: 64px 56px 0px;

        &__img{
            max-width: 262px;
            height: auto;

            margin-right: 0px;
            margin-bottom: 40px;

            img{
                display: block;
                height: auto;
                width: 100%
            }
        }

        &__border{
            margin-right: 0px;
            margin-bottom: 64px;
            width: 100%;
            span{
                display: block;
                height: 1px;
                max-width: 255px;
                width: auto;
                background-color: #E5E5E5;
            }
        }

        &__description{
            padding-top: 22px;
            width: auto;
            max-width: 264px;
            display: flex;
            flex-direction: column;

            margin-right: 0px;
            margin-bottom: 36px;
        }

        &__rate{
            margin-bottom: 94px;
        }
    }
}

.accordion{
    .heading{
        margin-bottom: 75px;
    }

    &__item{
        &:last-child{
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 152px;
        }
    }

    &__button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        border-top: 1px solid #E5E5E5;
        width: 100%;
        background-color: white;

        font-family: $font-family-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #535353;

        div{
            display: flex;
            align-items: center;
            span{
                text-align: start;
            }
        }
    }

    &__number{
        margin-right: 56px;

        font-family: 'Fira Sans',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: #007A33;
    }

    &__content{
        background-color: #FBFBFB;
        transition: all 0.2s;

        p{
            max-width: 896px;
            margin-bottom: 44px;

            &:last-child{
                margin-bottom: 0px;
            }
        }

        li{
            display: flex;
            align-items: center;
            gap: 8px;

            margin-bottom: 8px;
        }

        &_passive{
            height: 0px;
            padding: 0px 0px 0px 72px;
            color: transparent;

            img{
                display: none;
            }
        }

        &_active{
            height: auto;
            padding: 44px 0px 52px 72px;
            color: #535353;
        }
    }

    &__plus{
        width: 17px;
        height: 17px;
        margin-right: 50px;
        position relative;

        span{
            display: block;
            position: absolute;
            top: 8px;
            width: 17px;
            height: 1px;
            background-color: #007A33;

            transition: transform 0.2s;
        }

        &_passive{
            span{
                &:nth-child(1){
                    transform: rotate(90deg);
                }
            }
        }

        &_active{
            span{
                &:nth-child(1){
                    transform: rotate(180deg);
                }
                background-color: #848484;
            }
        }

        &:hover{
            span{
                background-color: #12B845;
            }
        }
    }

    &__footnote{
        font-size: 12px;
    }

    @media (max-width: 1200px) {
        &__button{
            height: 150px;
        }

        &__number{
            margin-right: 16px;
        }

        &__plus{
            margin-right: 16px;
        }

        &__content{
            &_passive{
                padding: 0px 0px 0px 11px;
            }

             &_active{
                padding: 44px 0px 52px 11px;
            }
        }
    }
}
