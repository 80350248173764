//index
.comp-in-figure{
  background-color: gray;

  .panel-figure{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .item{
      flex-basis: 208px;

      .size{
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 28px;
        color: #60605f;
        font-weight: 900;
        text-align: center;

        span{
          font-size: 54px;
          color: purple;
        }
        &__rubik{
        //font-family: 'Rubik';
        //font-weight: 700;
        //font-size: 34px !important;
          color: #60605f !important;
        }
      }

      p{
        text-align: center;
      }
    }
  }

  @media (max-width: 900px){
    .panel-figure{
      flex-direction: column;

      .item{
        flex-basis: auto;
        margin-bottom: 20px;

        &:last-child{
          margin-bottom: 0;
        }

        .size{
          margin-bottom: 15px;
        }
      }
    }
  }
}

//main points navigation
.block-services{
  color: black;

  @media (max-width: 1000px){
    width: 640px;
    margin-left: auto;
    margin-right: auto;

    .services_divorce{
      justify-content: center;

      .item{
        margin: 0 20px 50px;
      }
    }
  }

  @media (max-width: 700px){
    margin: 0 auto;
  }

  @media (max-width: 660px){
    width: auto;

    .services_divorce{
      .item{
        flex-basis: 100%;

        a{
          display: block;
          max-width: 223px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.block_services{

  @media (max-width: 425px){
    &-corp{
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.back-link{
  text-align: right;
  margin-bottom: 28px;
  font-size: 14px;
}


//404
.page_404 {
  position: relative;
  height: 543px
  margin-top: 100px;

  &__notice {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/src/img/elements/404_ellipse.webp) 50% 50% / cover no-repeat;
    width: 567px;
    height: 363px;

    .page_404__notice_inner{
      text-align: center;
      width: 400px;

      p{
        font-size: 24px;

        a{
          color: purple;
        }
      }

      .big__text{
        color: purple;
        margin-bottom: 22px;
      }
    }
  }

  &__child {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .circle {
    position: absolute

    &_1 {
      width: 40px
      top: 76px;
      left: 578px;
    }
    &_2 {
      width: 23px
      transform: rotate(234deg);
      top: 86px;
      left: 667px;
    }
    &_3 {
      width: 17px;
      transform: rotate(234deg);
      top: 110px;
      left: 755px;
    }
  }

  @media (max-width: 1200px){
    &__notice {
      top: 7%;
      left: 13%;
      z-index: 2;
    }

    &__child {
      left: 51%;
      right: auto;
    }

    .circle{
      &_1{
        display: none
      }
      &_2{
        top: 60px;
        left: 667px;
      }
      &_3{
        top: 84px;
        left: 722px;
      }
    }
  }

  @media (max-width: 1020px){
    height: 429px;

    &__notice {
      left: 2%
    }

    .circle{
      &_2{
        top: 28px;
        left: 61%;
      }
      &_3{
        top: 55px;
        left: 66%;
      }
    }

    &__child{
      height: 438px;
      left: 53%;
    }

  }

  @media (max-width: 750px){

    height: auto;

    .page_404__notice{
      background: none;
      height: auto;
      width: 100%;
      left: auto;
      position: relative;

      img{
        display: none;
      }
    }

    .page_404__notice_inner{
      position: relative;
      top: auto;
      left: auto;
      margin: 0 auto;
    }
    .circle{
      display: none
    }

    .page_404__child{
      display: block;
      position: relative;
      margin: 30px auto 0;
      left: auto;
      right: auto;
    }

  }

  @media (max-width: 425px){
    margin-top: 0;

    .page_404__child{
      height: 390px;
      margin-top: 11px;
    }

    .page_404__notice_inner{
      width: auto

      .big__text{
        font-size: 26px
        margin-bottom: 10px
      }

      p{
        font-size: 18px;
        margin-bottom: 0

        &:first-of-type{
          display: none
        }

        a{
          font-size: inherit
        }
      }
    }
  }
}

.no-webp .page_404 {
  &__notice {
    background-image: url(/src/img/elements/404_ellipse.png);
  }
}

//INFORMATION
//company
.licences-list{
  .licence{
    text-align: center;
    flex: 25% 0 0;

    a{
      display: inline-block;
      margin-bottom: 40px;
      outline: none;
    }
    img{
      height: 257px;
    }
    p{
      text-align: center
      line-height: 24px
    }
  }

  @media (max-width: 900px){
    flex-wrap: wrap;

    .licence{
      flex: 50% 0 0;
    }
  }

  @media (max-width: 600px){
    flex-wrap: wrap;

    .licence{
      flex: 100% 0 0;

      a{
        margin-bottom: 10px;
      }
    }
  }
}
.our-clients{
  flex-flow: row wrap;
  justify-content: center;

  &__inner{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    flex: 33.3% 0 0;
    margin-bottom: 35px;
  }

  @media (max-width: 910px){
    &__inner{
      flex: 50% 0 0;
    }
  }

  @media (max-width: 610px){
    &__inner{
      flex: 100% 0 0;
    }
  }
}

//vacancy
.vacancy{
  &__sites-list{
    .item{
      border-radius: 6px;
      
      border: border;
      padding: 20px;
      text-align: center;
      flex: 263px 0 0;

      h3{
        margin-bottom: 25px;
        font-weight: 700;
        color: black !important;
      }

      &__img{
        height: 150px;
        margin-bottom: 25px;

        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    @media (max-width: 1120px){
      justify-content: center;
      flex-flow: row wrap;

      .item{
        margin: 0 20px 20px;
      }
    }
  }
}

//CORPORATE
//Internet
.div_offer-wifi{
  margin-top: 75px;
  margin-bottom: 40px;

  .img__lines{
    position: absolute;
    left: calc(50% - 188px);
    top: 33px;
  }
  .off{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 70px;
    right: 0;

    .offer-wifi_p{
      position: absolute;
      left: 0;
      top: 0;

      &_1{
        left: 27px;
        top: 13px;
      }
      &_2{
        left: 148px;
        top: 138px;
      }
      &_3{
        left: 76px;
        top: 294px;
      }
      &_4{
        left: 707px;
        top: 13px;
      }
      &_5{
        left: 704px;
        top: 138px;
      }
      &_6{
        left: 723px;
        top: 294px;
      }
    }
  }

  .off_2{
    display: none;
  }
}
.slider-ui_corp-internet{
  position: relative;
  flex-basis: 286px;
  height: 10px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;

  /* Ползунок */
  .ui-slider-handle {
    width: 16px;
    height: 30px;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    position: absolute;
    top: -10px;
    margin-left: -8px;
    z-index: 200;
    background: red;
  }

  .slider-ui__result{
    position: absolute;
    bottom: -50px;
    left: calc(50% - 7px);
    width: 80px;
    text-align: center;
    transform: translateX(-40%);
    font-size: 14px;
  }
}

.block_corp-internet_claim{
  padding-top: 110px;

  .claim_corp-internet{
    border-radius: 6px;

    margin-bottom: 100px;
  }

  .tac{
    margin-bottom: 50px;
  }

  .msg-form-error{
    margin-top: -10px;
    margin-bottom: 20px;
  }

  @media (max-width: 1120px){
    .claim{
      &__form-data{
        flex-flow: column nowrap;

        .cont{
          flex: auto;
          margin-bottom: 18px;

          input{
            flex-basis: calc(100% - 105px);
            max-width: none;
          }

          &_address{
            margin-top: 0;

            textarea{
              height: 54px;
            }
          }
        }
      }

      &__parameters{
        .parameters__data{
          flex-flow: column nowrap;

          .bl1, .bl2{
            flex-basis: auto;

            label{
              margin-right: 10px;
              flex: 90px 0 0;
            }
          }

          .bl1{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-bottom: 30px;

            .cont{
              margin-top: 0;
              flex-basis: 40%;

              input{
                width: 100%;
              }
            }
          }
          .bl2{
            .cont{
              display: block;

              textarea{
                margin-top: 12px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px){
    padding-right: 0;
    padding-left: 0;

    .claim_corp-internet{
      padding: 59px 20px 30px;
    }
  }

  @media (max-width: 670px){
    .parameters__data{
      .bl1{
        flex-wrap: wrap !important;

        .cont{
          flex-basis: 100% !important;

          input, .slider-ui_corp-internet{
            flex-basis: 486px;

            .slider-ui__result{
              transform: translateX(-46%);
            }
          }

          &:last-child{
            margin-top: 64px;
          }
        }
      }
    }
  }

  @media (max-width: 425px){
    label{
      font-size: 14px !important;
    }

    input{
      height: 45px;
      padding: 8px 10px;
    }

    .claim_corp-internet{
      padding-left: 15px;
      padding-right: 15px;
    }

    .claim__form-data{
      label{
        flex: 67px 0 0;
      }

      input, textarea{
        width: 230px;
        flex: 230px 0 1 !important;
        padding: 8px 10px;
      }

      textarea{
        height: 90px !important;
      }

      .cont_address{
        label{
          margin-right: 0;
          align-self: start;
        }
      }
    }

    .claim__parameters{
      h4, p{
        text-align: center;
      }
    }
  }

  @media (max-width: 370px){
    .claim__form-data{

      input, textarea{
        width: 180px;
        flex: 180px 0 1 !important;
      }
    }

    .parameters__data{
      label{
        flex: 64px 0 0 !important;
      }

      input{
        width: 180px;
      }
    }
  }
}

.block_corp-internet_wifi{
  @media (max-width: 1300px){
    .off{
      // position: absolute;
      // top: 0;
      // bottom: 0;
      left: 20px;
      // right: 0;
    }
  }

  @media (max-width: 1200px){
    .div_offer-wifi{
      .img__lines, .off{
        display: none;
      }

      .off_2{
        display: block;
        margin-left: 60px;
      }
    }
  }

  @media (max-width: 680px){
    .div_offer-wifi{
      .img__phone{
        width: 155px;
      }

      .off_2{
        margin-left: 30px;
      }
    }
  }

  @media (max-width: 425px){
    padding-left: 0;
    padding-right: 0;

    .div_offer-wifi{
      .img__phone{
        width: 130px;
      }

      .off_2{
        margin-left: 10px;
      }
    }
  }
}

.container_corp-internet_VPN{
  @media (min-width: 426px){
    .img-small{
      display: none;
    }
  }

  @media (max-width: 1200px){
    img{
      width: 100%;
    }
  }

  @media (max-width: 768px){
    .ul-normal{
      column-count: 1;
    }
    p{
      text-align: center;
    }
  }

  @media (max-width: 425px){

    img{
      display: none;
    }
    .img-small{
      display: block;
    }
  }
}

//telephony
.corp-tel_numbers{
  margin-bottom: 0;

  .item{
    flex: auto 0 0;

    .number{
      font-size: 40px;
      font-weight: 700;
      color: gray-black;
      margin: 56px 0 48px;
    }

    .ul-normal{
      margin-bottom: 50px;
      padding-top: 10px;
      padding-bottom: 10px;

      li{
        text-align: left;
      }
    }

    &:nth-child(2){
      .ul-normal{
        padding-left: 25px;
        padding-right: 25px;
        border-right: 1px solid #d7d7d7;
        border-left: 1px solid #d7d7d7;
      }
    }
  }

  @media (max-width: 1160px){
    flex-flow: row wrap;

    .item{
      flex: 450px 0 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 30px;

      .ul-normal{
        margin-bottom: 25px;
      }

      &:nth-child(2){
        .ul-normal{
          border: none;
        }
      }
    }
  }
  @media (max-width: 929px){
    flex-flow: row wrap;

    .item{
      padding-bottom: 40px;
      border-bottom: 1px solid red;

      &:last-child{
        border: none;
        padding-bottom: 0;
      }
    }
  }
  @media (max-width: 425px){
    .item{
      flex: auto 0 0;

      .number{
        font-size: 30px;
        margin: 22px 0 12px;
      }
    }
  }
}

.corp-tel_atc{
  img{
    margin-bottom: 50px;
    width: 100%;
  }

  .img_small{
    display: none;
  }

  .ul-normal{
    column-gap: 30px;
    margin-bottom: 50px;
  }

  @media (max-width: 650px){
    .img_big{
      display: none;
    }
    .img_small{
      display: block;
      width: auto;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 375px){
    .img_small{
      width: 100%;
    }
  }
}

.corp-tel_sip{
  .img_big{
    margin: 50px 0;
  }

  @media (max-width: 1100px){
    .img_big{
      width: 100%;
    }
  }
  @media (max-width: 768px){
    .img_big{
      display: none;
    }
    .adaptation{
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      margin-bottom: 56px;

      img{
        margin-right: 28px;
        width: 263px;
      }
    }
  }
  @media (max-width: 650px){
    .adaptation{
      flex-flow: row wrap;

      img{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }
    }
  }
}

.corp-tel_services{
  .item__info{
    padding: 30px 30px 46px !important;
  }
}

//television
.television_schema{
  .img-small{
    display: none;
  }
  .img-big{
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 1024px){
    img{
      width: 100%;
    }
  }
  @media (max-width: 670px){
    .img-big{
      display: none;
    }
    .img-small{
      display: block;
      width: auto;
      margin: 0 auto;
    }
  }
  @media (max-width: 370px){
    .img-small{
      width: 100%;
    }
  }
}
.television_smart{
  .block_smart-tv{
    margin-top: 30px;
    align-items: flex-start;
    text-align: left;

    img{
      margin-top: 20px;
      margin-right: 100px;
    }

    .cost{
      display: block;
      margin-bottom: 25px;

      span{
        font-size: 30px;
        color: red;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1024px){
    .block_smart-tv{
      img{
        margin-right: 17px;
      }
    }
  }
  @media (max-width: 960px){
    .block_smart-tv{
      flex-flow: row wrap;

      img{
        margin: 0 auto 30px;
      }
      .cost{
        text-align: center;
      }
      .a-button{
        margin: 0 auto;
        display: block;
      }
    }
  }
  @media (max-width: 570px){
    .block_smart-tv{
      img{
        width: 100%;
      }
    }
  }
}
.television_instruction{
  .instruction{
    flex-flow: row wrap;
    margin-top: 70px;
    margin-bottom: 0;
    text-align: left;
    justify-content: space-between;

    .item{
      flex: 49% 0 1;
      margin-bottom: 90px;

      .head{
        margin-bottom: 50px;
        font-size: 24px;
        color: black;
        text-align: center;
      }

      .img{
        height: 270px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;
      }
    }
  }

  @media (max-width: 850px){
    .instruction{
      .item{
        flex: 100% 0 1;

        .link{
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 425px){
    .instruction{
      .item{
        margin-bottom: 30px;

        .head{
          margin-bottom: 30px;
        }

        .img{
          margin-bottom: 0;

          img{
            width: 100%;
          }
        }
      }
    }
  }
}

//PHISYC
//internet
.number_line{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  .left_scobe, .right_scobe{
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 0;
  }
  .left_scobe{
    &:after{
      content: url(/src/img/elements/scobe_left.png);
      margin-left: 5px;
    }
  }
  .right_scobe{
    &:before{
      content: url(/src/img/elements/scobe_left.png);
      transform: rotateY(180deg);
      margin-right: 5px;
    }
  }

  .line{
    width: 31%;
    border-bottom: 1px dashed #93b60d;
  }

  @media (max-width: 1230px){
    display: none;
  }
}

//telephony
.addition_tariff{
  p{
    text-align: center

    &:nth-child(2){
      margin-bottom: 0;
    }
  }
}

//REALTY
//

//OPERATORS
//network
.oper_container-datacenter{
  img{
    width: 100%;
    margin-bottom: 30px;
  }
}
.oper_nodes{
  background: #f5f5f5 url(/src/img/elements/oper_nodes-background.webp) 50% 100%/contain no-repeat;
  padding-bottom: 270px;

  @media (max-width: 1024px){
    padding-bottom: 214px;
  }
  @media (max-width: 768px){
    padding-bottom: 160px;
  }
  @media (max-width: 560px){
    padding-bottom: 120px;
  }
  @media (max-width: 480px){
    background #f5f5f5 !important;
    padding-bottom: 60px;
  }
}
.no-webp .oper_nodes {
  background-image: url(/src/img/elements/oper_nodes-background.png);
}

.network-list{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .item{
    border-radius: 6px;
    border: border;
    flex: 14.3% 0 0;
    margin-bottom: 25px;

    .head{
      text-align: center;
      color: #5f5f5f;
      padding: 15px 0;
    }
    .pic{
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        max-height: 90px;
      }
    }
  }

  @media (max-width: 1024px){
    .item{
      flex: 18% 0 0;
    }
  }
  @media (max-width: 900px){
    .item{
      flex: 23% 0 0;
    }
  }
  @media (max-width: 768px){
    .item{
      flex: 31% 0 0;
    }
  }
  @media (max-width: 560px){
    .item{
      flex: 48% 0 0;
    }
  }
}

//monitoring
.links-monitoring{
  border-radius: 6px;
  border: border;
  padding: 45px 20px;

  p{
    margin-bottom: 42px;
  }

  .list-links{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &__item{
      border-radius: 6px;

      background-color: yellow;
      padding: 15px 10px;
      margin-bottom: 20px;
      flex: 49% 0 0;

      &--full {
        flex-basis: 100%;
      }

      .link__arrows{
        text-align: left;
        a{
          span{
            text-decoration: none;
            transition: all .3s;
          }
        }
        .arrows{
          i{
            svg{
              path{
                fill: black !important;
              }
            }
          }
        }

        &:hover{
          a{
            span{
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px){
    .list-links{
      flex-flow: column nowrap;
      align-items: center;

      &__item{
        width: 540px;
      }
    }
  }
  @media (max-width: 600px){
    padding: 30px 10px;

    .list-links{
      &__item{
        width: 100%;

        .link__arrows{
          a{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .arrows{
              flex: 20px 0 0;
            }
          }
        }
      }
    }
  }
}

//STOCKS 404














