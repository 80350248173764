@keyframes pulse{
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.4);
  }
}

@keyframes grayToColor{
  100%{
    filter: grayscale(0);
  }
}