.europe-physical {
    background: url(/src/img/pages/BG_europe.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading {
        max-width: 658px;

        padding-top: 275px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        h1 {
            margin: 0 !important;
            padding: 0 !important;

            text-align: left;

            font-h1(52px, font-color-light);
        }
    }

    &__content {
        ul{
            li{
                marker-3(rgba(245, 241, 248, 0.45) !important);
                font-p-300(17px, font-color-light);

                margin-bottom: 5px;
            }
        }
    }

    @media (max-width: 1279px) {
        background: url(/src/img/pages/BG_europe_mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 719px;

        &__page {
            max-width: 100%;

            padding: 0 30px;
        }

        .heading {
            max-width: 100%;

            padding-top: 357px;
            padding-bottom: 25px;
            margin-bottom: 25px;

            h1 {
                font-h1(30px, font-color-light);
            }
        }
    }
}

.europe-physical-services {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 110px;
    }

    .heading {
        margin-bottom: 25px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__content {
        max-width: 927px;

        p {
            margin-bottom: 20px;

            font-p(17px, font-color-normal);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 80px;
        }

        .heading {
            margin-bottom: 20px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__content {
            max-width: 100%;

            p {
                font-p(15px, font-color-normal);
            }
        }
    }
}

.europe-physical-infographics {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    &__wrap{
        position: static;
    }

    &__card-wrap{
        display: flex;
        gap: 55px;

        position: static;
    }

    &__card{
        min-width: 281px;
    }

    &__card-img{
        height: 91px;
        margin-bottom: 25px;

        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &__card-heading{
        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 15px;

        a{
            display: flex;
            align-items: center;
            gap: 9px;

            text-decoration: none;
        }

        span{
            font-h3(20px, font-color-green);
        }

        a:hover{
            span{
                font-h3(20px, font-color-green-hover);
            }

            svg path{
                fill: font-color-green-hover;
            }
        }
    }

    &__card-content{
        ul{
            display: flex;
            flex-direction: column;
            gap: 5px;

            li{
                marker-3();
                font-p(17px, font-color-normal) 
            }
        }
    }

    @media (max-width: 1679px) {
         &__card {
            min-width: 241px;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            padding: 0;
            margin-bottom: 80px;
        }

        &__wrap{
            height: 314px;
            width: 100%;
            margin-bottom: 45px;

            overflow: hidden;

            position: relative;
        }

        &__card-wrap{
            gap: 10px;

            position: absolute;
            top: 0;
            left: 10px;

            transition: left 0.7s;
        }

        &__card{
            min-width: auto;
            width: 240px;
            height: 314px;

            padding: 20px;

            border: 1px solid #E5E5E5;
            border-radius: 6px;
        }

        &__card-img{
            margin-bottom: 10px;
        }

        &__card-heading{
            margin-bottom: 16px;

            span{
                font-h3(17px, font-color-green);
            }
        }

        &__card-content{
            ul{
                gap: 8px;

                li{
                    font-p(15px, font-color-normal) 
                }
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.europe-physical-internet{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading {
        margin-bottom: 67px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__rates{
        display: block;
        margin-bottom: 60px;
    }

    &__rates-header{
        display: flex;
        gap: 150px;

        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;

        span{
            font-p(12px, font-color-normal);
        }

        &_1{
            min-width: 392px;

            display: flex;
            justify-content: space-between;
        }

        &_2{
            min-width: 254px;

            display: flex;
            justify-content: space-between;
        }
    }

    &__rates-body{
        display: flex;
        align-items: center;
        gap: 150px;

        height: 125px;

        border-bottom: 1px solid #E5E5E5;

        &_1{
            min-width: 392px;

            display: flex;
            justify-content: space-between;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &_2{
            min-width: 254px;

            display: flex;
            justify-content: space-between;

            font-p(17px, font-color-normal);
        }

        &_button{
            button_green_light();

            width: 100%;
            display: flex;
            justify-content: flex-end;

            padding-right: 55px;
        }
    }

    &__rates-mobile{
        display: none;
    }

    &__footnote{
        font-p(17px, font-color-normal);
        span{
            span{
                font-p-500(17px, font-color-normal);
            }
        }
    }
    
    @media (max-width: 1679px) {
        &__rates-header{
            gap: 110px;

            &_1{
                min-width: 350px;
            }
        }

        &__rates-body{
            gap: 110px;

            &_1{
                min-width: 350px;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            padding: 0;
            margin-bottom: 80px;
        }

        .heading {
            padding: 0 30px;
            margin-bottom: 35px;

            h2 {
                font-h2(20px, font-color-dark);
            }
        }

        &__rates{
            display: none;
        }

        &__rates-mobile{
            display: block;

            width: 100%;
            overflow: hidden;

            position: relative;
            height: 393px;

            margin-bottom: 20px;
        }

        &__rates-mobile-wrap{
            display: flex;
            gap: 10px;

            position: absolute;
            left: 10px;
            top: 15px;

            transition: left 0.7s;
        }

        &__rates-mobile-card{
            min-width: 300px;
            height: 353px;

            padding: 30px 20px 45px 20px;

            border: 1px solid #F8F8F8;
            box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
            border-radius: 6px;
        }

        &__rates-mobile-card-heading{
            display: flex;
            justify-content: space-between;

            margin-bottom: 25px;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &__rates-mobile-card-traffic{
            margin-bottom: 30px;

            span{
                display: block;
            }

            span:first-child{
                font-p(12px, font-color-normal);

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 5px;
            }

            span:last-child{
                font-p(17px, font-color-normal);
            }
        }

        &__rates-mobile-card-speed{
            margin-bottom: 40px;
            
            span{
                display: block;
            }

            span:first-child{
                font-p(12px, font-color-normal);

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 5px;
            }

            span:last-child{
                font-p(17px, font-color-normal);
            }
        }

        &__rates-mobile-card-button{
            button_green_light();
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;
            margin-bottom: 35px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__footnote{
            padding: 0 30px;

            font-p(15px, font-color-normal);
            span{
                span{
                    font-p-500(15px, font-color-normal);
                }
            }
        }
    }
}

.europe-physical-advantages {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading {
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__card {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 50px 15px;

            span{
                display: block;
            }

            li {
                flex: 1 0 100%;
                max-width: 388px;

                display: flex;

                font-p(17px, font-color-normal);

                span:first-child{
                    min-width: 35px;

                    font-p-25-500(font-color-green)
                }

                span:last-child{
                    max-width: 308px;
                }
            }

            li:nth-child(n+5){
                height: 29px;

                span{
                    align-self: center;
                }
            }
        }
    }

    @media (max-width: 1679px) {
         &__card {
            ul {
                li {
                    max-width: 380px;
                }
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        .heading {
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__card {
            ul {
                gap: 30px;

                li {
                    max-width: 100%;

                    font-p(15px, font-color-normal);

                    span:first-child{
                        line-height: 130%;
                    }

                    span:last-child{
                        max-width: 100%;
                    }
                }

                li:nth-child(n+5){
                    height: auto;

                    span{
                        align-self: center;
                    }
                }
            }
        }
    }
}

.europe-physical-phonerates{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 105px;
    }

    .heading {
        margin-bottom: 65px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__rates{
        display: block;

        margin-bottom: 60px;
    }

    &__rates_mobile{
        display: none;
    }

    &__rates-header{
        display: flex;

        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;

        span{
            font-p(12px, font-color-normal);

            span{
                font-weight: 500;
                color: font-color-dark;
            }
        }
    }

    &__rates-header_1{
        width: 250px;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        margin-right: 120px;
    }

    &__rates-header_2, 
    &__rates-header_3 {
        width: 135px;

        margin-right: 45px;
    }

    &__rates-header_4{
        width: 135px;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        margin-right: 93px;
    }

    &__rates-body{
        border-bottom: 1px solid #E5E5E5;
    }

    &__rates-body_visible{
        height: 125px;

        display: flex;
        align-items: center;
    }
    
    &__rates-body_1{
        width: 250px;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        margin-right: 120px;

        font-p-20-500(font-color-green);
    }

    &__rates-body_2,
    &__rates-body_3{
        width: 135px;

        margin-right: 45px;

        font-p(17px, font-color-normal);
    }

    &__rates-body_4{
        width: 135px;

        margin-right: 93px;

        font-p(17px, font-color-normal);
    }

    &__rates-body_button{
        margin-right: 61px;

        button_green_light();
    }

    &__rates-body_button-open{
        button{
            border-radius: 0;
            background-color: transparent;

            border-bottom: 1px dashed #848484;

            span{
                margin-right: 3px;
                font-p(12px, font-color-normal);
            }

            span:nth-child(2){
                display: none;
            }
        }

        svg{
            transition: transform 0.3s;
            transform: rotate(90deg);
        }

        button:hover{
            border-bottom: 1px dashed #C6C6C6;

            span{
                color: #C6C6C6 !important;
            }
            
            svg{
                path{
                    stroke: #C6C6C6;
                }
            }
        }

        button.active{
            border-bottom: 1px dashed #C6C6C6;

            svg{
                transform: rotate(-90deg);

                path{
                    stroke: #C6C6C6;
                }
            }

            span{
                color: #C6C6C6 !important;
            }

            span:nth-child(1){
                display: none;
            }

            span:nth-child(2){
                display: inline;
            }
        }
    }

    &__rates-body_hidden{
        transition: all 0.3s !important;
        *{
            transition: all 0.3s !important;
        }

        background: #FBFBFB;

        padding: 70px 0 75px 370px;
    }

    &__rates-body_hidden.active{
        padding: 0px 0 0px 370px;

        *{
            padding-bottom: 0px !important;
            margin-bottom: 0px !important;

            // line-height: 0%  !important;
            height: 0 !important;
            color: transparent !important;

            border: 0px transparent !important
        }

        @media (max-width: 1679px){
            padding: 0px 0 0px 310px;
        }
    }

    &__rates-body_hidden > .hidden-content_heading{
        width: 495px;

        padding-bottom: 5px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 20px;

        font-p(12px, font-color-normal);
    }

    &__rates-body_hidden > .hidden-content_rates{
        display: flex;
        gap: 44px;
    }

    &__rates-body_hidden > .hidden-content_rates > div{
        display: flex;
        gap: 15px;

        font-p(17px, font-color-normal);

        span:first-child{
            font-weight: 500;
        }
    }

    &__correction{
        p{
            font-p(17px, font-color-normal);
            margin-bottom: 0;
        }
    }

    &__products-buttons{
        display: none;
    }

    @media (max-width: 1679px) {
        &__rates-header_1{
            width: 210px;

            margin-right: 100px;
        }

        &__rates-header_2, 
        &__rates-header_3 {
            margin-right: 25px;
        }

        &__rates-header_4{
            margin-right: 35px;
        }

        &__rates-body_1{
            width: 210px;

            margin-right: 100px;
        }

        &__rates-body_2,
        &__rates-body_3{
            margin-right: 25px;
        }

        &__rates-body_4{
            margin-right: 35px;
        }

        &__rates-body_hidden{
            padding: 70px 0 75px 310px;
        }

        &__rates-body_hidden > .hidden-content_heading{
            width: 455px;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0;
            margin-bottom: 45px;
        }

        .heading {
            padding: 0 30px;
            margin-bottom: 25px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__rates{
            display: none;
        }

        &__rates_mobile{
            display: block;
        }

        &__rates-mobile-wrap{
            padding-top: 15px;
            padding-bottom: 25px
            margin-bottom: 20px;

            width: 100%;
            overflow: hidden;
        }

        &__rates-mobile-cards{
            display: flex;
            gap: 10px;

            position: relative;
            left: 10px;

            transition: left 0.7s;
        }

        &__rates-mobile-card{
            min-width: 300px;
            max-width: 300px;
            height: fit-content;

            border: 1px solid #F8F8F8;
            box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
            border-radius: 6px;

            padding: 30px 20px 45px;
        }

        &__rates-mobile-body_1{
            display: flex;
            justify-content: space-between;

            margin-bottom: 25px;

            font-p-20-500(font-color-green);
        }

        &__rates-mobile-body_2,
        &__rates-mobile-body_3,
        &__rates-mobile-body_4,{
            margin-bottom: 30px;

            display: flex;
            flex-direction: column;

            span:first-child{
                font-p(12px, #848484 !important);
                line-height: 150%;

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 5px;

                span{
                    border-bottom: none;
                    font-p-500(12px, font-color-dark);
                }
            }

            span:last-child{
                font-p-500(15px, font-color-normal);
            }
        }

        &__rates-mobile-body_4,{
            span:last-child{
                font-p(15px, font-color-normal);
            }
        }

        &__rates-mobile-body_5{
            margin-bottom: 40px;
        }

        &__rates-mobile-body_5 > .body-header{
            cursor: pointer;

            display: flex;
            justify-content: space-between;
            align-items: center;

            font-p(12px, #848484 !important);

            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;

            svg{
                transition: transform 0.3s;

                transform: rotate(90deg);

                path{
                    stroke: #C6C6C6; 
                }
            }

            svg.active{
                transform: rotate(-90deg);
            }
        }

        &__rates-mobile-body_5 > .body-content{
            transition: all 0.3s;
            *{
                transition: all 0.3s;
            }
            
            display: flex;
            flex-direction: column;
            gap: 5px;

            padding-top: 10px;

            div{
                width: 126px;
                display: flex;
                justify-content: space-between;

                span:first-child{
                    font-p-500(15px, font-color-dark);
                }
                span:last-child{
                    font-p(15px, font-color-normal);
                }
            }

            &.active{
                padding-top: 0px !important;
                gap: 0px !important;

                div{
                    span{
                       line-height: 0% !important; 
                       color: transparent !important;
                    }
                }
            }
        }

        &__rates-mobile-body_button{
            button_green_light();
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;
            margin-bottom: 35px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__correction{
            padding: 0 30px;
            
            p{
                font-p(15px, font-color-normal);
            }
        }
    }
}

.europe-physical-phone-international{
    .telephony-physical-international__page{
        margin-bottom: 80px;

        .heading{
            cursor: pointer;
            padding-bottom: 15px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .telephony-physical-international__buttons{
        padding-top: 80px;
    }

    &__plus{
            height: 17px;
            width: 17px;
            position: relative;

            margin-right: 25px;

        span{
            display: block;
            width: 17px;
            height: 1px;
            background-color: #007a33;
            position: absolute;
            left: 0;
            top: 8px;
            transition: all 0.3s;
        }

        span:first-child{
            transform: rotate(90deg);
        }
    }

    &__plus.active{
        span:first-child{
            transform: rotate(0deg) !important;
        }
    }

    &__hidden{
        display: block;

        transition: all 0.2s;
        *{
            transition: all 0.2s;
        }
    }

    &__hidden.active{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;

        height: 0px !important;

        color: transparent !important;

        border: 0px transparent !important;

        button{
            display: none;
        }

        *{
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            margin-bottom: 0px !important;
            margin-top: 0px !important;

            height: 0px !important;

            color: transparent !important;

            border: 0px transparent !important;

            button{
                display: none;
            }
        }
    }

    @media (max-width: 1679px) {
        .telephony-physical-international__page{
            margin-bottom: 0;
        }
    }

    @media (max-width: 1279px) {
        .telephony-physical-international__page{
            margin-bottom: 0;

            .heading{
                padding-left: 0;
                padding-right: 0;
                margin-left: 30px;
                margin-right: 30px;
                margin-bottom: 0;

                h2{
                    padding-right: 20px;
                }
            }
        }
    }
}

.europe-physical-television{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 160px;
    }

    .heading {
        margin-bottom: 65px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__rates{
        display: block;
    }

    &__rates-header{
        display: flex;
        gap: 300px;

        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;

        span{
            font-p(12px, font-color-normal);
        }

        &_1{
            min-width: 388px;

            display: flex;
            justify-content: space-between;
        }

        &_2{
            min-width: 120px;
        }
    }

    &__rates-body{
        display: flex;
        align-items: center;

        height: 125px;

        border-bottom: 1px solid #E5E5E5;

        &_1{
            min-width: 388px;

            display: flex;
            justify-content: space-between;

            margin-right: 300px;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &_button-modal{
            min-width: 120px;

            display: flex;
            justify-content: space-between;

            margin-right: 264px;

            button {
                background-color: white;
                
                span{
                    font-p(17px, font-color-green);
                }
            }

            button:hover{
                span{
                    font-p(17px, font-color-green-hover);
                }
            }
        }

        &_button{
            button_green_light();

            width: 100%;
            display: flex;
            justify-content: flex-end;

            margin-right: 55px;
        }
    }

    &__rates-mobile{
        display: none;
    }

    @media (max-width: 1679px) {
        &__rates-header{
            gap: 230px;

            padding-bottom: 8px;

            &_1{
                min-width: 350px;
            }
        }

        &__rates-body{
            &_1{
                min-width: 350px;
                margin-right: 230px;
            }

            &_button-modal{
                margin-right: 212px;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            padding: 0;
            margin-bottom: 80px;
        }

        .heading {
            padding: 0 30px;
            margin-bottom: 35px;

            h2 {
                font-h2(20px, font-color-dark);
            }
        }

        &__rates{
            display: none;
        }

        &__rates-mobile{
            display: block;

            margin-bottom: 20px;

            height: 310px;

            // overflow: auto;
            overflow: hidden;
            position relative;

        }

        // &__rates-mobile::-webkit-scrollbar {
        //     // width: 0;
        // }

        &__rates-mobile-wrap{
            display: flex;
            gap: 10px;

            position: absolute;
            left: 10px;
            top: 15px;
        }

        &__rates-mobile-card{
            min-width: 300px;
            height: 270px;

            padding: 30px 20px 45px 20px;

            border: 1px solid #F8F8F8;
            box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
            border-radius: 6px;
        }

        &__rates-mobile-card-heading{
            display: flex;
            justify-content: space-between;

            margin-bottom: 25px;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &__rates-mobile-card-channels{
            margin-bottom: 30px;

            span{
                display: block;
                font-p(12px, font-color-normal);

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 5px;
            }
        }

        &__rates-mobile-card-modal{
            button {
                background-color: white;
                padding: 0;
                
                span{
                    border-bottom: none;
                    margin-bottom: 0;
                    font-p(15px, font-color-green);
                }
            }

            button:hover{
                span{
                    font-p(15px, font-color-green-hover);
                }
            }
        }

        &__rates-mobile-card-button{
            button_green_light();
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;
            margin-bottom: 35px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.europe-physical-tvpackages{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading {
        display: flex;
        justify-content: center;

        margin-bottom: 25px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__content{
        display: flex;
        justify-content: center;

        margin-bottom: 80px;

        p{
            max-width: 813px;
            margin-bottom: 0;

            text-align: center;
            font-p(17px, font-color-normal);
        }
    }

    &__wrap{
        display: flex;
        justify-content: center;
    }

    &__card-wrap{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        max-width: 1063px;
    }

    &__card{
        overflow: hidden;

        width: 262px;
        height: 487px;

        display: flex;
        flex-direction: column;

        position: relative;

        background: linear-gradient(360deg, #12B845 -3.85%, #007A33 17.9%, #006527 32.43%, #00551D 47.29%);
        border-radius: 6px;

        padding: 291px 35px 45px 35px;
    }

    &__card-img, 
    &__card-img-bg{
        position: absolute;

        top: 0;
        left: 0;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__card-img-bg{
        height: 100%;
        width: 100%;

        // background: #DA1E4C;
        opacity: 0.6;
    }

    &__card-heading{
        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-bottom: 15px;
        span{
            font-h3-ls(25px, font-color-light);
        }
    }

    &__card-channels{
        margin-bottom: 30px;

        button {
            font-p(12px, #E9FCEE);
            background-color: transparent;

            &:hover{
                color: #12B845;
            }
        }
    }

    &__card-button{
        button_green_shadow();
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0;
            margin-bottom: 80px;
        }

        .heading {
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 20px;

            h2 {
                font-h2(17px, font-color-dark);
            }
        }

        &__content{
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 35px;

            p{
                max-width: 100%;

                text-align: left;
                font-p(15px, font-color-normal);
            }
        }

        &__wrap{
            justify-content: flex-start;

            position: relative;

            height: 487px;

            overflow: hidden;

            margin-bottom: 45px;
        }

        &__card-wrap{
            flex-wrap: nowrap;
            gap: 10px;

            max-width: fit-content;

            position: absolute;
            left: 10px;
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.europe-physical-equipment{
    display: flex;
    justify-content: center;

    &__page{        
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2{
            font-h2(36px, font-color-dark);
        }
    }

    &__equipment{
        // margin-bottom: 20px;
    }

    &__equipment-content{
        display: flex;
        gap: 146px;
        margin-bottom: 106px;
    }

    &__equipment-content-heading{
        margin-bottom: 25px;

        h3{
            text-align: left;

            font-h2(28px, font-color-dark);
        }

        svg{
            display: none;
        }
    }

    &__equipment-content-txt{
        margin-bottom: 81px;

        max-width: 704px;

        p{
            margin-bottom: 0;

            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    &__equipment-content-price{
        display: flex;
        align-items: center;

        &_green{
            padding-right: 35px;
            border-right: 1px solid #E5E5E5;
            margin-right: 35px;

            font-h2(36px, font-color-green);
        }

        &_green-small{
            max-width: 74px;

            font-p-500(17px, font-color-green);
            line-height: 140%;
        }
    }

    &__equipment-content-img{
        height: 267px;

        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &__data{
        display: flex;
        justify-content: space-between;
    }

    &__data-accordion_1, &__data-accordion_2{
        span{
            font-h2(28px, font-color-dark);
        }

        ul{
            li{
                margin-bottom: 5px;
                position relative;
                padding-left: 13px;

                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;

                

                &:before{
                    position absolute;
                    top: 13px;
                    left: 0px;
                    
                    content: '';
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #535353
                }

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__data-accordion-heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        padding-right: 25px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
    }

    &__data-accordion-content{
        transition: all 0.3s;
        padding-top: 0;
        ul li{
            transition: all 0.3s;
            margin-bottom: 0;
            line-height: 0%;

            color: transparent;

            &:before{
                height: 0;
            }
        }

        &.active{
            padding-top: 25px;

            ul li{
                line-height: 170%;
                margin-bottom: 5px;
                color: #535353;
                &:before{
                    height: 3px;
                }
            }
        }
    }

    &__data-accordion_1{
        flex: 1 0 100%;
        max-width: 791px;
    }
    
    &__data-accordion_2{
        flex: 1 0 100%;
        max-width: 388px;
    }

    &__plus{
        height: 17px;
        width: 17px;

        //margin-right: 25px;

        position: relative;

        span{
            display: block;
            width: 17px;
            height: 1px;
            background-color: #007A33;

            position: absolute;
            left: 0;
            top: 8px;

            transition: all 0.3s;

            &:nth-child(1){
                transform: rotate(90deg);
            }
        }
        &.active{
            span{
                &:nth-child(1){
                    transform: rotate(0deg);
                }
            }
        }
    }

    @media (max-width: 1679px){
        &__page{        
            padding: 0 55px;
            margin-bottom: 160px;
        }

        &__equipment-content{
            gap: 189px;
        }

        &__equipment-content-heading,
        &__equipment-content-txt{
            max-width: 496px;
        }

        &__data-accordion_1{
            max-width: 578px;
        }
        
        &__data-accordion_2{
            max-width: 479px;
        }
    }

    @media (max-width: 1279px){
        &__page{        
            flex: 1 0 100%;
            max-width: 1440px;

            padding: 0 30px;

            margin-bottom: 80px;
        }

        .heading{
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__equipment-content{
            gap: 0;
            flex-direction: column-reverse;
            justify-content: space-between;

            margin-bottom: 40px;
        }

        &__equipment-content-heading{
            max-width: 100%;
            display: flex;
            justify-content: space-between;

            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;

            h3{
                padding-right: 10px;
                margin-bottom: 0;

                font-size: 17px;
                line-height: 150%;
            }

            svg{
                transition: all 0.3s;

                display: block;
                align-self: flex-end;
                transform: rotate(90deg);

                margin-right: 6px;
                margin-bottom: 6px;

                &.active{
                    transform: rotate(270deg);

                    path{
                        stroke: #E5E5E5;
                    }
                }
            }
        }

        &__equipment-content-txt{
            max-width: 100%;

            transition: all 0.3s;
            padding-top: 0;
            margin-bottom: 0;

            p{
                transition: all 0.3s;
                font-size: 15px;
                line-height: 0%;
                color: transparent;
            }

            &.active{
                padding-top: 25px;

                p{
                    line-height: 150%;
                    color: #535353;
                }
            }
        }

        &__equipment-content-price{
            display: flex;
            align-items: center;

            padding-top: 25px;

            &_green{
                font-size: 20px;
                line-height: 150%;
            }

            &_green-small{
                max-width: 100%;

                font-size: 15px;
                line-height: 170%;
            }
        }

        &__equipment-content-img{
            margin-bottom: 23px;

            max-width: 480px;
            height: 156px;
        }
        
        &__data{
            flex-direction: column;
        }

        &__data-accordion_1, &__data-accordion_2{
            max-width: 100%;

            span{
                font-size: 17px;
                line-height: 150%;
            }

            ul{
                margin-top: 5px;

                li{
                    font-size: 15px;

                    &:before{
                        top: 11px;
                    }
                }
            }
        }

        &__data-accordion_1{
            margin-bottom: 30px;
        }
        
        &__data-accordion-heading{
            padding-bottom: 5px;
            padding-right: 6px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;
        }
    }
}

.europe-physical-surveillance{
    display: flex;
    justify-content: center;

    &__page{        
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;

        display: flex;
        gap: 150px;
    }

    &__img{
        border-radius: 6px;
        overflow: hidden;
        max-width: 527px;
        
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__content{
        width: 656px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 35px;

        h2{
            font-h2(36px, font-color-dark);
        }
    }

    ul{
        margin-bottom: 60px;

        li{
            margin-bottom: 5px;
            font-p(17px, font-color-normal);
            marker-3();

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    &__button{
        button {
            button_green();
        }
    }

    @media (max-width: 1679px){
        &__page{
            gap: 66px;
        }

        &__content{
            width: 577px;
        }
    }

    @media (max-width: 1279px){
        &__page{        
            flex: 1 0 100%;
            max-width: 1440px;

            padding: 0 30px;

            gap: 45px;
            flex-direction: column;

            margin-bottom: 80px;
        }

        &__content{
            width: 100%;
        }

        .heading{
            padding-bottom: 10px;
            margin-bottom: 20px;

            h2{
                font-h3(20px, font-color-dark);
            }
        }

        ul{
            margin-bottom: 45px;

            li{
                font-p(15px, font-color-normal);
                // marker-3();
            }
        }
    }
}

