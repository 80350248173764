.footer {
  background-color: gray-black;
  font-size: 14px;
  color: #fff;
  line-height: 1;

  & > div {
    margin: 0 auto;
    // max-width: 1180px;

    max-width: 1440px;

    padding: 0 55px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  li {
    list-style: none;
    height: 50px;

  }

  .logo{
    svg{
      path{
        fill: #fff;
      }
    }
  }

  .nav-header {
    font-size: 16px;
    text-transform: uppercase;
    height: 58px;
  }

  .socials {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .soc {
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #878787;
      border-radius: 50%;
      width: 29px;
      height: 29px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, .2);
      }

      &_f {
        svg {
          width: 10px;
          height: 22px;
        }
      }
      &_vk {
        svg {
          width: 20px;
          height: 11px;
        }
      }
      &_twitter {
        svg {
          width: 18px;
          height: 15px;
        }
      }
    }
  }

  &__container_desc {
    .top-side {
      padding: 50px 0 17px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .left-side {
        position: relative;
        // padding: 0 30px 0 44px;

        padding: 0 30px 0 0;

        &:after {
          content: '';
          position: absolute;
          display: block;
          right: 0;
          top: -9px;
          background-color: #888888;
          width: 1px;
          height: 200px;
        }

        .logo {
          display: block;
          margin-bottom: 26px;
        //margin-top: -3px;

          svg {
            width: 196px;
            height: 38px;

            path, polygon{
              fill: white  !important;
            }
          }
        }

        .phone-cont {
          margin-bottom: 15px;

          .phone {
            font-weight: 700;
            margin-top: 3px;
          }
          .email {
            margin-top: 3px;
            font-weight: 700;
          }
        }

        .tech-email{
          margin-bottom: 10px;
        }
      }

      .center-side {
        flex-basis: 56%;

        .for-desc {
          display: flex;
          justify-content: space-between;

          nav {
            max-width: 142px;

            ul {
              padding: 0;
            }
          }
        }

        .for-tablet {
          display: none;
        }
      }

      .right-side {
        padding-right: 26px;
      }
    }

    .bottom-side {
      padding: 20px 16px 25px;
      border-top: 1px solid #888888;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  .footer__container_mobile {
    display: none;
  }

  @media (max-width: 1279px) {
    & > div {
      padding: 0 30px;
    }
  }

  @media (max-width: 1080px) {
    &__container_desc {
      // width: 96%;

      .top-side {
        padding: 32px 0 12px;

        .left-side {
          flex-basis: 234px ;
          padding-left: 8px ;
          padding-right: 28px ;

          &:after {
            display: none ;
          }

          .logo {
            margin-bottom: 22px ;

            svg{
              width: 177px;
              height: 34px;
            }
          }

          .phone-cont {
            margin-bottom: 15px ;

            .common-questions {
              display: none;
            }
          }

          .email {
            margin-bottom: 19px ;
          }
        }

        .center-side {
          flex-basis: 302px ;
          flex-grow: 1;

          .for-desc {
            display: none ;
          }
          .for-tablet {
            display: block ;
            padding: 22px 0 11px;
            border-right: 1px solid #888888;
            border-left: 1px solid #888888;

            nav {
              margin: 0 auto;
              max-width: 192px;
            }
            a {
              display: block;
              height: 33px;

              &:last-child{
                height: auto;
              }
            }
          }
        }

        .right-side{
          flex-basis: 206px ;
          padding:22px 0 11px;
          flex-grow: 1;

          nav{
            max-width: 120px;
            margin: 0 auto;

            .nav-header{
              height: 38px;
            }

            li{
              height: 33px;
            }
          }
        }
      }
      .bottom-side {
        padding: 18px 8px 13px ;

        div {
          font-size: 12px;

          &:last-child {
            margin-top: 9px;
            flex-basis: 100%;
          }
        }
      }
    }

  }

  @media (max-width: 620px) {
    &__container_desc{
      display: none;
    }
    &__container_mobile{
      display: block !important;
      // max-width: 350px !important;
      margin:0 auto !important;
      padding: 23px 0 17px;

      .logo{
        text-align: center;
        margin-bottom: 25px;
        display: block;

        svg{
          width: 177px;
          height: 34px;
        }
      }

      .contacts{
        margin-bottom: 18px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 0 8px;

        div{
          font-weight: 700;
        }
      }

      .socials{
        margin-bottom: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        .soc{
          width: 29px;
          height: 29px;
        }

        i:last-child{
          margin-right: 0;
        }
      }

      .information{
        padding: 18px 4px;
        border-top: 1px solid #888888;
        border-bottom: 1px solid #888888;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }

      .copyright{
        padding: 20px 8px 0;

        div{
          margin-bottom: 10px;
          font-size: 12px;

          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
