.page-internet-gpon__internet-plus-cable-tv {

  .accordion-rates__heading1,
  .accordion-rates__showcase1 {
    width: 400px;
    margin-right: 120px;
  }

  .accordion-rates__heading2 span:nth-child(2),
  .accordion-rates__showcase2 span:nth-child(2) {
    left: 180px;
  }

}

.page-internet-gpon__telephony {

  .telephony-physical-rates__header_1,
  .telephony-physical-rates__block_1 {
    max-width: 281px;
  }

  .telephony-physical-rates__block_3 {
    max-width: 452px;
  }

}
