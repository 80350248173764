.application-form {
  background: #F5F5F5;

  &__grid {
    display: grid;
    grid-template-columns: minmax(300px, 556px) 180px 304px;
    grid-gap: 30px;
    margin-top: 45px;

    input, textarea {
      font-size: 16px;
    }

    textarea {
      grid-column: span 2;
    }

    button {
      width: 280px;
      justify-self: end;
    }

    .form__response-msg, .msg-form-error {
      grid-column: span 3;
    }

    @media (max-width: 900px){
      grid-template-columns: 1fr;

      textarea {
        grid-column: span 1;
      }

      button {
        justify-self: start;
      }

      .form__response-msg, .msg-form-error {
        grid-column: span 1;
      }
    }

    @media (max-width: 425px){
      grid-gap: 15px;
      button {
        justify-self: center;
        margin-top: 10px;
      }
    }
  }

  .js-attachment-btn-wrap {
    grid-column: span 2;
    width: 260px;
    margin-bottom: 0;

    @media (max-width: 900px){
      grid-column: span 1;
    }
  }
}