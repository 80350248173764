font-color-light = #F8F8F8 !important;
font-color-normal = #535353 !important;
font-color-dark = #202020 !important;
font-color-green = #007A33 !important;
font-color-green-hover = #12B845 !important;
font-family-heading = 'Rubik', sans-serif;
font-family-content = 'Fira Sans', sans-serif;

font-h1(fontSize, fontColor) {
    font-family: font-family-heading;
    font-style: normal;
    font-weight: 500;
    font-size: fontSize;
    line-height: 120%;
    color: fontColor;
}

font-h2(fontSize, fontColor) {
    font-family: font-family-heading;
    font-style: normal;
    font-weight: 500;
    font-size: fontSize;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: fontColor;
}

font-h3-ls(fontSize, fontColor) {
    font-family: font-family-heading;
    font-style: normal;
    font-weight: 500;
    font-size: fontSize;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: fontColor;
}

font-h3(fontSize, fontColor) {
    font-family: font-family-heading;
    font-style: normal;
    font-weight: 500;
    font-size: fontSize;
    line-height: 150%;
    color: fontColor;
}

font-p-25-500(fontColor) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: fontColor;
}

font-p-20-500(fontColor) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 145%;
    letter-spacing: 0.02em;
    color: fontColor;
}

font-p-20-600(fontColor = font-color-light) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: fontColor;
}

font-p-300(fontSize, fontColor) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 300;
    font-size: fontSize;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: fontColor;
}

font-p-500(fontSize, fontColor) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 500;
    font-size: fontSize;
    line-height: 170%;
    color: fontColor;
}

font-p(fontSize, fontColor) {
    font-family: font-family-content;
    font-style: normal;
    font-weight: 400;
    font-size: fontSize !important;
    line-height: 170%;
    color: fontColor;
}

// marker-3() {
//     margin-left: 13px;
//     position: relative;
//     &:before {
//         content: '';
//         min-width: 3px;
//         min-height: 3px;
//         border-radius: 50%;
//         background-color: font-color-normal;

//         position: absolute;
//         top: 12px;
//         left: -10px;
//     }
//     @media (max-width: 1279px){
//         &:before {
//             top: 11px;
//         }
//     }
// }

marker-3(fontColor = font-color-normal) {
    margin-left: 13px;
    position: relative;
    &:before {
        content: '';
        min-width: 3px;
        min-height: 3px;
        border-radius: 50%;
        background-color: fontColor;

        position: absolute;
        top: 12px;
        left: -10px;
    }
    @media (max-width: 1279px){
        &:before {
            top: 11px;
        }
    }
}

//  зеленая кнопка, ховер светло зеленый
button_green() {
    display: flex;
    align-items: center;
    gap: 14px;

    padding: 15px 29px 15px 25px;

    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
    border-radius: 6px;

    font-family: font-family-content;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 150%;
    color: #FFFFFF;

    &:hover{
        background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
    }

    svg{
        path{
            fill: #FFFFFF;
        }
    }
}

//  зеленая кнопка - рамка, ховер темно зеленый
button_green_light() {
    button{
        transition: background 0.2s;

        display: flex;
        align-items: center;
        gap: 14px;

        padding: 10px 39px 10px 35px;

        border: 1px solid #007A33;
        border-radius: 6px;

        background: white;

        svg path{
            fill: #007A33;
        }

        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
        color: #007A33;
    }

    button:hover{
        border: 1px solid white;
        background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
        color: white;

        svg path{
            fill: white;
        }
    }

    &_active{
        button{
            border: 1px solid white;
            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            color: white;

            svg path{
                fill: white;
            }
        }
        button:hover{
            background: linear-gradient(180deg, #12B845 100%, #12B845 100%);
        }
    }

    button.disable{
        background: #E5E5E5;
        color: #FFFFFF;
        border: 1px solid white;

        svg path{
            fill: white;
        }
    }

    @media (max-width: 1279px) {
        button{
            padding: 10px 20px;
        }
    }
}

//  зеленая кнопка темно зеленая, ховер - рамка
button_green_light_revers() {
    button{
        transition: background 0.2s;

        display: flex;
        align-items: center;
        gap: 14px;

        padding: 10px 39px 10px 35px;

        border-radius: 6px;

        border: 1px solid white;
        background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
        color: white;
        svg path{
            fill: white;
        }

        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
    }

    button:hover{
        border: 1px solid #007A33;
        background: white;
        color: #007A33;
        svg path{
            fill: #007A33;
        }
    }

    &_active{
        button{
            border: 1px solid #007A33;
            background: white;
            color: #007A33;
            svg path{
                fill: #007A33;
            }
        }
        button:hover{
            background: linear-gradient(180deg, #12B845 100%, #12B845 100%);
        }
    }

    button.disable{
        background: #E5E5E5;
        color: #FFFFFF;
        border: 1px solid white;

        svg path{
            fill: white;
        }
    }

    @media (max-width: 1279px) {
        button{
            padding: 10px 20px;
        }
    }
}

//  зеленая кнопка с тенью, ховер светло зеленая без тени
button_green_shadow() {
    button{
        transition: all 0.2s;

        display: flex;
        align-items: center;
        gap: 14px;

        padding: 10px 29px 10px 25px;

        border-radius: 6px;
        background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
        box-shadow: 0px 2px 6px rgba(1, 84, 29, 0.8), 0px 20px 48px rgba(1, 84, 29, 0.4), 0px 40px 160px rgba(1, 84, 29, 0.9);

        svg path{
            fill: white;
        }

        font-family: 'Fira Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #FFFFFF;
    }

    button:hover{
        background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
        box-shadow: 0px 0px 0px rgba(1, 84, 29, 0.8), 0px 20px 48px rgba(1, 84, 29, 0.4), 0px 40px 160px rgba(1, 84, 29, 0.9);
    }
}


.page.package {
    max-width: 1330px;

    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 170%;
    color: #535353;
}

.heading.package {
    h2 {
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: #202020;
    }

    @media (max-width: 1279px) {
        h2 {
            font-size: 20px;
            line-height: 150%;
        }
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page {
    max-width: 1170px;
    flex: 1 0 100%;

    display: flex;
    flex-direction: column;

    font-family: $font-family-content;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #535353;

    p {
        margin-bottom: 0px;
    }

    @media (max-width: 1200px) {
        padding: 20px 15px 0;
    }
}

.heading {
    display: flex;

    h1 {
        //font-family: 'PT Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 46px;
        line-height: 52px;
        letter-spacing: 0.01em;
        color: #202020 !important;

        margin-bottom: 48px;
        padding-top: 60px;
    }

    h2 {
        //font-family: 'PT Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #202020 !important;
    }

    h3 {
        //font-family: 'PT Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.01em;
        color: #202020 !important;

        margin-bottom: 0px;
    }

    @media (max-width: 1200px) {
        h1 {
            padding-top: 0px;
        }
    }

    @media (max-width: 452px) {
        h1 {
            text-align: start;
            font-size: 35px;
        }
    }
}

.bgc-green {
    color: white !important;
}
