.menu-global{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1796px;

        padding: 0 98px;

        z-index: 2;
    }
    &__logo{
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 96px;

        border-bottom: 1px solid rgba(245, 241, 248, 0.45);
        margin-top: 10px;
        margin-bottom: 20px;
    }
    &__logo-img, &__logo-contacts{
        display: flex;
        gap: 60px;
    }
    &__logo-img{
        &-home{
            width: 199px;

            position: relative;
            top: -5px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &-geo{
            display: flex;
            align-items: center;
            gap: 7px;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 145%;
                letter-spacing: 0.02em;
                color: #F5F1F8;
            }
        }
    }
    &__logo-contacts{
        a{
            text-decoration: none;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 145%;
            letter-spacing: 0.02em;
            color: #F5F1F8;

            transition: all 0.3s;

            &:hover{
                color: #12B845;
            }
        }
    }
    &__nav{
        height: 90px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &-button{
            display: flex;
            gap: 35px;

            button{
                display: flex;
                align-items: center;
                gap: 10px;

                background: transparent;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 145%;
                letter-spacing: 0.02em;
                color: #F5F1F8;

                transition: all 0.3s;

                &:hover{
                    color: #12B845;

                    svg{
                        path{
                            stroke: #12B845;
                        }
                    }
                }
            }

            .button_passive{
                color: #F5F1F8;
            }

            .button_active{
                color: #12B845;
            }
        }

        &-account{
            cursor pointer;

            svg{
                path{
                    stroke: white;

                    transition: all 0.3s;
                }
            }

            &:hover{
                svg{
                    path{
                        stroke: #12B845;
                    }
                }
            }
        }
        &-links{
            position: relative;
        }
    }
    &__menu{
        border-top: 1px solid rgba(18, 184, 69, 0.4);

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;

        display: flex;
        gap: 80px;

        padding-top 40px;

        span{
            height: 29px;

            margin-bottom 20px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 145%;
            letter-spacing: 0.02em;
            color: #F5F1F8;
        }

        a{
            height: 29px;

            margin-bottom 15px;

            text-decoration none;

            width: fit-content;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 170%;
            letter-spacing: 0.03em;
            color: #F5F1F8;

            &:hover{
                color: #12B845;
            }
        }

        div{
            display: flex;
            flex-direction: column;
        }

        &_active{
            border-top: 1px solid rgba(18, 184, 69, 0.4);

            a{
                transition: font-size 0.3s 0.1s;
            }
            span{
                transition: font-size 0.3s 0.1s;
            }
        }

        &_passive{
            border-top: 1px solid rgba(18, 184, 69, 0);
            a{
                height: 0;
                color: transparent;

                margin-bottom 0;
                font-size: 0;

                transition: all 0.1s;
            }
            span{
                height: 0;
                color: transparent;

                margin-bottom 0;
                font-size: 0;

                transition: all 0.1s;
            }
        }
    }
    &__BG{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        background: linear-gradient(2.28deg, rgba(14, 26, 19, 0) 2.6%, rgba(14, 26, 19, 0.3) 18.67%, rgba(14, 26, 19, 0.8) 39.82%, #0E1A13 61.95%);

        height: 700px;

        transition: all 0.3s;
        z-index: 1;

        &_passive{
            top: -1000px;
        }

        display: flex;
        justify-content: center;
    }
    &__BG-banner{
        position: absolute;
        top: 260px;
        right: 58px;

        transition: all 0.3s;

        &_passive{
            top: -1000px;
        }
    }
    &__BG-banner-content{
        background: url(/src/img/menu/menu_offer.jpg) no-repeat center;
        height: 260px;
        width: 595px;

        padding: 55px 65px;

        span{
            display: block;
            width: 189px;
            margin-bottom: 35px;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: #FFFFFF;
        }

        a{
            display: inline-flex;
            gap: 14px;
            align-items: center;

            padding: 10px 38px 10px 35px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            border-radius: 6px;

            text-decoration: none;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: #FFFFFF;

            svg{
                path{
                    fill: white;
                }
            }

            &:hover{
                background: #12B845;
            }
        }
    }
    &__mobile{
        display: none;
    }

    @media (max-width: 1526px){
        &__page, &__BG-page{
            max-width: 1440px;

            padding: 0 55px;
        }
        &__BG-banner{
            right: 55px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0;
        }
        &__logo, &__nav, &__nav-links, &__BG, &__BG-banner{
            display: none;
        }
        &__mobile{
            display: flex;
            flex-direction: column;

            width: 100%;
            position relative;
        }

        &__mobile-top{
            padding: 12px 30px 0;

            display: flex;
            justify-content: space-between;
            align-items: center;

            z-index: 1;
        }

        &__mobile-logo{
            width: 116px;

            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &__mobile-sidebar{
            display: flex;
            gap: 31px;
            align-items: center;

            padding-top: 6px;
        }

        &__mobile-phone{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: linear-gradient(170.97deg, #12B845 6.87%, #007A33 44.45%, #00551D 72.29%);

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__mobile-burger{
            width: 28px;
            height: 16px;
            display: flex;

            span{
                transition: top 0.1s, opacity 0s 0.1s, transform 0.1s 0.1s, background-color 0s 0.1s;

                position: absolute;

                display: block;
                width: 20px;
                height: 2px;
                background-color: white;

                border-radius: 1px;

                right: 0;

                &:nth-child(1){
                    top: 0;
                    transform: rotate(0deg);
                }
                &:nth-child(2){
                    top: 7px;
                    opacity: 1;
                }
                &:nth-child(3){
                    top: 14px;
                    transform: rotate(0deg);
                }
            }

            &_active{
                span{
                    background-color: #848484;

                    &:nth-child(1){
                        top: 7px;
                        transform: rotate(45deg);
                    }
                    &:nth-child(2){
                        top: 7px;
                        //display: none;
                        opacity: 0;
                    }
                    &:nth-child(3){
                        top: 7px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &__mobile-content{
            background: #202020;

            position: absolute;
            height: fit-content;
            width: 100%;

            padding: 82px 30px 40px;

            left: 0;
            top: -1500px;

            transition: top 0.5s;

            &_active{
                top: 0;
            }
        }

        &__mobile-content-links{
            margin-bottom: 85px;
            &-card{
                &-header{
                    transition: all 0.3s;

                    padding-top: 30px;
                    border-bottom: 1px solid rgba(245, 241, 248, 0.45);
                    //margin-bottom: 25px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span{
                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 170%;
                        color: #FFFFFF;
                    }

                    &-img{
                        margin-right: 10px;

                        svg{
                            transition: all 0.3s;
                            transform: rotate(90deg);
                            path{
                                stroke: #C6C6C6;
                            }
                        }

                        &_active{
                            svg{
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    &_active{
                        margin-bottom: 25px;
                    }
                }

                &-ul{
                    display: flex;
                    flex-direction: column;

                    a{
                        transition: all 0.3s;
                        height: 0;
                        display: block;

                        margin-bottom: 0;

                        text-decoration: none;

                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 170%;
                        color: transparent;

                        &:last-child{
                            margin-bottom: 0;
                        }
                    }

                    &_active{
                        a{
                            margin-bottom: 20px;
                            height: 26px;

                            color: #C6C6C6;
                        }
                    }
                }
            }
        }
        
        &__mobile-content-contacts{
            display: flex;
            flex-direction: column;
            align-items: center;

            &-img-geo{
                display: flex;
                align-items: center;
                gap: 7px;

                margin-bottom: 40px;

                span{
                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 170%;
                    color: #F5F1F8;
                }
            }

            &-phone{
                margin-bottom: 10px;

                a{
                    text-decoration: none;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 145%;
                    letter-spacing: 0.02em;
                    color: #F5F1F8;
                }
            }

            &-mail{
                //margin-bottom: 10px;
                margin-bottom: 40px;

                a{
                    text-decoration: none;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 170%;
                    color: #12B845;
                }
            }
            &-telegram{
                margin-bottom: 40px;

                a{
                    text-decoration: none;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 170%;
                    color: #F5F1F8;
                }
            }

            &-call{
                width: 100%;
                padding-bottom: 50px;
                border-bottom: 1px solid rgba(245, 241, 248, 0.25);
                margin-bottom: 50px;

                display: flex;
                justify-content: center;

                button{
                    padding: 10px 30px;

                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    border-radius: 6px;

                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 25px;
                    letter-spacing: 0.05em;
                    font-variant: small-caps;
                    color: #FFFFFF;
                }
            }
        }
        &__mobile-content-footer{
            display: flex;
            flex-direction: column;

            span, a{
                text-decoration: none;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 170%;
                letter-spacing: 0.02em;
                color: #C6C6C6;
            }

            &-links{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;

                margin-bottom: 40px;
            }
        }
    }
}




