.telephony-physical{
    background: url(/src/img/pages/BG_telephony.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;

        font-p-300(17px, font-color-light);
    }

    .heading{
        padding-top: 275px;
        margin-bottom: 20px;

        span{
            margin-bottom: 0px;
            padding-top: 0px;

            font-h1(52px, font-color-light);
        }
    }

    &__content{
        max-width: 658px;

        padding-bottom: 45px;
        border-bottom: solid 1px rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        p{
            margin-bottom: 0;

            font-p-300(17px, font-color-light);
        }
    }

    ul{
        li{
            position relative;

            display: flex;
            align-items: center;

            padding-left: 13px;
            margin-bottom: 5px;

            font-p-300(17px, font-color-light);

            &:before{
                content: '';
                height: 3px;
                min-width: 3px;
                background: rgba(245, 241, 248, 0.45);
                border-radius: 50%;
                margin-right: 10px;

                position absolute;
                left: 0;
                top: 12px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/BG_telephony_mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 776px;
        //height: auto;

        &__page{
            max-width: 100%;

            padding: 0 30px;
        }

        .heading{
            padding-top: 357px;
            margin-bottom: 25px;

            span{
                text-align: left;
                font-size: 30px;
            }
        }

        &__content{
            max-width: 100%;

            padding-bottom: 40px;
            margin-bottom: 25px;
        }

        ul{
            margin-bottom: 80px;
        }
    }
}

.telephony-physical-introduction{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 120px;
    }

    .heading{
        margin-bottom: 25px;

        h1{
            margin-bottom: 0px !important;
            padding-top: 0px !important;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        max-width: 927px;

        p{
            margin-bottom: 0px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }


    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 20px;

            h1{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            max-width: 100%;

            p{
                font-size: 15px;
            }
        }
    }
}

.telephony-physical-rates{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px;
        margin-bottom: 120px;
    }

    .heading{
        margin-bottom: 82px;

        h2{

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__header{
        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;

        display: flex;

        span{
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 170%;
            text-align: right;
            color: #848484;
        }

        &_1{
            max-width: 388px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            margin-right: 110px;
        }

        &_2{
            max-width: 200px;
            display: flex;
            flex: 1 0 100%;

            margin-right: 75px;
        }
    }

    &__wrap{
        position: static;
    }

    &__list{
        position: static;
    }

    &__block{
        display: flex;
        align-items: center;

        height: 165px;

        border-bottom: 1px solid #E5E5E5;

        &_1{
            max-width: 388px;

            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            margin-right: 110px;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #007A33;
            }
        }

        &_2{
            max-width: 200px;
            display: flex;
            flex-direction: column;
            
            flex: 1 0 100%;
            margin-right: 75px;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }

            &-1{
                display: none;
            }
        }

        &_3{
            max-width: 530px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1 0 100%;

            &-price{
                display: flex;
                flex-direction: column;

                span{
                    font-family: 'Fira Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 170%;
                    color: #535353;
                }
            }

            &-bold{
                font-weight: 500 !important;
            }
        }    
    }

    &__button{
        button{
            padding: 10px 38px 10px 35px;

            display: flex;
            gap: 13px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px !important;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: #007A33;
        }
    }

    &__products-buttons{
        display: none;
    }

    @media (max-width: 1679px){
        &__header{
            &_1{
                max-width: 281px;;
            }
        }

        &__block{
            height: 125px;

            &_1{
                max-width: 281px;;
            }

            &_3{
                max-width: 452px;;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px;
            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 20px;
            padding: 0 30px;

            h2{
                font-size: 17px;
                line-height: 150%;
            }
        }

        &__header{
            display: none;
        }

        &__wrap{
            width: 100%;
            height: 390px;
            overflow: hidden;

            margin-bottom: 20px;

            position: relative;
        }

        &__list{
            display: flex;
            gap: 10px;

            position: absolute;
            top: 10px;
            left: 10px;

            transition: left 1s;
        }

        &__block{
            flex-direction: column;

            height: 356px;
            width: 300px;
            min-width: 300px;

            padding: 30px 20px 45px;

            border: 1px solid #F8F8F8;
            border-radius: 6px;
            box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);

            &_1{
                max-width: 100%;
                width: 100%;

                margin-right: 0px;
                margin-bottom: 25px;

                flex: 0 0 auto;
            }

            &_2{
                display: none;

                &-1{
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    &-header{
                        width: 100%;

                        padding-bottom: 5px;
                        border-bottom: 1px solid #E5E5E5;
                        margin-bottom: 5px;

                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                        letter-spacing: 0.01em;
                        color: #848484;
                    }

                    &-content{
                        margin-bottom: 30px;

                        display: flex;

                        span{
                            &:first-child{
                                margin-right: 4px;
                            }

                            font-family: 'Fira Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 170%;
                            color: #535353;
                        }
                    }
                }
            }

            &_3{
                max-width: 100%;
                width: 100%;
                
                justify-content: flex-start;
                align-items: flex-start;
                flex: 1 0 auto;

                flex-direction: column;

                &-price{
                    margin-bottom: 40px;

                    flex-direction: row;

                    span{
                        font-size: 15px;

                        &:first-child{
                            margin-right: 4px;
                        }
                    }
                }
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.telephony-physical-international{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px;
        margin-bottom: 240px;
    }

    .heading{
        margin-bottom: 80px;

        h2{

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__buttons{
        margin-bottom: 80px;

        display: flex;
        gap: 20px;

        .button-telephony{
            cursor: pointer;

            border-bottom: 1px solid #007A33;

            &_active, &:hover{
                color: #12B845;
                border-bottom: 1px solid #12B845;

                button{
                    color: #12B845 !important;
                    border-bottom: 2px solid #12B845 !important;
                }
            }

            button{
                border-radius: 0;
                border-bottom: 2px solid transparent;

                padding: 15px 85px 10px 0;
                background-color: white;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #007A33;
            }
        }

        &-select-wrap{
            display: none;
        }
    }

    &__header{
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        gap: 110px;

        padding-bottom: 8px;

        &-1{
            width: 388px;
            display: flex;
            justify-content: space-between;
        }

        &-2{
            display: flex;
        }

        span{
            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #848484;
        }
    }

    &__wrap{
        position: static;
    }

    &__cards{
        position: static;
    }

    &__card{
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        gap: 110px;

        padding: 40px 0;

        &-1{
            width: 388px;
            min-width: 388px;
            display: flex;
            justify-content: space-between;

            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #535353;
            }
        }

        &-2{
            span{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }

            &-1{
                display: none;
            }
        }
    }

    &__products-buttons{
         display: none;
    }

    @media (max-width: 1679px){
        &__header{
            &-1{
                width: 280px;
            }
        }

        &__card{
            &-1{
                width: 280px;
                min-width: 280px;
            }
        }
    }

    @media (max-width: 1279px){
        &__page{
            flex: 1 0 100%;
            max-width: 100%;

            padding: 0px;
            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 30px;
            padding: 0 30px;

            h2{
                font-size: 17px;
                line-height: 150%;
            }
        }

        &__buttons{
            display: none;

            &-select-wrap{
                display: block;

                padding: 0 30px;
                margin-bottom: 15px;
            }

            &-select{
                width: 100%;
                padding: 10px 20px;

                background: #FFFFFF;
                border: 1px solid #E5E5E5;
                border-radius: 6px;

                &:focus {
                    outline: none;
                }
            }
        }

        &__header{
            display: none;
        }

        &__wrap{
            width: 100%;
            overflow: hidden;

            margin-bottom: 20px;

            height 375px;

            position: relative;
        }

        &__cards{
            position: absolute;
            top: 15px;
            left: 10px;

            display: flex;
            gap: 10px;

            transition: left 1s;
        }

        &__card{
            box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);
            border: 1px solid #F8F8F8;
            border-radius: 6px;

            width: 300px;
            min-width: 300px;
            height: 340px;

            flex-direction: column;
            gap: 0;

            padding: 30px 20px;

            &-1{
                width: 100%;
                min-width: 100%;
                height: 55px;
                min-height: 55px;

                span{
                    font-size: 17px;

                    &:last-child{
                        width: 105px;
                    }
                }
            }

            &-2{
                -webkit-mask-image: linear-gradient(to bottom, white 45%, transparent);
                overflow-y: auto;

                height: 100%;

                &::-webkit-scrollbar {
                    width: 0;
                }

                span{
                    font-size: 15px;
                    padding-bottom: 100px;
                }

                &-1{
                    display: block;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;
                    margin-bottom: 5px;

                    span{
                        font-family: 'Fira Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 150%;
                        letter-spacing: 0.01em;
                        color: #848484;
                    }
                }
            }
        }

        &__products-buttons{
            overflow: hidden;

            -webkit-mask-image: linear-gradient(to right, white 45%, transparent);
            //overflow-x: scroll;
            //
            //&::-webkit-scrollbar {
            //    width: 0px;
            //}


            display: flex;
            gap: 10px;

            padding-left: 30px;
            padding-right: 30px;

            .button{
                height: 4px;
                width: 35px;
                min-width: 35px;

                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.telephony-physical-sip{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 240px 55px 0;
        margin-bottom: 110px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 55px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        max-width: 927px;

        p{
            margin-bottom: 20px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;

            &:last-child{
                margin-bottom: 0px;
            }
        }
    }

    @media (max-width: 1679px){
        &__page{
            padding: 160px 55px 0;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 45px;
        }

        .heading{
            padding-bottom: 10px;
            margin-bottom: 20px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            max-width: 100%;

            p{
                font-size: 15px;
            }
        }
    }
}

.telephony-physical-advantages{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;

        display: flex;
        gap: 55px;
    }

    &__card{
        max-width: 281px;

        &-img{
            margin-bottom: 25px;
            height: 91px;

            img{
                display: block;
                height: 100%;
                width: auto;
            }
        }

        &-text{
            p{
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 1679px){
        &__page{
            gap: 40px;

            margin-bottom: 160px;
        }

        &__card{
            max-width: 256px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            gap: 30px;
            flex-direction: column;

            padding: 0px 30px;
            margin-bottom: 80px;
        }

        &__card{
            max-width: 100%;

            &-img{
                margin-bottom: 10px;
            }

            &-text{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}

.telephony-offer{
    &__description{
        display: flex;
        gap: 40px;

        margin-bottom: 100px;

        li{
            display: flex;
            align-items: center;
            gap: 8px;

            margin-bottom: 8px;

            img{
                align-self: flex-start;
                padding-top: 7px;
            }
        }
    }

    @media (max-width: 900px) {
        &__description{
            flex-direction: column;
        }
    }
}

.telephony-info{
    max-width: 767px;

    p{
        //font-family: 'PT Sans';
        font-style: normal;
        font-size: 28px;
        line-height: 44px;
        letter-spacing: 0.01em;
        color: green;

        margin-bottom: 88px;
    }
}

.telephony-rates{
    .lermontovskiy-telephony__list{
        margin-bottom: 144px;
    }
}

.international-communication{
    .heading{
        margin-bottom: 37px;
    }

    &__filters{
        display: flex;
    }

    &__changer{
        display: flex;
        gap: 12px;

        margin-bottom: 75px;
        margin-right: 85px;

        &-box{
            width: 44px;
            height: 24px;
            background: linear-gradient(308.02deg, #3EAB60 10.59%, #007A33 71.5%);
            border-radius: 12px;
            cursor: pointer;

            display: flex;
            align-items: center;

            position relative;
        }

        &-button{
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin: 2px;
            background: #FFFFFF;
            box-shadow: 0px 1px 2px #00551D;
            position: absolute;
            transition: all 0.1s linear;

            &_active{
                left: 20px;
            }

            &_passive{
                left: 0px;
            }
        }

        &-text, &-text-2{
            font-style: normal;
            font-size: 20px;
            line-height: 22px;
            
            &_active{
                color: #202020;
            }

            &_passive{
                color: #848484;
            }
        }
    }

    &__checkboxes{
        gap: 72px;

        &_active{
            display: flex;
        }

        &_passive{
            display: none;
        }

        input, label{
            cursor: pointer;
        }

        input{
            display: none;
        }

        label{
            position relative;
        }

        label::before{
            content: '';
            position absolute;
            left: -28px;
            top: 0;

            width: 16px;
            height: 16px;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 2px;
        }

        input:checked + label:before {
            // content: attr(data-onlabel);
            background: #12B845;
            border: 1px solid #12B845;

            // display: flex;
            // align-items: center;
            // justify-content: center;

            // color: white;
        }

        input:checked + label:after {
            content: '';
            position absolute;
            left: -22px;
            top: 2px;

            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
    
    &__header{
        display: flex;
        gap: 98px;
        padding-bottom: 8px;
        border-bottom: 1px solid #E5E5E5;

        &-1{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1 0 100%;

            max-width: 320px;
        }

        span{
            font-family: $font-family-content;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #848484;
        }
    }

    &__item{
        gap: 98px;
        padding: 46px 0;
        border-bottom: 1px solid #E5E5E5;

        &-1{
            display: flex;
            justify-content: space-between;
            flex: 1 0 100%;

            max-width: 320px;

            span{
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                color: #007A33;
            }

            span:nth-child(1){
                max-width: 170px;
            }
        }

        &-2{
            span{
                font-family: $font-family-content;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #535353;
            }
        }

        &_active{
            display: flex;
        }

        &_passive{
            display: none;    
        }
    }

    &__list{
        margin-bottom: 144px;

        &-1{
            &_active{
                display: block;
            }
            &_passive{
                display: none;
            }
        }

        &-2{
            &_active{
                display: block;
            }
            &_passive{
                display: none;
            }
        }
    }

    @media (max-width: 1200px) {
        &__changer{
            margin-bottom: 50px;
            margin-right: 0px;
        }

        &__filters{
            flex-direction: column;
        }

        &__checkboxes{
            gap: 50px;
            padding-left: 30px;
            margin-bottom: 50px;

            flex-wrap: wrap;
        }
    }

    @media (max-width: 700px) {
        &__header{
            // display: flex;
            gap: 15px;
            // padding-bottom: 8px;
            // border-bottom: 1px solid #E5E5E5;

            flex-direction: column;

            &-1{
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // flex: 1 0 100%;

                // max-width: 320px;
            }

            span{
                // font-family: $font-family-content;
                // font-style: normal;
                // font-weight: 400;
                // font-size: 12px;
                // line-height: 16px;
                // color: #848484;
            }
        }

        &__item{
            gap: 15px;
            // padding: 46px 0;
            // border-bottom: 1px solid #E5E5E5;

            flex-direction: column;

            &-1{
                // display: flex;
                // justify-content: space-between;
                // flex: 1 0 100%;

                // max-width: 320px;

                span{
                    // font-family: 'PT Sans';
                    // font-style: normal;
                    // font-weight: 700;
                    // font-size: 20px;
                    // line-height: 22px;
                    // color: #007A33;
                }

                span:nth-child(1){
                    // max-width: 170px;
                }
            }

            &-2{
                span{
                    // font-family: $font-family-content;
                    // font-style: normal;
                    // font-weight: 400;
                    // font-size: 16px;
                    // line-height: 28px;
                    // color: #535353;
                }
            }

            &_active{
                // display: flex;
            }

            &_passive{
                // display: none;    
            }
        }
    }
}