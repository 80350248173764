@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

// @font-face {
//   font-family: 'Open Sans';
//   src: url('fonts/opensans/opensans.woff2') format('woff2'),
//           url('fonts/opensans/opensans.woff') format('woff'),
//           url('fonts/opensans/opensans.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('fonts/opensans/opensanssemibold.woff2') format('woff2'),
//           url('fonts/opensans/opensanssemibold.woff') format('woff'),
//           url('fonts/opensans/opensanssemibold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('fonts/opensans/opensansbold.woff2') format('woff2'),
//           url('fonts/opensans/opensansbold.woff') format('woff'),
//           url('fonts/opensans/opensansbold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Open Sans';
//   src: url('fonts/opensans/opensanscondensedbold.woff2') format('woff2'),
//           url('fonts/opensans/opensanscondensedbold.woff') format('woff'),
//           url('fonts/opensans/opensanscondensedbold.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Rubik';
  src: url('fonts/Rubik/Rubikbold.woff2') format('woff2'),
          url('fonts/Rubik/Rubikbold.woff') format('woff'),
          url('fonts/Rubik/Rubikbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'Montserrat';
//   src: url('fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
//   font-weight: 800;
//   font-style: normal;
//   font-display: swap;
// }

/* cyrillic-ext */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/ptsans/jizaRExUiTo99u79D0-ExdGM.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/ptsans/jizaRExUiTo99u79D0aExdGM.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/ptsans/jizaRExUiTo99u79D0yExdGM.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/ptsans/jizaRExUiTo99u79D0KExQ.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('fonts/ptsans/jizfRExUiTo99u79B_mh0OOtLQ0Z.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('fonts/ptsans/jizfRExUiTo99u79B_mh0OqtLQ0Z.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('fonts/ptsans/jizfRExUiTo99u79B_mh0OCtLQ0Z.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'PT Sans';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('fonts/ptsans/jizfRExUiTo99u79B_mh0O6tLQ.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
/* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2') format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2') format('woff2');
//   unicode-range: U+0370-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2') format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmSU5fBBc4.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu72xKOzY.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu5mxKOzY.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu7mxKOzY.woff2') format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu4WxKOzY.woff2') format('woff2');
//   unicode-range: U+0370-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu7WxKOzY.woff2') format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu7GxKOzY.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('fonts/roboto/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2') format('woff2');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2') format('woff2');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* greek-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2') format('woff2');
//   unicode-range: U+1F00-1FFF;
// }
// /* greek */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2') format('woff2');
//   unicode-range: U+0370-03FF;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2') format('woff2');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2') format('woff2');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
//   src: url('fonts/roboto/KFOlCnqEu92Fr1MmEU9fBBc4.woff2') format('woff2');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
