.topline{
  margin: 0 auto;
  padding: 12px 0;
  font-size: 14px;
  color: gray-black;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border: border;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;

  a{
    text-decoration: none;
    color: gray-black;
  }

  .tlocation{
    span{
      display: inline-block;
      padding: 0 15px;
    }

    &__links{
      display: inline-block;

      a{
        &.active-link{
          font-weight: 700;
        }
      }
    }
  }

  .tnav{
    .dropdown, &__contacts{
      display: inline-block;
      padding: 0 25px 0 20px;
    }

    .dropdown{
      position: relative;
      cursor: pointer;
      z-index: 3;
      padding-right: 52px;

      &:before{
        content: '';
        position: absolute;
        top: calc(50% - 6px);
        right: 33px;
        display: block;
        width: 10px;
        height: 10px;
        background: url(img/svg/arrow_mobile.svg) no-repeat;
        transform: rotate(-90deg);
      }

      &__content{
        display: none;
        position: absolute;
        padding: 9px 0;
        top: 22px;
        left: -20px;
        border: border;
        border-radius: 7px;
        background-color: #fff;
        width: 168px;
        transition: all .3s;

        &:before{
          content: '';
          position: absolute;
          top: -6px;
          display: block;
          background-color: #fff;
          transform: rotate(45deg);
          border: 1px solid #ebebeb;
          border-bottom: none;
          border-right: none;
          width: 10px;
          height: 10px;
          right: 23.96px; 
        }

        a{
          display: block;
          padding: 12px 10px;
          text-align: center;
          
          &:hover{
            background-color: #ebebeb;
          }
        }
      }

      &_active{
        .dropdown__content{
          display: block;
        }
      }
    }

    &__contacts{
      &:before{
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        display: block;
        background-color: #ebebeb;
        width: 1px;
        height: calc(100% + 4px);
      }
    }
  }
    
  @media (max-width: 640px){
    margin: 0 9px;

    .tlocation{
      span{
        display: none;
      }

      &__links{
        padding-left: 10px;
      }
    }
  }
    
  @media (max-width: 500px){
    .tnav{
      .dropdown{
        display: none;
      }

      &__contacts{
        padding: 0 10px 0 0;

        &:before{
          display: none;
        }
      }
    }
  }  
}





