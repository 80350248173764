.cable-tv-adv {
  --white: #FFF;
  --green-pantone: #007A33;
  --green-bright: #12B845;
  --button-bg-hover: var(--green-pantone);
  --button-bg-active: var(--green-bright);
  --button-bg-gradient: linear-gradient(180deg, #3EAB60 0%, #007A33 100%);
  --border-radius: 6px;

  background: url('/src/img/pages/cable-tv-adv.jpg') 50% 50% / cover no-repeat;
  min-height: 260px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 40px 65px;
  padding: 20px 72px;

  @media (max-width: 650px) {
    background: url('/src/img/pages/cable-tv-adv_sm.jpg') 50% 0 / auto no-repeat;
    padding: 262px 40px 70px;
    align-content: flex-start;
  }

  &__text {
    color: var(--white, #fff);
    font-family: Rubik, Fira Sans, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: 0.25px;
  }

  /* duplication with physical/order_sydney styles */
  .btn {
    display: inline-flex;
    vertical-align: middle;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
    font-family: 'Fira Sans', sans-serif;
    color: var(--white);
    background: var(--button-bg-hover);
    cursor: pointer;
    border: none;
    outline: none;
    transition: all .2s;
    padding: 20px 40px;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: .25px;

    @media (max-width: 768px) {
      padding: 15px 25px;
      font-size: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--button-bg-gradient);
      transition: opacity .2s;
    }

    &:hover::before {
      opacity: 0;
    }

    &:active {
      background: var(--button-bg-active);
    }

    > span {
      position: relative;
      padding-top: 0.1em;
      margin: -0.05em 0;
    }

    svg {
      position: relative;
    }
  }

  .button-shadow-1 {
    box-shadow: 0px 40px 160px 0px rgba(1, 84, 29, 0.90), 0px 20px 48px 0px rgba(1, 84, 29, 0.40), 0px 2px 6px 0px rgba(1, 84, 29, 0.80);
  }
}
