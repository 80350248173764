@import '../../../node_modules/slick-carousel/slick/slick.css';

.slider{
  width: 100%;
  height: 540px;
  margin-top: 48px;
  overflow: hidden;

  button{
    padding: 0;
  }

  .slick-dots{
    position: absolute;
    bottom: 28px;
    right: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    li{
      list-style: none;
      margin-right: 14px;

      button{
        font-size: 0;
        outline: none;
        width: 14px;
        height: 14px;
        border: 3px solid #fff;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        text-indent: -9999px
      }

      &.slick-active{
        button{
          background-color: #c8c8c8;
        }
      }
    }
  }
  .slick-prev,.slick-next{
    position: absolute;
    width: 54px;
    height: 150px;
    background-color: rgba(255,255,255,0.2);
    top: calc(50% - 75px);
    font-size: 0;
    outline: none;
    border: 0;
    z-index: 999;
    cursor: pointer;
    transition: all .2s;
    text-indent: -99999px;

    &:hover{
      background-color: rgba(255,255,255,0.3);
    }

    &:before, &:after{
      content: '';
      position: absolute;
      display: block;
      background-color: #fff;
      width: 21px;
      height: 2px;
      top: 50%;
      left: calc(50% - 11px);
      transform-origin: left center;
    }
  }
  .slick-prev{
    &:before{
      transform: rotate(-45deg);
    }
    &:after{
      transform: rotate(45deg);
    }
  }
  .slick-next{
    right: 0;
    left: auto;

    &:before, &:after {
      transform-origin: right center;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }

  .slick-list, .slick-track{
    height: 100%;

    .slick-slide{
      & > div{
        height: 100%;
      }
    }
  }

  .slide{
    width: 100%;
    height: 100%;

    .appeal{
      font-size: 36px;
      font-weight: 700;
      display: block;
    }

    .ul-appeal{
      font-size: 24px;

      li{
        &:before{
          color: black !important;
          content: '•';
          top: 1px;
        }
      }
    }
  }

  @media (max-width: 768px){
    height: 456px;
  }
  @media (max-width: 600px){
    height: 380px;
  }

//main
  &_main{
    height: 540px;
    margin-top: -50px;

    .slide{

      .cont{
        height: 100%;
      }

      &_babyin{
        background: #000 url(img/slider/babyin.webp) 50% 50%/cover no-repeat;

        .cont{
          .inner{
            position: absolute;
            top: 171px;
            right: 0;

            p{
              color: #fff;
              font-size: 36px;
              font-weight: 700;
              text-align: center;
              margin-bottom: 53px;
            }
            span{
              color: yellow;
              font-size: 26px;
              font-weight: 700;
            }
          }
        }

        @media (max-width: 768px){
          background: url(img/slider/babyin.webp) 21% 50%/cover no-repeat #000;
          padding: 10px 0;

          .cont{
            .inner{
              max-width: 340px;
              margin: 0 auto;
              position: relative;
              top: inherit;
              right: inherit;

              p{
                font-size: 26px;
                margin-bottom: 25px;
              }
              span{
                font-size: 24px;
                display: inline-block;
                text-align: center;
                line-height: 1.2;
              }
            }
          }
        }

        @media (max-width: 600px){
          background-position: 30% 50%;

          .cont{
            .inner{
              max-width: 310px;

              p{
                font-size: 20px;
                margin-bottom: 25px;
              }
              span{
                font-size: 18px;
              }
            }
          }
        }
      }

      //stock-together
      &_stock-together{
        background: #000 url(img/slider/slide_stock-together.png) 50% 50%/cover no-repeat;
        padding-top: 50px;
        font-family: 'Montserrat';
        color: #fff;

        @media (max-width: 425px){
          padding-top: 23px;
          background-position: 60% 50%;
          background-image: url(img/slider/slide_stock-together--mobile.png);
        }

        .st-{
          &top{
            font-size: 26px;
            margin-bottom: 35px;

            span{
              font-weight: 700;
            }

            @media (max-width: 425px){
              margin-bottom: 10px;
              font-size: 14px;
            }
          }
          &main{
            z-index: 2;
            font-size: 58px;
            font-weight: 800;
            width: 422px;
            line-height: 62px;
            text-transform: uppercase;
            background: linear-gradient(to right, #c99747, #fbd35b);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #c99747;

            @media (max-width: 425px){
              font-size: 30px;
              line-height: 32px;
              width: 220px;
            }
          }
          &bottom{
            font-size: 26px;
            line-height: 40px;
            margin-bottom: 30px;
            font-weight: 800;
            width: 488px;
            text-transform: uppercase;
            .hide{
              display: none;
            }
            span{
              font-weight: 500
            }

            @media (max-width: 425px){
              font-size: 16px;
              line-height: 20px;
              width: 165px;
              margin-top: 5px;
              margin-bottom: 46px;

              .hide{
                display: block;
              }
            }
          }
          &ps{
            font-weight: 500;
            font-size: 10px;

            @media (max-width: 425px){
              font-size: 7px;
              line-height: 11px;
              width: 133px;
            }
          }

          &period{
            position: absolute;
            top: 57px;
            left: 493px;
            border-radius: 50%;
            width: 249px;
            height: 249px;
            background-color: #ed1556;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-weight: 400;
            transform: rotate(-14deg);
            line-height: 26px;
            font-size: 20px;
            span{
              font-weight: 800
            }

            @media (max-width: 768px){
              width: 150px;
              height: 150px;
              line-height: 17px;
              font-size: 13px;
              top: 105px;
              left: 411px;
            }

            @media (max-width: 425px){
              width: 121px;
              height: 121px;
              line-height: 16px;
              font-size: 12px;
              top: 20px;
              left: auto;
              right: 13px;
            }
          }
        }
      }

      &_telephony{
        background: #fff url(img/elements/banner_corp-telephony.webp) 50% 50%/cover no-repeat;
        padding: 60px 0;

        .appeal{
          margin-bottom: 60px;
        }

        img{
          margin-bottom: 65px;
        }

        .note{
          color: #505050;
        }

        @media (max-width: 1023px){
          background-position: 80% 50%;

          .appeal, .note{
            text-align: center;
          }
          .appeal{
            margin-bottom: 270px;
          }
          .note{
            color: #fff;
          }
          img{
            display: block;
            margin: 0 auto;
            width: 350px;
          }
        }
        @media (max-width: 768px){
          padding: 20px 0;

          .appeal{
            font-size: 26px;
            width: 265px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 225px;
          }
          .note{
            width: 365px;
            margin-left: auto;
            margin-right: auto;
            font-size: 12px;
          }
        }
        @media (max-width: 600px){
          .appeal{
            margin-bottom: 157px;
          }
        }
        @media (max-width: 530px){
          background-position: 72% 50%;
        }
        @media (max-width: 425px){
          background-position: 72% 50%;

          img{
            width: 287px;
          }
          .note{
            width: 293px;
          }
          .appeal{
            margin-bottom: 177px;
          }
        }
      }
    }


    @media (max-width: 768px){
      height: 456px;
    }

    @media (max-width: 700px){
      margin-top: 0;
    }

    @media (max-width: 600px){
      height: 380px;
    }
  }

//clients, projects
  &_clients, &_projects{
    height: 110px;

    .slide{
      display: inline-flex !important;
      justify-content: center;
      align-items: center;

      img{
        max-height: 100%;
        max-width: 300px;
      }
    }

    .slick-prev,.slick-next{
      width: 26px;
      height: 50px;
      top: calc(50% - 25px);

      &:before, &:after{
        background-color: #ebebea;
        width: 33px;
      }
    }
    .slick-next{
      &:before, &:after{
        left: calc(50% - 22px);
      }
    }

    @media (max-width: 425px){
      margin: 42px 0;

      .slick-slide{
        img{
          max-width: 192px;
          max-height: 110px;
        }
      }
    }
  }
  &_projects{
    height: 200px;

    .slick-slide{
      outline: 0;
    }

    .slide{
      align-items: flex-end;
      width: 100%;

      a{
        div{
          color: #0169D3;
        }
      }

      img{
        max-width: 200px;
      }

      .info{
        max-width: 210px;
        line-height: 24px;
        margin-left: 10px;

        .name{
          font-size: 18px;
          font-weight: 700;
        }
      }
      
      @media (max-width: 425px){
        flex-flow: column nowrap;
        align-items: center;
        img{
          width: 170px;
          max-height: 170px
          margin-bottom: 10px;
        }

        .info{
          margin-left: 0;
          line-height: 20px;
          .name, .address{
            text-align: center;
          }

          .name{
            font-size: 16px;
          }
          .address{
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 425px){
      height: 265px;
    }
  }

  //nodes
  &_nodes{
    height: 100%;
    margin-top: 0;

    .slick-next,.slick-prev{
      background-color: transparent;
      width: 26px;
      height: 12px;
      top: calc(50% - 6px);

      &:before, &:after{
        background-color: #717171;
        width: 6px;
        height: 1px;
      }
    }
    .slick-prev{
      &:before, &:after{
        left: calc(100% - 6px);
      }
    }
  }


  //sliders other pages
  &_corp-television{
    .slide{
      &_fan{
        padding-top: 50px;
        background: #fff url(/src/img/elements/slider_corp-television.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-left: 40px;
          padding-right: 40px;

          p{
            font-size: 36px;
            font-weight: 700;
            color: #fff;
            text-align: center;
          }

          .ul-list{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: 0 auto;
            width: 880px;

            ul{
              color: #fff;
              width: 200px;

              li{
                font-size: 24px;
                list-style: disc;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
      
    @media (max-width: 1024px){
      .slide_fan{
        background-position: 64% 50%;
      }
      .cont{
        .ul-list{
          width: auto !important;
        }
      }
    }
    @media (max-width: 768px){
      .slide_fan{
        .cont{
          p{
            font-size: 26px;
          }

          .ul-list{
            display: none;
          }
        }
      }
    }
    @media (max-width: 425px){
      .slide_fan{
        background-position: 60% 50%;
        padding-top: 24px;
      }
    }
  }

  &_realty-managment{
    .slide{
      &_city{
        padding-top: 50px;
        background: #fff url(/src/img/elements/slide_city.webp) 50% 50%/cover no-repeat;

        .cont{
          .appeal{
            margin-bottom: 30px;
            color: purple;
          }
        }

        @media (max-width: 768px){
          padding-top: 40px;
          background: #fff url(/src/img/elements/slide_city-small.webp) 50% 50%/cover no-repeat;

          .appeal{
            font-size: 26px;
            text-align: center;
          }
          .ul-appeal{
            display: none;
          }
        }
        @media (max-width: 425px){
          padding-top: 15px;
        }
      }
    }
  }

  &_phiz-internet{
    .slide{
      &_phiz-internet{
        background: #fff url(img/elements/banner_phiz-internet.webp) 50% 50%/cover no-repeat;

        .inner-cont{
          height: 100%;

          .appeal{
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 28px;
            display: block;
          }

          .banner_ul{
            margin-top: 50px;

            li{
              font-size: 24px;
              padding-left: 22px;
              line-height: 1;
              margin-bottom: 10px;
            }
          }
        }
        
        @media (max-width: 768px){
          .inner-cont{
            padding-top: 20px;
          }
        }
        @media (max-width: 560px){
          background-position: 67% 50%;
          .inner-cont{
            padding-top: 5px;

            .appeal{
              text-align: center;
              font-size: 26px;
            }
            .banner_ul{
              display: none;
            }
          }
        }  
      }

      &_phiz-birulovo{
        background #fff url(img/elements/slide_phiz_2-birulovo-bg.webp) 50% 50%/cover no-repeat

        a{
          position: absolute;
          top 0
          left 0
          right 0
          bottom 0
          z-index 1
        }
        
        @media (max-width: 1200px){
          background #fff url(img/elements/slide_phiz_2-birulovo-bg--1200.webp) 50% 50%/cover no-repeat
        }
        @media (max-width: 768px){
          background #fff url(img/elements/slide_phiz_2-birulovo-bg--768.webp) 50% 50%/cover no-repeat
        }

        &::before, &::after {
          content ''
          position absolute
        }
        &::before {
          right 50%
          bottom 36px
          margin-right -182px
          width 770px
          height 477px
          background transparent url(img/elements/slide_phiz_2-birulovo-plate.webp) 50% 50%/contain no-repeat
          @media (max-width: 1200px) {
            top: 40px
            right: 30%
            bottom: 40px
            left: 20px
            margin-right: 0
            width: auto
            height: auto
          }
          @media (max-width: 1024px) {
            right: 20%
          }
          @media (max-width: 768px) {
            top: 20px
            right: 20px
            bottom: 20px
          }
          @media (max-width: 600px) {
            background-image url(img/elements/slide_phiz_2-birulovo-plate--600.webp)
            top: 10px
            right: 5px
            bottom: 10px
            left: 5px
          }
        }
        &::after {
          left 50%
          bottom 0
          margin-left 102px
          width 493px
          height 540px
          background transparent url(img/elements/slide_phiz_2-birulovo-girl.webp) 50% 100%/contain no-repeat
          @media (max-width: 1200px) {
            top: 40px
            right: 20px
            bottom: 0
            left: 60%
            margin-left: 0
            width: auto
            height: auto
          }
          @media (max-width: 768px) {
            display none
          }
        }
      }
    }
  }

  &_oper-network{
    .slide{
      &_ournetwork{
        background: #fff url(/src/img/elements/slide_oper-join.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-top: 65px;

          .appeal{
            color: #fff;
            margin-bottom: 50px;
          }

          .ul-normal{
            li{
              color: #fff;
              font-size: 24px;
            }
          }
        }
          
        @media (max-width: 768px){
          .appeal{
            font-size: 26px;
            text-align: center;
          }

          .ul-normal{
            width: 450px;
            margin-left: auto;
            margin-right: auto;

            li{
              font-size: 16px !important;
            }
          }
        }
        @media (max-width: 425px){
          .cont{
            padding-top: 20px;

            .appeal{
              margin-bottom: 20px;
              line-height: 1.2;
            }
            .ul-normal{
              width: auto;

            }
          }
        }
      }
    }
  }

  &_oper-channel{
    .slide {

      &_rent{
        background: #fff url(/src/img/elements/slide_oper-rent.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-top: 150px;

          .appeal{
            margin-bottom: 30px;
          }

          .ul-normal{
            li{
              font-size: 24px;

              &:before{
                color: black !important;
              }
            }
          }
        }
      }
        
      @media (max-width: 768px){
        background: #fff url(/src/img/elements/slide_oper-rent--small.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-top: 40px;

          .appeal{
            font-size: 22px;
            text-align: center;
          }

          .ul-normal{
            display: none;
          }
        }
      }  
    }
  }

  &_oper-registration{
    .slide {

      &_as-lir-pi {
        background: #fff url(/src/img/elements/slide_oper-pi-as.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-top: 90px;

          .appeal{
            &_1{
              margin-bottom: 15px;
            }
            &_2{
              margin-bottom: 40px;
            }
          }

          .ul-normal{
            li{
              font-size: 24px;

              &:before{
                color: black !important;
              }
            }
          }
        }

        @media (max-width: 768px){
          background: #fff url(/src/img/elements/slide_oper-pi-as--small.webp) 50% 50%/cover no-repeat;

          .cont{
            padding-top: 45px;

            .appeal{
              text-align: center;
              font-size: 22px;

              &_1{
                margin-bottom: 10px;
              }
            }

            .ul-normal{
              display: none;
            }
          }
        }
        @media (max-width: 600px){
          .cont {
            padding-top: 25px;
          }
        }
      }
    }
  }

  &_oper-monitoring{
    .slide {
      .cont {
        width: auto;
      }

      &_monitoring {
        background: #fff url(/src/img/elements/slide_oper-monitoring.webp) 50% 50%/cover no-repeat;

        .cont{
          padding-top: 110px;
          position: absolute;
          right: 21%;

          .appeal{
            margin-bottom: 62px;
          }

          .ul-normal{
            li{
              font-size: 24px;

              &:before{
                color: black !important;
              }
            }
          }
        }

        @media (max-width: 1440px){
          .cont{
            right: 5%;
          }
        }
        @media (max-width: 1024px){
          .cont{
            right: 5%;
            width: 500px
          }
        }
        @media (max-width: 768px){
          background: #fff url(/src/img/elements/slide_oper-monitoring--small.webp) 50% 50%/cover no-repeat;

          .cont{
            width: auto;
            right: auto;
            position: relative;
            padding-top: 30px;

            .appeal{
              text-align: center;
              font-size: 26px;
            }

            .ul-normal{
              display: none;
            }
          }
        }
      }
    }
  }

  .banner_action_skidka-50 {
    @media (max-width: 425px){
      .banner__img--fry {
        width: 220px;
      }

      .banner__img--fire50 {
        top: -26px;
        right: -20px;
      }

      .banner__content {
        bottom: 177px;
      }
    }
  }
}

.no-webp .slider{
  &_main{
    .slide{
      &_babyin{
        background-image: url(img/slider/babyin.png);
        @media (max-width: 768px){
          background-image: url(img/slider/babyin.png);
        }
      }
      &_telephony{ background-image: url(img/elements/banner_corp-telephony.png);}
    }
  }

  &_corp-television{
    .slide{
      &_fan{ background-image: url(/src/img/elements/slider_corp-television.png);}
    }
  }

  &_realty-managment{
    .slide{
      &_city{
        background-image: url(/src/img/elements/slide_city.png);
        @media (max-width: 768px){
          background-image: url(/src/img/elements/slide_city-small.png);
        }
      }
    }
  }

  &_phiz-internet{
    .slide{
      &_phiz-internet{
        background-image: url(img/elements/banner_phiz-internet.png);
      }

      &_phiz-birulovo{
        background-image: url(img/elements/slide_phiz_2-birulovo-bg.jpg)

        @media (max-width: 1200px){
          background-image: url(img/elements/slide_phiz_2-birulovo-bg--1200.jpg)
        }
        @media (max-width: 768px){
          background-image:  url(img/elements/slide_phiz_2-birulovo-bg--768.jpg)
        }

        &::before {
          background-image: url(img/elements/slide_phiz_2-birulovo-plate.png)
          @media (max-width: 600px) {
            background-image: url(img/elements/slide_phiz_2-birulovo-plate--600.png)
          }
        }
        &::after {
          background-image: url(img/elements/slide_phiz_2-birulovo-girl.png)
        }
      }
    }
  }

  &_oper-network{
    .slide{
      &_ournetwork{
        background-image: url(/src/img/elements/slide_oper-join.png);
      }
    }
  }

  &_oper-channel{
    .slide {
      &_rent{
        background-image: url(/src/img/elements/slide_oper-rent.png);
      }

      @media (max-width: 768px){
        background-image: url(/src/img/elements/slide_oper-rent--small.png);
      }
    }
  }

  &_oper-registration{
    .slide {
      &_as-lir-pi {
        background-image: url(/src/img/elements/slide_oper-pi-as.png);
        @media (max-width: 768px){
          background-image: url(/src/img/elements/slide_oper-pi-as--small.png);
        }
      }
    }
  }

  &_oper-monitoring{
    .slide {
      &_monitoring {
        background-image: url(/src/img/elements/slide_oper-monitoring.png);
        @media (max-width: 768px){
          background-image: url(/src/img/elements/slide_oper-monitoring--small.png);
        }
      }
    }
  }
}












