.surveillance{
    background: url(/src/img/pages/headerSurveillance.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;

        font-p-300(17px, font-color-light);
    }

    .heading{
        padding-top: 275px;
        margin-bottom: 20px;

        span{
            margin-bottom: 0px;
            padding-top: 0px;

            font-h1(52px, font-color-light);
        }
    }

    &__content{
        max-width: 658px;

        padding-bottom: 45px;
        border-bottom: solid 1px rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        p{
            margin-bottom: 0;

            font-p(17px, font-color-light);
        }
    }

    ul{
        li{
            position relative;

            display: flex;
            align-items: center;

            margin-bottom: 5px;
            padding-left: 13px;

            &:last-child{
                margin-bottom: 0px;
            }

            &:before{
                content: '';
                min-height: 3px;
                min-width: 3px;
                background: rgba(245, 241, 248, 0.45);
                border-radius: 50%;

                position: absolute;
                top: 12px;
                left: 0;
            }

            font-p-300(17px, font-color-light);
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/headerMobileSurveillance.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 776px;

        &__page{
            max-width: 100%;

            padding: 0 30px;
        }

        .heading{
            padding-top: 357px;
            margin-bottom: 25px;

            span{
                text-align: left;
                font-size: 30px;
            }
        }

        &__content{
            max-width: 100%;

            padding-bottom: 40px;
            margin-bottom: 25px;
        }

        ul{
            margin-bottom: 80px;


        }
    }
}

.surveillance-introduction{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 110px;
    }

    .heading{
        margin-bottom: 25px;

        h1{
            padding-top: 0;
            margin-bottom: 0;

            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        max-width: 927px;

        p{
            margin-bottom: 0px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 80px;
        }

        .heading{
            margin-bottom: 20px;

            h1{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            max-width: 100%;

            p{
                font-size: 15px;
            }
        }
    }
}

.surveillance-rates{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading{
        margin-bottom: 25px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        max-width: 927px;
        margin-bottom: 80px;

        p{
            margin-bottom: 0px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    &__cards-wrap{
        position: static;
    }

    &__cards-block{
        display: flex;
        gap: 30px;

        position: static;
    }

    &__card{
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 310px;

        padding-top: 80px;
        padding-bottom: 90px;

        border: 1px solid #F8F8F8;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 4px;

        &-img{
            margin-bottom: 55px;

            img{
                display: block;
                width: 100%;
            }
        }

        &-heading{
            margin-bottom: 55px;

            h3{
                margin-bottom: 0;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 160%;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
        }

        &-content{
            margin-bottom: 65px;

            p{
                margin-bottom: 0px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                text-align: center;
                color: #535353;
            }
        }

        &-button{
            button{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 14px;

                padding: 15px 30px;
                border: 1px solid #007A33;
                border-radius: 6px;

                background-color: white;

                transition: all 0.2s;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 150%;
                text-align: right;
                letter-spacing: 0.01em;
                color: #007A33;


                &:hover{
                    background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
                    color: #FFFFFF;

                    svg path{
                        fill: white;
                        transition: all 0.2s;
                    }
                }
            }
        }
    }

    &__products-buttons{
        // display: none;
    }

    @media (max-width: 1679px){
        &__page{
            margin-bottom: 185px;
        }

        &__content{
            margin-bottom: 75px;
        }

        &__cards-block{
            gap: 15px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0 30px;
            margin-bottom: 20px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 20px;

            p{
                font-size: 15px;
            }
        }

        &__cards-wrap{
            width: 100%;
            height: 425px;

            overflow: hidden;

            position: relative;
        }

        &__cards-block{
            gap: 10px;

            position absolute;
            top: 10px;
            left: 10px;

            transition: left 1s;
        }

        &__card{
            min-width: 300px;
            width: 300px;

            padding-top: 35px;
            padding-bottom: 45px;

            &-img{
                margin-bottom: 25px;
            }

            &-heading{
                margin-bottom: 45px;

                h3{
                    font-size: 17px;
                    line-height: 150%;
                }
            }

            &-content{
                margin-bottom: 45px;

                p{
                    font-size: 15px;
                }
            }

            &-button{
                button{
                    padding: 10px 25px;

                    font-size: 20px !important;
                }
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.surveillance-result{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;

        display: flex;
        gap: 70px;
    }

    &__card{
        display: flex;
        gap: 55px;

        &-content{
            max-width: 319px;
            padding-top: 25px;
        }

        &-img{
            width: 236px;
        }

        &-img_desktop{
            // display: none;
            img{
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &-img_mobile{
            display: none;
        }

        &-heading{
            margin-bottom: 15px;

            h3{
                margin-bottom: 0px;
                text-align: start;

                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                color: #202020 !important;
            }
        }

        &-text{
            p{
                margin-bottom: 0px;

                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }
        }
    }

    @media (max-width: 1679px){
        &__page{
            margin-bottom: 185px;
            gap: 40px;
        }

        &__card{
            gap: 35px;

            &-img{
                width: 183px;
            }

        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 80px;

            flex-direction: column;
            gap: 65px;
        }

        &__card{
            flex-direction: column;
            gap: 30px;

            overflow: hidden;
        }

        &__card-img{
            width: 100%;
            height: 281px;
            display: flex;
            justify-content: center;

            &_desktop{
                display: none;
            }

            &_mobile{
                display: block;

                img{
                    display: block;
                    height: 100%;
                    width: auto;
                }
            }
        }

        &__card-content{
            max-width: 100%;
            padding-top: 0;
        }

        &__card-text{
            p{
                font-size: 15px;
            }
        }
    }
}

.surveillance-connect{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__cards-block{
        display: flex;
        gap: 55px;
    }

    &__card{
        max-width: 281px;

        &-img{
            height: 91px;

            display: flex;
            align-items: center;

            margin-bottom: 25px;

            img{
                display: block;
                height: 100%;
                width: auto;
            }
        }

        &-text{
            p{
                font-family: 'Fira Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;
            }
        }
    }

    @media (max-width: 1679px){
        &__page{
            margin-bottom: 160px;
        }

        .heading{
            margin-bottom: 75px;
        }

        &__cards-block{
            gap: 40px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px 30px;
            margin-bottom: 80px;
        }

        .heading{
            padding-bottom: 10px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__cards-block{
            flex-direction: column;
            gap: 30px;
        }

         &__card{
            max-width: 100%;

            &-img{
                margin-bottom: 10px;
            }

            &-text{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}

.surveillance-internet{
    background: url(/src/img/pages/BG_surveillance-internet.jpg) no-repeat center, linear-gradient(180deg, #0B2616 0%, #092C18 28%, #08341A 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    display: flex;
    justify-content: center;

    &__page{
        height: 460px;

        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px 0px;
    }

    .heading{
        padding-top: 120px;
        margin-bottom: 80px;

        max-width: 658px;

        span{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF !important;
        }
    }

    &__button{
        button, a{
            display: flex;
            align-items: center;
            padding: 15px 29px 15px 25px;

            gap: 14px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            border-radius: 6px;

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            text-align: right;
            color: #FFFFFF;

            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }

        a{
            text-decoration: none;
            display: inline;
            cursor: pointer;
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/BG_surveillance-internet-mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;


        &__page{
            height: 594px;

            padding: 0px 30px 0px;
        }

        .heading{
            padding-top: 292px;
            margin-bottom: 55px;

            width: 100%;

            span{
                font-size: 17px;
            }
        }
    }
}

.surveillance-advantages{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 165px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__content{
        display: flex;
        flex-wrap: wrap;
        gap: 54px 60px;
    }

    &__item{
        width: 335px;

        display: flex;

        span, p{
            display: block;
        }

        span{
            width: 35px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #007A33;
        }

        p{
            margin-bottom: 0;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    @media (max-width: 1679px){
        .heading{
            margin-bottom: 75px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 80px 30px 0;
            margin-bottom: 80px;
        }

        .heading{
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            gap: 30px;
        }

        &__item{
            width: 100%;

            span{
                height: 38px;
            }

            p{
                font-size: 15px;
            }
        }
    }
}

.surveillance-options{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;

        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading{
        margin-bottom: 75px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 160%;
            letter-spacing: 0.01em;
            color: #202020;
        }
    }

    &__products-wrap{
        position static;
    }

    &__products{
        display: flex;
        gap: 30px;

        position static;
    }

    &__card{
        height: 520px;
        width: 423px;
        padding: 144px 65px 0;

        border-radius: 6px;

        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: right top;
    }

    &__card-header{
        width: 160px;
        margin-bottom: 60px;

        span{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF;
        }
    }

    &__card-button{
        padding-bottom: 65px;
        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-bottom: 20px;

        button{
            transition: all 0.2s;
            height: 50px;
            padding: 0 29px 0 25px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            box-shadow: 0 2px 6px rgba(1, 84, 29, 0.8), 0 20px 48px rgba(1, 84, 29, 0.4), 0 40px 160px rgba(1, 84, 29, 0.9);
            border-radius: 6px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: #FFFFFF;

            svg path{
                fill: white
            }

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }
        }
    }

    &__card-content{
        margin-bottom: 75px;

        p{
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #E9FCEE;
        }
    }

    &__products-buttons{
        display: none;
    }

    @media (max-width: 1679px){
        &__card{
            width: 370px;
            padding: 144px 45px 0;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0;
            margin-bottom: 80px;
        }

        .heading{
            padding: 0 30px;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__products-wrap{
            width: 100%;
            height: 322px;

            overflow: hidden;

            margin-bottom: 45px;

            position relative;
        }

        &__products{
            gap: 10px;

            position: absolute;
            top: 0;
            left: 10px;

            transition: left 1s;
        }

        &__card{
            width: 300px;
            min-width: 300px;
            height: 322px;

            padding: 45px 30px 25px;

            background-size: 105% auto;

            &-header{
                width: 160px;
                margin-bottom: 30px;

                span{
                    font-size: 17px;
                }
            }

            &-button{
                padding-bottom: 45px;
                border-bottom: 1px solid rgba(245, 241, 248, 0.25);
                margin-bottom: 15px;
            }
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}




.surveillance-offer{
    &__content{
        max-width: 767px;
        padding-bottom: 44px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 28px;
    }

    &__description{
        margin-bottom: 144px;

        li{
            display: flex;
            align-items: center;
            gap: 8px;

            margin-bottom: 8px;

            img{
                align-self: flex-start;
                padding-top: 7px;
            }
        }
    }
}

.surveillance-price{
    .lermontovskiy-surveillance__content{
        margin-bottom: 72px;
    }

    .lermontovskiy-surveillance__list{
        margin-bottom: 200px;
    }
}
