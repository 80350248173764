.design-v2 {
  // $body-font-family = 'Roboto', sans-serif;
  // $heading-font-family = 'PT Sans', sans-serif;
  $body-font-family = 'Fira Sans', sans-serif;
  $heading-font-family = 'Rubik', sans-serif;
  $body-text-color = #454C57;
  $heading-text-color = #231F20;

  font-family: $body-font-family;
  font-size: 16px;
  line-height: 1.75;
  color: $body-text-color;

  .pull-top-at-content-start {
    margin-top: -50px;

    @media (max-width: 425px) {
      margin-top: -20px;
    }
  }

  .heading {
    font-family: $heading-font-family;
    font-weight: 500;
    color: $heading-text-color !important;
    line-height: 1.25;
    padding: 0;
    margin: 0 0 1em;
    text-align: left;

    h1, h2, h3, h4, h5, h6 {
      color: inherit !important;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-align: inherit;
      text-transform: inherit;
      padding: 0;
      margin: 0;
    }

    &--h1 {
      font-size: 46px;
      line-height: 1.13;
      letter-spacing: .01em;

      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 1.2;
      }
    }

    &--h2 {
      font-size: 40px;
      line-height: 1.2;
      letter-spacing: .005em;


      @media (max-width: 768px) {
        font-size: 38px;
        line-height: 1.1579;
      }
    }

    &--h2-regular {
      font-size: 36px;
      line-height: 1.3333;
      font-weight: 400;
      letter-spacing: .005em;

      @media (max-width: 768px) {
        font-size: 34px;
        line-height: 1.2941;
      }
    }

    &--h3 {
      font-size: 28px;
      line-height: 1.2857;
      letter-spacing: .01em;
    }

    &--h4 {
      font-size: 24px;
      line-height: 1.1667;
    }

    &--h4-light {
      font-family: $body-font-family;
      font-size: 20px;
      line-height: 1.6;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 1.5556;
      }
    }

    &--h5,
    &--h6 {
      @extends .design-v2 .heading--h4;
    }

  }

  h1, h2, h3, h4, h5, h6 {
    @extend .design-v2 .heading;
  }

  h1 {
    @extend .design-v2 .heading--h1;
  }

  h2 {
    @extend .design-v2 .heading--h2;
  }

  h3 {
    @extend .design-v2 .heading--h3;
  }

  h4, h5, h6 {
    @extend .design-v2 .heading--h4;
  }

  a:link, a:visited {
    color: #5662A0;
  }
  a:hover {
    text-decoration: none;
  }

  p {
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 1em;
  }
  p:last-child {
    margin-bottom: 0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    background: #C3C9D2;
  }

  ul, ol {
    margin: 0;
    padding: 0;

    &:not(:first-child) {
      margin-top: 2em;
    }

    &:not(:last-child) {
      margin-bottom: 2em;
    }

    > li {
      padding: 0 0 0 12px;
      margin: 0 0 12px;
      font-size: 16px;
      line-height: 24px;
      list-style: none;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 8px;
        margin-right: 8px;
        margin-left: -12px;
        background: url("data:image/svg+xml,%3Csvg width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.75 7.073L3.25 4.06014L0.753132 1.04729' stroke='%23454C57' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") 50% 50% no-repeat;
        background-size: contain;
        transform: translateY(-10%);
      }
    }

    &.list--on-dark > li::before {
      background-image: url("data:image/svg+xml,%3Csvg width='4' height='8' viewBox='0 0 4 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.75 7.073L3.25 4.06014L0.753132 1.04729' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
    }

    &.list--compact {
      &:not(:first-child) {
        margin-top: 1.25em;
      }

      & > li {
        margin: 0 0 4px;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .d-block {
    display: block !important;
  }

  .bg-pastel-purple {
    background-color: #c597c3 !important;
  }

  .color-grape-purple {
    color: #804195 !important;
  }

  .color-darkest-gray {
    color: #454C57 !important;
  }

  .bg-off-white {
    background: #f7f8f9;
  }

  .bg-white {
    background: #fff !important;
  }

  .purple-gradient-text {
    background: linear-gradient(180deg, #D06EB8 -9.37%, #A04B9D 52.85%, #804195 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .max-w-600p {
    max-width: 600px;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .mh-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .p-0 {
    padding: 0 !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-4p {
    margin-bottom: 4px !important;
  }

  .mb-16p {
    margin-bottom: 16px !important;
  }

  .mb-20p {
    margin-bottom: 20px !important;
  }

  .mb-24p {
    margin-bottom: 24px !important;
  }

  .mb-28p {
    margin-bottom: 28px !important;
  }

  .mb-36p {
    margin-bottom: 36px !important;
  }

  .mb-48p {
    margin-bottom: 48px !important;
  }

  .mb-64p {
    margin-bottom: 64px !important;
  }

  .mb-80p {
    margin-bottom: 80px !important;
  }

  .mt-100p {
    margin-top: 100px !important;
  }
  .mb-100p {
    margin-bottom: 100px !important;
  }

  .pr-28p {
    padding-right: 28px !important;
  }

  .pt-40p {
    padding-top: 40px !important;
  }

  .pt-100p {
    padding-top: 100px !important;
  }
  .pb-100p {
    padding-bottom: 100px !important;
  }

  .pt-120p {
    padding-top: 120px !important;
  }
  .pb-120p {
    padding-bottom: 120px !important;
  }

  .pt-136p {
    padding-top: 136px !important;
  }
  .pb-136p {
    padding-bottom: 136px !important;
  }

  .pt-148p {
    padding-top: 148px !important;
  }
  .pb-148p {
    padding-bottom: 148px !important;
  }


  @media (min-width: 769px) {
    .mb-md-76p {
      margin-bottom: 76px !important;
    }

    .mb-md-80p {
      margin-bottom: 80px !important;
    }

    .pt-md-50p {
      padding-top: 50px !important;
    }

    .pt-md-56p {
      padding-top: 56px !important;
    }

    .pt-md-60p {
      padding-top: 60px !important;
    }
  }

  @media (min-width: 1025px) {
    .mb-lg-80p {
      margin-bottom: 80px !important;
    }

    .mt-lg-100p {
      margin-top: 100px !important;
    }
    .mb-lg-100p {
      margin-bottom: 100px !important;
    }

    .mr-lg-50p {
      margin-right: 50px;
    }

    .pt-lg-40p {
      padding-top: 40px !important;
    }

    .pt-lg-100p {
      padding-top: 100px !important;
    }
    .pb-lg-100p {
      padding-bottom: 100px !important;
    }

    .pt-lg-120p {
      padding-top: 120px !important;
    }
    .pb-lg-120p {
      padding-bottom: 120px !important;
    }

    .pt-lg-136p {
      padding-top: 136px !important;
    }
    .pb-lg-136p {
      padding-bottom: 136px !important;
    }

    .pt-lg-148p {
      padding-top: 148px !important;
    }
    .pb-lg-148p {
      padding-bottom: 148px !important;
    }
  }

  .section {
    padding: 80px 0;

    @media (max-width: 768px) {
      padding: 40px 0;
    }
  }

  .btn, button.btn, a.btn {
    display: inline-block;
    outline: 0;
    text-align: center;
    border: 0;
    background: #804195;
    border-radius: 48px;
    padding: 12px 32px;
    font-family: $body-font-family;
    font-size: 18px !important;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: .055em;
    text-transform: lowercase;
    font-variant: small-caps;
    color: #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all .35s;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, #D06EB8 -9.37%, #A04B9D 52.85%, #804195 100%);
      opacity: 1;
      transition: opacity .35s;
    }

    &--secondary:not(:disabled) {
      color: #804195;
      background: transparent;
      box-shadow: inset 0 0 0 1px #804195;

      &::before {
        display: none;
      }
    }

    &--secondary-2:not(:disabled) {
      color: #fff;
      background: transparent;
      box-shadow: inset 0 0 0 1px rgba(255,255,255,.35);

      &::before {
        display: none;
      }
    }

    &:hover {
      color: #ECBDF7;
      background: #804195;
      box-shadow: none;

      &::before {
        opacity: 0;
      }
    }

    &:disabled {
      color: #EDEFF2;
      background: #C3C9D2;
      cursor: default;

      &::before {
        display: none;
      }
    }

    &:active:not(:disabled) {
      background: #895695;
      transition: none;
    }

    span {
      position: relative;
      backface-visibility: hidden;
    }

  }

  .placard {
    position: relative;
    top:50px;
    min-height 424px;
    display: flex;

    &__container {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .placard-2gis {
    background: #F7F8F9 url(/src/img/elements/2gis__placard.webp) 50% 50%/auto 100% no-repeat;

    .no-webp & {
      background-image: url(/src/img/elements/2gis__placard.jpg);
    }
  }

  .placard-promo {
    padding-top: 266px;
    padding-bottom: 86px;
    color: #fff;
    background-color: #070707;
    background-image: image-set(
      url(/src/img/elements/promo-01-bg.webp) 1x unquote("type('image/webp')"),
      url(/src/img/elements/promo-01-bg-2x.webp) 2x unquote("type('image/webp')"),
      url(/src/img/elements/promo-01-bg.jpg) 1x unquote("type('image/jpeg')"),
      url(/src/img/elements/promo-01-bg-2x.jpg) 2x unquote("type('image/jpeg')")
    );
    background-position: center bottom;
    background-repeat: no-repeat;

    @media (min-width: 1280px) {
      min-height: 632px;
    }

    @media (max-width: 1279px) {
      padding-top: 125px;
    }

    @media (max-width: 768px) {
      padding-top: 355px;
      background-image: image-set(
        url(/src/img/elements/promo-01-bg-1280.webp) 1x unquote("type('image/webp')"),
        url(/src/img/elements/promo-01-bg-1280-2x.webp) 2x unquote("type('image/webp')"),
        url(/src/img/elements/promo-01-bg-1280.jpg) 1x unquote("type('image/jpeg')"),
        url(/src/img/elements/promo-01-bg-1280-2x.jpg) 2x unquote("type('image/jpeg')")
      );
      background-position: center top;
    }

    .heading {
      color: inherit !important;

      @media (min-width: 769px) and (max-width: 1200px) {
        text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
      }

      @media (max-width: 768px) {
        font-size: 30px;
        font-weight: 500;
      }
    }

    &__text {
      color: rgba(255,255,255,.88);
      margin-bottom: 45px;
      max-width: 464px;

      @media (min-width: 769px) and (max-width: 1200px) {
        text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
      }

      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }

  .placard-promo-2 {
    padding-top: 44px;
    padding-bottom: 44px;
    color: #fff;
    background-color: #070707;
    background-image: image-set(
      url(/src/img/elements/promo-02-bg.webp) 1x unquote("type('image/webp')"),
      url(/src/img/elements/promo-02-bg-2x.webp) 2x unquote("type('image/webp')"),
      url(/src/img/elements/promo-02-bg.jpg) 1x unquote("type('image/jpeg')"),
      url(/src/img/elements/promo-02-bg-2x.jpg) 2x unquote("type('image/jpeg')")
    );
    background-position: center center;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      padding-top: 192px;
      padding-bottom: 20px;
      background-image: image-set(
        url(/src/img/elements/promo-02-bg-1280.webp) 1x unquote("type('image/webp')"),
        url(/src/img/elements/promo-02-bg-1280-2x.webp) 2x unquote("type('image/webp')"),
        url(/src/img/elements/promo-02-bg-1280.jpg) 1x unquote("type('image/jpeg')"),
        url(/src/img/elements/promo-02-bg-1280-2x.jpg) 2x unquote("type('image/jpeg')")
      );
      background-position: center top;
    }

    .container {
      @media (max-width: 768px) {
        max-width: 410px;
      }
    }

    .heading {
      color: inherit !important;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 30px;
        font-weight: 500;
      }
    }

    &__text {
      color: rgba(255,255,255,.88);
      max-width: 530px;

      @media (min-width: 769px) and (max-width: 1024px) {
        text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
      }
    }
  }

  .aside-icon-block {
    display: flex;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 28px;
    }

    & + & {
      margin-top: 60px;
    }

    &__icon {
      flex: none;
      display: flex;
      justify-content: center;
    }

    &__content {
      flex: 1 1 0;
      padding-left: 36px;
      max-width: 700px;

      @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 21px;
      }
    }
  }

  .triangle-icon {

    @media (min-width: 769px) {
      margin-left: -10px;
    }

    &__triangle {
      transition: transform 1.1s cubic-bezier(.6, 0, .03, 1);
      transform-origin: 50% 50%;

      ^[1]--1:hover ^[1..2] {
        transform: translateX(22px) rotate(30deg);
      }

      ^[1]--2:hover ^[1..2] {
        transform: translateX(28px) rotate(30deg);
      }

      ^[1]--3:hover ^[1..2] {
        transform: translate(16px, 4px) rotate(30deg);
      }
    }

    &__spiral {
      transition: transform 1.1s cubic-bezier(.6, 0, .03, 1);
      transform-origin: 50% 60%;

      ^[1]:hover ^[1..2] {
        transform: rotate(60deg);
      }
    }

    &__circle {
      transition: transform 1.1s cubic-bezier(.6, 0, .03, 1);
      transform-origin: 50% 50%;
      transform-box: fill-box;

      ^[1]:hover ^[1..2] {
        transform: rotate(60deg);
      }
    }

    &__elem {

    }
  }

  .section-presents {
    background: #F7F8F9 url(/src/img/elements/2gis__presents-bg.jpg) 50% 0 no-repeat;
  }

  .plate {
    background: #F8F3F8;
    border-radius: 4px;
    padding: 48px 48px 64px;

    @media (max-width: 768px) {
      padding: 24px 24px 48px;
    }
  }

  .card {
    @extend .design-v2 .plate;

    background: #fff;
    border: 1px solid #F7F8F9;
    box-shadow: 0 8px 20px rgba(195,201,210,.3);
    transition: box-shadow .25s;

    &:hover {
      box-shadow: 0 2px 12px rgba(195,201,210,.5);
    }
  }

  .factoids {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: space-between;

    &__item {
      flex: 1 1 auto;
      padding: 0 10px 40px;

      @media (max-width: 1100px) {
        flex-basis: 33.3333%;
      }

      @media (max-width: 600px) {
        flex-basis: 50%;
      }

      @media (max-width: 400px) {
        flex-basis: 100%;
        text-align: center;
      }
    }

    &__num {
      font-size: 72px;
      line-height: 1.11;

      @media (max-width: 768px) {
        font-size: 50px;
      }
    }

    &__rouble {
      font-family: $heading-font-family;
      font-size: 0.77778em;
      display: inline-block;
      transform: translate(10%, 15%);
    }

    &__plus {
      font-family: $heading-font-family;
      font-size: 0.77778em;
      display: inline-block;
      transform: translateY(10%);
      vertical-align: top;
    }

    &__text {
      line-height: 1.5;

      @media (max-width: 400px) {
        br {
          display: none;
        }
      }
    }
  }

  .work-object {

    &__pic {
      border: 1px solid #E3E7EC;
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    &__title {
      margin-bottom: 4px;
    }

    &__desc {
      line-height: 1.75;
    }

  }

  .contacts-line {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-left: -24px;

    &__item {
      padding: 0 24px;
      border-left: 1px solid #c3c9d2;
      margin-left: -1px;
      font-size: 20px;
      line-height: 1.6;
      font-weight: 300;

      a {
        font-size: inherit;
      }

      &--tel a {
        color: #231f20;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }

  .logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: -10px -14px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 116.17%);
      visibility: visible;
      opacity: 1;
      transition: all .25s;
      pointer-events: none;
    }

    &:hover::after {
      visibility: hidden;
      opacity: 0;
    }

    @media(max-width: 768px) {
      margin: -6px -7px;
      justify-content: flex-start;
    }

    &__item {
      padding: 21px 14px;

      @media(max-width: 768px) {
        padding: 12px 7px;
      }

      @media(max-width: 425px) {
        flex-basis: 50%;
      }

      a, picture, img {
        display: block;
      }

      img {
        filter: grayscale(1);
        opacity: .5;
        transition: filter .25s, opacity .25s;
        margin: 0 auto;
      }

      &:hover img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  .input-field {
    display: block;
    position: relative;

    &__input {
      display: block;
      width: 100%;
      margin: 0;
      background-color: transparent;
      border: solid #C3C9D2;
      border-width: 0 0 1px;
      border-radius: 0;
      outline: none;
      transition: border-color .25s, box-shadow .25s;
      font-family: $body-font-family;
      font-size: 16px !important;
      line-height: 1.75;
      padding: 0;
      box-shadow: none;

      &::-ms-clear {
        display: none;
      }

      &:focus {
        border-color: #788497;
      }

      &:disabled {
        resize: none;
        background-color: transparent;
        border-color: #C3C9D2;
        color: #C3C9D2;
      }

      &--textarea {
        height: 29px;
        min-height: 29px;
        max-height: 160px;
        overflow: auto;
        resize: vertical;
      }

      &--select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-position: right 7px center;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L4.01285 3.5L7.02571 1.00313' stroke='%23454C57' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        padding-right: 20px;
      }

      &--invalid, &--invalid:focus {
        border-color: #ED2157;
      }
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 1.75;
      cursor: text;
      color: #788497;
      transition: font-size .15s, transform .15s;
      pointer-events: none;

      /.design-v2 .input-field__input:focus + .input-field__label,
      /.design-v2 .input-field__input--filled + .input-field__label {
        font-size: 12px;
        transform: translateY(-21px);
      }

      /.design-v2 .input-field__input--invalid + .input-field__label {
        color: #ED2157;
      }

      /.design-v2 .input-field__input:disabled + .input-field__label {
        color: #C3C9D2;
      }

      ///.design-v2 .input-field__input--filled:disabled + .input-field__label {
      //  display: none;
      //}

      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 50%;
        z-index: -1;
      }
    }

  }

  .form {
    max-width: initial;

    @media (max-width: 425px) {
      padding: 0;
    }

    //&__field {
    //  margin-bottom: 50px;
    //}

    &__response {
      &:empty {
        display: none;
      }
    }

    &__policy {
      font-size: 12px;
      line-height: 1.33;
      color: #999;

      a {
        font-size: inherit;
      }
    }
  }

  .snackbar {
    background-color: #2f2f2f;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 14px;
    font-weight: 500;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);

    &--success {
      background-color: #4caf50;
    }

    &--error {
      background-color: #f44336;
    }
  }

  .form-grid {
    display: flex;
    flex-wrap: wrap;
    margin: -20px;

    @media (max-width: 500px) {
      margin: -20px -12px;
    }

    &__item {
      min-width: 160px;
      flex: 1 1 0px;
      padding: 20px;

      @media (max-width: 500px) {
        padding: 20px 12px;
      }

      &--name {
        flex-basis: 65%;
        max-width: 355px;

        @media (max-width: 500px) {
          max-width: initial;
        }
      }

      &--email {
        flex-basis: 35%;
      }

      &--present {
        flex-basis: 65%;
        max-width: 355px;
        padding-top: 30px;

        @media (max-width: 500px) {
          max-width: initial;
        }
      }

      &--files {
        flex-basis: 100%;
      }

      &--comment {
        flex-basis: 100%;
      }

      &--response {
        flex-basis: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }

      &--button {
        flex-basis: auto;
        flex-grow: 0;
        align-self: center;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &--policy {
        flex-basis: 0px;
        align-self: center;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

  }

  .popup {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(35,31,32,.85);
    outline: none;
    opacity: 0;
    overflow-x: auto;
    overflow-y: scroll;
    visibility: hidden;

    /.css-loaded .popup {
      transition: all .2s ease-in-out;
    }

    /.ie .popup {
      display: block;
    }

    @media (max-width: 768px) {
      padding: 10px;
    }

    &--visible {
      opacity: 1;
      visibility: visible;
      transition: all .1s ease-in-out;
    }

    &--hiding {
      overflow: hidden;
    }

    &__container {
      @extend .design-v2 .card;

      @media (max-width: 768px) {
        padding: 48px 24px;
      }

      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      max-width: 400px;
      transform: scale(.95);
      transition: all .2s ease-in-out;
      position: relative;

      /.ie .popup__container {
        margin-left: auto;
        margin-right: auto;
      }

      /.design-v2 .popup--visible .popup__container {
        transform: scale(1);
      }

      &--md {
        max-width: 700px;
      }
    }

    &__close {
      display: block;
      position: absolute;
      top: 11px;
      right: 11px;
      width: 25px;
      height: 25px;
      border: 5px solid transparent;
      cursor: pointer;
      overflow: hidden;
      background: transparent;
      outline: none;
      padding: 0;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 21px;
        background: #C3C9D2;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:hover::before,
      &:hover::after {
        background: #ED2157;
      }
    }
  }

}
