.steps-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -15px;

  &__item {
    padding: 15px;
    flex: 0 0 25%;

    ~/--5-steps & {
      flex-basis: 20%;
    }

    @media (max-width: 1024px) {
      flex-basis: 50%;

      ~/--5-steps & {
        flex-basis: 50%;
      }
    }

    @media (max-width: 768px) {
      flex-basis: 100%;
      display: flex;

      ~/--5-steps & {
        flex-basis: 100%;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-right: 20px;
    }
  }

  &__num {
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 50%;
    font-size: 20px;
    line-height: 1;
    color: var(--theme-color);
  }

  &__arrow {
    flex: 1 1 0px;
    display: block;
    height: 1px;
    color: #BACAD3;
    background: currentColor;
    margin-left: 12px;
    margin-right: 1px;
    position: relative;

    @media (max-width: 768px) {
      height: auto;
      width: 1px;
      min-height: 50px;
      margin-left: 0;
      margin-top: 12px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      width: 4px;
      height: 4px;
      border: solid currentColor;
      border-width: 1px 1px 0 0;
      transform-origin: 100% 0;
      transform: translateY(.5px) rotate(45deg);

      @media (max-width: 768px) {
        top: initial;
        right: 0;
        bottom: -1px;
        border-width: 0 1px 1px 0;
        transform-origin: 100% 100%;
        transform: translateX(-.5px) rotate(45deg);
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;

    @media (max-width: 768px) {
      padding-top: 8px;
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: .25em;
  }
}
