.theme-line {
  display: flex;

  &__item {
    flex: 1 1 25%;
    height: 5px;

    &--1 {
      background-color: #c20a45;
    }

    &--2 {
      background-color: #B1D237;
    }

    &--3 {
      background-color: #6331AE;
    }

    &--4 {
      background-color: #FBCE23;
    }
  }
}
