.smotreshka-physical {
    background: url(/src/img/pages/BG_smotreshka.jpg) no-repeat center, linear-gradient(180deg, #0E1A13 0%, #0C2014 28%, #093019 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    height: 700px;
    width: 100%;

    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
    }

    .heading {
        max-width: 658px;

        padding-top: 275px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(245, 241, 248, 0.45);
        margin-bottom: 35px;

        h1 {
            margin: 0 !important;
            padding: 0 !important;

            text-align: left;

            font-h1(52px, font-color-light);
        }
    }

    &__content {
        ul{
            li{
                margin-bottom: 5px;
                marker-3();
                font-p-300(17px, font-color-light);

                &:before {
                    background: rgba(245, 241, 248, 0.45) !important;
                }
            }
        }
    }

    @media (max-width: 1279px) {
        background: url(/src/img/pages/BG_smotreshka_mobile.jpg) no-repeat, #11301b;
        background-position: center;
        background-size: auto 100%;

        height: 750px;

        &__page {
            max-width: 100%;

            padding: 0 30px;
        }

        .heading {
            max-width: 100%;

            padding-top: 357px;
            padding-bottom: 25px;
            margin-bottom: 25px;

            h1 {
                font-h1(30px, font-color-light);
            }
        }
    }
}

.smotreshka-physical-television{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 160px 55px 0;
        margin-bottom: 160px;
    }

    .heading {
        margin-bottom: 65px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__rates{
        display: block;
    }

    &__rates-header{
        display: flex;
        gap: 300px;

        padding-bottom: 10px;
        border-bottom: 1px solid #E5E5E5;

        span{
            font-p(12px, font-color-normal);
        }

        &_1{
            min-width: 388px;

            display: flex;
            justify-content: space-between;
        }

        &_2{
            min-width: 120px;
        }
    }

    &__rates-body{
        display: flex;
        align-items: center;

        height: 125px;

        border-bottom: 1px solid #E5E5E5;

        &_1{
            min-width: 388px;

            display: flex;
            justify-content: space-between;

            margin-right: 300px;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &_button-modal{
            min-width: 130px;

            display: flex;
            justify-content: space-between;

            margin-right: 254px;

            button {
                background-color: white;

                span{
                    font-p(17px, font-color-green);
                }
            }

            button:hover{
                span{
                    font-p(17px, font-color-green-hover);
                }
            }
        }

        &_button{
            button_green_light();

            width: 100%;
            display: flex;
            justify-content: flex-end;

            margin-right: 55px;
        }
    }

    &__rates-mobile{
        display: none;
    }

    .footnote{
        padding-top: 60px;

        p{
            font-p(17px, font-color-normal);
            margin-bottom: 0;

            span{
                font-p-500(17px, font-color-normal)
            }
        }

        @media (max-width: 1279px) {
            padding: 0 30px;

            p{
                font-p(15px, font-color-normal);

                span{
                    font-p-500(15px, font-color-normal)
                }
            }
        }
    }

    @media (max-width: 1679px) {
        &__rates-header{
            gap: 230px;

            padding-bottom: 8px;

            &_1{
                min-width: 350px;
            }
        }

        &__rates-body{
            &_1{
                min-width: 350px;
                margin-right: 230px;
            }

            &_button-modal{
                margin-right: 200px;
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            padding: 80px 0 0;
            margin-bottom: 80px;
        }

        .heading {
            padding: 0 30px;
            margin-bottom: 35px;

            h2 {
                font-h2(20px, font-color-dark);
            }
        }

        &__rates{
            display: none;
        }

        &__rates-mobile{
            display: block;

            margin-bottom: 20px;

            height: 310px;

            overflow: hidden;
            position relative;
        }

        // &__rates-mobile::-webkit-scrollbar {
        //     // width: 0;
        // }

        &__rates-mobile-wrap{
            transition: left 1s;
            display: flex;
            gap: 10px;

            position: absolute;
            left: 10px;
            top: 15px;
        }

        &__rates-mobile-card{
            min-width: 300px;
            height: 270px;

            padding: 30px 20px 45px 20px;

            border: 1px solid #F8F8F8;
            box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);
            border-radius: 6px;
        }

        &__rates-mobile-card-heading{
            display: flex;
            justify-content: space-between;

            margin-bottom: 25px;

            span{
                font-p-500(20px, font-color-green);
            }
        }

        &__rates-mobile-card-channels{
            margin-bottom: 30px;

            span{
                display: block;
                font-p(12px, font-color-normal);

                padding-bottom: 5px;
                border-bottom: 1px solid #E5E5E5;
                margin-bottom: 5px;
            }
        }

        &__rates-mobile-card-modal{
            button {
                background-color: white;
                padding: 0 !important;

                span{
                    border-bottom: none;
                    margin-bottom: 0;
                    font-p(15px, font-color-green);
                }
            }

            button:hover{
                span{
                    font-p(15px, font-color-green-hover);
                }
            }
        }

        &__rates-mobile-card-button{
            button_green_light();
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;
            margin-bottom: 35px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.smotreshka-physical-tvpackages{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading {
        display: flex;
        justify-content: center;

        margin-bottom: 25px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    .heading-3{
        margin-bottom: 65px;

        h3{
            font-h2(28px, font-color-dark);
        }
    }

    &__content{
        display: flex;
        justify-content: center;

        margin-bottom: 80px;

        p{
            max-width: 813px;
            margin-bottom: 0;

            text-align: center;
            font-p(17px, font-color-normal);

            span{
                font-p-500(17px, font-color-normal);
            }
        }
    }

    &__wrap{
        display: flex;
        justify-content: center;
    }

    &__card-wrap{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        max-width: 1063px;

        position: static;
    }

    &__card{
        overflow: hidden;

        width: 262px;
        height: 487px;

        display: flex;
        flex-direction: column;

        position: relative;

        background: linear-gradient(360deg, #12B845 -3.85%, #007A33 17.9%, #006527 32.43%, #00551D 47.29%);
        border-radius: 6px;

        padding: 291px 35px 45px 35px;
    }

    &__card-img,
    &__card-img-bg{
        position: absolute;

        top: 0;
        left: 0;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__card-img-bg{
        height: 100%;
        width: 100%;

        // background: #DA1E4C;
        opacity: 0.6;
    }

    &__card-heading{
        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-bottom: 15px;
        span{
            font-h3-ls(25px, font-color-light);
        }
    }

    &__card-channels{
        margin-bottom: 30px;
        width: fit-content;

        button {
            padding: 0;
            font-p(12px, #E9FCEE);
            background-color: transparent;

            &:hover{
                color: #12B845;
            }
        }
    }

    &__card-button{
        button_green_shadow();

        div{
            display: none;
        }
    }

    &__card.active &__card-img-bg{
        background: #00551D;
    }

    &__card.active:hover &__card-img-bg{
        background: #DA1E4C;
    }

    &__card.active &__card-channels{
        margin-bottom: 44px;
    }

    &__card.active &__card-button{
        button{
            display: none;
        }

        div{
            display: flex;
            align-items: center;
            gap: 10px;
        }

        span{
                font-p-20-500(font-color-light);
            }

        .checkbox{
            height: 22px;
            width: 22px;

            border-radius: 2px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .checkbox_check{
            display: flex;
            .checkbox{
                background: #12B845;
            }
        }

        .checkbox_close{
            display: none
            .checkbox{
                cursor: pointer;
                background: #DA1E4C;
            }

            span{
                color: rgba(245, 241, 248, 0.45) !important;
            }
        }
    }

    &__card.active:hover &__card-button{
        .checkbox_check{
            display: none;
        }
        .checkbox_close{
            display: flex;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0;
            margin-bottom: 80px;
        }

        .heading {
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 20px;

            h2 {
                font-h2(17px, font-color-dark);
            }
        }

        &__content{
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 35px;

            p{
                max-width: 100%;

                text-align: left;
                font-p(15px, font-color-normal);
            }
        }

        &__wrap{
            justify-content: flex-start;

            position: relative;

            height: 487px;

            overflow: hidden;

            margin-bottom: 45px;
        }

        &__card-wrap{
            transition: left 1s;
            flex-wrap: nowrap;
            gap: 10px;

            max-width: fit-content;

            position: absolute;
            left: 10px;
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.smotreshka-physical-cinema{
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 240px;
    }

    .heading {
        display: flex;
        justify-content: center;

        margin-bottom: 25px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    .heading-3{
        margin-bottom: 65px;

        h3{
            font-h2(28px, font-color-dark);
        }
    }

    &__content{
        display: flex;
        justify-content: center;

        margin-bottom: 80px;

        p{
            max-width: 813px;
            margin-bottom: 0;

            text-align: center;
            font-p(17px, font-color-normal);

            span{
                font-p-500(17px, font-color-normal);
            }
        }
    }

    &__gallery{
        margin-bottom: 110px;
    }

    &__wrap{
        display: flex;
        justify-content: center;
    }

    &__card-wrap{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        max-width: 1063px;

        position: static;
    }

    &__card{
        overflow: hidden;

        width: 262px;
        height: 487px;

        display: flex;
        flex-direction: column;

        position: relative;

        background: linear-gradient(360deg, #12B845 -3.85%, #007A33 17.9%, #006527 32.43%, #00551D 47.29%);
        border-radius: 6px;

        padding: 291px 20px 45px;
    }

    &__card-img,
    &__card-img-bg{
        position: absolute;

        top: 0;
        left: 0;

        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__card-img-bg{
        height: 100%;
        width: 100%;

        // background: #DA1E4C;
        opacity: 0.6;
    }

    &__card-heading{
        border-bottom: 1px solid rgba(245, 241, 248, 0.25);
        margin-bottom: 15px;
        span{
            font-h3-ls(25px, font-color-light);
        }
    }

    &__card-channels{
        margin-bottom: 35px;
        width: fit-content;

        button {
            padding: 0;
            font-p(12px, #E9FCEE);
            background-color: transparent;

            &:hover{
                color: #12B845;
            }
        }
    }

    &__card-button{
        button_green_shadow();

        div{
            display: none;
        }
    }

    &__card.active &__card-img-bg{
        background: #00551D;
    }

    &__card.active:hover &__card-img-bg{
        background: #DA1E4C;
    }

    &__card.active &__card-channels{
        margin-bottom: 44px;
    }

    &__card.active &__card-button{
        button{
            display: none;
        }

        div{
            display: flex;
            align-items: center;
            gap: 10px;
        }

        span{
                font-p-20-500(font-color-light);
            }

        .checkbox{
            height: 22px;
            width: 22px;

            border-radius: 2px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .checkbox_check{
            display: flex;
            .checkbox{
                background: #12B845;
            }
        }

        .checkbox_close{
            display: none
            .checkbox{
                cursor: pointer;
                background: #DA1E4C;
            }

            span{
                color: rgba(245, 241, 248, 0.45) !important;
            }
        }
    }

    &__card.active:hover &__card-button{
        .checkbox_check{
            display: none;
        }
        .checkbox_close{
            display: flex;
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0;
            margin-bottom: 80px;
        }

        .heading {
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 20px;

            h2 {
                font-h2(17px, font-color-dark);
            }
        }

        &__content{
            justify-content: flex-start;

            padding: 0 30px;
            margin-bottom: 35px;

            p{
                max-width: 100%;

                text-align: left;
                font-p(15px, font-color-normal);
            }
        }

        &__gallery{
            margin-bottom: 80px;
        }

        &__wrap{
            justify-content: flex-start;

            position: relative;

            height: 487px;

            overflow: hidden;

            margin-bottom: 45px;
        }

        &__card-wrap{
            transition: left 1s;
            flex-wrap: nowrap;
            gap: 10px;

            max-width: fit-content;

            position: absolute;
            left: 10px;
        }

        &__products-buttons{
            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }
    }
}

.smotreshka-physical-connect{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px 0px;
        margin-bottom: 240px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2{
            font-h2(36px, font-color-dark);
        }
    }

    &__content{
        display: flex;
        gap: 115px;
    }

    &__content-Instruction-heading{
        margin-bottom: 35px;

        h3{
            margin-bottom: 0px;
            text-align: left;

            font-h2(28px, font-color-dark);
        }
    }

    &__content-Instruction-text{
        max-width: 552px;

        margin-bottom: 60px;

        p{
            margin-bottom: 0;

            font-p(17px, font-color-normal);
        }
    }

    &__content-Instruction-button{
        button{
            button_green();
        }
    }

    &__content-Instruction-button-mobile{
        display: none;
    }

    &__content-icons{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        max-width: 530px;
        min-width: 530px;

        position: static;
    }

    &__card{
        cursor: pointer;
        border: 1px solid #F8F8F8;
        border-radius: 6px;
        box-shadow: 0px 8px 20px rgba(210, 210, 210, 0.3);

        overflow: hidden;

        max-width: 260px;
        min-width: 260px;
        height: 255px;
        padding: 50px 45px 45px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__card-img{
        height: 42px;

        display: flex;
        justify-content: center;

        margin-bottom: 36px;

        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &__card-heading{
        padding-bottom: 5px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 15px;

        width: 100%;
        text-align: center;

        h4{
            margin-bottom: 0px;
            font-h3(17px, font-color-normal);
        }
    }

    &__card-text{
        text-align: center;

        p{
            margin-bottom: 0px;

            font-p(12px, #848484 !important);
            line-height: 150%;
        }
    }

    &__products-buttons{
        display: none;
    }

    &__modal{
        position: fixed;
        background-color: rgba(0, 0, 0, 0.6);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;

        display: none;
        justify-content: center;
        align-items: center;

        &.active{
            display: flex;
        }
    }

    &__modal-window{
        // min-width: 523px;
        max-width: 523px;

        background: #FFFFFF;
        border: 1px solid #F8F8F8;
        box-shadow: 0 8px 20px rgba(210, 210, 210, 0.3);
        border-radius: 6px;

        position: relative;

        padding: 75px 75px 85px;
    }

    &__modal-close{
        position: absolute;
        top: 22px;
        right: 22px;

        width: 15px;
        height: 15px;

        display: flex;
        justify-content: center;

        cursor: pointer;

        span{
            position: absolute;
            top: 6px;

            width: 22px;
            height: 1px;
            background-color: #C6C6C6;

            &:nth-child(1){
                transform: rotate(45deg);
            }
            &:nth-child(2){
                transform: rotate(-45deg);
            }
        }

        &:hover{
            span{
                background-color: #535353;
            }
        }
    }

    &__modal-logo{
        height: 42px;
        margin-bottom: 61px;

        display: flex;
        align-items: center;

        position: relative;
        left: -13px;

        img{
            display: block;
            height: 100%
            width: auto;
        }

        span{
            font-p-500(30px, font-color-dark);
            line-height: 135%;
            letter-spacing: 0.02em;
        }
    }

    &__modal-heading{
        margin-bottom: 40px;

        h3{
            text-align: left;
            margin-bottom: 0;

            font-h2(28px, font-color-dark);
        }
    }

    &__modal-manual{
        margin-bottom: 25px;

        p{
            margin-bottom: 0;

            font-p(17px, font-color-normal);
        }
    }

    &__modal-links{
        a{
            display: block;
            height: 100%;
            text-decoration: none;

            font-p(17px, font-color-green);
        }

        li{
            margin-bottom: 10px;
        }

        li:last-child{
            margin-bottom: 0;
        }
    }

    @media (max-width: 1679px){
        &__page{
            margin-bottom: 160px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            max-width: 100%;

            padding: 0px;
            margin-bottom: 80px;
        }

        .heading{
            margin: 0px 30px 0px;
            padding-bottom: 10px;
            margin-bottom: 35px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__content{
            flex-direction: column;
            gap: 0;
        }

        &__content-Instruction{
            margin: 0px 30px 0px;
        }

        &__content-Instruction-heading{
            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 20px;

            h3{
                font-size: 17px;
                line-height: 150%;
            }
        }

        &__content-Instruction-text{
            max-width: 100%;

            margin-bottom: 30px;

            p{
                font-p(15px, font-color-normal);
            }
        }

        &__content-Instruction-button{
            display: none;
        }

        &__content-Instruction-button-mobile{
            display: block;

            margin: 0px 30px 0px;

            button{
                button_green();
            }
        }

        &__content-icons-wrap{
            width: 100%;
            height: 295px;
            overflow: hidden;

            margin-bottom: 20px;

            position relative;
        }

        &__content-icons{
            flex-wrap: nowrap;

            max-width: 100%;
            min-width: 100%;

            position absolute;
            left: 10px;
            top: 15px;

            transition: left 1s;
        }

        &__products-buttons{
            margin-bottom: 55px;

            display: flex;
            gap: 10px;

            padding-left: 30px;

            .button{
                height: 4px;
                width: 35px;
                border-radius: 2px;

                background-color: #E5E5E5;

                div{
                    height: 4px;
                    width: 20px;
                    border-radius: 2px;
                }

                &_active div{
                    background: linear-gradient(90deg, #3EAB60 0%, #007A33 98.75%);
                }

                &_passive div{
                    background-color: #E5E5E5;
                }
            }
        }

        &__modal-window{
            padding: 30px 20px 45px;
        }

        &__modal-close{
            top: 16px;
            right: 16px;
        }

        &__modal-logo{
            height: 30px;
            margin-bottom: 35px;

            left: -20px;

            span{
                font-p-500(20px, font-color-dark);
                line-height: 150%;
            }
        }

        &__modal-heading{
            margin-bottom: 15px;

            h3{
                font-h3(17px, font-color-dark)
            }
        }

        &__modal-manual{
            margin-bottom: 30px;

            p{
                font-p(15px, font-color-normal);
            }
        }

        &__modal-links{
            a{
                font-p(15px, font-color-green);
            }

            li{
                margin-bottom: 20px;
            }

            li:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.smotreshka-physical-package{
    background: url(/src/img/pages/BG_iptv-24tv-package.jpg) no-repeat center, linear-gradient(180deg, #0B2616 0%, #092C18 28%, #08341A 52.86%, #073A1C 73.29%, #073A1C 100%);
    background-size: auto 100%;
    display: flex;
    justify-content: center;

    &__page{
        height: 460px;

        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0px 55px 0px;
    }

    .heading{
        padding-top: 85px;
        margin-bottom: 25px;

        width: 523px;

        h2{
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #FFFFFF !important;
        }
    }

    &__content{
        max-width: 620px;
        margin-bottom: 65px;

        p{
            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 170%;
            letter-spacing: 0.03em;
            color: #E9FCEE;
        }
    }

    &__button{
        button, a{
            display: flex;
            align-items: center;
            padding: 15px 29px 15px 25px;

            gap: 14px;

            background: linear-gradient(180deg, #3EAB60 7.5%, #007A33 120%);
            border-radius: 6px;

            &:hover{
                background: linear-gradient(180deg, #12B845 7.5%, #12B845 120%);
            }

            font-family: 'Fira Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            text-align: right;
            color: #FFFFFF;

            svg{
                path{
                    fill: #FFFFFF;
                }
            }
        }

        a{
            text-decoration: none;
            display: inline;
            cursor: pointer;
        }
    }

    @media (max-width: 1279px){
        background: url(/src/img/pages/BG_iptv_24tv_package_mobile.jpg) no-repeat center, #11301b;
        background-size: auto 100%;
        background-position: center;

        &__page{
            height: 594px;

            padding: 0px 30px 0px;
        }

        .heading{
            padding-top: 292px;
            margin-bottom: 20px;

            width: 100%;

            h2{
                font-size: 17px;
            }
        }

        &__content{
            max-width: 100%;
            margin-bottom: 40px;

            p{
                font-size: 12px;
            }
        }

    }
}

.smotreshka-physical-equipment{
    display: flex;
    justify-content: center;

    &__page{
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 240px 55px 0;
        margin-bottom: 240px;
    }

    .heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2{
            font-h2(36px, font-color-dark);
        }
    }

    &__equipment{
        // margin-bottom: 20px;
    }

    &__equipment-content{
        display: flex;
        gap: 146px;
        margin-bottom: 106px;
    }

    &__equipment-content-heading{
        margin-bottom: 25px;

        h3{
            text-align: left;

            font-h2(28px, font-color-dark);
        }

        svg{
            display: none;
        }
    }

    &__equipment-content-txt{
        margin-bottom: 81px;

        max-width: 704px;

        p{
            margin-bottom: 0;

            font-family: 'Fira Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 170%;
            color: #535353;
        }
    }

    &__equipment-content-price{
        display: flex;
        align-items: center;

        &_green{
            padding-right: 35px;
            border-right: 1px solid #E5E5E5;
            margin-right: 35px;

            font-h2(36px, font-color-green);
        }

        &_green-small{
            max-width: 74px;

            font-p-500(17px, font-color-green);
            line-height: 140%;
        }
    }

    &__equipment-content-img{
        height: 267px;

        img{
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &__data{
        display: flex;
        justify-content: space-between;
    }

    &__data-accordion_1, &__data-accordion_2{
        span{
            font-h2(28px, font-color-dark);
        }

        ul{
            li{
                margin-bottom: 5px;
                position relative;
                padding-left: 13px;

                font-family: 'Fira Sans', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 170%;
                color: #535353;



                &:before{
                    position absolute;
                    top: 13px;
                    left: 0px;

                    content: '';
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #535353
                }

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__data-accordion-heading{
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        padding-right: 25px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;
    }

    &__data-accordion-content{
        transition: all 0.3s;
        padding-top: 0;
        ul li{
            transition: all 0.3s;
            margin-bottom: 0;
            line-height: 0%;

            color: transparent;

            &:before{
                height: 0;
            }
        }

        &.active{
            padding-top: 25px;

            ul li{
                line-height: 170%;
                margin-bottom: 5px;
                color: #535353;
                &:before{
                    height: 3px;
                }
            }
        }
    }

    &__data-accordion_1{
        flex: 1 0 100%;
        max-width: 791px;
    }

    &__data-accordion_2{
        flex: 1 0 100%;
        max-width: 388px;
    }

    &__plus{
        height: 17px;
        width: 17px;

        //margin-right: 25px;

        position: relative;

        span{
            display: block;
            width: 17px;
            height: 1px;
            background-color: #007A33;

            position: absolute;
            left: 0;
            top: 8px;

            transition: all 0.3s;

            &:nth-child(1){
                transform: rotate(90deg);
            }
        }
        &.active{
            span{
                &:nth-child(1){
                    transform: rotate(0deg);
                }
            }
        }
    }

    @media (max-width: 1679px){
        &__page{
            padding: 160px 55px 0;
            margin-bottom: 160px;
        }

        &__equipment-content{
            gap: 189px;
        }

        &__equipment-content-heading,
        &__equipment-content-txt{
            max-width: 496px;
        }

        &__data-accordion_1{
            max-width: 578px;
        }

        &__data-accordion_2{
            max-width: 479px;
        }
    }

    @media (max-width: 1279px){
        &__page{
            flex: 1 0 100%;
            max-width: 1440px;

            padding: 80px 30px 0;

            margin-bottom: 80px;
        }

        .heading{
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2{
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__equipment-content{
            gap: 0;
            flex-direction: column-reverse;
            justify-content: space-between;

            margin-bottom: 40px;
        }

        &__equipment-content-heading{
            max-width: 100%;
            display: flex;
            justify-content: space-between;

            padding-bottom: 5px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;

            h3{
                padding-right: 10px;
                margin-bottom: 0;

                font-size: 17px;
                line-height: 150%;
            }

            svg{
                transition: all 0.3s;

                display: block;
                align-self: flex-end;
                transform: rotate(90deg);

                margin-right: 6px;
                margin-bottom: 6px;

                &.active{
                    transform: rotate(270deg);

                    path{
                        stroke: #E5E5E5;
                    }
                }
            }
        }

        &__equipment-content-txt{
            max-width: 100%;

            transition: all 0.3s;
            padding-top: 0;
            margin-bottom: 0;

            p{
                transition: all 0.3s;
                font-size: 15px;
                line-height: 0%;
                color: transparent;
            }

            &.active{
                padding-top: 25px;

                p{
                    line-height: 150%;
                    color: #535353;
                }
            }
        }

        &__equipment-content-price{
            display: flex;
            align-items: center;

            padding-top: 25px;

            &_green{
                font-size: 20px;
                line-height: 150%;
            }

            &_green-small{
                max-width: 100%;

                font-size: 15px;
                line-height: 170%;
            }
        }

        &__equipment-content-img{
            margin-bottom: 23px;

            max-width: 480px;
            height: 156px;
        }

        &__data{
            flex-direction: column;
        }

        &__data-accordion_1, &__data-accordion_2{
            max-width: 100%;

            span{
                font-size: 17px;
                line-height: 150%;
            }

            ul{
                margin-top: 5px;

                li{
                    font-size: 15px;

                    &:before{
                        top: 11px;
                    }
                }
            }
        }

        &__data-accordion_1{
            margin-bottom: 30px;
        }

        &__data-accordion-heading{
            padding-bottom: 5px;
            padding-right: 6px;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;
        }
    }
}

.smotreshka-physical-advantages {
    display: flex;
    justify-content: center;

    &__page {
        flex: 1 0 100%;
        max-width: 1440px;

        padding: 0 55px;
        margin-bottom: 245px;
    }

    .heading {
        padding-bottom: 15px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 110px;

        h2 {
            font-h2(36px, font-color-dark);
        }
    }

    &__card {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 50px 15px;

            li {
                flex: 1 0 100%;
                max-width: 388px;

                display: flex;

                font-p(17px, font-color-normal);

                span:first-child{
                    min-width: 35px;

                    font-p-25-500(font-color-green)
                }
            }

            li:nth-child(n+4){
                span:last-child{
                    align-self: center;
                }
            }

            span{
                display: block;
            }
        }
    }

    @media (max-width: 1679px) {
         &__card {
            ul {
                li {
                    max-width: 380px;
                }
            }
        }
    }

    @media (max-width: 1279px) {
        &__page {
            max-width: 100%;

            padding: 0 30px;
            margin-bottom: 80px;
        }

        .heading {
            padding-bottom: 10px;
            margin-bottom: 30px;

            h2 {
                font-h3(20px, font-color-dark);
            }
        }

        &__card {
            ul {
                gap: 30px;

                li {
                    max-width: 100%;

                    font-p(15px, font-color-normal);
                }

                li:nth-child(n+3){
                    span:last-child{
                        align-self: center;
                    }
                }
            }
        }
    }
}

.smotreshka-physical-cart{
    z-index: 10;

    width: 100%;

    // display: flex;
    display: none;
    justify-content: center;

    position: fixed;
    left: 0;
    bottom: 0;

    &__page{
        background: white;
        flex: 1 0 100%;
        max-width: 927px;

        border: 1px solid #F8F8F8;
        box-shadow: 0px -2px 25px rgba(210, 210, 210, 0.3);
        border-radius: 6px;
    }

    &__total{
        height: 105px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0px 75px;
    }

    &__total-summ{
        display: flex;
        align-items: center;
        gap: 25px;

        span{
            font-p-20-500(font-color-dark);
        }

        svg{
            transition: transform 0.3s;
        }
        .active{
            svg{
                transform: rotate(180deg);
            }
        }
    }

    &__total-summ_mobile{
        display: none;
    }

    &__total-summ-txt{
        display: flex;
        align-items: center;
        gap: 5px;

        border-bottom: 1px dashed rgba(32, 32, 32, 0.6);

        cursor: pointer;

        svg{
            display: block;
        }
    }

    &__total_button{
        // button_green_light();
        button_green_light_revers();
    }

    &__content{
        background: #F8F8F8;

        padding: 30px 75px 25px;

        &.passive{
            display: none;
        }
    }

    &__content-head{
        font-p(12px, #848484!important);

        display: flex;
        gap: 120px;

        margin-bottom: 5px;

        &_1, &_2{
            display: flex;
            justify-content: space-between;
        }

        &_1{
            width: 388px;
        }

        &_2{
            width: 269px;
        }
    }

    &__content-rate-wrap,
    &__content-rate-wrap-base,
    &__content-rate-wrap-base_err{
        height: 69px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-top: 1px solid #E5E5E5;
    }

    //  базовый тариф
    &__content-rate-wrap-base{
        display: none;
        button{
            padding: 0 !important;
        }
    }

    //  пакеты
    &__content-rate-wrap{
        display: none;
    }

    &__content-rate{
        display: flex;
        gap: 120px;

        &_1{
            width: 388px;
            display: flex;
            justify-content: space-between;

            font-p-500(17px, font-color-normal);
        }
    }

    &__content-rate-button-modal{
        button {
            background-color: transparent;
            padding: 0 !important;

            span{
                font-p(17px, font-color-green);
            }
        }

        button:hover{
            span{
                font-p(17px, font-color-green-hover);
            }
        }
    }

    &__content-button-close{
        margin-right: 23px;
        padding: 0 !important;

        background-color: transparent;

        height: 15px;
        width: 15px;

        position: relative;

        display: flex;
        justify-content: center;

        span{
            position: absolute;
            top: 7px;

            display: block;
            width: 20px;
            height: 1px;
            background-color: #848484;
        }

        span:first-child{
            transform: rotate(45deg);
        }

        span:last-child{
            transform: rotate(-45deg);
        }
    }

    &__content-rate-wrap-base_err &__content-rate_1{
        button{
            padding: 0 !important;
            background-color: transparent;
        }
        span{
            font-p-500(17px, font-color-green);
        }
        span:hover{
            font-p-500(17px, font-color-green-hover);
        }
    }

    &__content-rate-wrap-base_err &__content-rate_2{
        span{
            display: flex;
            gap: 10px;

            font-p(12px, #DA1E4C !important);
        }

        span::before{
            content: '!';
            display: flex;
            justify-content: center;
            align-items: center;

            height: 20px;
            min-width: 20px;
            border-radius: 50%;
            background-color: #DA1E4C;

            color: white;
            font-size: 15px;
        }
    }

    &__grey{
        font-p(17px, #848484 !important);
    }

    @media (max-width: 927px){
        &__page{
            max-width: 100%;
        }

        &__total{
            height: 95px;

            padding: 0px 20px;
        }

        &__total-summ{
            display: none;
        }

        &__total-summ_mobile{
            display: flex;

            span{
                font-p-500(17px, font-color-dark);
            }
        }

        &__content{
            padding: 0 20px;
        }

        &__content-head{
            display: none;
        }

        &__content-rate-wrap,
        &__content-rate-wrap-base,
        &__content-rate-wrap-base_err{
            height: auto;
            padding: 25px 0 20px;
        }

        &__content-rate-wrap-base,
        &__content-rate-wrap-base_err{
            border-top: none;
        }

        &__content-rate-wrap-base &__content-rate_1{
            div{
                display: flex;
                flex-direction: column;
            }
        }

        &__content-rate-wrap-base_err &__content-rate_1{
            button{
                background-color: transparent;
            }
            span{
                font-p-500(15px, font-color-green);
            }
            span:hover{
                font-p-500(15px, font-color-green-hover);
            }
        }

        &__content-rate-wrap-base_err &__content-rate{
            gap: 15px;
        }

        &__content-rate{
            flex-direction: column;
            gap: 5px;

            &_1{
                width: 221px;

                font-p-500(15px, font-color-normal);
            }
        }

        &__content-rate-button-modal{
            button {
                background-color: transparent;
                padding: 0 !important;

                span{
                    font-p(15px, font-color-green);
                }
            }

            button:hover{
                span{
                    font-p(15px, font-color-green-hover);
                }
            }
        }

        &__grey{
            font-p(15px, #848484 !important);
        }
    }
}
