.sokol_h2{
  line-height: 36px;
  color: #1C1C1C;
  margin-bottom: 20px;
  font-size: 24px;
  
  @media (max-width: 425px){
    text-align: center;
  }
}
.sokol_blcommon{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;

  @media (max-width: 768px){
    display: block;

    div{
      margin-bottom: 30px;
      text-align: center;
    }
  }
}

.sokol_bl2{
  .sbl2_left{
    margin-top: 74px;
    width: 560px;

    .table{
      margin-bottom: 20px;

      .thead{
        .th-col{
          padding-left: 25px !important;
        }
      }
      .th-col{
        padding: 25px 0 25px 25px;
        color: #5F5F5F;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;

        &:first-child{
          flex: 54% 0 0;
        }
        &:last-child{
          flex: 46% 0 0;
        }

        .number{
          width: 40px
          height: 40px
          background-color: #1C1C1C;
          color: #fff;
          border-radius: 50%;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 25px;
          font-weight: 700;
        }

        &.price{
          font-size: 24px;
          .oldprice{
            color: #888888;
            margin-right: 22px;
            &:before{
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              background-color: #888;
              transform: rotate(-20deg);
              transform-origin: center;
            }
          }
          .newprice{
            font-weight: 700;
            i{
              font-style: normal;
              font-size: 16px;
              color: #1C1C1C;
              bottom: 7px;
            }
          }
        }
      }
    }

    .sbl2_ps{
      color: #B6B6B6;
      font-size: 14px;
      text-align: right;
      i{
        font-style: normal;
        font-size: 16px;
        color: #1C1C1C;
        bottom: 7px;
      }
    }
  }
  .sbl2_right{
    position: absolute
    right: -112px
    top: -74px;
  }
  
  @media (max-width: 1200px){
    .sbl2_right{
      position: relative;
      right: auto;
      top: auto;
    }
  }
  @media (max-width: 600px){
    .sbl2_left{
      width: 100%;
    }

    .sbl2_right{
      img{
        width: 100%;
      }
    }
  }
  @media (max-width: 425px){
    .sbl2_left{
      .table{
        .th-col{
          padding: 0;
          line-height: 20px;
          &:last-child{
            justify-content: center;
          }

          .number{
            display: none;
          }

          &.price{
            font-size: 16px;
            .oldprice{
              margin-right: 10px;
            }
          }
        }
      }

      .sbl2_ps{
        font-size: 11px;
        i{
          bottom: 2px;
        }
      }
    }
  }
}

.sokol_house-incost{
  margin: 75px 0 85px;
  h3{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.sokol_advantages-vols{
  margin-top: 80px;

  .sokol_bl3{
    margin-top: 45px;
    display: flex;

    .sbl3-item{
      flex: 1;
      margin-right: 25px;
      &:last-child{
        margin-right: 0;
        .sbl3-head{
          .string{
            display: none;
          }
        }
      }

      .sbl3-head{
        margin-bottom: 25px;

        .number{
          width: 40px
          height: 40px
          background-color: #93B60D;
          color: #fff;
          border-radius: 50%;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
        }
        .string{
          position: absolute;
          top: 50%;
          height: 1px;
          right: 0;
          width: calc(100% - 50px);
          background-color: #EBEBEB;
        }
      }

      p{
        margin-bottom: 0;
        &.pbold{
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
    }

    @media (max-width: 1000px){
      flex-wrap: wrap;
      justify-content: space-around;

      .sbl3-item{
        flex: 30% 0 0;
        margin: 0 0 20px;
        .sbl3-head{
          text-align: center;
          
          .string{
            display: none;
          }
          .number{
            display: inline-flex;
          }
        }
        p{
          text-align: center
        }
      }
    }

    @media (max-width: 680px){
      .sbl3-item{
        flex: 48% 0 0;
      }
    }
    @media (max-width: 425px){
      .sbl3-item{
        flex: 100% 0 0;

        .sbl3-head{
          margin-bottom: 15px;
          .number{
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}

.sokol-tariffs{
  .sokol-tariff{
    h2{
      color: #1C1C1C !important;
      margin-bottom: 20px;
      line-height: 24px;
      font-size: 20px;

      @media (max-width: 425px){
        font-size: 16px;
      }
    }

    .table{
      .th-col{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: left;
        padding-left: 25px !important;
        padding-right: 25px !important;
        div{
          width: 100%;
          &.trg{
            font-size: 24px;
            line-height: 36px;
          }
        }

        .corr{
          color: #5f5f5f;
          font-size: 16px;
          @media (max-width: 425px){
            font-size: 14px;
          }
        }
      }
      .tbody{
        .trow{
          .th-col{
            &:first-child{
              font-size: 24px;
            }
          }
        }
      }
      
      @media (max-width: 900px){
        .thead{
          display: none;
        }
        .trow{
          flex-wrap: wrap;
          justify-content: space-around;
        }
        .th-col{
          flex: 33% 0 0 !important;
        }
      }
      @media (max-width: 425px){
        .th-col{
          flex: 50% 0 0 !important;
          justify-content: center !important;
          text-align: center;

          &.sti-btn{
            padding-top: 10px !important;
          }
        }
      }
    }

    &--internet{
      .table{
        .sti-name{
          flex: 23% 1 1;
          padding-left: 25px !important;
        }
        .sti-btn{
          flex: 35% 1 1;
          justify-content: flex-end;
          padding-right: 45px !important;
          text-align: right;
        }
        .sti-traffic, .sti-speed, .sti-cost{
          flex: 130px 0 0;
        }
      }
      
      @media (max-width: 425px){
        .table{
          .sti-btn{
            padding-right: 25px !important;
          }
        }
      }
    }

    &--telephony{
      @media (max-width: 425px){
        .table{
          .th-col{
            &:first-child{
              flex: 100% 0 0 !important;
            }
          }
        }
      }
    }

    &--television{
      .table{
        margin-bottom: 25px;

        .sti-channels{
          font-size: 24px;
          color: #1C1C1C;

          i{
            font-style: normal;
            font-size: 16px;
            color: #1C1C1C;
            bottom: 7px;
          }
        }
      }

      .st_ps{
        margin-left: 27%;
        font-size: 14px;
        color: #B6B6B6;

        i{
          font-style: normal;
          font-size: 16px;
          color: #1C1C1C;
          bottom: 7px;
        }
      }

      @media (max-width: 425px){
        .table{
          .th-col{
            &:first-child{
              flex: 100% 0 0 !important;
            }
          }
        }
        
        .st_ps{
          margin-left: 0;
          text-align: right;
        }
      }
    }

    &--cloud{
      margin-top: 60px;

      .sc-bl1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 24px;

        img{
          margin-right: 9%;
        }

        @media (max-width: 1000px){
          flex-wrap: wrap;

          div{
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
          }
          img{
            margin: 0 auto;
            display: inline-block;
          }
        }
      }

      .sc-bl2{
        margin-top: 45px;
        margin-bottom: 55px;

        .sc-app{
          border: 1px solid #EBEBEB;
          border-radius: 3px;
          padding: 20px;
          align-items: center;
          width: 276px;
          display: inline-flex;
          &:first-child{
            margin-right: 30px;
          }

          a{
            margin-left: 21px;
          }
        }

        @media (max-width: 620px){
          .sc-app{
            display: flex;
            margin: 0 auto 20px;
            &:first-child{
              margin-right: auto;
            }
          }
        }
      }

      .table{
        .sti-time{
          .time{
            font-size: 24px;
            color: #1C1C1C;
          }
        }
      }

      @media (max-width: 425px){
        .table{
          .th-col{
            &:first-child{
              flex: 100% 0 0 !important;
            }
          }
        }
      }
    }
  }
}
  
.show-mobile{
  display: none;
  
  @media (max-width: 425px){
    display: block;
  }
}

.hide-mobile{
  display: block;

  @media (max-width: 425px){
    display: none;
  }
}










